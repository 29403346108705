// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import { atom } from "recoil";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useState, useEffect } from "react";
import Loading from "./components/Loading";
import axiosInstance from "./services/interceptore";

export const authAtom = atom({
  key: "auth",
  // get initial state from local storage to enable user to stay logged in
  default: JSON.parse(localStorage.getItem("user")),
});
// ----------------------------------------------------------------------

export const phoneRegex = "^(+d{ 1, 2 } s)?(?d{ 3 } )?[s.-]d{ 3 } [s.-]d{ 4 }$";

export default function App() {
  // const auth = useRecoilValue(authAtom);
  // const navigate = useNavigate();

  // useEffect(() => {
  //     if (!auth) {
  //     // not logged in so redirect to login page with the return url
  //     navigate('/login', { replace: true });
  //     }
  //     console.log(auth)
  // }, []); //
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config) => {
        setLoading(true);
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
        return response;
      },
      (error) => {
        setLoading(false);
        console.error("Error Interceptor:", error);
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  return (
    <ThemeProvider>
      <Loading show={loading} />
      <ScrollToTop />

      <BaseOptionChartStyle />
      <Router />
    </ThemeProvider>
  );
}
