import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField,FormSelect } from '../../../components/hook-form';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// ----------------------------------------------------------------------

export default function RegisterForm() {
    const navigate = useNavigate();
    const [state, setState] = useState('');

    const [city, setCity] = useState('');


    const [showPassword, setShowPassword] = useState(false);

    const RegisterSchema = Yup.object().shape({
        firstName: Yup.string().required('First name required'),
        lastName: Yup.string().required('Last name required'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
        userName: Yup.string().required('Username name required'),
        mobNo: Yup.string().required('Mobile number is required'),
        pincode: Yup.string().required('Pincode Required'),
        address: Yup.string().required('Address is required'),
        panNumber: Yup.string().required('PAN number Required'),
        registrationNumber: Yup.string().required('Registration number Required'),
        gstn: Yup.string().required('GSTN Required'),
    });

    const defaultValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async () => {
        navigate('/dashboard', { replace: true });
    };

    const handleChangeState = (event) => {
        setState(event.target.value);
    };

    const handleChangeCity = (event) => {
        setCity(event.target.value);
    };

    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <RHFTextField name="firstName" label="First name" />
                    <RHFTextField name="lastName" label="Last name" />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <RHFTextField name="panNumber" label="Pancard Number" />
                    <RHFTextField name="registrationNumber" label="Registration Number" />
                    <RHFTextField name="gstn" label="GST Number" />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <RHFTextField name="mobNo" label="Mobile Number" />
                    <RHFTextField name="email" label="Email Address" />
                </Stack>

                {/*  <RHFTextField name="email" label="Email address" />*/}

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <RHFTextField name="address" multiline={true} rows={4} label="address" style={{ width: 570 }} />
                    <Stack direction={{ xs: 'row', sm: 'column' }} spacing={2}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                            <FormControl fullWidth>
                                <InputLabel id="state">State</InputLabel>
                                <Select
                                    labelId="state"
                                    id="state"
                                    name="state"
                                    value={state}
                                    label="State"
                                    onChange={handleChangeState}
                                >
                                    <MenuItem value={10}>Maharashtra</MenuItem>
                                    <MenuItem value={21}>Delhi</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="city">City</InputLabel>
                                <Select
                                    labelId="city"
                                    id="city"
                                    name="city"
                                    value={city}
                                    label="City"
                                    onChange={handleChangeCity}
                                >
                                    <MenuItem value={10}>Mumbai</MenuItem>
                                    <MenuItem value={21}>Noida</MenuItem>
                                </Select>
                            </FormControl>


                        </Stack>
                        <RHFTextField name="pincode" label="Pincode" style={{ width: 570 }} />
                    </Stack>
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <RHFTextField name="userName" label="Username (Email)" />
                    <RHFTextField
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Stack>


                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                    Register
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
