import axios from "axios";
import { configUrl } from "../config.js";
import axiosInstance from "./interceptore.js";
// const userToken = localStorage.getItem("userToken");
// const axiosInstance = axios.create({
//   baseURL: configUrl.StrataServer,
//   // headers: {
//   //   Authorization: `Bearer ${userToken}`,
//   // },
// });

const GetAllMedicines = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/MedicineStock/GetAllMedicines?clientId=${clientId}`
  );

  console.log(response);
  return response.data;
};

const AddMedicines = async (data) => {
  let response = await axiosInstance.post(
    `/api/MedicineStock/AddMedicines`,
    data
  );

  console.log(response);
  return response.data;
};

const GetAllMedicinesByName = async (clientId, query) => {
  let response = await axiosInstance.get(
    `/api/MedicineStock/getAllMedicinesByName/${clientId}?query=${query}`
  );

  console.log(response);
  return response.data;
};

const GetMedicineDetails = async (medId) => {
  let response = await axiosInstance.get(
    `/api/MedicineStock/GetMedicineDetails?medicineId=${medId}`
  );

  console.log(response);
  return response.data;
};

const UpdateMedicines = async (data) => {
  let response = await axiosInstance.put(
    `/api/MedicineStock/UpdateMedicines`,
    data
  );

  console.log(response);
  return response.data;
};

export default {
  GetAllMedicines: GetAllMedicines,
  AddMedicines: AddMedicines,
  GetAllMedicinesByName: GetAllMedicinesByName,
  GetMedicineDetails: GetMedicineDetails,
  UpdateMedicines: UpdateMedicines,
};
