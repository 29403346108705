import axios from "axios";
import { configUrl } from "../config.js";
import { Buffer } from "buffer";
import axiosInstance from "./interceptore.js";
// const userToken = localStorage.getItem("userToken");

// const axiosInstance = axios.create({
//   baseURL: configUrl.StrataServer,
//   // headers: {
//   //   Authorization: `Bearer ${userToken}`,
//   // },
// });

const GetAllDoctors = async () => {
  let response = await axiosInstance.get(`/api/doctor/GetAllDoctors`);

  console.log(response.data.data.result);
  return response.data;
};
const GetAllStates = async () => {
  let response = await axiosInstance.get(`/api/util/GetAllStates`);
  console.log(response);
  return response.data;
};
const GetAllCities = async (cities) => {
  if (cities) {
    let response = await axiosInstance.get(
      `/api/util/GetAllCities?districId=` + cities
    );
    return response.data;
  } else {
    let response = await axiosInstance.get(`/api/util/GetAllCities?districId=`);
    return response.data;
  }
};
const GetAllDistricts = async (stateID) => {
  if (stateID != undefined) {
    let response = await axiosInstance.get(
      `/api/util/GetAllDistricts?stateID=` + stateID
    );
    return response.data;
  } else {
    let response = await axiosInstance.get(
      `/api/util/GetAllDistricts?stateID=`
    );
    return response.data;
  }
};
const UpdateDoctor = async (doctor) => {
  let response = await axiosInstance.put(`/api/doctor/UpdateDoctor`, doctor);
  console.log(response);
  return response.data;
};
const SwitchUpdateDoctor = async (type, id, status) => {
  let response = await axiosInstance.put(
    `/api/Util/UpdateActiveStatus?type=${type}&id=${id}&status=+${status}`
  );
  console.log(response);
  return response.data;
};
const RegisterDoctor = async (doctor) => {
  let response = await axiosInstance.post(`/auth/register`, doctor);
  console.log(response);
  return response.data;
};
const DeleteDoctor = async (id) => {
  let response = await axiosInstance.delete(
    `/api/Doctor/DeleteDoctor?id=${id}`
  );
  console.log(response);
  return response.data;
};

const UploadAttachment = async (file, docId, type) => {
  let formData = new FormData();

  formData.append("file", file);
  let response = await axiosInstance.post(
    `/api/Doctor/UploadAttachment?doctorId=${docId}&type=${type}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  console.log(response);
  return response;
};

const GetFile = async (docId, type) => {
  let response = await axiosInstance.get(
    `/api/Doctor/getFile?doctorId=${docId}&type=${type}`
  );
  console.log(response);
  return response.data;
};

export default {
  GetAllDoctors: GetAllDoctors,
  UpdateDoctor: UpdateDoctor,
  DeleteDoctor: DeleteDoctor,
  SwitchUpdateDoctor: SwitchUpdateDoctor,
  RegisterDoctor: RegisterDoctor,
  GetAllStates: GetAllStates,
  GetAllCities: GetAllCities,
  GetAllDistricts: GetAllDistricts,
  UploadAttachment: UploadAttachment,
  GetFile: GetFile,
};
