import React, { useEffect, useRef, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Stack,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AbhaService from "../services/AbhaService";
import AbhaPopup from "../components/AbhaPopup";
import DownloadIcon from "@mui/icons-material/Download";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AbhaRegistration = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [aadhaar, setAdharNumber] = useState("");
  // const [token, setToken] = useState("");
  // const [cert, setCert] = useState("");
  const [number, setNumber] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [mobileOtp, setMobileOTP] = useState("");
  const [mobileOtpError, setMobileOtpError] = useState(false);
  const [txnId, setTxnid] = useState("");
  const [abhaAddress, setAbhaAddress] = useState("");
  const [profileData, setProfileData] = useState();
  // const [showAadhaar, setShowAadhaar] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [showNumberOTP, setShowNumberOTP] = useState(false);
  const [show, setShow] = useState(false);
  const [imageString, setImageString] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [showError, setShowError] = useState(false);
  const [newNumberError, setNewNumberError] = useState(false);
  const [isAadharValid, setIsAadharValid] = useState(false);
  const [otpError, setOtpError] = useState(false);
  // const [otpSent, setOtpSent] = useState(false);
  const [showAbhaAddress, setShowAbhaAddress] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [buttonClickCount, setButtonClickCount] = useState(0);
  const [buttonClickCount1, setButtonClickCount1] = useState(0);
  const [genrateToken, setGenrateToken] = useState(false);
  const [remainingTime, setRemainingTime] = useState(30);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const isMobile = useMediaQuery("(max-width:950px)");

  useEffect(() => {
    // generateToken();
    if (!genrateToken) {
      generateToken();
      setGenrateToken(true);
    }
    const interval = setInterval(() => {
      generateToken();
    }, 600000);
  }, []);

  useEffect(() => {
    if (remainingTime > 0 && buttonDisabled) {
      const timerInterval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timerInterval);
    } else if (remainingTime === 0) {
      setButtonDisabled(false);
    }
  }, [remainingTime, buttonDisabled]);

  const generateToken = async () => {
    const response = await AbhaService.generateToken();
    // setToken(response.session.accessToken);
    // setCert(response.cert);
    const accessToken = response.session.accessToken;
    const cert = response.cert;
    localStorage.setItem("token", accessToken);
    localStorage.setItem("cert", cert);
  };
  const token = localStorage.getItem("token");
  const cert = localStorage.getItem("cert");

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setShowError(false);
  };

  // aadharNumbarHandeler

  const aadhaarRefs = useRef([]);

  const handelAadhaarChange = (index, value) => {
    const sanitizedValue = value.replace(/[^\d]/g, "").slice(0, 4);

    const updatedAadharNumber = aadhaar.split("");
    updatedAadharNumber.splice(index * 4, 4, sanitizedValue);
    setAdharNumber(updatedAadharNumber.join(""));

    if (aadhaarRefs.current[index + 1] && sanitizedValue.length === 4) {
      aadhaarRefs.current[index + 1].focus();
    }
  };

  const handleDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !aadhaar[index * 4]) {
      aadhaarRefs.current[index - 1].focus();
    }
  };

  // otpHandeler

  const otpRefs = useRef([]);
  const handleOtpChange = (index, value) => {
    const sanitizedValue = value.replace(/[^\d]/g, "").slice(0, 1);

    setOTP((prevOTP) => {
      const updatedOTP = prevOTP.split("");
      updatedOTP[index] = sanitizedValue;
      return updatedOTP.join("");
    });

    if (otpRefs.current[index + 1] && sanitizedValue.length > 0) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleMobileOtpChange = (index, value) => {
    const sanitizedValue = value.replace(/[^\d]/g, "").slice(0, 1);

    setMobileOTP((prevOTP) => {
      const updatedOTP = prevOTP.split("");
      updatedOTP[index] = sanitizedValue;
      return updatedOTP.join("");
    });

    if (otpRefs.current[index + 1] && sanitizedValue.length > 0) {
      otpRefs.current[index + 1].focus();
    }
  };
  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !mobileOtp[index]) {
      otpRefs.current[index - 1].focus();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handelResend = () => {
  //   if (counter === 0) {
  //     setCounter(60);
  //     resendOTP();
  //   }
  // };

  // const resendOTP = async () => {
  //   try {
  //     setOtpSent(true);

  //     const response = await AbhaService.resendOtp({
  //       aadhaar: aadhaar,
  //       authenticationToken: token,
  //       txnId: txnId,
  //       cert: cert,
  //     });
  //     console.log(response);
  //   } catch (error) {
  //     console.log("something wrong", error);
  //   }
  // };
  const imgUrl = `data:image/png;base64,${imageString}`;
  const fileName = "abhaCard.png";

  const handelRefresh = () => {
    window.location.reload();
  };

  const handelBackAadhaar = () => {
    setShowOTP(false);
    setAdharNumber("");
    setTxnid("");
    setIsAadharValid(false);
    setOtpError(false);
    setButtonClickCount(0);
    setButtonDisabled(false);
    setRemainingTime(30);
    // generateToken();
  };

  const handelBackAadhaarFromMobile = () => {
    setShowOTP(false);
    setAdharNumber("");
    setOTP("");
    setButtonClickCount1(0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handelBackMobile = () => {
    setShowNumberOTP(false);
    setNewNumber("");
    setMobileOtpError(false);
    setButtonClickCount1(0);
  };
  const handleNext = async () => {
    if (activeStep === 0) {
      try {
        if (buttonClickCount === 0) {
          if (aadhaar.trim() === "") {
            setIsAadharValid(true);
          }

          if (token && aadhaar && isChecked) {
            const response = await AbhaService.adharVerify({
              aadhaar: aadhaar,
              authenticationToken: token,
              cert: cert,
            });
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setTxnid(response.txnId);
            setNumber(response.mobileNumber);
            setIsAadharValid(false);
            if (response.code != 422) {
              setShowOTP(true);
              setButtonDisabled(true);
              setButtonClickCount(buttonClickCount + 1);
            } else {
              setOpen(true);
            }
          } else if (!isChecked) {
            setShowError(true);
          }
        } else if (buttonClickCount === 1) {
          if (txnId && showOTP) {
            try {
              if (otp.trim() === "") {
                setOtpError(true);
              } else {
                setOtpError(false);
                const response = await AbhaService.VerifyOtp({
                  otp: otp,
                  authenticationToken: token,
                  txnId: txnId,
                  cert: cert,
                });
                toast.success("Aadhaar OTP Verified", {
                  position: "top-right",
                  toastId: "success1",
                });
                setProfileData(response);

                if (response.new == false) {
                  setShow(true);
                  setButtonClickCount(buttonClickCount + 1);
                } else {
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }

                // setProfileData(response);
                // setActiveStep((prevActiveStep) => prevActiveStep + 1);

                console.log(response);
              }
            } catch (error) {
              console.log("please enter correct otp", error);
            }
          }
        } else if (buttonClickCount === 2) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } catch (error) {
        console.log("something wrong", error);
      }
    } else if (activeStep === 1) {
      // try {
      //   if (otp.trim() === "") {
      //     setOtpError(true);
      //   } else {
      //     const response = await AbhaService.createAbhaNumber({
      //       otp: otp,
      //       authenticationToken: token,
      //       txnId: txnId,
      //       cert: cert,
      //     });
      //     if (response.new == false) {
      //       setShow(true);
      //     } else if (response.new == true) {
      //       setProfileData(response);
      //       setActiveStep((prevActiveStep) => prevActiveStep + 1);
      //     }
      //     console.log(response);
      //   }
      // } catch (error) {
      //   console.log("please enter correct otp", error);
      // }
      // const TempNumber = "8692873260";
      // if (buttonClickCount1 === 0) {
      //   if (newNumber === TempNumber) {
      //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
      //   } else {
      //     setShowNumberOTP(true);
      //     setButtonClickCount1(buttonClickCount1 + 1);
      //   }
      // } else if (buttonClickCount === 1) {
      //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // }

      try {
        if (buttonClickCount1 === 0) {
          if (newNumber.trim() === "") {
            setNewNumberError(true);
          } else {
            setNewNumberError(false);
            const response = await AbhaService.checkAndVerifyNumber({
              authenticationToken: token,
              txnId: txnId,
              mobile: newNumber,
              cert: cert,
            });
            console.log(response);
            if (response.hasOwnProperty("mobileLinked")) {
              toast.success(`${newNumber} is already linked`, {
                position: "top-right",
                toastId: "success1",
              });
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
              return;
            } else {
              toast.warning(
                `${newNumber} is not linked please verify this same with OTP sent to the mobile Number`,
                {
                  position: "top-right",
                  toastId: "success1",
                }
              );
              setShowNumberOTP(true);
              setButtonClickCount1(buttonClickCount1 + 1);
            }
          }
        } else if (buttonClickCount1 === 1) {
          if (mobileOtp.trim() === "") {
            setMobileOtpError(true);
          } else {
            setMobileOtpError(false);
            const response = await AbhaService.verifyMobileOtp({
              otp: mobileOtp,
              authenticationToken: token,
              txnId: txnId,
              cert: cert,
            });
            toast.success("Mobile OTP Verified", {
              position: "top-right",
              toastId: "success1",
            });
            console.log(response);
            if (response.txnId) {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          }
        }
      } catch (err) {
        console.log(err, "somthing wrong");
      }
    } else if (activeStep === 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      console.log(profileData);
    } else if (activeStep === 3) {
      try {
        const response = await AbhaService.createAbhaNumber({
          healthId: abhaAddress,
          authenticationToken: token,
          txnId: txnId,
          cert: cert,
        });
        toast.success(
          "ABHA Card Created Successfully,Please Download Your Card",
          {
            position: "top-right",
            toastId: "success1",
          }
        );
        setShowAbhaAddress(true);
        console.log(response);
        setImageString(response);
      } catch (error) {
        console.log("somting wrong", error);
      }
    }
  };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  const steps = [
    "Enter Adhar Number",
    "Validate Mobile Number",
    "Profile Review",
    "ABHA Number Creation",
  ];

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            {showOTP ? (
              <Stack spacing={1} margin="10px 0 3px 0">
                {show ? (
                  <Stack m={6} spacing={3}>
                    <Typography variant="h4" color="red" textAlign="center">
                      Abha number is already exist!
                    </Typography>
                    <Typography color="blue" textAlign="center" variant="h4">
                      {profileData.healthIdNumber}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack spacing={3} p={4}>
                    <Typography>
                      We just sent an OTP on the Mobile Number {number} linked
                      with your Aadhaar
                    </Typography>

                    <div>
                      {[...Array(6)].map((_, index) => (
                        <TextField
                          key={index}
                          inputRef={(el) => (otpRefs.current[index] = el)}
                          type="text"
                          variant="outlined"
                          size="small"
                          value={otp[index] || ""}
                          onChange={(e) =>
                            handleOtpChange(index, e.target.value)
                          }
                          onKeyDown={(e) => handleKeyDown(index, e)}
                          style={{
                            width: "40px",
                            margin: "2px",
                            border: "1px solid black",
                            borderRadius: "10px",
                          }}
                          inputProps={{ maxLength: 1 }}
                          // error={otpError}
                          // helperText={otpError ? "Otp is required" : ""}
                          required // Add required validation
                        />
                      ))}
                      {otpError && (
                        <p style={{ color: "red", marginBottom: "0" }}>
                          OTP is required
                        </p>
                      )}
                    </div>

                    <span>
                      {" "}
                      Click here to {""}
                      <Button
                        disabled={buttonDisabled}
                        onClick={handelBackAadhaar}
                        style={{ marginRight: "8px" }}
                      >
                        Re-Enter Aadhaar Address
                      </Button>
                      {buttonDisabled && remainingTime}
                    </span>

                    {/* {otpSent ? (
                  <Typography>
                    OTP has been sent. Please wait for the OTP to arrive.
                  </Typography>
                ) : (
                  <>
                    {counter > 0 ? (
                      <span>Resend OTP in {counter} seconds</span>
                    ) : (
                      <Button
                        onClick={handelResend}
                        style={{
                          outline: "none",
                          width: "150px",
                        }}
                      >
                        Resend OTP
                      </Button>
                    )}
                  </>
                )} */}
                  </Stack>
                )}
              </Stack>
            ) : (
              <Stack
                direction={{ xs: "column", sm: "column" }}
                p={3}
                spacing={2}
              >
                <Typography>
                  An OTP will be sent to the registered mobile number
                </Typography>

                <div>
                  <Typography>Enter Aadhaar Number</Typography>
                  {[...Array(3)].map((_, index) => (
                    <TextField
                      key={index}
                      inputRef={(el) => (aadhaarRefs.current[index] = el)}
                      type="text"
                      variant="outlined"
                      size="small"
                      value={aadhaar.slice(index * 4, index * 4 + 4)}
                      onChange={(e) =>
                        handelAadhaarChange(index, e.target.value)
                      }
                      onKeyDown={(e) => handleDown(index, e)}
                      style={{
                        width: isMobile ? "80px" : "100px",
                        margin: isMobile ? "4px" : "5px",
                        border: "1px solid black",
                        borderRadius: "8px",
                      }}
                      inputProps={{ maxLength: 4 }}
                      required // Add required validation
                    />
                  ))}
                  {isAadharValid && (
                    <p style={{ color: "red", marginBottom: "0" }}>
                      Aadhar Number is required
                    </p>
                  )}
                </div>
                <Typography
                  overflow="scroll"
                  height="130px"
                  fontSize="15px"
                  bgcolor="#F1F1F1"
                  p={1}
                >
                  I, hereby declare that I am voluntarily sharing my Aadhaar
                  Number and demographic information issued by UIDAI, with
                  National Health Authority (NHA) for the sole purpose of
                  creation of ABHA number . I understand that my ABHA number can
                  be used and shared for purposes as may be notified by ABDM
                  from time to time including provision of healthcare services.
                  Further, I am aware that my personal identifiable information
                  (Name, Address, Age, Date of Birth, Gender and Photograph) may
                  be made available to the entities working in the National
                  Digital Health Ecosystem (NDHE) which inter alia includes
                  stakeholders and entities such as healthcare professionals
                  (e.g. doctors), facilities (e.g. hospitals, laboratories) and
                  data fiduciaries (e.g. health programmes), which are
                  registered with or linked to the Ayushman Bharat Digital
                  Mission (ABDM), and various processes there under. I authorize
                  NHA to use my Aadhaar number for performing Aadhaar based
                  authentication with UIDAI as per the provisions of the Aadhaar
                  (Targeted Delivery of Financial and other Subsidies, Benefits
                  and Services) Act, 2016 for the aforesaid purpose. I
                  understand that UIDAI will share my e-KYC details, or response
                  of “Yes” with NHA upon successful authentication. I have been
                  duly informed about the option of using other IDs apart from
                  Aadhaar; however, I consciously choose to use Aadhaar number
                  for the purpose of availing benefits across the NDHE. I am
                  aware that my personal identifiable information excluding
                  Aadhaar number / VID number can be used and shared for
                  purposes as mentioned above. I reserve the right to revoke the
                  given consent at any point of time as per provisions of
                  Aadhaar Act and Regulations.
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label=" I agree to give the necessary permission to eOHC to set up my ABHA Health Locker"
                />
                {showError && (
                  <Typography color="red " mb={2}>
                    Consent is required
                  </Typography>
                )}
              </Stack>
            )}
          </>
        );
      case 1:
        return (
          <>
            {showNumberOTP ? (
              <Stack m={6} spacing={2}>
                <Typography>Enter OTP here</Typography>
                <div>
                  {[...Array(6)].map((_, index) => (
                    <TextField
                      key={index}
                      inputRef={(el) => (otpRefs.current[index] = el)}
                      type="text"
                      variant="outlined"
                      size="small"
                      value={mobileOtp[index] || ""}
                      onChange={(e) =>
                        handleMobileOtpChange(index, e.target.value)
                      }
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      style={{
                        width: "40px",
                        margin: "2px",
                        border: "1px solid black",
                        borderRadius: "10px",
                      }}
                      inputProps={{ maxLength: 1 }}
                      // error={otpError}
                      // helperText={otpError ? "Otp is required" : ""}
                      required // Add required validation
                    />
                  ))}
                  {mobileOtpError && (
                    <p style={{ color: "red", marginBottom: "0" }}>
                      OTP is required
                    </p>
                  )}
                </div>
                <span>
                  {" "}
                  Click here to {""}
                  <a
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={handelBackMobile}
                  >
                    Change Mobile Number
                  </a>
                </span>
              </Stack>
            ) : (
              <Stack spacing={2} p={4}>
                <Typography variant="h6">Link Your Mobile </Typography>
                <Typography>
                  Enter the mobile number you want to link with this ABHA
                </Typography>
                <TextField
                  label="Enter Mobile Number"
                  onChange={(e) => setNewNumber(e.target.value)}
                  type="text"
                  variant="outlined"
                  style={{ width: isMobile ? "80%" : "50%" }}
                  error={newNumberError}
                  helperText={newNumberError ? "Mobile Number is required" : ""}
                  required //
                />
                {/* 
                {buttonDisabled ? (
                  <Typography>{remainingTime}</Typography>
                ) : (
                  <span>
                    Click here to {""}
                    <a
                      style={{ cursor: "pointer", color: "blue" }}
                      onClick={handelBackAadhaarFromMobile}
                    >
                      Change Aadhar Number
                    </a>{" "}
                  </span>
                )} */}
              </Stack>
            )}
          </>
        );
      case 2:
        return (
          <>
            <Stack spacing={5} p={4}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={5}>
                <Box flex={1.5}>
                  <Typography>Full Name</Typography>
                  <Typography variant="h6">{profileData.name}</Typography>
                </Box>
                <Box flex={1}>
                  <Typography>Gender</Typography>
                  <Typography variant="h6">{profileData.gender}</Typography>
                </Box>
                <Box flex={1}>
                  <Typography>Date OF Birth</Typography>
                  <Typography variant="h6">{profileData.birthdate}</Typography>
                </Box>
              </Stack>

              <Stack>
                <Typography>Address</Typography>
                <Typography variant="h6">{`${profileData.house},${profileData.landmark},${profileData.locality},${profileData.district},${profileData.state} `}</Typography>
              </Stack>
            </Stack>
          </>
        );
      case 3:
        return (
          <>
            {showAbhaAddress ? (
              <Stack m={6} spacing={4}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <img
                    src={imgUrl}
                    alt="img"
                    style={{
                      width: "700px",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  />
                  <Button
                    variant="contained"
                    href={imgUrl}
                    download={fileName}
                    style={{ width: "150px" }}
                    startIcon={<DownloadIcon />}
                  >
                    Download
                  </Button>
                </Box>
                <span>
                  Click here to {""}
                  <a
                    style={{ cursor: "pointer", color: "blue" }}
                    onClick={handelRefresh}
                  >
                    Create New ABHA Number
                  </a>{" "}
                </span>
              </Stack>
            ) : (
              <Stack m="10px 0 10px 0" p={5}>
                <Typography textAlign="center" variant="h5">
                  Click Below to Create ABHA Address
                </Typography>
              </Stack>
            )}
          </>
          // ) : (
          //   <Stack m={6} spacing={2}>
          //     <Typography>Enter ABHA Address</Typography>
          //     <TextField
          //       variant="standard"
          //       label="ABHA Address"
          //       onChange={(e) => setAbhaAddress(e.target.value)}
          //     />
          //   </Stack>
          // )}
          // </> // <>
          //   {showAbhaAddress ? (
          //     <Stack spacing={3} p={4} textAlign="center" mt={5}>
          //       <Typography variant="h5" color="green">
          //         Thank You <CheckCircleIcon />
          //       </Typography>

          //       <Typography variant="h6">Your Abha Number Is</Typography>
          //       <Typography variant="h5" color="blue">
          //         {profileData.healthIdNumber}
          //       </Typography>
          //     </Stack>
          //   ) : (
          //     <Stack m={5}>
          //       <Typography variant="h6">
          //         Congratulations! Your demographic details have been
          //         successfully verified through your Aadhaar card. Now proceed
          //         to generate your ABHA Number for enhanced experiences.
          //       </Typography>
          //     </Stack>
          //   )}
          // </>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Stack bgcolor="white">
      <Header />
      <ToastContainer />
      <Stack margin="2% 6% 6% 6%" padding={4} boxShadow={8} borderRadius={2}>
        <div>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            <div>{getStepContent(activeStep)}</div>
            <div>
              {/* {activeStep === steps.length - 1 ? (
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  style={{ marginRight: "10px" }}
                  hidden
                >
                  Back
                </Button>
              ) : (
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  style={{ marginRight: "10px" }}
                >
                  Back
                </Button>
              )} */}

              {activeStep === steps.length - 1 && showAbhaAddress ? (
                ""
              ) : (
                <Button
                  // disabled={show && activeStep == 0}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  style={{ marginLeft: "25px" }}
                >
                  {activeStep === steps.length - 1
                    ? "Create Abha Address"
                    : "Next"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Stack>
      <AbhaPopup open={open} handleClose={handleClose} />
      <Footer />
    </Stack>
  );
};

export default AbhaRegistration;
