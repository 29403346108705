import axios from "axios";
import { da } from "date-fns/locale";
import { configUrl } from "../config.js";
import axiosInstance from "./interceptore.js";
// import axiosInstance from "./interceptore.js";
// const userToken = localStorage.getItem("userToken");
// const axiosInstance = axios.create({
//   baseURL: configUrl.StrataServer.trim("/"),
//   // headers: {
//   //   Authorization: `Bearer ${userToken}`,
//   // },
// });

const GetAllMedicalTests = async (requestOptions) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetAllMedicalTests`
  );
  console.log(response);
  return response.data;
};

const AddMedicalTest = async (data) => {
  let response = await axiosInstance.post(
    `/api/HealthCheckup/AddMedicalTest`,
    data
  );
  console.log(response);
  return response.data;
};

const UpdateMedicalTest = async (data) => {
  let response = await axiosInstance.put(
    `/api/HealthCheckup/UpdateMedicalTest`,
    data
  );
  console.log(response);
  return response.data;
};

const GetAllTestCategories = async (requestOptions) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetAllTestCategory`
  );
  console.log(response);
  return response.data;
};

const AddTestCategory = async (data) => {
  let response = await axiosInstance.post(
    `/api/HealthCheckup/AddMedicalTestCategory`,
    data
  );
  console.log(response);
  return response.data;
};

const UpdateTestCategory = async (data) => {
  let response = await axiosInstance.put(
    `/api/HealthCheckup/UpdateMedicalTestCategory`,
    data
  );
  console.log(response);
  return response.data;
};

const getAllUnits = async () => {
  let response = await axiosInstance.get(`/api/HealthCheckup/getAllUnits`);
  console.log(response);
  return response.data;
};

const GetMedicalTestsForRequest = async () => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetMedicalTestsForRequest`
  );
  console.log(response);
  return response.data;
};

const CreateRequest = async (data) => {
  let response = await axiosInstance.post(
    `/api/HealthCheckup/CreateRequest`,
    data
  );
  console.log(response);
  return response.data;
};

const GetAllRequestForDoctor = async (docId) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetAllRequestForDoctor?doctorId=` + docId
  );
  return response.data;
};
const GetAllRequestForClients = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetAllRequestForClients?clientId=${clientId}`
  );
  console.log(response);
  return response.data;
};

const GetAllQuotationForClients = async (clientId, hcId) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetAllQuotationForClients?clientId=${clientId}&healthcheckupId=${hcId}`
  );
  console.log(response);
  return response.data;
};
const GetAllQuotationForDoctor = async (docId) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetAllQuotationForDoctor?doctorId=${docId}`
  );
  console.log(response);
  return response.data;
};

const GetAllRequestDetailsForDoctor = async (hcid, docid) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetAllRequestDetailsForDoctor?healthCheckupId=${hcid}&doctorId=${docid}`
  );
  return response.data;
};

const SubmitQuotation = async (data) => {
  let response = await axiosInstance.post(
    `/api/HealthCheckup/SubmitQuotation`,
    data
  );
  return response.data;
};

const AssignHealthCheckupToDoctor = async (doctorId, healthCheckupId) => {
  let response = await axiosInstance.post(
    `/api/HealthCheckup/AssignHealthCheckupToDoctor?doctorId=${doctorId}&healthCheckupId=${healthCheckupId}`
  );
  return response.data;
};

const GetAllHealthCheckupsForDoctor = async (doctorId, clientId) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetAllHealthCheckupsForDoctor?doctorId=${doctorId}&clientId=${clientId}`
  );
  return response.data;
};

const GetHealthCheckUpDetails = async (hcId) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetHealthCheckUpDetails?healthCheckupId=${hcId}`
  );
  return response.data;
};
const SearchEmployee = async (value, clientId, hc) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetEmpForHealthCheckup?query=${value}&clientId=${clientId}&hcId=${hc}`
  );
  console.log(response);
  return response.data;
};

const SubmitHealthCheckForEmployee = async (data) => {
  let response = await axiosInstance.post(
    `/api/HealthCheckup/SubmitHealthCheckForEmployee`,
    data
  );
  console.log(response.data);
  return response.data;
};

const GetEmployeeHealthCheckupListByClient = async (clientId, hc) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetEmployeeHealthCheckupListByClient?clientId=${clientId}&hcId=${hc}`
  );
  return response.data;
};

const PrintHealthCheckupForm = async (hcId, empId) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/PrintHealthCheckupForm?empId=${empId}&hcId=${hcId}`
  );
  return response.data;
};
const GetQuotationUpDetails = async (quotationId) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetQuotationUpDetails?quotationId=${quotationId}`
  );
  return response.data;
};

const DeleteMedicalTest = async (id) => {
  let response = await axiosInstance.get(
    `/api/HealthCheckup/DeleteMedicalTest?id=${id}`
  );
  return response.data;
};

const GetMedicalTestNames = async () =>{
  let response = await axiosInstance.get(
    `/api/HealthCheckup/GetTestNames`
  );
  return response.data;
}

const importEmployeeHealthCheckups = async(data) =>{
  let response = await axiosInstance.post(
    `/api/HealthCheckup/BulkInsertHealthCheckUp`,data
  );
  return response.data;
}

export default {
  GetAllMedicalTests: GetAllMedicalTests,
  AddMedicalTest: AddMedicalTest,
  UpdateMedicalTest: UpdateMedicalTest,
  GetAllTestCategories: GetAllTestCategories,
  AddTestCategory: AddTestCategory,
  UpdateTestCategory: UpdateTestCategory,
  getAllUnits: getAllUnits,
  GetMedicalTestsForRequest: GetMedicalTestsForRequest,
  CreateRequest: CreateRequest,
  GetAllRequestForDoctor: GetAllRequestForDoctor,
  GetAllRequestForClients: GetAllRequestForClients,
  GetAllQuotationForClients: GetAllQuotationForClients,
  GetAllRequestDetailsForDoctor: GetAllRequestDetailsForDoctor,
  GetAllQuotationForDoctor: GetAllQuotationForDoctor,
  SubmitQuotation: SubmitQuotation,
  AssignHealthCheckupToDoctor: AssignHealthCheckupToDoctor,
  GetAllHealthCheckupsForDoctor: GetAllHealthCheckupsForDoctor,
  GetHealthCheckUpDetails: GetHealthCheckUpDetails,
  SearchEmployee: SearchEmployee,
  SubmitHealthCheckForEmployee: SubmitHealthCheckForEmployee,
  GetEmployeeHealthCheckupListByClient: GetEmployeeHealthCheckupListByClient,
  PrintHealthCheckupForm: PrintHealthCheckupForm,
  GetQuotationUpDetails: GetQuotationUpDetails,
  DeleteMedicalTest: DeleteMedicalTest,
  GetMedicalTestNames:GetMedicalTestNames,
  importEmployeeHealthCheckups:importEmployeeHealthCheckups
};
