import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import Avatar from "react-avatar";
import Grid from "@material-ui/core/Grid";

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import { Container, Typography } from "@mui/material";
import healthCheckup from "../services/healthCheckupService";
import { authAtom } from "../App";
import { useRecoilValue } from "recoil";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// components
import Page from "../components/Page";
import MedicalTestGrid from "../components/GridTables/MedicalTestGrid";
import MedicalTestCategotyGrid from "../components/GridTables/MedicalTestCategotyGrid";

function MedicalTest() {
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Medical Test Category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MedicalTestCategotyGrid />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Medical Test</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MedicalTestGrid />
          </AccordionDetails>
        </Accordion>
      </Container>
    </Page>
  );
}

export default MedicalTest;
