import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Card } from "@mui/material";
function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

export default function NavTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="">
        <div className="">
          <h5 className="card-title"> Report List </h5>
          <div className="form-row">
            <div className="form-group col-4">
              <div className="card m-3">
                <div className="list-group list-group-flush">
                  <div className="list-group-item">
                    <h5 className="card-title">
                      {" "}
                      <Link
                        to={{
                          pathname: "/dashboard/dochc",
                          // state:  {obj}
                        }}
                      >
                        Employee Health Checkup
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group col-4">
              <div className="card m-3">
                <div className="list-group list-group-flush">
                  <div className="list-group-item">
                    <h5 className="card-title">
                      {" "}
                      <Link
                        to={{
                          pathname: "/reports/misReport",
                          // state:  {obj}
                        }}
                      >
                        MIS Report
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
