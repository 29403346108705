// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components
// sections

import { AppWidgetSummary } from "../../sections/@dashboard/app";

import { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { atom } from "recoil";
import analyticService from "../../services/analyticService";

export const authAtom = atom({
  key: "auth",
  // get initial state from local storage to enable user to stay logged in
  default: JSON.parse(localStorage.getItem("user")),
});

// ----------------------------------------------------------------------

export default function DoctorDashboard(props) {
  const auth = useRecoilValue(authAtom);

  const [dashboardCount, setDashboardCount] = useState([]);

  useEffect(() => {
    GetDashBoardCount(props.auth.userId);
  }, []); //

  async function GetDashBoardCount(docId) {
    let res = await analyticService.GetDoctorDashboardCount(docId);
    console.log(res);
    setDashboardCount(res.data);
  }

  const theme = useTheme();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3} md={3}>
        <AppWidgetSummary
          title="Pending Request"
          total={dashboardCount.requestCount}
          icon={"material-symbols:pending-actions-sharp"}
        />
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <AppWidgetSummary
          title="Total Quotation Submited"
          total={dashboardCount.quotationCount}
          color="info"
          icon={"wpf:clipboard"}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <AppWidgetSummary
          title="Assign HealthCheck"
          total={dashboardCount.assignHealthCheckUpCount}
          color="info"
          icon={"wpf:check-file"}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3}>
        <AppWidgetSummary
          title="Total Employee"
          total={dashboardCount.employeeCount}
          color="info"
          icon={"ant-design:windows-filled"}
        />
      </Grid>

      {/*<Grid item xs={12} sm={6} md={3}>*/}
      {/*    <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />*/}
      {/*</Grid>*/}
      {/* <Grid item xs={12} md={6} lg={8}>
                    <AppWebsiteVisits
                        title="Website Visits"
                        subheader="(+43%) than last year"
                        chartLabels={[
                            '01/01/2003',
                            '02/01/2003',
                            '03/01/2003',
                            '04/01/2003',
                            '05/01/2003',
                            '06/01/2003',
                            '07/01/2003',
                            '08/01/2003',
                            '09/01/2003',
                            '10/01/2003',
                            '11/01/2003',
                        ]}
                        chartData={[
                            {
                                name: 'Team A',
                                type: 'column',
                                fill: 'solid',
                                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                            },
                            {
                                name: 'Team B',
                                type: 'area',
                                fill: 'gradient',
                                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                             },
                             {
                                 name: 'Team C',
                                 type: 'line',
                                 fill: 'solid',
                                 data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                             },
                         ]}
                     />
                  </Grid> */}

      {/* <Grid item xs={12} md={6} lg={4}>
                      <AppCurrentVisits
                          title="Current Visits"
                          chartData={[
                              { label: 'America', value: 4344 },
                              { label: 'Asia', value: 5435 },
                              { label: 'Europe', value: 1443 },
                              { label: 'Africa', value: 4443 },
                          ]}
                          chartColors={[
                              theme.palette.primary.main,
                              theme.palette.chart.blue[0],
                              theme.palette.chart.violet[0],
                              theme.palette.chart.yellow[0],
                          ]}
                      />
                  </Grid> */}

      {/* <Grid item xs={8} md={4} lg={6}>
                      <EmployeeByState
                          title="Employees by state"
                          subheader=""
                          chartData={employeeByStateList}
                      />
                  </Grid>

                  <Grid item xs={8} md={4} lg={6}>
                      <EmployeeByDistrict
                          title="Employees by district"
                          subheader=""
                          chartData={employeeByDistrictList}
                      />
                  </Grid>

                  <Grid item xs={8} md={4} lg={6}>
                      <EmployeeByCity
                          title="Employees by city"
                          subheader=""
                          chartData={employeeByCityList}
                      />
                  </Grid>

                  <Grid item xs={8} md={4} lg={6}>
                      <EmployeeByTreatment
                          title="Employees by Treatment"
                          subheader=""
                          chartData={employeeByTreatmentList}
                      />
                  </Grid> */}

      {/* <Grid item xs={12} md={6} lg={4}>
                      <AppCurrentSubject
                          title="Current Subject"
                          chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
                          chartData={[
                              { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                              { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                              { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
                          ]}
                          chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
                      />
                  </Grid> */}
    </Grid>
  );
}
