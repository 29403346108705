import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import healthCheckupService from "../../services/healthCheckupService";
import { object } from "prop-types";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App(props) {
  let count = 0;

  // form validation rules
  const validationSchema = Yup.object().shape({
    testCheckupMappings: Yup.array().of(
      Yup.object().shape({
        amount: Yup.number(),
        healthCheckupId: Yup.number(),
        doctorId: Yup.number(),
        clientId: Yup.number(),
        testCategoryId: Yup.number(),
        isActive: Yup.boolean(),
        medicalTestId: Yup.number(),
        quotationId: Yup.number(),
        createdBy: Yup.number(),
        updatedBy: Yup.number(),
      })
    ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // functions to build form returned by useForm() and useFieldArray() hooks
  const { register, control, handleSubmit, reset, formState, watch } =
    useForm(formOptions);
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    name: "testCheckupMappings",
    control,
  });
  const [GroupArr, setGroupArr] = useState([]);
  const [disable, setDisable] = useState(false);

  // watch to enable re-render when ticket number is changed
  // const numberOfTickets = watch('numberOfTickets');

  useEffect(() => {
    console.log(props);
    gettestCheckupMappingss();
  }, []);

  function onSubmit(data) {
    //alert(JSON.stringify(data));
    console.log(JSON.stringify(data));
    // const newObj = {};
    // display form data on success
    // var data =  data.testCheckupMappingss.map(item=>{
    //     newObj.doctorId = props.auth.userId
    //     newObj.testCheckupMappingssId = item.testCheckupMappingssId
    //     newObj.testAmount = item.testAmount
    //     newObj.healthcheckupId = props.healthcheckupId
    //     return newObj
    //  });

    healthCheckupService.SubmitQuotation(data).then((res) => {
      console.log(res);
      if (res.message == "Success") {
        // alert("Quotation submit successfully");
        toast.success("Quotation submit successfully", {
          position: "top-center",
          toastId: "success1",
        });
        setDisable(true);
        setTimeout(() => {
          props.closeModal();
        }, 2000);
      }
    });
  }

  async function gettestCheckupMappingss() {
    fields.forEach((item) => {
      remove(item);
    });
    let res = await healthCheckupService.GetAllRequestDetailsForDoctor(
      props.healthcheckupId,
      props.auth.userId
    );
    console.log(res.data);
    res.data.map((item) => {
      fields.push(item);
    });

    let newDirectory = Object.values(
      fields.reduce((acc, item) => {
        console.log(acc);
        if (!acc[item.group])
          acc[item.group] = {
            name: item.group,
            categoryId: item.testCategoryId,
            label: [],
          };
        acc[item.group].label.push(
          new Object({
            mappingId: item.testHealthCheckupMappingId,
            id: item.code,
            label: item.label,
            isVision: item.isVission,
          })
        );
        return acc;
      }, {})
    );
    console.log(newDirectory);
    setGroupArr(newDirectory);
    console.log(GroupArr);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="card m-3">
        {GroupArr.map(
          (item, i) => (
            console.log(item),
            (
              <div key={i} className="list-group list-group-flush">
                <div className="list-group-item">
                  <div className="form-row">
                    <input
                      type="hidden"
                      value={0}
                      {...register(
                        `testCheckupMappings.${i}.testCategoryQuotMappingId`
                      )}
                    />
                    <input
                      type="hidden"
                      value={true}
                      {...register(`testCheckupMappings.${i}.isActive`)}
                    />
                    <input
                      type="hidden"
                      value={item.categoryId}
                      {...register(`testCheckupMappings.${i}.testCategoryId`)}
                    />

                    <input
                      type="hidden"
                      value={props.healthcheckupId}
                      {...register(`testCheckupMappings.${i}.healthCheckupId`)}
                    />
                    <input
                      type="hidden"
                      value={props.auth.userId}
                      {...register(`testCheckupMappings.${i}.doctorId`)}
                    />
                    <input
                      type="hidden"
                      value={0}
                      {...register(`testCheckupMappings.${i}.clientId`)}
                    />
                    <input
                      type="hidden"
                      value={0}
                      {...register(`testCheckupMappings.${i}.createdBy`)}
                    />
                    <input
                      type="hidden"
                      value={0}
                      {...register(`testCheckupMappings.${i}.updatedBy`)}
                    />
                    <input
                      type="hidden"
                      value={new Date().toISOString()}
                      {...register(`testCheckupMappings.${i}.deleteDate`)}
                    />
                    <input
                      type="hidden"
                      value={0}
                      {...register(`testCheckupMappings.${i}.quotationId`)}
                    />
                    <input
                      type="hidden"
                      value={new Date().toISOString()}
                      {...register(`testCheckupMappings.${i}.createdDate`)}
                    />
                    <input
                      type="hidden"
                      value={new Date().toISOString()}
                      {...register(`testCheckupMappings.${i}.updatedDate`)}
                    />
                    <div className="form-group col-4">
                      <h5 className="card-title">{item.name}</h5>

                      {/* <input name={`testCheckupMappings[${++count}]label`} {...register(`testCheckupMappings.${++count}.label`)} type="text" value = {l1.label} disabled="true" className={`form-control ${errors.testCheckupMappings?.[++count]?.label ? 'is-invalid' : ''}`} />
                          <div className="invalid-feedback">{errors.testCheckupMappings?.[++count]?.label?.message}</div>  */}
                    </div>
                    <div className="form-group col-8">
                      <input
                        placeholder="Amount"
                        name={`testCheckupMappings[${i}]amount`}
                        {...register(`testCheckupMappings.${i}.amount`)}
                        type="text"
                        className={`form-control ${
                          errors.testCheckupMappings?.[i]?.amount
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.testCheckupMappings?.[i]?.amount?.message}
                      </div>
                    </div>
                    {/* <input type="hidden" value={++count} /> */}
                  </div>
                  {item.label.map((l1, i1) => {
                    return (
                      console.log(l1),
                      console.log(count),
                      (
                        <div className="form-row">
                          {/* <input
                        type="hidden"
                        value={true}
                        {...register(`testCheckupMappings.${count}.isActive`)}
                      />
                      <input
                        type="hidden"
                        value={l1.mappingId}
                        {...register(
                          `testCheckupMappings.${count}.testCheckupMappingId`
                        )}
                      />
                      <input
                        type="hidden"
                        value={l1.id}
                        {...register(
                          `testCheckupMappings.${count}.medicalTestId`
                        )}
                      />
                      <input
                        type="hidden"
                        value={props.healthcheckupId}
                        {...register(
                          `testCheckupMappings.${count}.healthCheckupId`
                        )}
                      />
                      <input
                        type="hidden"
                        value={props.auth.userId}
                        {...register(`testCheckupMappings.${count}.doctorId`)}
                      />
                      <input
                        type="hidden"
                        value={0}
                        {...register(`testCheckupMappings.${count}.clientId`)}
                      />
                      <input
                        type="hidden"
                        value={0}
                        {...register(`testCheckupMappings.${count}.createdBy`)}
                      />
                      <input
                        type="hidden"
                        value={0}
                        {...register(`testCheckupMappings.${count}.updatedBy`)}
                      />
                      <input
                        type="hidden"
                        value={new Date().toISOString()}
                        {...register(`testCheckupMappings.${count}.deleteDate`)}
                      />
                      <input
                        type="hidden"
                        value={0}
                        {...register(
                          `testCheckupMappings.${count}.quotationId`
                        )}
                      />
                      <input
                        type="hidden"
                        value={new Date().toISOString()}
                        {...register(
                          `testCheckupMappings.${count}.createdDate`
                        )}
                      />
                      <input
                        type="hidden"
                        value={new Date().toISOString()}
                        {...register(
                          `testCheckupMappings.${count}.updatedDate`
                        )}
                      /> */}
                          <div className="form-group col-4">
                            <label>{l1.label}</label>
                            {/* <input name={`testCheckupMappings[${++count}]label`} {...register(`testCheckupMappings.${++count}.label`)} type="text" value = {l1.label} disabled="true" className={`form-control ${errors.testCheckupMappings?.[++count]?.label ? 'is-invalid' : ''}`} />
                          <div className="invalid-feedback">{errors.testCheckupMappings?.[++count]?.label?.message}</div>  */}
                          </div>
                          {/* <div className="form-group col-8">
                        <input
                          placeholder="Amount"
                          name={`testCheckupMappings[${count}]testAmount`}
                          {...register(
                            `testCheckupMappings.${count}.testAmount`
                          )}
                          type="text"
                          className={`form-control ${
                            errors.testCheckupMappings?.[count]?.testAmount
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {
                            errors.testCheckupMappings?.[count]?.testAmount
                              ?.message
                          }
                        </div> 
                      </div>  */}
                          {/* <input type="hidden" value={++count} /> */}
                        </div>
                      )
                    );
                  })}
                </div>
                <input type="hidden" value={++count} />
              </div>
            )
          )
        )}
        <div className="card-footer text-center border-top-0">
          <button
            type="submit"
            className="btn btn-primary mr-1"
            disabled={disable}
          >
            Submit Quotation
          </button>
          {/* <button onClick={() => reset()} type="button" className="btn btn-secondary mr-1">Reset</button> */}
        </div>
      </div>
      <ToastContainer />
    </form>
  );
}

export { App };
