import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Stack } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ImportHealthCheckUpErrorPopup({
  validationErrors,
  handelCloseErrorPopUp,
  validationErrorsPopUp,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={validationErrorsPopUp}
      >
        <IconButton
          aria-label="close"
          onClick={handelCloseErrorPopUp}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Stack>
          {validationErrors.length > 0 && (
            <div className="validation-errors">
              {validationErrors.map((sheetErrors, index) => (
                <Alert key={index} severity="error">
                  {`Errors in sheet "${sheetErrors.name}":`}
                  <ul>
                    {sheetErrors.errors.map((error, errorIndex) => (
                      <li key={errorIndex}>{error}</li>
                    ))}
                  </ul>
                </Alert>
              ))}
            </div>
          )}
        </Stack>
      </BootstrapDialog>
    </div>
  );
}
export default ImportHealthCheckUpErrorPopup;
