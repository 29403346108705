import axios from "axios";
import { configUrl } from "../config.js";
import axiosInstance from "./interceptore.js";
// const userToken = localStorage.getItem("userToken");
// const axiosInstance = axios.create({
//   baseURL: configUrl.StrataServer,
//   // headers: {
//   //   Authorization: `Bearer ${userToken}`,
//   // },
// });

const SwitctUpdate = async (type, id, status) => {
  console.log(id);
  let response = await axiosInstance.put(
    `/api/Util/UpdateActiveStatus?type=${type}&id=${id}&status=+${status}`
  );
  console.log(response);
  return response.data;
};
const DeletePatient = async (id) => {
  let response = await axiosInstance.get(`/api/patient/DeletePatient?id=${id}`);
  console.log(response);
  return response.data;
};
const GetAllPatients = async (id) => {
  let response = await axiosInstance.get(
    `/api/patient/GetAllPatients?id=${id}`
  );
  console.log(response);
  return response.data;
};
const GetTretMentReport = async (employeeID) => {
  let response = await axiosInstance.get(
    `/api/analytics/GetPatientTreatmentReport?employeeID=${employeeID}`
  );
  console.log(response);
  return response.data;
};
const UpdateEmployee = async (data) => {
  let response = await axiosInstance.post(`/api/patient/UpdatePatient`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

const upsertBulkEployee = async (data) => {
  let response = await axiosInstance.post(
    `/api/Patient/UpsertBulkEmployee
  `,
    data
  );

  return response.data;
};

export default {
  SwitctUpdate: SwitctUpdate,
  DeletePatient: DeletePatient,
  GetAllPatients: GetAllPatients,
  GetTretMentReport: GetTretMentReport,
  UpdateEmployee: UpdateEmployee,
  upsertBulkEployee: upsertBulkEployee,
};
