import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Switch from '@mui/material/Switch'
import DialogTitle from '@mui/material/DialogTitle';
import { useForm, useFieldArray, Controller } from "react-hook-form";

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState , useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ambulanceService from '../services/ambulanceService'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { atom } from 'recoil';
import { useRecoilValue } from 'recoil';
import {exportPDF} from '../utils/exportPdf';
import { render } from 'mustache';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const authAtom = atom({
    key: 'auth',
    // get initial state from local storage to enable user to stay logged in
    default: JSON.parse(localStorage.getItem('user'))
});

export default function AmbulanceReportByDate() {

    const location = useLocation()
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString();
  const auth = useRecoilValue(authAtom);

    const validationSchema = Yup.object().shape({
       
        startDate:Yup.string().required("Please Enter Ambulance number"),
        endDate:Yup.string().required("Please Enter Ambulance number"),

    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, control, handleSubmit, reset, formState, watch } = useForm(formOptions);
    const { errors } = formState;
    const { fields, append, remove } = useFieldArray({ name: 'testCheckupMapping', control });

    const [GroupArr, setGroupArr] = useState([]);
    // let GroupArr=[];
    const[DateArr,setDateArr] = useState([]);
    // let DateArr=[];

    const styles = `
    table, th, td {
      border: 1px solid black;  border-collapse: collapse;
      paddind:2px;
    }
    .verticalTableHeader {
      transform: rotate(180deg);
   writing-mode: vertical-rl;
  
}
  
   
  @media print {
    body * {
      visibility: hidden;
    }
    @page {size: landscape}
    #section-to-print, #section-to-print * {
      visibility: visible;
    } 
  }
  `;
  

    async function printpage() {
        window.print();
      }

    useEffect(() =>{
        var data = {startDate:firstDay,endDate:lastDay}
        getAmbulanceReport(data)

        // exportPDF(headers,reportData," Ambulace CheckList Report");
    },[])

    async function getAmbulanceReport(data){
        var headers = ["Name"]
        
        await ambulanceService.GetAmbulaceCheckListByDate(data).then(result => {
        let dateArr =[];
        setDateArr([]);
        setGroupArr([]);
         result.data.map((item,i)=>{
            if(headers.indexOf(item.date) === -1)
               { 
                 headers.push(item.date) 
                 setDateArr(old => [...old,item.date] )
               }

        })
        
        let newDirectory = Object.values(result.data.reduce((acc, item) => {
            console.log(acc)
            if (!acc[item.title]) 
            acc[item.title] = {
              data: [],
              title:item.title
            };
            acc[item.title].data.push(new Object({isOkay:item.isOkay,date:item.date}));
            return acc;
          }, []))
          var reportData =[];
          
          newDirectory.map((item, i) => {
            //  misReportDetails.push(item)
            // var tempString = item.title+","
            // item.data.map((i)=>{
            //     var t = i.isOkay==null?"-":i.isOkay;
            //     tempString = tempString +t +",";
            // })
            // tempString.trimEnd(",")
            // console.log(tempString);
            reportData.push(item)
            setGroupArr(old=> [...old,item])
        })
        // DateArr = headers;
        // exportPDF(headers,reportData," Ambulace CheckList Report");
        // GroupArr = newDirectory;
        console.log(headers)
        console.log(reportData);
        console.log(DateArr);
        

        // exportPDF(headers,reportData," Ambulace CheckList Report");
        console.log(GroupArr)
      });
    }


    async function onSubmit(data){
        //window.location='/ambulance/Ambulacereport?sd='+data.startDate+'&ed='+data.endDate;
        getAmbulanceReport(data)
    }

    return(
        <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="form-row">
                <div className="form-group col-3">
                  <input placeholder='Start Date' name={`startDate`} {...register(`startDate`)} type="date" className={`form-control ${errors.startDate ? 'is-invalid' : ''}`} />
                </div>
                <div className="form-group col-3">
                  <input placeholder='End Date' name={`endDate`} {...register(`endDate`)} type="date" className={`form-control ${errors.endDate ? 'is-invalid' : ''}`} />
                </div>
                  <div className="form-group col-4">
                    <button type="submit" className="btn btn-primary mr-1">
                      Submit Result
                    </button>
                  </div>
              </div>
            </div>
          </div>

        </form>

  {GroupArr.length>0?
  (
        <>
         <div style={{float :"right"}}>
        <Button
          className=""
          variant="contained"
          color="primary"
          onClick={printpage}
          type="button"
        >
          Print
        </Button>
      </div>
      <br></br>
      <div id="section-to-print">
        <div style={{
          display: "inline-block !important",
          position: "static !important",
          overflow: "visible !important",
          width: "100% !important" /* styles */,
          // fontSize: "1vmin"
        }}>


          <div style={{ width: "100%;" }}>
            <div>
              <div style={{float:"left"}}>
              </div>
              <div style={{ fontWeight: "bold", textAlign: "center" }}>
                <h2>Ambulance Report for Date range {new Date(firstDay).toLocaleDateString()} - {new Date(lastDay).toLocaleDateString()}</h2>
              
              </div>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>

            <table id="table-id" style={{
              width: "100%",
              border: "1px solid black", borderCollapse: "collapse"
            }}>
               <tbody>
            <tr> 
                <th className='verticalTableHeader'>Name</th>
                {/* {DateArr.map((item,i) =>{
                   return( <th>{new Date(item).toLocaleDateString()}</th> )
                })}{DateArr.map((item,i) =>{
                   return( <th>{new Date(item).toLocaleDateString()}</th> )
                })} */}
                {GroupArr[0].data.map((item1,j)=>{
                       return (<th className='verticalTableHeader'>{new Date(item1.date).toLocaleDateString()}</th>)
                    })}
            </tr> 
            {GroupArr.map((item,i) =>{
              return( <tr>
                    <th>{item.title}</th> 
                    {item.data.map((item1,j)=>{
                       return (<th>{item1.isOkay == true ? <>  <CheckCircleIcon/>  </> :<> <CancelIcon/> </> }</th>)
                    })}
                </tr> )
              })}
            </tbody>
            </table>
            </div>
            </div>
            </div>
        </>
    ):<> No Data Found </>
  } 
  <style>{styles}</style>   
  </>
    )
}

