import * as Yup from "yup";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { useSetRecoilState, atom } from "recoil";
import authService from "../../../services/authService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import { sendMessage } from "../../../services/smsService";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { DeviceUUID } from "device-uuid";
import { configUrl } from "../../../config";

const authAtom = atom({
  key: "auth",
  // get initial state from local storage to enable user to stay logged in
  default: JSON.parse(localStorage.getItem("user")),
});
const firebaseConfig = {
  // apiKey: "AIzaSyAcJvafNCneISHBGV5Jr9GZQU-Ogm4TkcQ",
  // authDomain: "device-auth-2bc1d.firebaseapp.com",
  // databaseURL: "https://device-auth-2bc1d-default-rtdb.firebaseio.com",
  // projectId: "device-auth-2bc1d",
  // storageBucket: "device-auth-2bc1d.appspot.com",
  // messagingSenderId: "443211793639",
  // appId: "1:443211793639:web:d5b5b1ceabec8e4b9a4e42",
  // measurementId: "G-G8E6ZQ7LJ2",
  apiKey: "AIzaSyB5OZtVYHO33B6-O3blcbAxpB7zBfM_DjQ",
  authDomain: "strataapi-cb60a.firebaseapp.com",
  databaseURL: "https://strataapi-cb60a-default-rtdb.firebaseio.com/",
  projectId: "strataapi-cb60a",
  storageBucket: "strataapi-cb60a.appspot.com",
  messagingSenderId: "1092030741951",
  appId: "1:1092030741951:web:67c284a25712eea0e0f50a",
  measurementId: "G-7JCFKFXC7T",
};
firebase.initializeApp(firebaseConfig);
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const setAuth = useSetRecoilState(authAtom);
  const [showPassword, setShowPassword] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [otp, setOTP] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showInput, setShowInput] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    formState: { errors },
  } = methods;

  const onSubmit = async ({ email, password, OTP }) => {
    if (password !== null) {
      setShowInput(true);
    } else {
      setShowInput(false);
    }
    var requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    };

    // fetch(`https://strata-portal.azurewebsites.net/auth/Login`,requestOptions).then(response=>response.json()).then(result => {
    const auth = firebase.auth();
    const db = firebase.database();
    var du = new DeviceUUID().parse();
    var dua = [
      du.language,
      du.platform,
      du.os,
      du.cpuCores,
      du.isAuthoritative,
      du.silkAccelerated,
      du.isKindleFire,
      du.isDesktop,
      du.isMobile,
      du.isTablet,
      du.isWindows,
      du.isLinux,
      du.isLinux64,
      du.isMac,
      du.isiPad,
      du.isiPhone,
      du.isiPod,
      du.isSmartTV,
      du.pixelDepth,
      du.isTouchScreen,
    ];
    var deviceId = du.hashMD5(dua.join(":"));

    let result = await authService
      .CheckUser({ email, password })
      .then((res) => {
        // if (
        //   res.data.result.userTypeId != 2 ||
        //   configUrl.StrataServer.includes("localhost")
        // ) {
        setAuth(res.data.result);
        localStorage.setItem("user", JSON.stringify(res.data.result));
        navigate("/dashboard/app", { replace: true });
        toast.success("User login successfully", {
          position: "top-center",
          toastId: "success1",
        });
        // } else {
        //   const clientId = res.data.result.clientId;
        //   db.ref(`devices/${deviceId}`)
        //     .once("value")
        //     .then((snapshot) => {
        //       const dbData = snapshot.val();
        //       console.log(dbData);
        //       if (dbData) {
        //         const storedClientId = dbData.clientId;

        //         if (storedClientId === clientId) {
        //           firebase
        //             .auth()
        //             .signInWithEmailAndPassword(email, password)
        //             .then(() => {
        //               db.ref(`devices/${deviceId}`).update({
        //                 loggedIn: true,
        //                 userId: auth.currentUser.uid,
        //               });
        //               setAuth(res.data.result);
        //               localStorage.setItem(
        //                 "user",
        //                 JSON.stringify(res.data.result)
        //               );
        //               navigate("/dashboard/app", { replace: true });
        //               toast.success("User login successfully", {
        //                 position: "top-center",
        //                 toastId: "success1",
        //               });
        //             })
        //             .catch((error) => {
        //               alert("Login failed!");
        //             });
        //         } else {
        //           toast.error(
        //             "You are not allowed to login on this device. Please login on the device where you first logged in",
        //             {
        //               position: "top-center",
        //               toastId: "success1",
        //             }
        //           );
        //         }
        //       } else {
        //         firebase
        //           .auth()
        //           .createUserWithEmailAndPassword(email, password)
        //           .then(() => {
        //             localStorage.setItem("deviceId", deviceId);

        //             db.ref(`devices/${deviceId}`).set({
        //               loggedIn: true,
        //               userId: auth.currentUser.uid,
        //               clientId: clientId,
        //             });

        //             setAuth(res.data.result);
        //             localStorage.setItem(
        //               "user",
        //               JSON.stringify(res.data.result)
        //             );
        //             navigate("/dashboard/app", { replace: true });
        //             toast.success("User login successfully", {
        //               position: "top-center",
        //               toastId: "success1",
        //             });
        //           })
        //           .catch((error) => {
        //             toast.error(
        //               "You are not allowed to login on this device. Please login on the device where you first logged in",
        //               {
        //                 position: "top-center",
        //                 toastId: "success1",
        //               }
        //             );
        //           });
        //       }
        //   });
        //}
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error("invalid username or password", {
            position: "top-center",
            toastId: "success1",
          });
        }
      });

    // })

    console.log(email);
  };
  async function handelOtpSent() {
    let res = await sendMessage();
    console.log(res);
    setShowInput(true);
  }
  const mainOTP = "123456";
  const handleOTP = (e) => {
    setOTP(e.target.value);
  };
  function handleOTPValidate() {
    if (otp === mainOTP) {
      setIsAuthenticated(true);
      alert("otp valitdte");
    } else {
      alert("Invalid OTP");
    }
  }
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      {/* {showInput && <TextField name="OTP" label="OTP" onChange={handleOTP} />}
        <Stack spacing={3} direction={{ xs: "column", sm: "row" }}>
          <Button variant="contained" size="small" onClick={handelOtpSent}>
            Sent OTP
          </Button>
          <Button variant="contained" size="small" onClick={handleOTPValidate}>
            Valitdte OTP
          </Button>
        </Stack>
      </Stack> */}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        {/* <RHFCheckbox name="remember" label="Remember me" />*/}
        {/* <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
      </Stack>
      {/* {isAuthenticated ? ( */}
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
      {/* ) : (
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          disabled={true}
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      )} */}

      <ToastContainer />
    </FormProvider>
  );
}
