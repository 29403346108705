import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DoctorForm from "../components/Forms/DoctorForm";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Switch from "@mui/material/Switch";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import Iconify from "../components/Iconify";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../sections/@dashboard/user";
// mock
import UserList from "../_mock/user";
import { authAtom } from "../App";
import { useRecoilValue } from "recoil";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import doctorService from "../services/doctorService.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "mobile", label: "Mobile", alignRight: false },
  { id: "state", label: "State", alignRight: false },
  { id: "district", label: "District", alignRight: false },
  { id: "city", label: "City", alignRight: false },
  { id: "isActive", label: "IsActive", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.fullName
          ?.toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
export default function DoctorPage() {
  const [open, setOpen] = React.useState(false);

  const [clientsList, setClientsList] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);

  const auth = useRecoilValue(authAtom);

  useEffect(() => {
    fetchDoctors();
    getalldoctors();
    setRefresh();
  }, [refresh]); //

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selectedDoc, setSelectedDoc] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = clientsList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleExport = () => {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(clientsList);

    XLSX.utils.book_append_sheet(workbook, worksheet, "DoctersList");

    XLSX.writeFile(workbook, "DoctersList.xlsx");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - clientsList.length) : 0;

  const filteredUsers = applySortFilter(
    clientsList,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleClickOpen = (id) => {
    setSelectedDoc(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function fetchDoctors() {
    let res = await doctorService.GetAllDoctors();
    console.log(res);
    setClientsList(res.data.result);
    //fetch(`https://strata-portal.azurewebsites.net//api/doctor/GetAllDoctors`).then(response => response.json()).then(result => {
    //     setClientsList(result.data.result);
    //     console.log(result.data);
    // })
  }

  async function deleteDoctor(employeeID) {
    var result = window.confirm("Want to delete?");
    if (result) {
      let res = await doctorService.DeleteDoctor(employeeID);
      console.log(res.data);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    //if (result) {
    //    var requestOptions = {
    //        method: 'Delete',
    //    };
    //    fetch(`https://strata-portal.azurewebsites.net//api/Doctor/DeleteDoctor?id=`+employeeID,requestOptions).then(response => response.json()).then(result => {
    //         setClientsList(result.data.result);
    //         console.log(result.data);
    //     })
    //     setTimeout(() => {
    //        window.location.reload();
    //     }, 1000);

    // }
  }

  const hadleswitchChage = (type, id, status) => {
    let res = doctorService.SwitchUpdateDoctor(type, id, status);
    console.log(res);
    // alert("Record updated successfully ")
    toast.success("Record updated successfully", {
      position: "top-center",
      toastId: "success1",
    });
    //var requestOptions = {
    //    method: 'Put',
    //};
    //fetch(`https://strata-portal.azurewebsites.net//api/Util/UpdateActiveStatus?type=`+type+`&id=`+id+`&status=+`+status,requestOptions).then(response => response.json()).then(result => {
    //    //  setClientsList(result.data.result);
    //     alert("Record updated successfully ")
    // })
    //  setTimeout(() => {
    //     window.location.reload();
    //  }, 1000);
  };

  const getalldoctors = async () => {
    let alldoctor = await doctorService.GetAllDoctors();

    console.log("alldoctor", alldoctor.data.result);

    setClientsList(
      alldoctor.data.result.map((x, i) => {
        console.log("alldoctor", x);
        return { ...x, id: i };
      })
    );
  };

  return (
    <div>
      {/*<Button variant="outlined" onClick={handleClickOpen}>*/}
      {/*    Add Doctor*/}
      {/*</Button>*/}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Doctor
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            handleClickOpen(0);
          }}
          component={RouterLink}
          to="#"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Add Doctor
        </Button>
      </Stack>

      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          handleExport={handleExport}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={clientsList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    console.log(row);
                    const {
                      userId,
                      email,
                      fullName,
                      state,
                      mobile,
                      district,
                      city,
                      isActive,
                    } = row;
                    const isItemSelected = selected.indexOf(fullName) !== -1;

                    return (
                      <TableRow
                        hover
                        key={userId}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, fullName)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={fullName} />
                            <Typography variant="subtitle2" noWrap>
                              {fullName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{mobile}</TableCell>
                        <TableCell align="left">{state}</TableCell>
                        <TableCell align="left">{district}</TableCell>
                        <TableCell align="left">{city}</TableCell>
                        <TableCell align="left">
                          <Switch
                            defaultChecked={isActive}
                            onChange={() => {
                              hadleswitchChage("User", userId, !isActive);
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <EditIcon
                            onClick={() => {
                              handleClickOpen(userId);
                            }}
                          />
                          <DeleteIcon
                            onClick={() => {
                              deleteDoctor(userId);
                            }}
                          />
                          {/* <Button variant="contained" onClick={() => { handleClickOpen(userId) }} component={RouterLink} to="#" startIcon={<Iconify icon="ant-design:edit-outlined" />}>
                                                    Edit Doctor
                                                </Button> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={clientsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Dialog open={open} onClose={handleClose} fullWidth="lg" maxWidth="lg">
        <DialogTitle>Doctor</DialogTitle>
        <DialogContent>
          <DoctorForm
            closeModal={handleClose}
            doctorID={selectedDoc}
            fetchDoctors={fetchDoctors}
            setRefresh={setRefresh}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
}
