import React, { Component } from "react";
import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRecoilValue } from "recoil";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { atom } from "recoil";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import treatmentService from "../../services/treatmentService";
import stockManagementService from "../../services/stockManagementService";
import EditableTable from "../../utils/EditableTable";
import CreateIcon from "@material-ui/icons/Create";
import {
  Box,
  Button,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import healthCheckupService from "../../services/healthCheckupService";
import { SignalCellularConnectedNoInternet1Bar } from "@material-ui/icons";
import employeeService from "../../services/employeeService";
import { object } from "prop-types";
import { parse } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const authAtom = atom({
  key: "auth",
  // get initial state from local storage to enable user to stay logged in
  default: JSON.parse(localStorage.getItem("user")),
});

export default function DoctorHealthCheckRequestForm(props) {
  let count = 0;
  // form validation rules
  const theme = useTheme();
  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const hc = new URLSearchParams(location.search).get("hc");
  const docId = new URLSearchParams(location.search).get("doc");

  const validationSchema = Yup.object().shape({
    testCheckupMapping: Yup.array().of(
      Yup.object().shape({
        reading: Yup.string() /*.required(
          'Test Reading is required, Please type "NA" if reading is not available'
        )*/,
        testId: Yup.number(),
        isVision: Yup.boolean(),
      })
    ),
    // testCheckupMapping: Yup.array().of(
    //   Yup.object().shape({
    //     testId: Yup.number(),
    //     isVision: Yup.boolean()
    //   }
    //   )
    // ),
    // testCheckupMapping: Yup.array().of(
    //   Yup.object().shape({
    //     isVision: Yup.boolean()
    //   }
    //   )
    // ),
    height: Yup.number(),
    // .required(
    //   'Height is required, Please type "0" if reading is not available'
    // )
    weight: Yup.number(),
    // .required(
    //   'Weight is required, Please type "0" if reading is not available'
    // )
    result: Yup.string(), //.required("Final result is required"),
    advice: Yup.string(), //.required("Advice is required"),

    healthCheckupId: Yup.number(),
    bmi: Yup.number() /*.required(
      'BMI is required, Please type "0" if reading is not available'
    )*/,
    bp: Yup.number(),
    // .required(
    //   'B.P Low is required, Please type "0" if reading is not available'
    // )
    bpHigh: Yup.number() /*.required(
      'B.P High is required, Please type "0" if reading is not available'
    )*/,
    expectedWeight: Yup.number() /*.required(
      'Expected Weight is required, Please type "0" if reading is not available'
    )*/,
    pulse: Yup.number() /*.required(
      'Pulse is required, Please type "0" if reading is not available'
    )*/,
    cholera: Yup.boolean(), //.required("Please select the value"),
    typhoid: Yup.boolean(), //.required("Please select the value"),
    isFit: Yup.boolean(),
    vissionTestMapping: Yup.object().shape({
      healthCheckupId: Yup.number(),
      visionTableId: Yup.number(),
      colorBlind: Yup.boolean(),
    }),
    // vissionTestMapping: Yup.array().of(
    //   Yup.object().shape({
    //     healthcheckupId: Yup.number().required()
    //   },

    //   )
    // ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  // functions to build form returned by useForm() and useFieldArray() hooks
  const { register, control, handleSubmit, reset, formState, watch } =
    useForm(formOptions);
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    name: "testCheckupMapping",
    control,
  });
  const [GroupArr, setGroupArr] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [patientOpen, setPatientOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [patientValue, setPatientvalue] = useState("");

  const [patientOptions, setPatientOptions] = useState([]);

  const Patientloading = patientOpen && patientOptions.length === 0;

  const [hcRequiedInfo, setHcRequiredInfo] = useState();
  // watch to enable re-render when ticket number is changed
  // const numberOfTickets = watch('numberOfTickets');

  useEffect(() => {
    console.log(hc);
    console.log(docId);
    gettestCheckupMapping();
    fetchDetails();
    // setTimeout(async function(){

    // },1000)
  }, []);

  function onSubmit(data) {
    console.log(data.vissionTestMapping);
    data.vissionTestMapping.healthCheckupId = parseInt(hc);
    data.vissionTestMapping.visionTableId = 0;
    data.vissionTestMapping.colorBlind =
      data.vissionTestMapping.colorBlind == undefined
        ? false
        : data.vissionTestMapping.colorBlind;
    data.healthCheckupId = parseInt(hc);
    data.testCheckupMapping.reading =
      data.testCheckupMapping.reading == undefined
        ? "0"
        : data.testCheckupMapping.reading;
    // alert(JSON.stringify(data));
    console.log(data);
    healthCheckupService.SubmitHealthCheckForEmployee(data).then((res) => {
      if (res.message == "Success") {
        // alert("Health Checkup Details Added Sucessfully");
        toast.success("Health Checkup Details Added Sucessfully", {
          position: "top-center",
          toastId: "success1",
        });

        setTimeout(() => {
          setPatientOptions([]);
        }, 2000);
      } else {
        // alert(
        //   "Something went wrong, Please try after some time or contact Admin with this error." +
        //     "  " +
        //     res.message
        // );
        toast.error(
          "Something went wrong, Please try after some time or contact Admin with this error." +
            "  " +
            res.message,
          {
            position: "top-center",
            toastId: "success1",
          }
        );
      }
    });
  }

  async function gettestCheckupMapping() {
    fields.forEach((item) => {
      remove(item);
    });
    let res = await healthCheckupService.GetAllRequestDetailsForDoctor(
      hc,
      auth.userId
    );
    console.log(res.data);
    res.data.map((item) => {
      fields.push(item);
    });

    let newDirectory = Object.values(
      fields.reduce((acc, item) => {
        console.log(acc);
        if (!acc[item.group])
          acc[item.group] = {
            name: item.group,
            label: [],
          };
        acc[item.group].label.push(
          new Object({
            id: item.code,
            label: item.label,
            isVision: item.isVission,
          })
        );
        return acc;
      }, {})
    );
    console.log(newDirectory);
    setGroupArr(newDirectory);
    console.log(GroupArr);
  }
  const onPatientChangeHandle = async (value, clientId = 0) => {
    var client = hcRequiedInfo?.clientId || clientId;
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log(value);
    let res = await healthCheckupService.SearchEmployee(value, client, hc);
    console.log(res);
    setPatientOptions(res.data);
  };

  async function fetchDetails() {
    let res = await healthCheckupService
      .GetHealthCheckUpDetails(hc)
      .then((res) => {
        console.log(res);
        setHcRequiredInfo(res.data);
        if (!patientOpen) {
          onPatientChangeHandle("", res.data.clientId);
        }
      });
  }

  function VisionComponent() {
    return (
      <div className="list-group list-group-flush">
        <div className="list-group-item">
          {/* <h5 className="card-title">Vision</h5> */}
          <div className="form-row">
            <input
              type="hidden"
              value="1"
              {...register(`vissionTestMapping.visionTableId`)}
            />
            <input
              type="hidden"
              value={hc}
              {...register(`vissionTestMapping.healthCheckupId`)}
            />
            <div className="form-group col-6">
              <label>With Glass</label>
              <div className="form-row">
                <div className="form-group col-6">
                  <div>
                    <label>Near</label>
                  </div>
                  <input
                    name={`vissionTestMapping.wGlassRightNear`}
                    {...register(`vissionTestMapping.wGlassRightNear`)}
                    type="text"
                    placeholder="Right"
                    className={`form-control mt-2 ${
                      errors.vissionTestMapping?.wGlassRightNear
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.vissionTestMapping?.wGlassRightNear?.message}
                  </div>
                  <input
                    name={`vissionTestMapping.wGlassLeftNear`}
                    {...register(`vissionTestMapping.wGlassLeftNear`)}
                    type="text"
                    placeholder="Left"
                    className={`form-control mt-2 ${
                      errors.vissionTestMapping?.wGlassLeftNear
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.vissionTestMapping?.wGlassLeftNear?.message}
                  </div>
                </div>
                <div className="form-group col-6">
                  <label>Far</label>
                  <input
                    name={`vissionTestMapping.wGlassRightFar`}
                    {...register(`vissionTestMapping.wGlassRightFar`)}
                    type="text"
                    placeholder="Right"
                    className={`form-control mt-2 ${
                      errors.vissionTestMapping?.wGlassRightFar
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.vissionTestMapping?.wGlassRightFar?.message}
                  </div>
                  <input
                    name={`vissionTestMapping.wGlassLeftFar`}
                    {...register(`vissionTestMapping.wGlassLeftFar`)}
                    type="text"
                    placeholder="Left"
                    className={`form-control mt-2 ${
                      errors.vissionTestMapping?.wGlassLeftFar
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.vissionTestMapping?.wGlassLeftFar?.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group col-6">
              <label>Without Glass</label>
              <div className="form-row">
                <div className="form-group col-6">
                  <div>
                    <label>Near</label>
                  </div>
                  <input
                    name={`vissionTestMapping.woGlassRighNear`}
                    {...register(`vissionTestMapping.woGlassRighNear`)}
                    type="text"
                    placeholder="Right"
                    className={`form-control mt-2 ${
                      errors.vissionTestMapping?.woGlassRighNear
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.vissionTestMapping?.woGlassRighNear?.message}
                  </div>
                  <input
                    name={`vissionTestMapping.woGlassLeftNear`}
                    {...register(`vissionTestMapping.woGlassLeftNear`)}
                    type="text"
                    placeholder="Left"
                    className={`form-control mt-2 ${
                      errors.vissionTestMapping?.woGlassLeftNear
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.vissionTestMapping?.woGlassLeftNear?.message}
                  </div>
                </div>
                <div className="form-group col-6">
                  <label>Far</label>
                  <input
                    name={`vissionTestMapping.woGlassRightFar`}
                    {...register(`vissionTestMapping.woGlassRightFar`)}
                    type="text"
                    placeholder="Right"
                    className={`form-control mt-2 ${
                      errors.vissionTestMapping?.woGlassRightFar
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.vissionTestMapping?.WithGlassFarRight?.message}
                  </div>
                  <input
                    name={`vissionTestMapping.woGlassLeftFar`}
                    {...register(`vissionTestMapping.woGlassLeftFar`)}
                    type="text"
                    placeholder="Left"
                    className={`form-control mt-2 ${
                      errors.vissionTestMapping?.woGlassLeftFar
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.vissionTestMapping?.WithGlassFarFeft?.message}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group col-6">
              <label>Color Bind</label>
              <select
                name="vissionTestMapping.colorBlind"
                {...register(`vissionTestMapping.colorBlind`)}
                className={`form-control mt-2 ${
                  errors.vissionTestMapping?.colorBlind ? "is-invalid" : ""
                }`}
              >
                <option value={true}>Yes</option>
                <option value={false} selected>
                  No
                </option>
              </select>
              <div className="invalid-feedback">
                {errors.vissionTestMapping?.colorBlind?.message}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return patientOptions.length > 0 ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" value={hc} {...register(`healthCheckupId`)} />
      <div className="card m-3">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <div className="form-row">
              <div className="form-group col-6">
                <h1>Title: {hcRequiedInfo?.title}</h1>
                <h1>Client Name: {hcRequiedInfo?.clientName}</h1>
              </div>
              <div className="form-group col-6">
                <Link
                  to={{
                    pathname: "/reports/allemphcList",
                    // state:  {obj}
                    search: "?cl=" + hcRequiedInfo?.clientId + "&hcri=" + hc,
                  }}
                >
                  List of completed health checkups
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <div className="form-row">
              {/* <div className="form-group col-6">

                <label>Select Employee </label>
              </div> */}
              <div className="form-group col-12">
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      style={{ width: 300 }}
                      open={patientOpen}
                      onOpen={() => {
                        setPatientOpen(true);
                      }}
                      onClose={() => {
                        setPatientOpen(false);
                      }}
                      onChange={(e, value) => {
                        console.log(value);
                        onChange(value.id);
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.label}
                      options={patientOptions}
                      loading={Patientloading}
                      name="PatientId"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Employee"
                          variant="outlined"
                          size="small"
                          style={{ width: 500 }}
                          name="PatientId"
                          onChange={(ev) => {
                            // dont fire API if the user delete or not entered anything
                            if (
                              ev.target.value !== "" ||
                              ev.target.value !== null
                            ) {
                              onPatientChangeHandle(ev.target.value);
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {Patientloading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                  onChange={([event, data]) => {
                    return data;
                  }}
                  name="employeeId"
                  control={control}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-4">
                <input
                  placeholder="Height"
                  name={`height`}
                  {...register(`height`)}
                  type="number"
                  className={`form-control ${
                    errors.height ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">{errors.height?.message}</div>
              </div>
              <div className="form-group col-4">
                <input
                  placeholder="Weight"
                  name={`weight`}
                  {...register(`weight`)}
                  type="number"
                  className={`form-control ${
                    errors.weight ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">{errors.weight?.message}</div>
              </div>
              <div className="form-group col-4">
                <input
                  placeholder="Expected Weight"
                  name={`expectedWeight`}
                  {...register(`expectedWeight`)}
                  type="number"
                  className={`form-control ${
                    errors.expectedWeight ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.expectedWeight?.message}
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-4">
                <div className="form-row">
                  <div className="form-group col-6">
                    <input
                      placeholder="B.P. Low"
                      name={`bp`}
                      {...register(`bp`)}
                      type="number"
                      className={`form-control ${
                        errors.bp ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">{errors.bp?.message}</div>
                  </div>
                  <div className="form-group col-6">
                    <input
                      placeholder="B.P. High"
                      name={`bpHigh`}
                      {...register(`bpHigh`)}
                      type="number"
                      className={`form-control ${
                        errors.bpHigh ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.bpHigh?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group col-4">
                <input
                  placeholder="Pulse"
                  name={`pulse`}
                  {...register(`pulse`)}
                  type="number"
                  className={`form-control ${errors.pulse ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.pulse?.message}</div>
              </div>
              <div className="form-group col-4">
                <input
                  placeholder="BMI"
                  name={`bmi`}
                  {...register(`bmi`)}
                  type="number"
                  className={`form-control ${errors.bmi ? "is-invalid" : ""}`}
                />
                <div className="invalid-feedback">{errors.bmi?.message}</div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <br />

        {GroupArr.map((item, i) => (
          <div key={i} className="list-group list-group-flush">
            <div className="list-group-item">
              <h5 className="card-title">{item.name}</h5>

              {item.label.map((l1, i1) => {
                return (
                  console.log(l1),
                  console.log(count),
                  l1.isVision == false ? (
                    <div className="form-row">
                      <input
                        type="hidden"
                        value={l1.isVision}
                        {...register(`testCheckupMapping.${count}.isVision`)}
                      />
                      <input
                        type="hidden"
                        value={l1.id}
                        {...register(`testCheckupMapping.${count}.testId`)}
                      />
                      <input
                        type="hidden"
                        value={auth.userId}
                        {...register(`testCheckupMapping.${count}.doctorId`)}
                      />
                      <div className="form-group col-4">
                        <label>{l1.label}</label>
                        {/* <input name={`testCheckupMapping[${++count}]label`} {...register(`testCheckupMapping.${++count}.label`)} type="text" value = {l1.label} disabled="true" className={`form-control ${errors.testCheckupMapping?.[++count]?.label ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.testCheckupMapping?.[++count]?.label?.message}</div>  */}
                      </div>
                      <div className="form-group col-8">
                        <input
                          placeholder="Absent"
                          name={`testCheckupMapping[${count}]reading`}
                          {...register(`testCheckupMapping.${count}.reading`)}
                          type="text"
                          // value="Absent"
                          className={`form-control ${
                            errors.testCheckupMapping?.[count]?.reading
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.testCheckupMapping?.[count]?.reading?.message}
                        </div>
                      </div>
                      <input type="hidden" value={++count} />
                    </div>
                  ) : (
                    <div>
                      {VisionComponent()}
                      <div className="form-row">
                        <input
                          type="hidden"
                          value={l1.isVision}
                          {...register(`testCheckupMapping.${count}.isVision`)}
                        />
                        <input
                          type="hidden"
                          value={l1.id}
                          {...register(`testCheckupMapping.${count}.testId`)}
                        />
                        <div className="form-group col-2">
                          <label>Vision Remark</label>
                        </div>
                        <div className="form-group col-10">
                          <input
                            type="hidden"
                            value={auth.userId}
                            {...register(
                              `testCheckupMapping.${count}.doctorId`
                            )}
                          />
                          <input
                            placeholder="Absend"
                            name={`testCheckupMapping[${count}]reading`}
                            {...register(`testCheckupMapping.${count}.reading`)}
                            type="text"
                            className={`form-control ${
                              errors.testCheckupMapping?.[count]?.reading
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <div className="invalid-feedback">
                            {
                              errors.testCheckupMapping?.[count]?.reading
                                ?.message
                            }
                          </div>
                        </div>
                      </div>
                      <input type="hidden" value={++count} />
                    </div>
                  )
                );
              })}
            </div>
          </div>
        ))}
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <div className="form-row">
              <div className="form-group col-4">
                <label> Vaccination</label>
                <input
                  style={{ marginTop: "9px" }}
                  placeholder="Vaccination"
                  name={`vaccination`}
                  {...register(`vaccination`)}
                  type="text"
                  className={`form-control ${
                    errors.vaccination ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                  {errors.vaccination?.message}
                </div>
              </div>
              <div className="form-group col-4">
                <label>Cholera</label>
                <select
                  name="cholera"
                  {...register(`cholera`)}
                  className={`form-control mt-2 ${
                    errors.cholera ? "is-invalid" : ""
                  }`}
                >
                  <option value={false} selected>
                    Select
                  </option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
                <div className="invalid-feedback">
                  {errors.cholera?.message}
                </div>
              </div>
              <div className="form-group col-4">
                <label>Typhoid</label>
                <select
                  name="typhoid"
                  {...register(`typhoid`)}
                  className={`form-control mt-2 ${
                    errors.typhoid ? "is-invalid" : ""
                  }`}
                >
                  <option value={false} selected>
                    Select
                  </option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
                <div className="invalid-feedback">
                  {errors.typhoid?.message}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <h5 className="card-title">Checkup Result</h5>
            <div className="form-row">
              <div className="form-group col-2">
                <label>Health Checkup Result</label>
              </div>
              <div className="form-group col-2">
                UnFit{" "}
                <Switch
                  name={`isFit`}
                  {...register(`isFit`)}
                  className={`form-control`}
                ></Switch>{" "}
                Fit
                {/* <input type="radio" value="True" name={`isFit`} {...register(`isFit`)} className={`form-control ${errors.result ? 'is-invalid' : ''}`}/> Fit
                <input type="radio" value="False" name={`isFit`} {...register(`isFit`)} className={`form-control ${errors.result ? 'is-invalid' : ''}`}/> Unfit */}
                {/* <input placeholder='Remark' name={`result`} {...register(`result`)} type="text" multiple={true} className={`form-control ${errors.result ? 'is-invalid' : ''}`} /> */}
                {/* <div className="invalid-feedback">{errors.isFit?.message}</div> */}
              </div>
              <div className="form-group col-8">
                <input
                  placeholder="Remark"
                  name={`result`}
                  {...register(`result`)}
                  type="text"
                  multiple={true}
                  className={`form-control ${
                    errors.result ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">{errors.result?.message}</div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-2">
                <label>Advice</label>
              </div>
              <div className="form-group col-10">
                <input
                  placeholder="Advice"
                  name={`advice`}
                  {...register(`advice`)}
                  type="text"
                  className={`form-control ${
                    errors.advice ? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">{errors.advice?.message}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-footer text-center border-top-0">
          <button type="submit" className="btn btn-primary mr-1">
            Submit Result
          </button>
          {/* <button onClick={() => reset()} type="button" className="btn btn-secondary mr-1">Reset</button> */}
        </div>
      </div>
    </form>
  ) : (
    <div className="card m-3">
      <div className="list-group list-group-flush">
        <div className="list-group-item">
          <div className="form-row">
            <div className="form-group col-6">
              <h1>Title: {hcRequiedInfo?.title}</h1>
              <h1>Client Name: {hcRequiedInfo?.clientName}</h1>
            </div>
            <div className="form-group col-6">
              <Link
                to={{
                  pathname: "/reports/allemphcList",
                  // state:  {obj}
                  search: "?cl=" + hcRequiedInfo?.clientId + "&hcri=" + hc,
                }}
              >
                List of completed health checkups
              </Link>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-12">
              No Employee left for Health Checkup
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
