import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PreEmployeementForm from "../components/Forms/PreEmployeementForm";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Switch from "@mui/material/Switch";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import Iconify from "../components/Iconify";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../sections/@dashboard/user";
import { useForm, useFieldArray, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
// mock
import UserList from "../_mock/user";
import { authAtom } from "../App";
import { useRecoilValue } from "recoil";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import doctorService from "../services/doctorService.js";
import PreEmploymentHCService from "../services/PreEmploymentHCService";
import * as XLSX from "xlsx";
import printJS from "print-js";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "date", label: "Date", alignRight: false },
  { id: "ename", label: "Name", alignRight: false },
  { id: "pc", label: "P/C", alignRight: false },
  { id: "spo2", label: "SpO2", alignRight: false },
  { id: "Temp", label: "Temp", alignRight: false },
  { id: "Pulse", label: "Pulse/ min", alignRight: false },
  { id: "Age", label: "Age (Year)", alignRight: false },
  { id: "Height", label: "Height(cm)", alignRight: false },
  { id: "Weight", label: "Weight (kg)", alignRight: false },
  { id: "bp", label: "B.P mm/Hg", alignRight: false },
  { id: "bloodGroup", label: "Blood Group", alignRight: false },
  { id: "vertigo", label: "Vertigo", alignRight: false },
  { id: "vision", label: "Vision", alignRight: false },
  { id: "suger", label: "Suger", alignRight: false },
  { id: "allrgy", label: "Allrgy", alignRight: false },
  { id: "Remark", label: "Remark", alignRight: false },
  { id: "cName", label: "Name Of Contractor", alignRight: false },
  { id: "FirstAider", label: "First Aider", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (array == undefined) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.employeeName

          ?.toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function PreEmployeementReportByDate() {
  const [open, setOpen] = React.useState(false);

  const [clientsList, setClientsList] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);

  const auth = useRecoilValue(authAtom);
  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();
  let lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0
  ).toISOString();
  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required("Please Enter Ambulance number"),
    endDate: Yup.string().required("Please Enter Ambulance number"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, control, handleSubmit, reset, formState, watch } =
    useForm(formOptions);
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    name: "testCheckupMapping",
    control,
  });

  // async function printpage() {
  //   window.print();
  // }
  const printpage = () => {
    const printableContent = document.getElementById("section-to-print");
    printJS({
      printable: printableContent,
      type: "html",
      style: `
      .MuiTable-root {
        border-collapse: collapse;
        width: 100%;
      }
        #section-to-print th,
      #section-to-print td {
          border: 0.5px solid black;
          padding: 4px;

         text-align:"center"
        }
      `,
    });
  };

  useEffect(() => {
    let data = null;
    fetchPatients();
    setRefresh();
  }, [refresh]); //

  async function fetchPatients(data) {
    let res = await PreEmploymentHCService.getAllPreEmplymentHC(auth.clientId);
    console.log(res);
    if (data != undefined) {
      res.data.filter((d) => {
        return (
          new Date(d.date) >= new Date(date.startDate) &&
          new Date(d.date) <= new Date(date.endDate)
        );
      });
    }
    setClientsList(res.data);
    // console.log(res)
    //    fetch(`https://strata-portal.azurewebsites.net//api/patient/GetAllPatients?id=`+auth.clientId).then(response => response.json()).then(result => {
    //         setClientsList(result.data.result);
    //         console.log(result.data);
    //     })
  }
  async function onSubmit(data) {
    //window.location='/ambulance/Ambulacereport?sd='+data.startDate+'&ed='+data.endDate;
    fetchPatients(data);
  }

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("preEmploymentId");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selectedDoc, setSelectedDoc] = useState(0);

  const styles = `
    table, th, td {
      border: 1px solid black;  border-collapse: collapse;
      paddind:2px;
    }
    .verticalTableHeader {
     /* transform: rotate(180deg); */
   writing-mode: vertical-rl;
  
}
    
   
  @media print {
    body * {
      visibility: hidden;
    }
    @page {size: landscape}
    #section-to-print, #section-to-print * {
      visibility: visible;
    } 
  }
  `;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = clientsList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleExport = () => {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(clientsList);

    XLSX.utils.book_append_sheet(workbook, worksheet, "preEmpByDateList");

    XLSX.writeFile(workbook, "preEmpByDateList.xlsx");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - clientsList.length) : 0;

  const filteredUsers = applySortFilter(
    clientsList,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleClickOpen = (id) => {
    setSelectedDoc(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Pre-Employeement
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            handleClickOpen(0);
          }}
          component={RouterLink}
          to="#"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Add Pre-Employeement
        </Button>
      </Stack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <div className="form-row">
              <div className="form-group col-3">
                <input
                  placeholder="Start Date"
                  name={`startDate`}
                  {...register(`startDate`)}
                  type="date"
                  className={`form-control ${
                    errors.startDate ? "is-invalid" : ""
                  }`}
                />
              </div>
              <div className="form-group col-3">
                <input
                  placeholder="End Date"
                  name={`endDate`}
                  {...register(`endDate`)}
                  type="date"
                  className={`form-control ${
                    errors.endDate ? "is-invalid" : ""
                  }`}
                />
              </div>
              <div className="form-group col-4">
                <button type="submit" className="btn btn-primary mr-1">
                  Submit Result
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div>
        {/*<Button variant="outlined" onClick={handleClickOpen}>*/}
        {/*    Add Doctor*/}
        {/*</Button>*/}

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            handleExport={handleExport}
          />

          <Scrollbar>
            <div style={{ float: "right" }}>
              <Button
                className=""
                variant="contained"
                color="primary"
                onClick={printpage}
                type="button"
              >
                Print
              </Button>
            </div>
            <br></br>
            <div id="section-to-print">
              <div
                style={{
                  display: "inline-block !important",
                  position: "static !important",
                  overflow: "visible !important",
                  width: "100% !important" /* styles */,
                  // fontSize: "1vmin"
                }}
              >
                <div style={{ width: "100%;" }}>
                  <div>
                    <div style={{ float: "left" }}></div>
                    <div style={{ fontWeight: "bold", textAlign: "center" }}>
                      <h2>
                        Pre-Employment Report for Date range{" "}
                        {new Date(firstDay).toLocaleDateString()} -{" "}
                        {new Date(lastDay).toLocaleDateString()}
                      </h2>
                    </div>
                  </div>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={clientsList.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {filteredUsers
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            console.log(row);
                            const {
                              allergy,
                              bloodGroup,
                              bpHigh,
                              bpLow,
                              contractorName,
                              date,
                              employeeId,
                              employeeName,
                              firstAided,
                              height,
                              isContractor,
                              preEmploymentId,
                              pulse,
                              remark,
                              spO2,
                              suger,
                              temp,
                              vertigo,
                              vision,
                              weight,
                              age,
                            } = row;
                            const isItemSelected =
                              selected.indexOf(preEmploymentId) !== -1;

                            return (
                              <TableRow
                                hover
                                key={preEmploymentId}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    disabled={true}
                                    checked={isItemSelected}
                                    onChange={(event) =>
                                      handleClick(event, employeeName)
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    <Typography variant="subtitle2" noWrap>
                                      {new Date(date).toLocaleDateString()}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">
                                  {employeeName}
                                </TableCell>
                                <TableCell align="left">
                                  {isContractor ? "C" : "E"}
                                </TableCell>
                                <TableCell align="left">{spO2}</TableCell>
                                <TableCell align="left">{temp}</TableCell>
                                <TableCell align="left">{pulse}</TableCell>
                                <TableCell align="left">{age}</TableCell>
                                <TableCell align="left">{height}</TableCell>
                                <TableCell align="left">{weight}</TableCell>
                                <TableCell align="left">
                                  {bpHigh + "/" + bpLow}
                                </TableCell>
                                <TableCell align="left">{bloodGroup}</TableCell>
                                <TableCell align="left">{vertigo}</TableCell>
                                <TableCell align="left">{vision}</TableCell>
                                <TableCell align="left">{suger}</TableCell>
                                <TableCell align="left">{allergy}</TableCell>
                                <TableCell align="left">{remark}</TableCell>
                                <TableCell align="left">
                                  {contractorName ?? "-"}
                                </TableCell>
                                <TableCell align="left">{firstAided}</TableCell>

                                {/* <TableCell align="right"> */}
                                {/* <EditIcon  onClick={() => { handleClickOpen(userId) }} /> */}
                                {/* <DeleteIcon  onClick={() => { deleteDoctor(userId) }}/> */}
                                {/* <Button variant="contained" onClick={() => { handleClickOpen(userId) }} component={RouterLink} to="#" startIcon={<Iconify icon="ant-design:edit-outlined" />}>
                                                    Edit Doctor
                                                </Button> */}
                                {/* </TableCell> */}
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>

                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={6}
                              sx={{ py: 3 }}
                            >
                              <SearchNotFound searchQuery={filterName} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={clientsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <Dialog open={open} onClose={handleClose} fullWidth="lg" maxWidth="lg">
          <DialogTitle>Pre-Employeement</DialogTitle>
          <DialogContent>
            <PreEmployeementForm
              closeModal={handleClose}
              auth={auth}
              selectedID={selectedDoc}
              setRefresh={setRefresh}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
      <style>{styles}</style>
    </>
  );
}
