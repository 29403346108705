import axios from "axios";

import { configUrl } from "../config.js";

const axiosInstance = axios.create({
  baseURL: configUrl.StrataServer,
});

const generateToken = async () => {
  try {
    let response = await axiosInstance.get(`api/abha/session`, {
      clientId: "SBX_003321",
      clientSecret: "99830ae4-f484-42e2-a530-af9f1dfd3bf1",
    });
    console.log(response.data);
    const token = response.data.accessToken;

    // localStorage.setItem("accessToken", token);

    return response.data;
  } catch (error) {
    console.error("Error generating token:", error);
  }
};

const adharVerify = async (obj) => {
  let response = await axiosInstance.post(`/api/Abha/getOtp`, obj);
  console.log(response);

  return response.data;
};

const VerifyOtp = async (obj) => {
  let response = await axiosInstance.post(`/api/Abha/verifyOtp`, obj);
  console.log(response);

  return response.data;
};

const checkAndVerifyNumber = async (obj) => {
  let response = await axiosInstance.post(
    `/api/Abha/checkAndGenerateMobileOTP`,
    obj
  );
  console.log(response);

  return response.data;
};

const verifyMobileOtp = async (obj) => {
  let response = await axiosInstance.post(`/api/Abha/verifyMobileOTP`, obj);
  console.log(response);

  return response.data;
};
const createAbhaNumber = async (obj) => {
  let response = await axiosInstance.post(
    `/api/Abha/createHealthIdWithPreVerified`,
    obj
  );
  console.log(response);

  return response.data;
};
const resendOtp = async (obj) => {
  let response = await axiosInstance.post(`/api/Abha/resendOtp`, obj);
  console.log(response);

  return response.data;
};

export default {
  adharVerify: adharVerify,
  VerifyOtp: VerifyOtp,
  generateToken: generateToken,
  createAbhaNumber: createAbhaNumber,
  resendOtp: resendOtp,
  checkAndVerifyNumber: checkAndVerifyNumber,
  verifyMobileOtp: verifyMobileOtp,
};
