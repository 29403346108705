import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PatientForm from "../components/Forms/PatientForm";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Switch from "@mui/material/Switch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Link } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import Iconify from "../components/Iconify";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../sections/@dashboard/user";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { authAtom } from "../App";
import healthCheckupService from "../services/healthCheckupService";
import * as XLSX from "xlsx";
const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "StartDate", label: "Start Date", alignRight: false },
  { id: "EndDate", label: "End Date", alignRight: false },
  { id: "AssignedDate", label: "Assigned Date", alignRight: false },
  // { id: 'DateOfTreatement', label: 'Date Of Treatement', alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_quotation) =>
        _quotation.title
          ?.toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function HealthCheckupList(props) {
  const [open, setOpen] = React.useState(false);

  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();

  useEffect(() => {
    fetchHealthCheckups();
    console.log(auth);
  }, []); //

  const [healthCheckupList, setHealthCheckupList] = React.useState([]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("amount");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = healthCheckupList.map((n) => n.quotationId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleExport = () => {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(healthCheckupList);

    XLSX.utils.book_append_sheet(workbook, worksheet, "hcpReportList");

    XLSX.writeFile(workbook, "hcpReportList.xlsx");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - healthCheckupList.length)
      : 0;

  const filteredUsers = applySortFilter(
    healthCheckupList,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  async function fetchHealthCheckups() {
    if (auth.userTypeId == 3) {
      let res = await healthCheckupService.GetAllHealthCheckupsForDoctor(
        auth.userId,
        0
      );
      console.log(res);
      setHealthCheckupList(res.data);
    } else {
      let res = await healthCheckupService.GetAllHealthCheckupsForDoctor(
        0,
        auth.clientId
      );
      console.log(res);
      setHealthCheckupList(res.data);
    }
  }

  function renderUrl(hcId, docId) {
    let obj = { hc: hcId, docId: docId };
    return (
      <Link
        to={{
          pathname: "/dashboard/healthCheckup",
          // state:  {obj}
          search: "?hc=" + hcId + "&doc=" + docId,
        }}
      >
        Select
      </Link>
    );
  }

  return (
    <div>
      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          handleExport={handleExport}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={healthCheckupList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                checkAllDisabled={true}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      healthCheckupId,
                      title,
                      startDate,
                      endDate,
                      doctorId,
                      assignedDate
                    } = row;
                    const isItemSelected =
                      selected.indexOf(healthCheckupId) !== -1;

                    return (
                      <TableRow
                        hover
                        key={healthCheckupId}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            hidden={true}
                            checked={isItemSelected}
                            onChange={(event) =>
                              handleClick(event, healthCheckupId)
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center">
                            <Typography variant="subtitle2" noWrap>
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {new Date(startDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="left">
                          {new Date(endDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="left">
                          {new Date(assignedDate).toLocaleDateString()}
                        </TableCell>
                        {/* <TableCell align="left"><Switch defaultChecked={isActive}  onClick={()=>{hadleswitchChage("User",userId,!isActive)}}/></TableCell> */}
                        {auth.userTypeId == 3 ? (
                          <TableCell align="right">
                            {renderUrl(healthCheckupId, doctorId)}
                            <span> | </span>

                            <Link
                              to={{
                                pathname: "/reports/healthCheckupGraphReport",
                                // state:  {obj}
                                search: "?ehn=" + healthCheckupId,
                              }}
                            >
                              Graphical Representation
                            </Link>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {auth.userTypeId != 3 ? (
                          <>
                            <TableCell align="right">
                              <Link
                                to={{
                                  pathname: "/reports/allemphcList",
                                  // state:  {obj}
                                  search:
                                    "?cl=" +
                                    auth.clientId +
                                    "&hcri=" +
                                    healthCheckupId,
                                }}
                              >
                                Employee specific Report
                              </Link>
                              <span> | </span>
                              <Link
                                to={{
                                  pathname: "/reports/misReport",
                                  // state:  {obj}
                                  search: "?ehn=" + healthCheckupId,
                                }}
                              >
                                MIS Report
                              </Link>
                              <span> | </span>

                              <Link
                                to={{
                                  pathname: "/reports/form7Report",
                                  // state:  {obj}
                                  search: "?ehn=" + healthCheckupId,
                                }}
                              >
                                Form 7 Report
                              </Link>

                              <span> | </span>

                              <Link
                                to={{
                                  pathname: "/reports/healthCheckupGraphReport",
                                  // state:  {obj}
                                  search: "?ehn=" + healthCheckupId,
                                }}
                              >
                                Graphical Representation
                              </Link>
                            </TableCell>
                          </>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={healthCheckupList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </div>
  );
}
