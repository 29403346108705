import { faker } from "@faker-js/faker";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import Iconify from "../components/Iconify";
import {
  ClientAdminDashboard,
  ClientDoctorDashboard,
  AdminDashboard,
  EmployeeDashboard,
  DoctorDashboard,
} from "../components/Dashboards";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from "../sections/@dashboard/app";
// import { authAtom } from '../App';
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { atom } from "recoil";

// ----------------------------------------------------------------------

function renderDashboard(auth) {
  switch (auth?.userTypeId) {
    case 1:
      return <AdminDashboard />;
    case 2:
      return <ClientAdminDashboard auth={auth} />;
    case 3:
      return <DoctorDashboard auth={auth} />;
    case 4:
      return <EmployeeDashboard />;
    default:
      break;
  }
}

export const authAtom = atom({
  key: "auth",
  // get initial state from local storage to enable user to stay logged in
  default: JSON.parse(localStorage.getItem("user")),
});

export default function DashboardApp() {
  const theme = useTheme();
  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      // not logged in so redirect to login page with the return url
      navigate("/login", { replace: true });
    }
    console.log(auth);
  }, []); //
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        {renderDashboard(auth)}
      </Container>
    </Page>
  );
}
