import React, { Component } from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment, Switch } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../Iconify";
import { FormProvider, RHFTextField } from "../hook-form";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import treatmentService from "../../services/treatmentService";
import stockManagementService from "../../services/stockManagementService";
import DataGrids from "../../utils/DataGrid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGridPro,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
  randomId,
} from "@mui/x-data-grid-generator";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddStock(props) {
  const [medicineOpen, setMedicineOpen] = useState(false);
  const [unit, setUnit] = useState("");
  const [open, setOpen] = useState(false);
  const [tps, setTps] = useState(0);
  const [ns, setNs] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [medicineOptions, setMedicineOptions] = React.useState([]);
  const [type, setType] = useState("");
  const [focusedInput, setFocus] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    medicineId: 0,
    clientId: 0,
    name: "",
    medicineType: 0,
    weight: 0,
    unit: 0,
    quantity: 0,
    manifacturedDate: "",
    expiredDate: "",
    tabletPerStrip: 0,
    noStrips: 0,
  });
  const [batchList, setBatchList] = useState([]);
  const [disable, setDisable] = useState(false);
  const medicineloading = medicineOpen && medicineOptions.length === 0;

  const navigate = useNavigate();

  const methods = useForm();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
  } = methods;

  const onSubmit = async (data) => {
    console.log(data);
    data["clientId"] = props.clientId;
    data["NoStrips"] =
      data["NoStrips"] == undefined ? data["Quantity"] : data["NoStrips"];
    data["medicineId"] = props.medId;
    setDisable(true);
    stockManagementService.UpdateMedicines(data);
    //  alert("Stock Updated successfully");
    toast.success("Stock Updated successfully", {
      position: "top-center",
      toastId: "success1",
    });

    setTimeout(() => {
      props.closeModal();
    }, 2000);
    props.setRefresh(true);
  };

  const onMedicineChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log(value);
    let res = await stockManagementService.GetAllMedicinesByName(
      props.clientId,
      value
    );
    setMedicineOptions(res.data);
    // const response = await fetch(
    //     `https://strata-portal.azurewebsites.net/api/Doctor/getAllCauses?query=` + value
    // );

    // const result = await response.json();
    // const data = result.data;
    // setCauseOptions(data);
  };

  React.useEffect(() => {
    if (!open) {
      onMedicineChangeHandle("");
    }
    // if price and count exist and user isnt changing count, calculate count
    console.log(props.medId);
    if (!isLoaded) {
      GetMedicineDetails();
    }
  }, [open, tps, ns, quantity]);

  const handleChangeType = (event) => {
    setType(event);
    switch (event) {
      case 2:
        setUnit(2);
        break;
      case 3:
        setUnit(3);
      default:
        break;
    }
  };

  const handleChangeUnit = (event) => {
    setUnit(event);
  };

  async function GetMedicineDetails() {
    let res = await stockManagementService.GetMedicineDetails(props.medId);
    var result = res.data.result;
    setDefaultValues(result.medicine);
    setBatchList(result.batch);
    setType(result.medicine.medicineType);
    setIsLoaded(true);
    console.log(res.data.result);

    console.log(defaultValues);
  }

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <br />
          <Stack direction={{ xs: "column", sm: "row" }} top={3} spacing={3}>
            <Controller
              setValue={setValue("Name", defaultValues?.name)}
              rules={{ required: "Field is required." }}
              render={({ field: { onChange, value } }) => (
                console.log(defaultValues),
                (
                  <Autocomplete
                    freeSolo
                    autoSelect
                    id="Name"
                    style={{ width: 300 }}
                    open={medicineOpen}
                    onOpen={() => {
                      setMedicineOpen(true);
                    }}
                    onClose={() => {
                      setMedicineOpen(false);
                    }}
                    onChange={(e, value) => {
                      defaultValues.name = value.name || value;
                      onChange(value.name || value);
                    }}
                    disableClearable
                    value={value}
                    getOptionSelected={(option) => option.name === value}
                    getOptionLabel={(option) => option.name || option}
                    options={medicineOptions}
                    loading={medicineloading}
                    name="Name"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Medicine Name"
                        variant="outlined"
                        size="small"
                        style={{ width: 300 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(ev) => {
                          // dont fire API if the user delete or not entered anything
                          if (
                            ev.target.value !== "" ||
                            ev.target.value !== null
                          ) {
                            onMedicineChangeHandle(ev.target.value);
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {medicineloading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        error={Boolean(errors?.Name)}
                        helperText={errors.Name?.message}
                      />
                    )}
                  />
                )
              )}
              onChange={([event, data]) => {
                console.log(data);
                return data;
              }}
              name="Name"
              control={control}
            />

            <Controller
              control={control}
              name="MedicineType"
              rules={{ required: "Field is required." }}
              setValue={setValue("MedicineType", defaultValues?.medicineType)}
              render={({ field: { onChange, value } }) => (
                console.log(value),
                (
                  <TextField
                    id="MedicineType"
                    label="Medicine Type"
                    variant="outlined"
                    placeholder="Medicine Type"
                    size="small"
                    fullWidth
                    select
                    disabled
                    InputLabelProps={{ shrink: true }}
                    value={value}
                    selected={value}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      defaultValues.medicineType = value;
                      onChange(value);
                    }}
                    error={Boolean(errors?.MedicineType)}
                    helperText={errors.MedicineType?.message}
                    style={{ width: 390 }}
                  >
                    <MenuItem value={1}>Tablet</MenuItem>
                    <MenuItem value={2}>Syrup</MenuItem>
                    <MenuItem value={3}>Powder</MenuItem>
                  </TextField>
                )
              )}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            {type == 1 ? (
              ""
            ) : (
              <React.Fragment>
                <Controller
                  control={control}
                  name="Weight"
                  setValue={setValue("Weight", defaultValues?.weight)}
                  rules={{ required: "Weight is required." }}
                  render={({ field: { onChange, value } }) => (
                    console.log(errors),
                    (
                      <TextField
                        id="Weight"
                        label="Weight"
                        variant="outlined"
                        placeholder="Weight"
                        type="number"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          defaultValues.weight = value;
                          onChange(value);
                        }}
                        error={Boolean(errors?.Weight)}
                        helperText={errors.Weight?.message}
                        style={{ width: 390 }}
                      />
                    )
                  )}
                />
                <Controller
                  control={control}
                  name="Unit"
                  setValue={setValue("Unit", defaultValues?.unit)}
                  rules={{ required: "Field is required." }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="Unit"
                      label="Unit"
                      variant="outlined"
                      placeholder="Unit"
                      size="small"
                      fullWidth
                      select
                      disabled
                      InputLabelProps={{ shrink: true }}
                      value={value}
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;
                        onChange(value);
                        defaultValues.unit = value;
                      }}
                      error={Boolean(errors?.Unit)}
                      helperText={errors.Unit?.message}
                      style={{ width: 390 }}
                    >
                      <MenuItem value={1}>KG</MenuItem>
                      <MenuItem value={2}>ML</MenuItem>
                      <MenuItem value={3}>GM</MenuItem>
                    </TextField>
                  )}
                />
              </React.Fragment>
            )}
            <Controller
              control={control}
              name="Quantity"
              setValue={setValue("Quantity", defaultValues?.quantity)}
              rules={{ required: "Quantity is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="Quantity"
                  label="Quantity"
                  variant="outlined"
                  placeholder="Quantity"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    //defaultValues.quantity = value
                    setDefaultValues({ ...defaultValues, quantity: value });
                    onChange(value);
                    setQuantity(value);
                  }}
                  error={Boolean(errors?.Quantity)}
                  helperText={errors.Quantity?.message}
                  style={{ width: 390 }}
                />
              )}
            />
          </Stack>
          {/* <React.Fragment>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>  
                    <DataGrids
                    tableData={batchList}
                    columns={[{field:"tabletPerStrip", width: 200,  headerName: 'Tablet Per Strip' },{field:"noStrips", width: 200, headerName: 'No Strips' },
                    {field:"expiredDate",editable:true, width: 200,  headerName: 'Expired Date',  type: 'date', valueFormatter: params => 
                    params?.value.slice(0, params?.value.indexOf('T')) },
                    {field:"manifacturedDate",editable:true, width: 200, headerName: 'Manifactured Date', type: 'date', valueFormatter: params => 
                    params?.value.slice(0, params?.value.indexOf('T')) },
                    {
                        field: "col13",
                        headerName: "Action",
                        width: 150,
                        sortable: false,
                        filterable: false,
                        hideable: false,
                  
                        renderCell: (params) => {
                          const onClick = (e) => {
                            e.stopPropagation(); // don't select this row after clicking
                  
                            const api = params;
                            const thisRow = {};
                  
                            api
                              .getAllColumns()
                              .filter((c) => c.field !== "__check__" && !!c)
                              .forEach(
                                (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                              );
                  
                            console.log("---->", thisRow.col2, thisRow.col4);
                  
                            // setUserId(thisRow.col2);
                            // updateUser(thisRow.col2, thisRow.col4);
                            //   return alert(JSON.stringify(thisRow, null, 4));
                          };
                  
                          return (
                            <>
                              <Button variant="contained" onClick={onClick}>
                                update
                              </Button>
                            </>
                          );
                        },
                      },            
                    ]}
                    />  
                </Stack>
            </React.Fragment>    */}

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={disable}
          >
            Save
          </LoadingButton>
          <ToastContainer />
        </Stack>
      </FormProvider>
    </>
  );
}
