import React, { Component, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { main } from "../js/main";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "../App";
import healthCheckupService from "../services/healthCheckupService";
import doctorService from "../services/doctorService";
import { Button, Divider } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { textAlign } from "@mui/system";
function QuotationDetails(props) {
  // const location = useLocation()

  // const empId = new URLSearchParams(location.search).get("ebp");
  // const hcId = new URLSearchParams(location.search).get("ehn");
  const auth = useRecoilValue(authAtom);
  const styles = `
  table{
    border: 1px solid black !important;  border-collapse: collapse; width:100%;
    ;text-align: left;
  }
  th{
    border: 1px solid black !important;  border-collapse: collapse;
    padding: 10px ;   
  }
   td {
    border: 1px solid black !important;  border-collapse: collapse;
    padding: 10px 
  }
 
@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  } 
}
`;

  const [quotationDetails, setQuotationDetails] = useState([]);
  const [logo, setLogo] = useState();
  const [sign, setSign] = useState();
  const [stamp, setStamp] = useState();

  async function printpage() {
    window.print();
  }
  // GetQuotationUpDetails();
  useEffect(() => {
    console.log(props.quotationId);
    if (props.quotationId != 0) GetQuotationUpDetails();

    console.log(auth);
  }, []); //
  async function GetQuotationUpDetails() {
    let res = await healthCheckupService
      .GetQuotationUpDetails(props.quotationId)
      .then((res) => {
        if (res.message == "Success") {
          console.log(res);
          setQuotationDetails(res.data);
          console.log(quotationDetails);
          // if(quotationDetails.length>0){
          // FetchAttachmants("logo")
          // FetchAttachmants("signature")
          // FetchAttachmants("stamp")
          // }
          // $(targetContainer).html(html);
        } else {
          // alert("Something went wrong" + " : " + res.message);
          toast.error("Something went wrong" + " : " + res.message, {
            position: "top-center",
            toastId: "success1",
          });
        }
      });
  }

  async function FetchAttachmants(type) {
    var imageres = await doctorService.GetFile(
      quotationDetails[0].doctorId,
      type
    );
    console.log(imageres.data);
    if (type == "logo") setLogo(imageres.data);
    else if (type === "signature") setSign(imageres.data);
    else if (type == "stamp") setStamp(imageres.data);
    // setSign(
  }

  return (
    <>
      <style>{styles}</style>
      {props.quotationId != 0 ? (
        <div>
          {quotationDetails.length > 0 ? (
            <div>
              <div id="section-to-print">
                <div
                  style={{
                    display: "inline-block !important",
                    position: "static !important",
                    overflow: "visible !important",
                    width: "100% !important" /* styles */,
                    textAlign: "center",
                    // fontSize: "1vmin"
                  }}
                >
                  <div>
                    <div>
                      <div style={{ float: "left" }}>
                        {/* <img src={logo}></img> */}
                      </div>
                      <div
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          color: "#0072BB",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h2>{quotationDetails[0].doctorName}</h2>
                        <h5>Quotation</h5>
                      </div>
                      <div
                        style={{
                          fontWeight: "bold",
                          textAlign: "left",
                          border: "0.5px solid #0072BB",
                          margin: "20px 0 20px 0",
                          padding: "6px",
                          borderRadius: "3px",
                          color: "#0072BB",
                        }}
                      >
                        <h5 style={{ fontWeight: "bold" }}>
                          {quotationDetails[0].doctorClinic == null
                            ? quotationDetails[0].doctorName
                            : quotationDetails[0].doctorClinic}
                        </h5>
                        <p>
                          {quotationDetails[0].doctorAddress}
                          <br />
                          {quotationDetails[0].doctorEmail} /{" "}
                          {quotationDetails[0]?.doctorPhone}
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div style={{ textAlign: "center", display: "block" }}>
                    {/* <input type="hidden" value={new Date().toISOString()} {...register(`testCheckupMappings.${count}.updatedDate`)} /> */}

                    <table style={{ border: "1px solid" }}>
                      <thead>
                        <tr
                          style={{ backgroundColor: "#0072BB", color: "white" }}
                        >
                          <th>Test Name</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quotationDetails.map((l1, i1) => {
                          return (
                            console.log(l1),
                            (
                              <tr>
                                <td>
                                  <label>{l1.testName}</label>
                                </td>

                                <td>
                                  {" "}
                                  <label>{l1.testAmount}</label>{" "}
                                </td>
                              </tr>
                            )
                          );
                        })}

                        <tr>
                          <th>Total</th>
                          <th>{quotationDetails[0].totalAmount}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <Button
                className=""
                variant="contained"
                color="primary"
                onClick={printpage}
                type="button"
              >
                Print
              </Button>
            </div>
          ) : (
            <>No data</>
          )}
        </div>
      ) : (
        <> </>
      )}
      <ToastContainer />
    </>
  );
}

export default QuotationDetails;
