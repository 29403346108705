import React from "react";
import { CircularProgress, Box } from "@mui/material";

const Loading = ({ show }) => {
  return (
    show && (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="rgba(255, 255, 255, 0.8)"
        position="fixed"
        top={0}
        left={0}
        width="100%"
        zIndex={9999}
      >
        <CircularProgress />
      </Box>
    )
  );
};

export default Loading;
