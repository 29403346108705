// import React from "react";
// import Header from "../components/Header";
// import Footer from "../components/Footer";
// import { Box, Button, Stack, TextField, Typography } from "@mui/material";

// const CreateWithNumber = () => {
//   return (
//     <div>
//       <Header />
//       <Stack>
//         <Box boxShadow={10} p={8} m={10} borderRadius={2}>
//           <Stack spacing={2}>
//             <Typography textAlign="center" variant="h6">
//               Create Ayushman Bharat Health Account
//             </Typography>

//             <Typography textAlign="center" variant="h6" color="#E68A82">
//               ABHA (Health ID) Card
//             </Typography>
//             <Typography textAlign="center">using mobile number</Typography>

//             <TextField label="enter the mobie numebr"></TextField>
//             <Button variant="contained" style={{ width: "100px" }}>
//               {" "}
//               Create
//             </Button>
//           </Stack>
//         </Box>
//       </Stack>
//       <Footer />
//     </div>
//   );
// };

// export default CreateWithNumber;

import React, { useEffect, useRef, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Stack,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AbhaService from "../services/AbhaService";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AbhaPopup from "../components/AbhaPopup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const CreateWithNumber = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [aadhaar, setAdharNumber] = useState("");
  const [token, setToken] = useState("");
  const [cert, setCert] = useState("");
  const [number, setNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [txnId, setTxnid] = useState("");
  const [profileData, setProfileData] = useState();
  const [counter, setCounter] = useState(60);
  const [showAadhaar, setShowAadhaar] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isAadharValid, setIsAadharValid] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [showAbhaNumber, setShowAbhaNumber] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [buttonClickCount, setButtonClickCount] = useState(0);

  const isMobile = useMediaQuery("(max-width:950px)");

  useEffect(() => {
    generateToken();

    const interval = setInterval(() => {
      generateToken();
    }, 600000);
  }, []);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const generateToken = async () => {
    const response = await AbhaService.generateToken();
    setToken(response.session.accessToken);
    setCert(response.cert);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setShowError(false);
  };

  // aadharNumbarHandeler

  const aadhaarRefs = useRef([]);

  const handelAadhaarChange = (index, value) => {
    const sanitizedValue = value.replace(/[^\d]/g, "").slice(0, 4);

    const updatedAadharNumber = aadhaar.split("");
    updatedAadharNumber.splice(index * 4, 4, sanitizedValue);
    setAdharNumber(updatedAadharNumber.join(""));

    if (aadhaarRefs.current[index + 1] && sanitizedValue.length === 4) {
      aadhaarRefs.current[index + 1].focus();
    }
  };

  const handleDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !aadhaar[index * 4]) {
      aadhaarRefs.current[index - 1].focus();
    }
  };

  // otpHandeler

  const otpRefs = useRef([]);
  const handleOtpChange = (index, value) => {
    const sanitizedValue = value.replace(/[^\d]/g, "").slice(0, 1);

    setOTP((prevOTP) => {
      const updatedOTP = prevOTP.split("");
      updatedOTP[index] = sanitizedValue;
      return updatedOTP.join("");
    });

    if (otpRefs.current[index + 1] && sanitizedValue.length > 0) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && index > 0 && !otp[index]) {
      otpRefs.current[index - 1].focus();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log(buttonClickCount);
  const handleNext = async () => {
    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      try {
        if (buttonClickCount === 0) {
          if (aadhaar.trim() === "") {
            setIsAadharValid(true);
          }
          if (token && aadhaar && isChecked) {
            const response = await AbhaService.adharVerify({
              aadhaar: aadhaar,
              authenticationToken: token,
              cert: cert,
            });
            // setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setTxnid(response.txnId);
            setNumber(response.mobileNumber);
            if (response.code != 422) {
              setShowOTP(true);
              setButtonClickCount(buttonClickCount + 1);
            } else {
              setOpen(true);
            }
          } else if (!isChecked) {
            setShowError(true);
          }
        } else if (buttonClickCount === 1) {
          if (txnId && showOTP) {
            try {
              if (otp.trim() === "") {
                setOtpError(true);
              } else {
                const response = await AbhaService.createAbhaNumber({
                  otp: otp,
                  authenticationToken: token,
                  txnId: txnId,
                  cert: cert,
                });

                if (response.new == false) {
                  setShow(true);
                } else if (response.new == true) {
                  setProfileData(response);
                }

                console.log(response);
              }
            } catch (error) {
              console.log("please enter correct otp", error);
            }
          }
        }
      } catch (error) {
        console.log("something wrong", error);
      }
    } else if (activeStep === 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      console.log(profileData);
    } else if (activeStep === 3) {
      setShowAbhaNumber(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    "Enter Adhar Number",
    "ABHA Information",
    "ABHA Address",
    "ABHA number Creation",
  ];

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            {showOTP ? (
              <Stack spacing={1} margin="10px 0 3px 0">
                <Stack spacing={3} p={4}>
                  <Typography>
                    We just sent an OTP on your Mobile Number
                  </Typography>

                  <div>
                    {[...Array(6)].map((_, index) => (
                      <TextField
                        key={index}
                        inputRef={(el) => (otpRefs.current[index] = el)}
                        type="text"
                        variant="outlined"
                        size="small"
                        value={otp[index] || ""}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        style={{
                          width: "40px",
                          margin: "2px",
                          border: "1px solid black",
                          borderRadius: "10px",
                        }}
                        inputProps={{ maxLength: 1 }}
                        required // Add required validation
                      />
                    ))}
                    {otpError && (
                      <p style={{ color: "red", marginBottom: "0" }}>
                        OTP is required
                      </p>
                    )}
                  </div>
                </Stack>
              </Stack>
            ) : (
              <Stack
                direction={{ xs: "column", sm: "column" }}
                p={3}
                spacing={2}
              >
                <Typography>Enter Mobile Number</Typography>

                <TextField
                  type="text"
                  variant="outlined"
                  size="small"
                  label="Mobile Number"
                  style={{ width: isMobile ? "80%" : "50%" }}
                />

                {isAadharValid && (
                  <p style={{ color: "red", marginBottom: "0" }}>
                    Mobile Number is required
                  </p>
                )}
              </Stack>
            )}
          </>
        );
      case 1:
        return (
          <Stack spacing={2} p={4}>
            <Typography>Full Name</Typography>
            <TextField label="Enter Full Name" size="small" />

            <Stack spacing={2}>
              <Typography>Date Of Birth*</Typography>
              <Box display="flex" justifyContent="space-between">
                <TextField label="day" size="small" />
                <TextField label="month" size="small" />
                <TextField label="year" size="small" />
              </Box>
            </Stack>
            <Stack>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Gender
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                    size="small"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                    size="small"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Other"
                    size="small"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <TextField label="pincode" size="small" />
          </Stack>
        );
      case 2:
        return (
          <Stack m={6}>
            <Typography variant="h6" textAlign="center">
              Enter Abha Address
            </Typography>
            <Typography>PHR Address</Typography>
            <TextField label="abha address" variant="standard" />
          </Stack>
        );
      case 3:
        return (
          <>
            <Stack m={8} spacing={4}>
              <Typography variant="h5" textAlign="center">
                ABHA Address created successfully
              </Typography>
              <Button variant="contained">Download Your ABHA Card Now</Button>
            </Stack>
          </>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Stack bgcolor="white">
      <Header />

      <Stack
        margin={isMobile ? "10% 4% 10% 4%" : "4% 6% 6% 6%"}
        padding={isMobile ? "7%" : "4%"}
        boxShadow={8}
        borderRadius={2}
      >
        <div>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            <div>{getStepContent(activeStep)}</div>
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                style={{ marginRight: "10px" }}
              >
                Back
              </Button>

              <Button
                disabled={activeStep === 3}
                variant="contained"
                color="primary"
                onClick={handleNext}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </Stack>
      <AbhaPopup open={open} handleClose={handleClose} />
      <Footer />
    </Stack>
  );
};

export default CreateWithNumber;
