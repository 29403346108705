import React, { useState, useEffect } from "react";
import { forwardRef } from "react";
import Avatar from "react-avatar";
import Grid from "@material-ui/core/Grid";

import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import { Container, Typography, Stack } from "@mui/material";
import healthCheckup from "../../services/healthCheckupService";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import {
  Controller,
  useFormContext,
  FormProvider,
  useFormState,
} from "react-hook-form";
import utilService from "../../services/utile";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function validateEmail(email) {
  const re =
    /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
  return re.test(String(email).toLowerCase());
}

function MedicalTest() {
  const [data, setData] = useState([]); //table data
  //const auth = useRecoilValue(authAtom);
  //for error handling
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedRangeType, setSelectedRangeType] = useState("");
  const [rangeType, setRangeType] = useState([]);

  var columns = [
    { title: "id", field: "medicalTestId", hidden: true },
    {
      title: "Category",
      field: "testCategoryId",
      render: (rowData) =>
        categories.find((f) => {
          return f.testCategoryId == rowData.testCategoryId;
        }) == undefined
          ? ""
          : categories.find((f) => {
              return f.testCategoryId == rowData.testCategoryId;
            }).categoryName,
      editComponent: ({ value, onChange }) => (
        //console.log(props),
        <TextField
          id="categoryName"
          label="Category"
          variant="outlined"
          placeholder="Category"
          size="small"
          fullWidth
          select
          InputLabelProps={{ shrink: true }}
          value={value}
          onChange={(e) => {
            const {
              target: { value },
            } = e;
            onChange(value);
          }}
        >
          {categories?.map((c) => {
            return (
              <MenuItem value={c.testCategoryId}>{c.categoryName}</MenuItem>
            );
          })}
        </TextField>
      ),
    },

    { title: "Test Name", field: "testName" },
    {
      title: "Range Type",
      field: "rangeType",
      render: (rowData) =>
        rangeType.find((f) => {
          return f.value == rowData.rangeType;
        }) == undefined
          ? ""
          : rangeType.find((f) => {
              return f.value == rowData.rangeType;
            }).name,
      editComponent: ({ value, onChange }) => (
        //console.log(props),
        <TextField
          id="rangeType"
          label="Range Type"
          variant="outlined"
          placeholder="Range Type"
          size="small"
          fullWidth
          select
          InputLabelProps={{ shrink: true }}
          value={value}
          onChange={(e) => {
            const {
              target: { value },
            } = e;
            onChange(value);
            setSelectedRangeType(value);
          }}
        >
          {rangeType?.map((c) => {
            return <MenuItem value={c.value}>{c.name}</MenuItem>;
          })}
        </TextField>
      ),
    },
    {
      title: "Value",
      field: "normalRange",
      width: "30%",
      editComponent: (props) => {
        return (
          <>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
              {selectedRangeType == 1 ? (
                <>
                  <TextField
                    id="high"
                    onChange={(e) =>
                      props.onRowDataChange({
                        ...props.rowData,
                        high: e.target.value,
                      })
                    }
                    value={props.rowData.normalRange?.split("-")[0]}
                    style={{ width: 80 }}
                    type="number"
                  />
                  <TextField
                    id="low"
                    onChange={(e) =>
                      props.onRowDataChange({
                        ...props.rowData,
                        low: e.target.value,
                      })
                    }
                    value={props.rowData.normalRange?.split("-")[1]}
                    style={{ width: 80 }}
                    type="number"
                  />
                </>
              ) : selectedRangeType == 2 ? (
                <TextField
                  id="normalRange"
                  onChange={(e) =>
                    props.onRowDataChange({
                      ...props.rowData,
                      normalRange: e.target.value,
                    })
                  }
                  value={props.rowData.normalRange}
                  style={{ width: 80 }}
                  type="number"
                />
              ) : (
                "-"
              )}
            </Stack>
          </>
        );
      },
    },
    {
      title: "Unit",
      field: "unit",
      editComponent: (props) => (
        <Autocomplete
          freeSolo
          inputValue={props.value}
          onInputChange={(e, nv) => {
            props.onChange(nv);
          }}
          id="unit"
          options={units}
          getOptionLabel={(option) => option}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Unit" variant="outlined" />
          )}
        />
      ),
    },
  ];

  useEffect(() => {
    // hra.get("/users")
    //     .then(res => {
    //         setData(res.data.data)
    //      })
    //      .catch(error=>{
    //          console.log("Error")
    //      })

    healthCheckup.GetAllMedicalTests().then((res) => {
      setData(res.data.result);
    });

    healthCheckup.GetAllTestCategories().then((res) => {
      setCategories(res.data.result);
    });

    healthCheckup.getAllUnits().then((res) => {
      setUnits(res.data);
    });

    utilService.GetAllRangeType().then((res) => {
      console.log(res.data);
      setRangeType(res.data);
    });
  }, []);

  const handleRowUpdate = (newData, oldData, resolve) => {
    //validation
    let errorList = [];
    if (newData.testName === "") {
      errorList.push("Please enter test name");
    }
    if (newData.testCategoryId === "") {
      errorList.push("Please enter category");
    }
    if (newData.high === "" && newData.low === "" && selectedRangeType == 1) {
      errorList.push("Please enter normal range");
    }
    if (newData.unit === "") {
      errorList.push("Please enter unit");
    }

    if (errorList.length < 1) {
      newData["createdBy"] = 1;
      newData["updatedBy"] = 1;
      newData["createdDate"] = new Date();
      newData["updatedDate"] = new Date();
      newData["deleteDate"] = null;
      newData["normalRange"] =
        selectedRangeType == 1
          ? newData.high + "-" + newData.low
          : newData.normalRange;

      healthCheckup
        .UpdateMedicalTest(newData)
        .then((res) => {
          const dataUpdate = [...data];
          const index = oldData.tableData.id;
          dataUpdate[index] = newData;
          setData([...dataUpdate]);
          resolve();
          setIserror(false);
          setErrorMessages([]);
        })
        .catch((error) => {
          setErrorMessages(["Cannot add data. Server error!"]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowAdd = (newData, resolve) => {
    //validation
    let errorList = [];
    console.log(newData);
    if (newData.testName === undefined) {
      errorList.push("Please enter test name");
    }
    if (newData.testCategoryId === undefined) {
      errorList.push("Please enter category");
    }
    if (
      newData.high === undefined &&
      newData.low === undefined &&
      selectedRangeType == 1
    ) {
      errorList.push("Please enter normal range");
    }
    if (newData.unit === undefined) {
      errorList.push("Please enter unit");
    }

    if (errorList.length < 1) {
      //no error
      newData["createdBy"] = 1;
      newData["updatedBy"] = 1;
      newData["createdDate"] = new Date();
      newData["updatedDate"] = new Date();
      newData["deleteDate"] = null;
      newData["normalRange"] =
        selectedRangeType == 1
          ? newData.high + "-" + newData.low
          : newData.normalRange;

      var Apidata = [];
      Apidata.push(newData);

      console.log(JSON.stringify(newData));
      healthCheckup
        .AddMedicalTest(Apidata)
        .then((res) => {
          console.log(res);
          let dataToAdd = [...data];
          dataToAdd.push(newData);
          setData(dataToAdd);
          resolve();
          setErrorMessages([]);
          setIserror(false);
        })
        .catch((error) => {
          setErrorMessages(["Cannot add data. Server error!"]);
          setIserror(true);
          resolve();
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      resolve();
    }
  };

  const handleRowDelete = (oldData, resolve) => {
    healthCheckup
      .DeleteMedicalTest(oldData.medicalTestId)
      .then((res) => {
        const dataDelete = [...data];
        const index = oldData.tableData.medicalTestId;
        dataDelete.splice(index, 1);
        setData([...dataDelete]);
        resolve();
      })
      .catch((error) => {
        setErrorMessages(["Delete failed! Server error"]);
        setIserror(true);
        resolve();
      });
  };

  return (
    <>
      <div>
        {iserror && (
          <Alert severity="error">
            {errorMessages.map((msg, i) => {
              return <div key={i}>{msg}</div>;
            })}
          </Alert>
        )}
      </div>
      <MaterialTable
        title="Medical test list for health checkup"
        columns={columns}
        data={data}
        icons={tableIcons}
        editable={{
          // onRowUpdate: (newData, oldData) =>
          //     new Promise((resolve) => {
          //         handleRowUpdate(newData, oldData, resolve);

          //     }),
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              handleRowAdd(newData, resolve);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              handleRowDelete(oldData, resolve);
            }),
        }}
      />
    </>
  );
}

export default MedicalTest;
