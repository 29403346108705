import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Blog from "./pages/Blog";
import User from "./pages/User";
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import Register from "./pages/Register";
import Products from "./pages/Products";
import DashboardApp from "./pages/DashboardApp";
import ClientForm from "./components/Forms/ClientForm";
import DoctorForm from "./components/Forms/DoctorForm";
import PatientForm from "./components/Forms/PatientForm";
import ClientPage from "./pages/Client";
import DoctorPage from "./pages/Doctor";
import PreEmployeementPage from "./pages/PreEmployeement";
import PatientPage from "./pages/Patient";
import TreatmentPage from "./pages/Treatment";
import StockManagement from "./pages/StockManagement";
import Home from "./pages/Home";
import MedicalTest from "./pages/MedicalTest";
import HealthCheckupRequestForm from "./components/Forms/HealthCheckupRequestForm";
import Request from "./pages/Request";
import DoctorHealthCheckRequestForm from "./components/Forms/DoctorHealthCheckRequestForm";
import AssignedHealthCheckups from "./pages/AssignedHealthCheckups";
import AllEmpHealthCheckupList from "./pages/AllEmpHealthCheckupList";
import HealthCheckupReport from "./pages/HealthCheckupReport";
import QuotationList from "./pages/QuotationList";
import DoctorAttachments from "./pages/DoctorAttachments";
import AmbulanceCheckList from "./components/Forms/AmbulanceCheckList";
import AllAmbulanceList from "./pages/AllAmbulaceCheckList";
import AmbulanceReportByDate from "./pages/AmbulanceReportByMonth";
import MisReport from "./pages/misReport";
import Form7Report from "./pages/form7Report";
import Reports from "./pages/Reports";
import { useRecoilValue } from "recoil";
import { authAtom } from "./App";
import PreEmployeementReportByDate from "./pages/PreEmployeementReportByDate";
import HealthCheckupGraphReport from "./pages/HealthCheckupGraphReport";
import DefaultValue from "./pages/DefaultValues";
import AbhaRegistration from "./pages/AbhaRegistration";
import Abhapage from "./pages/Abhapage";
import CreateNumber from "./pages/CreateWithNumber";
import CreateWithNumber from "./pages/CreateWithNumber";
// ----------------------------------------------------------------------

export default function Router() {
  const auth = useRecoilValue(authAtom);
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "app", element: <DashboardApp /> },
        { path: "users", element: <User /> },
        { path: "products", element: <Products /> },
        { path: "blog", element: <Blog /> },
        { path: "client", element: <ClientPage /> },
        { path: "doctor", element: <DoctorPage /> },
        { path: "defaultvalue", element: <DefaultValue /> },
        { path: "patient", element: <PatientPage /> },
        { path: "treatment", element: <TreatmentPage /> },
        { path: "stock", element: <StockManagement /> },
        { path: "medicaltest", element: <MedicalTest /> },
        { path: "healthcheckrequest", element: <Request page="request" /> },
        {
          path: "dochc",
          element: <AssignedHealthCheckups page="assingedHc" />,
        },
        {
          path: "healthCheckup",
          element: <DoctorHealthCheckRequestForm page="healthCheckup" />,
        },
        {
          path: "allquotations",
          element: <QuotationList page="allquotations" auth={auth} />,
        },
        // { path: 'preemp', element: <PreEmployeementPage /> },
      ],
    },
    {
      path: "preemp",
      element: <DashboardLayout />,
      children: [
        { path: "preempList", element: <PreEmployeementPage /> },
        { path: "preempReport", element: <PreEmployeementReportByDate /> },
      ],
    },
    {
      path: "doctor",
      element: <DashboardLayout />,
      children: [{ path: "UploadAttachments", element: <DoctorAttachments /> }],
    },
    {
      path: "ambulance",
      element: <DashboardLayout />,
      children: [
        { path: "ambulanceCheckList", element: <AmbulanceCheckList /> },
        { path: "submitedCheckList", element: <AllAmbulanceList /> },
        { path: "Ambulacereport", element: <AmbulanceReportByDate /> },
      ],
    },
    {
      path: "reports",
      element: <DashboardLayout />,
      children: [
        { path: "allReports", element: <Reports page="Reports" /> },
        { path: "misReport", element: <MisReport page="misReport" /> },
        { path: "form7Report", element: <Form7Report page="form7Report" /> },
        {
          path: "allemphcList",
          element: <AllEmpHealthCheckupList page="healthCheckupList" />,
        },
        {
          path: "healthCheckupReport",
          element: <HealthCheckupReport page="healthCheckupReport" />,
        },
        {
          path: "healthCheckupGraphReport",
          element: <HealthCheckupGraphReport page="healthCheckupGraphReport" />,
        },
      ],
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "abhaform",
      element: <AbhaRegistration />,
    },
    {
      path: "abha",
      element: <Abhapage />,
    },
    {
      path: "create",
      element: <CreateWithNumber />,
    },
    {
      path: "register",
      element: <Register />,
    },

    {
      path: "/",
      element: <Home />,
      children: [
        { path: "/", element: <Navigate to="/" /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);
}
