import React, { Component } from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../components/Iconify";
import { FormProvider, RHFTextField } from "../../components/hook-form";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  TextField,
  Checkbox,
  MenuItem,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import doctorService from "../../services/doctorService.js";
import { configUrl } from "../../config.js";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import healthCheckupService from "../../services/healthCheckupService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles({
  autocomplete: {
    width: "300px",
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function HealthCheckupRequestForm(props) {
  const [gender, setGender] = React.useState("");

  const [state, setState] = React.useState("");

  const [city, setCity] = React.useState("");

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [level, setLevel] = useState("");
  const [countries, setCountries] = useState([]);
  const [disable, setDisable] = useState(false);

  const [defaultValues, setDefaultValues] = useState({
    fullName: "",
    userName: "SSGG",
    password: "",
    email: "",
    mobile: "",
    specialist: "",
    pincode: "",
    address: "",
    Id: "",
    cityId: "",
    stateId: "",
    districtId: "",
    gender: "",
  });

  const [taluka, setTaluka] = React.useState("");

  const methods = useForm();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
  } = methods;

  const onSubmit = async (data) => {
    console.log(data);
    if (selectedCountries == 0) {
      // alert("Please select test");
      toast.warn("Please select test", {
        position: "top-center",
        toastId: "success1",
      });

      return;
    }
    setDisable(true);
    const createRequest = {
      TestIds: selectedCountries.map((a) => a.code),
      HealthCheckup: {
        Title: data.Title,
        ClientId: props.auth.clientId,
        Location: data.address,
        HCLevel: data.Level,
        State: data.StateId,
        District: data.DistrictId,
        City: data.CityId,
        StartDate: data.StartDate,
        EndDate: data.EndDate,
        CreatedDate: new Date(),
        UpdatedDate: new Date(),
      },
    };
    console.log(JSON.stringify(createRequest));
    healthCheckupService.CreateRequest(createRequest).then((res) => {
      console.log(res);
      if (res.message == "Success") {
        // alert("Request Submited Successfully");
        toast.success("Request Submited successfully", {
          position: "top-center",
          toastId: "success1",
        });

        setTimeout(() => {
          props.closeModal();
        }, 1000);
        props.setRefresh(true);
      } else {
        // alert("Something Went wrong: " + res.message)
        toast.error("Something Went wrong: " + res.message, {
          position: "top-center",
          toastId: "success1",
        });
      }
    });
  };

  const handleChangeState = (event) => {
    setState(event);
    getDistrict(event);
  };

  const handleChangeCity = (event) => {
    setCity(event);
  };

  const handleChangeTaluka = (event) => {
    setTaluka(event);
    getCities(event);
  };

  useEffect(() => {
    getCities();
    getStates();
    getDistrict();
    GetMedicalTestsForRequest();
  }, []);

  const getStates = async () => {
    let response = await doctorService.GetAllStates();

    setStateList(response.data);
  };

  async function getCities(districId) {
    let response = await doctorService.GetAllCities(districId);
    console.log(response.data);
    setCityList(response.data);
    //fetch(`/api/util/GetAllCities?districId=` + districId).then(response => response.json()).then(result => {
    //    setCityList(result.data);
    //});
  }

  async function getDistrict(stateID) {
    let response = await doctorService.GetAllDistricts(stateID);
    console.log(response.data);
    setDistrictList(response.data);
  }

  function FormValueChange(value, key) {
    setDefaultValues((ev) => ({
      ...ev,
      [key]: value,
    }));
  }

  const handleChangeLevel = (event) => {
    setLevel(event);
  };

  const GetMedicalTestsForRequest = () => {
    healthCheckupService.GetMedicalTestsForRequest().then((res) => {
      console.log(res);
      setCountries(res.data);
    });
  };

  //////// Group by category /////////////////
  const classes = useStyles();
  const [selectedCountries, setSelectedCountries] = useState([]);
  console.log({ selectedCountries });

  const checkOption = (option) => {
    const check = selectedCountries.some((c) => c.code === option.code);
    return check;
  };

  const checkGroup = (group) => {
    const groupLength = countries.filter((c) => c.group === group).length;
    const selectedGroupLength = selectedCountries.filter(
      (c) => c.group === group
    ).length;
    return groupLength === selectedGroupLength;
  };

  const selectGroup = (group) => {
    const groupedCountries = countries.filter((c) => c.group === group);
    const selectedGroupCountries = selectedCountries.filter(
      (c) => c.group === group
    );

    if (selectedGroupCountries.length > 0) {
      setSelectedCountries((prevState) => [
        ...prevState.filter((c) => c.group !== group),
      ]);
    } else {
      setSelectedCountries((prevState) => [...prevState, ...groupedCountries]);
    }
  };

  const unselectOption = (code) => {
    setSelectedCountries((prevState) =>
      prevState.filter((c) => c.code !== code)
    );
  };
  ////////////////////////////////////

  return (
    <div className="list-group list-group-flush">
      <div className="list-group-item">
        {/* <h5 className="card-title">Vision</h5> */}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <br />
          <div className="form-row">
            <div className="form-group col-6">
              <div className="form-row">
                <Controller
                  control={control}
                  name="Title"
                  rules={{ required: "Title is required." }}
                  render={({ field }) => (
                    console.log(errors),
                    (
                      <TextField
                        id="Title"
                        label="Title"
                        variant="outlined"
                        placeholder="Title"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        {...field}
                        error={Boolean(errors?.Title)}
                        helperText={errors.Title?.message}
                        // style={{ width: 390 }}
                      />
                    )
                  )}
                />
              </div>
              <br />
              <div className="form-row">
                <div className="form-group col-3">
                  <Controller
                    control={control}
                    name="Level"
                    rules={{ required: "Field is required." }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="Level"
                        label="Level"
                        variant="outlined"
                        placeholder="Level"
                        size="small"
                        fullWidth
                        select
                        InputLabelProps={{ shrink: true }}
                        value={level}
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          onChange(value);
                          handleChangeLevel(value);
                        }}
                        error={Boolean(errors?.Level)}
                        helperText={errors.Level?.message}
                        // style={{ width: "auto" }}
                      >
                        <MenuItem value={1}>State</MenuItem>
                        <MenuItem value={2}>District</MenuItem>
                        <MenuItem value={3}>City</MenuItem>
                      </TextField>
                    )}
                  />
                </div>
                <div className="form-group col-3">
                  <Controller
                    control={control}
                    name="StateId"
                    value={state}
                    rules={{ required: "State is required." }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="StateId"
                        label="State"
                        variant="outlined"
                        placeholder="State"
                        size="small"
                        fullWidth
                        select
                        value={value}
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          onChange(value);
                          handleChangeState(value);
                        }}
                        error={Boolean(errors?.StateId)}
                        helperText={errors.StateId?.message}
                        // style={{ width: "auto" }}
                      >
                        {stateList?.map((state) => {
                          return (
                            <MenuItem value={state.stateId}>
                              {state.stateTitle}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    )}
                  />
                </div>
                <div className="form-group col-3">
                  {level >= 2 ? (
                    <Controller
                      control={control}
                      name="DistrictId"
                      value={taluka}
                      rules={{ required: "District Address is required." }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="DistrictId"
                          label="District"
                          variant="outlined"
                          placeholder="District"
                          size="small"
                          fullWidth
                          select
                          value={value}
                          onChange={(e) => {
                            const {
                              target: { value },
                            } = e;
                            onChange(value);
                            handleChangeTaluka(value);
                          }}
                          error={Boolean(errors?.DistrictId)}
                          helperText={errors.DistrictId?.message}
                          // style={{ width: "auto" }}
                        >
                          {districtList?.map((district) => {
                            return (
                              <MenuItem value={district.districtid}>
                                {district.districtTitle}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      )}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-group col-3">
                  {level == 3 ? (
                    <Controller
                      control={control}
                      name="CityId"
                      value={city}
                      rules={{ required: "City Address is required." }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="CityId"
                          label="City"
                          variant="outlined"
                          placeholder="City"
                          size="small"
                          fullWidth
                          select
                          value={value}
                          onChange={(e) => {
                            const {
                              target: { value },
                            } = e;
                            onChange(value);
                            handleChangeCity(value);
                          }}
                          error={Boolean(errors?.CityId)}
                          helperText={errors.CityId?.message}
                          // style={{ width: "auto" }}
                        >
                          {cityList?.map((city) => {
                            return (
                              <MenuItem value={city.id}>{city.name}</MenuItem>
                            );
                          })}
                        </TextField>
                      )}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="form-row">
                <Controller
                  control={control}
                  name="address"
                  rules={{ required: "Address is required." }}
                  render={({ field }) => (
                    <TextField
                      id="address"
                      label="address"
                      variant="outlined"
                      placeholder="address"
                      size="small"
                      fullWidth
                      {...field}
                      error={Boolean(errors?.address)}
                      helperText={errors.address?.message}
                      multiline={true}
                      rows={3.5}
                      style={{ width: 560 }}
                    />
                  )}
                />
              </div>
              <br />
              <div className="form-row">
                <div className="form-group col-6">
                  <Controller
                    control={control}
                    name="StartDate"
                    rules={{ required: "Start Date is required." }}
                    render={({ field }) => (
                      <TextField
                        id="StartDate"
                        label="Start Date"
                        variant="outlined"
                        placeholder="Start Date"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        type="date"
                        fullWidth
                        {...field}
                        error={Boolean(errors?.StartDate)}
                        helperText={errors.StartDate?.message}
                        // style={{ width: 390 }}
                      />
                    )}
                  />
                </div>
                <div className="form-group col-6">
                  <Controller
                    control={control}
                    name="EndDate"
                    rules={{ required: "End Date is required." }}
                    render={({ field }) => (
                      <TextField
                        id="End Date"
                        label="End Date"
                        variant="outlined"
                        placeholder="End Date"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        type="date"
                        fullWidth
                        {...field}
                        error={Boolean(errors?.EndDate)}
                        helperText={errors.EndDate?.message}
                        // style={{ width: 390 }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-6" style={{ paddingLeft: "10px" }}>
              <Controller
                control={control}
                name="MedicalTest"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    className={classes.autocomplete}
                    id="medicalTest"
                    options={countries}
                    classes={{
                      option: classes.option,
                    }}
                    onChange={(_, option) => setSelectedCountries([...option])}
                    value={selectedCountries}
                    inputValue={""}
                    autoHighlight
                    multiple
                    disableCloseOnSelect
                    getOptionSelected={(option, value) =>
                      option.code === value.code
                    }
                    getOptionLabel={(option) => option.label}
                    groupBy={(option) => option.group}
                    renderOption={(option) => (
                      <>
                        <Checkbox
                          key={option.code}
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={checkOption(option)}
                        />
                        {option.label}
                      </>
                    )}
                    renderGroup={(params) => (
                      <>
                        <Checkbox
                          key={params.group}
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={checkGroup(params.group)}
                          onChange={() => selectGroup(params.group)}
                        />
                        <span>{params.group}</span>
                        {params.children}
                      </>
                    )}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Choose tests"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                          }}
                        />
                      );
                    }}
                    renderTags={() => {
                      return selectedCountries.map((tag) => (
                        <LoadingButton
                          size="small"
                          variant="contained"
                          key={tag.code}
                          endIcon={<CancelIcon />}
                          onClick={() => unselectOption(tag.code)}
                        >
                          {tag.label}
                        </LoadingButton>
                      ));
                    }}
                  />
                )}
              />
            </div>
            <div className="form-group col-12">
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={disable}
              >
                Submit Request
              </LoadingButton>
            </div>
          </div>
        </FormProvider>
      </div>
      <ToastContainer />
    </div>
  );
}
