import axios from "axios";
import { configUrl } from "../config.js";
import axiosInstance from "./interceptore.js";
// const userToken = localStorage.getItem("userToken");
// const axiosInstance = axios.create({
//   baseURL: configUrl.StrataServer,
//   headers: {
//     Authorization: `Bearer ${userToken}`,
//   },
// });

const GetAllStates = async () => {
  let response = await axiosInstance.get(`/api/util/GetAllStates`);
  console.log(response);
  return response.data;
};
const GetAllCities = async (cities) => {
  if (cities) {
    let response = await axiosInstance.get(
      `/api/util/GetAllCities?districId=` + cities
    );
    return response.data;
  } else {
    let response = await axiosInstance.get(`/api/util/GetAllCities?districId=`);
    return response.data;
  }
};
const GetAllDistricts = async (stateID) => {
  if (stateID != undefined) {
    let response = await axiosInstance.get(
      `/api/util/GetAllDistricts?stateID=` + stateID
    );
    return response.data;
  } else {
    let response = await axiosInstance.get(
      `/api/util/GetAllDistricts?stateID=`
    );
    return response.data;
  }
};

const GetAllRangeType = async () => {
  let response = await axiosInstance.get(`/api/util/GetAllRangeType`);
  return response.data;
};
const GetDefaultValue = async () => {
  let response = await axiosInstance.get(`/api/util/GetDefaultValues`);
  return response.data;
};
const AddDefaultValues = async (defaultValue) => {
  let response = await axiosInstance.post(
    `/api/util/AddDefaultValues`,
    defaultValue
  );
  return response.data;
};

const DeleteDefaultValue = async (id) => {
  let response = await axiosInstance.get(
    `/api/Util/DeleteDefaultValues?id=${id}`
  );
  console.log(response);
  return response.data;
};

const UpdateDefaultValue = async (defaultValue) => {
  let response = await axiosInstance.post(
    `/api/Util/UpdateDefaultValues`,
    defaultValue
  );
  console.log(response);
  return response.data;
};

export default {
  GetAllStates: GetAllStates,
  GetAllCities: GetAllCities,
  GetAllDistricts: GetAllDistricts,
  GetAllRangeType: GetAllRangeType,
  GetDefaultValue: GetDefaultValue,
  AddDefaultValues: AddDefaultValues,
  DeleteDefaultValue: DeleteDefaultValue,
  UpdateDefaultValue: UpdateDefaultValue,
};
