import axios from "axios";
import { configUrl } from "../config.js";
import axiosInstance from "./interceptore.js";
// const userToken = localStorage.getItem("userToken");
// const axiosInstance = axios.create({
//   baseURL: configUrl.StrataServer,
//   headers: {
//     Authorization: `Bearer ${userToken}`,
//   },
// });

const GetEmployeeByState = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/EmployeeCountByState?clientId=${clientId}`
  );
  return response.data;
};

const GetEmployeeByDistrict = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/EmployeeCountByDistrict?clientId=${clientId}`
  );
  return response.data;
};

const GetEmployeeByCity = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/EmployeeCountByCity?clientId=${clientId}`
  );
  return response.data;
};

const GetEmployeeByTreatment = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/EmployeeCountByTreatment?clientId=${clientId}`
  );
  return response.data;
};

const GetEmployeeCount = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/GetEmployeeCount?clientId=${clientId}`
  );
  return response.data;
};

const GetTreatmentCount = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/GetTreatmentCount?clientId=${clientId}`
  );
  console.log(response);
  return response.data;
};

const GetAdminDashboardCount = async () => {
  let response = await axiosInstance.get(
    `/api/Analytics/GetAdminDashboardCount`
  );
  console.log(response);
  return response.data;
};
const GetClientByState = async (clientId) => {
  let response = await axiosInstance.get(`/api/Analytics/ClientCountByState`);
  return response.data;
};
const GetClientByDistrict = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/ClientCountByDistrict`
  );
  return response.data;
};
const GetClientByCity = async (clientId) => {
  let response = await axiosInstance.get(`/api/Analytics/ClientCountByCity`);
  return response.data;
};

const GetDoctorDashboardCount = async (docId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/GetDoctorDashboardCount?docId=${docId}`
  );
  return response.data;
};

const GetMISReportForHC = async (hcId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/GetMISReport?hcId=${hcId}`
  );
  return response.data;
};

const GetForm7Report = async (hcId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/GetForm7Report?hcId=${hcId}`
  );
  return response.data;
};
const HcGraphReport = async (hcId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/HcGraphReport?hcId=${hcId}`
  );
  return response.data;
};
const PatientByCause = async (cId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/PatientByCause?clientId=${cId}`
  );
  return response.data;
};

const GetMedicinUses = async (cId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/getMedicinUses?clientId=${cId}`
  );
  return response.data;
};
const GetHealthCheckupCount = async (cId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/getHealthCheckupCount?clientId=${cId}`
  );
  return response.data;
};
const GetFirstAidLocationCount = async (cId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/getFirstAidLocationCount?clientId=${cId}`
  );
  return response.data;
};

const GetPreEmpCharts = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/PreEmpCharts?clientId=${clientId}`
  );
  console.log(response.data);
  return response.data;
};

const HcGraphReportNoRange = async (hcId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/HcGraphReportNoRange?hcId=${hcId}`
  );
  return response.data;
};

export default {
  GetEmployeeByState: GetEmployeeByState,
  GetEmployeeCount: GetEmployeeCount,
  GetTreatmentCount: GetTreatmentCount,
  GetEmployeeByDistrict: GetEmployeeByDistrict,
  GetEmployeeByCity: GetEmployeeByCity,
  GetEmployeeByTreatment: GetEmployeeByTreatment,
  GetAdminDashboardCount: GetAdminDashboardCount,
  GetClientByState: GetClientByState,
  GetClientByDistrict: GetClientByDistrict,
  GetClientByCity: GetClientByCity,
  GetDoctorDashboardCount: GetDoctorDashboardCount,
  GetMISReportForHC: GetMISReportForHC,
  GetForm7Report: GetForm7Report,
  HcGraphReport: HcGraphReport,
  PatientByCause: PatientByCause,
  GetMedicinUses: GetMedicinUses,
  GetHealthCheckupCount: GetHealthCheckupCount,
  GetFirstAidLocationCount: GetFirstAidLocationCount,
  GetPreEmpCharts: GetPreEmpCharts,
  HcGraphReportNoRange: HcGraphReportNoRange,
};
