import axios from "axios";
import { configUrl } from "../config.js";
import axiosInstance from "./interceptore.js";
// const userToken = localStorage.getItem("userToken");
// const axiosInstance = axios.create({
//   baseURL: configUrl.StrataServer,
//   // headers: {
//   //   Authorization: `Bearer ${userToken}`,
//   // },
// });

const GetTretMent = async (id) => {
  let response = await axiosInstance.get(
    `/api/doctor/getAllTreatments?doctorId=${id}`
  );
  console.log(response);
  return response.data;
};

const AddTreatment = async (value) => {
  let response = await axiosInstance.post(`/api/doctor/AddTreatment`, value);
  console.log(response);
  return response.data;
};

const SearchPetiont = async (value, clientId) => {
  let response = await axiosInstance.get(
    `/api/Patient/searchPatient?query=${value}&clientId=${clientId}`
  );
  console.log(response);
  return response.data;
};

const GetAllCauses = async (value) => {
  let response = await axiosInstance.get(
    `/api/Doctor/getAllCauses?query=${value}`
  );
  console.log(response);
  return response.data;
};

export default {
  GetTretMent: GetTretMent,
  AddTreatment: AddTreatment,
  SearchPetiont: SearchPetiont,
  GetAllCauses: GetAllCauses,
};
