import axios from "axios";
import { da } from "date-fns/locale";
import { configUrl } from "../config.js";
import axiosInstance from "./interceptore.js";

// const userToken = localStorage.getItem("userToken");

// const axiosInstance = axios.create({
//   baseURL: configUrl.StrataServer.trim("/"),
//   headers: {
//     Authorization: `Bearer ${userToken}`,
//   },
// });
const GetAllCheckListForClient = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Ambulance/GetClientSubmitedCheckList?clientId=${clientId}`
  );
  console.log(response);
  return response.data;
};
const GetAllCheckList = async () => {
  let response = await axiosInstance.get(`/api/Ambulance/GetAllCheclList`);
  console.log(response);
  return response.data;
};
const SubmitCheckList = async (data) => {
  console.log(data);
  let response = await axiosInstance.post(
    `/api/Ambulance/RegAmbulanceCheckList`,
    data
  );
  return response.data;
};

const GetDetailCheckList = async (chekListMappingId) => {
  let response = await axiosInstance.get(
    `/api/Ambulance/GetDetailCheckList?mappingId=${chekListMappingId}`
  );
  console.log(response);
  return response.data;
};
const GetAmbulaceCheckListByDate = async (data) => {
  let response = await axiosInstance.get(
    `/api/Ambulance/GetAmbulaceCheckListByDate?startDate=${data.startDate}&endDate=${data.endDate}`
  );
  console.log(response);
  return response.data;
};

export default {
  GetAllCheckList: GetAllCheckList,
  SubmitCheckList: SubmitCheckList,
  GetDetailCheckList: GetDetailCheckList,
  GetAllCheckListForClient: GetAllCheckListForClient,
  GetAmbulaceCheckListByDate: GetAmbulaceCheckListByDate,
};
