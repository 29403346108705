import { Button, Typography, useMediaQuery } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Abhapage = () => {
  const isMobile = useMediaQuery("(max-width:1000px)");
  return (
    <>
      <link rel="stylesheet" href="/static/css/style.css"></link>
      <Stack bgcolor="white">
        <Header />

        <Stack
          direction={{ xs: "column", sm: "row" }}
          margin={isMobile ? "5%" : "2% 2% 2% 2%"}
        >
          <Box flex={1} p={isMobile ? 2 : 8}>
            <Typography variant="h3" mb={2}>
              ABHA - Ayushman Bharat Health Account or Health ID Card Approved
              by NDHM.GOV.IN
            </Typography>
            <Typography variant="h7" mt={2}>
              Ayushman Bharat Health Account (ABHA) or Health ID is an
              initiative of the Indian government under the Ayushman Bharat
              Digital Mission (ABDM) for Indian citizens to establish a
              centralised database of all their health-related data.
            </Typography>
            <Typography variant="h6" mt={2}>
              It is crucial to remember that
            </Typography>
            <Typography>
              • The health records associated with Health IDs or ABHA numbers
              can only be accessed with the informed consent of the individual.
              <br />• People have the option to create an alias, referred to as
              an "ABHA address" (similar to the email ID xyz@ndhm with a
              password).
            </Typography>
          </Box>

          <Box
            flex={1}
            bgcolor="white"
            p={isMobile ? 2 : 8}
            borderRadius={2}
            boxShadow={10}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="h3" textAlign="center">
              Create Ayushman Bharat Health Account
            </Typography>
            <Typography
              variant="h3"
              color="red"
              textAlign="center"
              fontWeight="bolder"
            >
              ABHA (Health ID) Card
            </Typography>
            <Typography mb={isMobile ? 6 : 8}>using</Typography>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
              {" "}
              <Button
                href="/abhaform"
                variant="contained"
                style={{ padding: "10px" }}
              >
                Aadhaar Number
              </Button>
              <Button variant="contained" href="#" style={{ padding: "10px" }}>
                Mobile number
              </Button>
            </Stack>
          </Box>
        </Stack>
        <Footer />
      </Stack>
    </>
  );
};

export default Abhapage;
