import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import CancelIcon from "@mui/icons-material/Cancel";

function AgChartErrorPopUp({ handleClose, openPopUp, data }) {
  console.log(data);

  return (
    <Dialog
      open={openPopUp}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogActions>
        <CancelIcon
          onClick={handleClose}
          style={{ position: "fixed", marginTop: "30px" }}
        />
      </DialogActions>
      {data.map((datas, index) => {
        return (
          <div style={{ padding: "15px", color: "red" }}>
            <li>{datas}</li>
          </div>
        );
      })}
    </Dialog>
  );
}

export default AgChartErrorPopUp;
