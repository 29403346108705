// import React, { useEffect, useState } from "react";
// import { AgGridReact } from "ag-grid-react";
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
// import "../../App.css";
// import Dialog from "@mui/material/Dialog";
// import { useRecoilValue } from "recoil";
// import { authAtom } from "../../App";
// import AgChartErrorPopUp from "../AgChartErrorPopUp";
// import HealthCheckUpErrorPopUp from "../HealthCheckUpErrorPopUp";

// const HealthCheckUpTable = ({ onClose, open }) => {
//   const [rowData, setRowData] = useState(generateDefaultRows());
//   const [onOpen, setOnOpen] = React.useState(false);
//   const [error, setError] = useState([]);

//   const auth = useRecoilValue(authAtom);

//   function generateDefaultRows() {
//     const defaultRows = [];
//     for (let i = 0; i < 50; i++) {
//       defaultRows.push({
//         // clientId: "",
//         // name: "",
//         // email: "",
//         // contactNumber: "",
//         // password: "",
//         gender: "",
//         dob: "",
//         address: "",
//         pincode: "",
//         nativePlace: "",
//         physician: "",
//         family: "",
//         occupation: "",
//         isSmoker: "",
//         quitSmokingHowLong: "",
//         howManyPacks: "",
//         isAlcoholic: "",
//         alcoholicSinceWhen: "",
//         quitAlcoholHowLong: "",
//         bowelhabits: "",
//         dyspepsia: "",
//       });
//     }
//     return defaultRows;
//   }

//   const [gridOptions] = useState({
//     defaultColDef: {
//       editable: true,
//       resizable: true,
//       width: 160,
//     },
//     rowHeight: 30,
//     columnDefs: [
//       {
//         headerName: "Employee Name",
//         headerClass: "ag-header-cell-custom",
//         field: "name",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Height",
//         field: "heighet",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Weight",
//         field: "weight",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Expected Weight",
//         field: "expectedWeight",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Bp",
//         field: "bp",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "High Bp",
//         field: "highBp",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Pulse",
//         field: "pulse",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "BMI",
//         field: "bmi",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },

//       {
//         headerName: "Vision With Glass Near Right",
//         field: "visionWithGlassNearRight",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Vision With Glass Near Left",
//         field: "visionWithGlassNearLeft",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Vision With Glass Far Right",
//         field: "visionWithGlassFarRight",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Vision With Glass Far Left",
//         field: "visionWithGlassFarLeft",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Vision Without Glass Near Right",
//         field: "visionWithGlassNearRight",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Vision Without Glass Near Left",
//         field: "visionWithGlassNearLeft",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Vision Without Glass Far Right",
//         field: "visionWithGlassFarRight",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Vision Without Glass Far Left",
//         field: "visionWithGlassFarLeft",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Color Bind",
//         field: "colorBind",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//         cellEditor: "agSelectCellEditor",
//         cellEditorParams: {
//           values: ["Yes", "No"],
//         },
//       },

//       {
//         headerName: "Vision Remark",
//         field: "visionRemark",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Vaccination",
//         field: "vaccination",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//       {
//         headerName: "Cholera",
//         field: "cholera",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//         cellEditor: "agSelectCellEditor",
//         cellEditorParams: {
//           values: ["Yes", "No"],
//         },
//       },
//       {
//         headerName: "Typhoid",
//         field: "typhoid",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//         cellEditor: "agSelectCellEditor",
//         cellEditorParams: {
//           values: ["Yes", "No"],
//         },
//       },
//       {
//         headerName: "Health Cheakup Result",
//         field: "healthCheckupResult",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//         cellEditor: "agSelectCellEditor",
//         cellEditorParams: {
//           values: ["UnFit", "Fit"],
//         },
//       },

//       {
//         headerName: "HCR Remark",
//         field: "remark",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },

//       {
//         headerName: "Advice",
//         field: "advice",
//         cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
//       },
//     ],
//   });

//   const handleClosePopUp = () => {
//     setOnOpen(false);
//   };

//   const handleSubmit = async () => {
//     setRowData(generateDefaultRows());

//     var filledRows = rowData.filter((row) =>
//       Object.values(row).some((value) => value !== "")
//     );

//     filledRows = filledRows.map((item) => {
//       const updatedItem = { ...item };
//       for (const key of Object.keys(updatedItem)) {
//         if (updatedItem[key] === "Yes") {
//           updatedItem[key] = "true";
//         } else if (updatedItem[key] === "No") {
//           updatedItem[key] = "false";
//         }
//       }
//       updatedItem.clientId = auth.clientId;
//       return updatedItem;
//     });
//     console.log(filledRows);

//     filledRows.forEach((row, rowIndex) => {
//       Object.keys(row).forEach((colName) => {
//         const value = row[colName];
//         console.log(value);

//         if (!value) {
//           error.push(`Row ${rowIndex + 1}: ${colName} is required.`);
//         } else {
//           // if (colName === "email" && !/^\S+@\S+\.\S+$/.test(value)) {
//           //   errors.push(
//           //     `Row ${rowIndex + 1}: ${colName} is not a valid email.`
//           //   );
//           // }
//           // if (colName === "contactNumber" && !/^\d+$/.test(value)) {
//           //   errors.push(
//           //     `Row ${rowIndex + 1}: ${colName} is not a valid number.`
//           //   );
//           // }
//         }
//       });
//     });

//     if (error.length > 0) {
//       // alert(errors.join("\n"));
//       setOnOpen(true);
//     } else {
//       alert("Data Filled");
//     }
//   };

//   return (
//     <>
//       <Dialog open={open} onClose={onClose} fullWidth="xl" maxWidth="xl">
//         {/* <Modal isOpen={open} onRequestClose={onClose} style={customStyles}> */}
//         <div
//           className="ag-theme-alpine"
//           style={{ height: "500px", width: "100%" }}
//         >
//           <AgGridReact gridOptions={gridOptions} rowData={rowData} />
//           <HealthCheckUpErrorPopUp
//             handleClosePopUp={handleClosePopUp}
//             onOpen={onOpen}
//             data={error}
//           />
//         </div>

//         <button onClick={handleSubmit}>Submit</button>
//         {/* </Modal> */}
//       </Dialog>
//     </>
//   );
// };

// export default HealthCheckUpTable;

import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../App";
import employeeService from "../../services/employeeService";
import { Email } from "@material-ui/icons";
import healthCheckupService from "../../services/healthCheckupService";

function ExcelDownloadButton() {
  const [clientList, setClientsList] = useState([]);
  const [names, setNames] = useState([]);
  const [emails, setEmails] = useState([]);
  const[employeeIds, setEmployeeIds] = useState([]);
  const[testNames, setTestNames] = useState([]);

  const auth = useRecoilValue(authAtom);

  
  console.log(emails);

  const downloadExcelTemplate = () => {
    const data = [
      testNames,
  ...names.map((name, index) => [name, emails[index], employeeIds[index]]),
];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const arrayBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([arrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "customized_template.xlsx");
  };
  useEffect(() => {
    fetchPatients();
    getMedicalTest();
  }, []);
  async function fetchPatients() {
    let res = await employeeService.GetAllPatients(auth.clientId);
    setClientsList(res.data.result);
    for (let i = 0; i < res.data.result.length; i++) {
      const Names = res.data.result[i].fullName;
  
      names.push(Names);
    }
    for (let i = 0; i < res.data.result.length; i++) {
      const Emails = res.data.result[i].email;
  
      emails.push(Emails);
    }
  
    for (let i = 0; i < res.data.result.length; i++) {
      const empIds = res.data.result[i].employeeID;
      employeeIds.push(empIds);
    }
  
  }

  async function getMedicalTest(){
    let res = await healthCheckupService.GetMedicalTestNames()
    var tdata = ["EmployeeName",
    "Email",
    "EmployeeId",
    "Date"]

    res.data.forEach(element => {
      tdata.push(element);
    });

    setTestNames(tdata);
    
  }

  console.log(clientList);

  return (
    <div>
      <Button
        startIcon={<DownloadForOfflineIcon />}
        variant="contained"
        onClick={downloadExcelTemplate}
      >
        Download Excel Template
      </Button>
    </div>
  );
}

export default ExcelDownloadButton;
