import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../App.css";
import Dialog from "@mui/material/Dialog";
import { useRecoilValue } from "recoil";
import { authAtom } from "../../App";
import employeeService from "../../services/employeeService";
import AgChartErrorPopUp from "../AgChartErrorPopUp";

const BulkEmployeePopup = ({ onClose, open }) => {
  const [rowData, setRowData] = useState(generateDefaultRows());
  const [newData, setNewData] = useState([]);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [errors, setErrors] = useState([]);

  const auth = useRecoilValue(authAtom);

  function generateDefaultRows() {
    const defaultRows = [];
    for (let i = 0; i < 50; i++) {
      defaultRows.push({
        // clientId: "",
        employeeId: "",
        name: "",
        email: "",
        contactNumber: "",
        password: "",
        gender: "",
        dob: "",
        address: "",
        pincode: "",
        nativePlace: "",
        physician: "",
        family: "",
        occupation: "",
        isSmoker: "",
        quitSmokingHowLong: "",
        howManyPacks: "",
        isAlcoholic: "",
        alcoholicSinceWhen: "",
        quitAlcoholHowLong: "",
        bowelhabits: "",
        dyspepsia: "",
      });
    }
    return defaultRows;
  }

  const [gridOptions] = useState({
    defaultColDef: {
      editable: true,
      resizable: true,
      width: 160,
    },
    rowHeight: 30,
    columnDefs: [
      // {
      //   headerName: "ClientID",
      //   field: "clientId",
      //   cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      //   hide: true,
      //   value: auth.clientId,
      // },
      {
        headerName: "Employee ID",
        field: "employeeId",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      },
      {
        headerName: "Name",
        headerClass: "ag-header-cell-custom",
        field: "name",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      },
      {
        headerName: "Email",
        field: "email",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      },
      {
        headerName: "Number",
        field: "contactNumber",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      },

      {
        headerName: "ContracterName",
        field: "contractorName",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      },
      {
        headerName: "Password",
        field: "password",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      },
      {
        headerName: "Gender",
        field: "gender",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Male", "Female", "Other"],
        },
      },
      {
        headerName: "BirthDate",
        field: "dob",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      },
      {
        headerName: "Address",
        field: "address",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      },

      {
        headerName: "Pincode",
        field: "pincode",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      },
      {
        headerName: "NativePlace",
        field: "nativePlace",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      },
      {
        headerName: "FamilyPhysician",
        field: "physician",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      },
      {
        headerName: "Family",
        field: "family",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Joint", "Nuclear"],
        },
      },
      {
        headerName: "Occupation",
        field: "occupation",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Government Servent", "Service", "Business"],
        },
      },
      {
        headerName: "Do you Smoke?",
        field: "isSmoker",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Yes", "No"],
        },
      },
      {
        headerName: "quitSmokingHowLong",
        field: "quitSmokingHowLong",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Less than year", "More than year", "More than 3 years"],
        },
      },
      {
        headerName: "If yes,packs/day",
        field: "howManyPacks",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
      },
      {
        headerName: "Consume alcohole?",
        field: "isAlcoholic",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Yes", "No"],
        },
      },
      {
        headerName: "Alcoholic Since When",
        field: "alcoholicSinceWhen",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Less than year", "More than year", "More than 3 years"],
        },
      },
      {
        headerName: "Quit Alcohol How Long",
        field: "quitAlcoholHowLong",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Less than year", "More than year", "More than 3 years"],
        },
      },

      {
        headerName: "Bowel Habits-Constipation",
        field: "bowelhabits",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Yes", "No"],
        },
      },
      {
        headerName: "Dyspepsia",
        field: "dyspepsia",
        cellStyle: { borderRight: "1px solid #ccc", backgroundColor: "white" },
        cellEditor: "agSelectCellEditor",
        cellEditorParams: {
          values: ["Yes", "No"],
        },
      },
    ],
  });

  const handleClose = () => {
    setOpenPopUp(false);
    setErrors([]);
  };

  const handleSubmit = async () => {
    var filledRows = rowData.filter((row) =>
      Object.values(row).some((value) => value !== "")
    );

    filledRows = filledRows.map((item) => {
      const updatedItem = { ...item };
      for (const key of Object.keys(updatedItem)) {
        if (updatedItem[key] === "Yes") {
          updatedItem[key] = "true";
        } else if (updatedItem[key] === "No") {
          updatedItem[key] = "false";
        }
      }
      updatedItem.clientId = auth.clientId;
      return updatedItem;
    });
    console.log(filledRows);

    filledRows.forEach((row, rowIndex) => {
      Object.keys(row).forEach((colName) => {
        const value = row[colName];

        // console.log(value);

        if (!value) {
          errors.push(`Row ${rowIndex + 1}: ${colName} is required.`);
        } else {
          if (colName === "email" && !/^\S+@\S+\.\S+$/.test(value)) {
            errors.push(
              `Row ${rowIndex + 1}: ${colName} is not a valid email.`
            );
          }

          if (colName === "contactNumber" && !/^\d+$/.test(value)) {
            errors.push(
              `Row ${rowIndex + 1}: ${colName} is not a valid number.`
            );
          }
        }
      });
    });

    if (errors.length > 0) {
      // alert(errors.join("\n"));
      setOpenPopUp(true);
    } else {
      const response = await employeeService.upsertBulkEployee(filledRows);
      console.log(response);
      onClose();
      // setOpenPopUp(false);
      alert("Data Submited");
      setRowData(generateDefaultRows());
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth="xl" maxWidth="xl">
      {/* <Modal isOpen={open} onRequestClose={onClose} style={customStyles}> */}
      <div
        className="ag-theme-alpine"
        style={{ height: "500px", width: "100%" }}
      >
        <AgGridReact gridOptions={gridOptions} rowData={rowData} />
        <AgChartErrorPopUp
          openPopUp={openPopUp}
          handleClose={handleClose}
          data={errors}
        />
      </div>

      <button onClick={handleSubmit}>Submit</button>
      {/* </Modal> */}
    </Dialog>
  );
};

export default BulkEmployeePopup;
