import React, { Component } from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../components/Iconify";
import {
  FormProvider as MyFormProvider,
  RHFTextField,
} from "../../components/hook-form";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { phoneRegex } from "../../App";
import useApi from "../../hooks/useApi";
import { getStates, getCities, getDistrict } from "../../api/util";
import { result } from "lodash";
import Alert from "@mui/material/Alert";
import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

import { makeStyles } from "@material-ui/core/styles";
import {
  Controller,
  useFormContext,
  FormProvider,
  useFormState,
} from "react-hook-form";
import { Hidden } from "@material-ui/core";
import clientService from "../../services/clientService.js";
import utile from "../../services/utile";
import { configUrl } from "../../config.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

export function RegisterForm(props) {
  console.log("RegisterForm", props);
  //console.log("RegisterForm", props.defaultValues.name)

  const navigate = useNavigate();
  const [state, setState] = useState("");

  const [city, setCity] = useState("");

  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const getDistrictData = useApi(getDistrict);
  const getCityData = useApi(getCities);
  const [currendefaultvalue, setCurrentDefoultValue] = useState({
    address: "",
    adminCityId: 0,
    adminContactNumber: "",
    adminDistrictId: 0,
    adminId: 0,
    adminStateId: 0,
    adminaddress: "",
    adminemail: "",
    adminpincode: "",
    cityId: 0,
    clinetId: 0,
    contactNumber: "",
    districtId: 0,
    email: null,
    fullName: "",
    gstn: "",
    name: "",
    panCardNumber: "",
    password: "",
    pincode: "",
    registrationNumber: "",
    stateId: 0,
    userName: "",
    userTypeId: null,
  });
  console.log(currendefaultvalue);

  const [taluka, setTaluka] = React.useState("");

  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const formState = useFormState();

  console.log(formState);
  useEffect(() => {
    getCities();
    getStates();
    getDistrict();
    if (props.defaultValues) {
      setCurrentDefoultValue(props.defaultValues);
      setState(props.defaultValues.stateId);
      setTaluka(props.defaultValues.districtId);
      setCity(props.defaultValues.cityId);
      console.log("currendefaultvalue", props.defaultValues);
    }
  }, [props.defaultValues]); //

  const [showPassword, setShowPassword] = useState(false);

  const getStates = async () => {
    let response = await clientService.GetAllStates();

    setStateList(response.data);
    //fetch(`/api/util/GetAllStates`).then(response => response.json()).then(result => {
    //    setStateList(result.data);
    //    console.log(result.data);
    //});
  };
  const getStateData = useApi(getStates);
  //function getCities(districId) {
  //    console.log(districId);
  //    if (districId != undefined) {
  //       fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllCities?districId=` + districId).then(response => response.json()).then(result => {
  //            setCityList(result.data);
  //        });
  //    }
  //    else {
  //       fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllCities?districId=`).then(response => response.json()).then(result => {
  //            setCityList(result.data);
  //        });
  //    }

  //}

  async function getCities(districId) {
    let response = await clientService.GetAllCities(districId);
    console.log(response.data);
    setCityList(response.data);
    //fetch(`/api/util/GetAllCities?districId=` + districId).then(response => response.json()).then(result => {
    //    setCityList(result.data);
    //});
  }
  async function getDistrict(stateID) {
    console.log(stateID);
    let res = await clientService.GetAllDistricts(stateID);
    console.log(res);
    setDistrictList(res.data);
    //if (stateID != undefined) {
    //   fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllDistricts?stateID=` + stateID).then(response => response.json()).then(result => {
    //        setDistrictList(result.data);
    //    });
    //}
    //else {
    //   fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllDistricts?stateID=`).then(response => response.json()).then(result => {
    //        setDistrictList(result.data);
    //    });
    //}
  }

  var method = props.clientId == 0 ? "post" : "put";
  var body = currendefaultvalue;
  body["UserTypeId"] = 2;
  console.log(JSON.stringify(body));

  var requestoptions = {
    method: method,
    headers: { "content-type": "application/json" },
    body: JSON.stringify(body),
  };
  function updateClient(user) {
    fetch(configUrl.StrataServer + `/api/client/UpdateClient`, requestoptions)
      .then((response) => response.json())
      .then((result) => {
        // alert("user registered successfully");
        toast.success("User registered successfully", {
          position: "top-center",
          toastId: "success1",
        });
      });
  }
  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const RegisterSchema = Yup.object().shape({
    Client: Yup.object().shape({
      Name: Yup.string().required("First name required"),
    }),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    userName: Yup.string().required("Username name required"),
    ContactNumber: Yup.string()
      .required("Mobile number is Required")
      .matches("[0-9]{10}", "Phone number is not valid"),
    pincode: Yup.string().required("Pincode Required"),
    address: Yup.string().required("Address is required"),
    panCardNumber: Yup.string().required("PAN number Required"),
    registrationNumber: Yup.string().required("Registration number Required"),
    gstn: Yup.string().required("GSTN Required"),
  });

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  function FormValueChange(value, key) {
    setCurrentDefoultValue((ev) => ({
      ...ev,
      [key]: value,
    }));
  }

  const handleChangeState = (event) => {
    setState(event);
    getDistrict(event);
    console.log(event);
  };

  const handleChangeCity = (event) => {
    setCity(event);
  };

  const handleChangeTaluka = (event) => {
    setTaluka(event);
    getCities(event);
  };
  const handleChange = (e) => {
    console.log("e.target.value", e.target.value);
    console.log("e.target.name", e.target.name);
  };

  return (
    <>
      <Controller
        control={control}
        name="Client.ClientId"
        setValue={setValue(
          "Client.ClientId",
          props.defaultValues?.client?.clientId
        )}
        render={({ field: { onChange, value } }) => (
          // console.log(prop)
          <Hidden value={value}></Hidden>
        )}
      />
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="Client.Name"
            setValue={setValue(
              "Client.Name",
              props.defaultValues?.client?.name
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="Name"
                name="Client.Name"
                label="Name"
                variant="outlined"
                placeholder="Name"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={value}
                size="small"
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.client.name = value;
                  onChange(value);
                }}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="Client.PanCardNumber"
            rules={{ required: "PAN Number is required." }}
            setValue={setValue(
              "Client.PanCardNumber",
              props.defaultValues?.client?.panCardNumber
            )}
            render={({ field: { onChange, value } }) => (
              console.log(props.defaultValues?.client?.panCardNumber),
              (
                <TextField
                  id="panCardNumber"
                  label="PAN Number"
                  variant="outlined"
                  placeholder="PAN Number"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  value={value}
                  fullWidth
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.client.panCardNumber = value;
                    onChange(value);
                  }}
                  error={Boolean(errors?.panCardNumber)}
                  helperText={errors.panCardNumber?.message}
                />
              )
            )}
          />

          <Controller
            control={control}
            name="Client.RegistrationNumber"
            rules={{ required: "Registration Number is required." }}
            setValue={setValue(
              "Client.RegistrationNumber",
              props.defaultValues?.client?.registrationNumber
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="registrationNumber"
                label="Registration Number"
                variant="outlined"
                placeholder="Registration Number"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={value}
                fullWidth
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.client.registrationNumber = value;
                  onChange(value);
                }}
                error={Boolean(errors?.registrationNumber)}
                helperText={errors.registrationNumber?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="Client.GSTN"
            rules={{ required: "GST Number is required." }}
            setValue={setValue(
              "Client.GSTN",
              props.defaultValues?.client?.gstn
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="gstn"
                label="GST Number"
                variant="outlined"
                placeholder="GST Number"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={value}
                fullWidth
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.client.gstn = value;
                  onChange(value);
                }}
                error={Boolean(errors?.gstn)}
                helperText={errors.gstn?.message}
              />
            )}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="Client.ContactNumber"
            rules={{
              required: "Mobile Number is required.",
              pattern: /[0-9]{10}/,
            }}
            setValue={setValue(
              "Client.ContactNumber",
              props.defaultValues?.client?.contactNumber
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="ContactNumber"
                label="Mobile Number"
                variant="outlined"
                placeholder="Mobile Number"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={value}
                fullWidth
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.client.contactNumber = value;
                  onChange(value);
                }}
                error={Boolean(errors?.ContactNumber)}
                helperText={errors.ContactNumber?.message}
                inputProps={{
                  maxLength: 10,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="Client.Email"
            rules={{
              required: "Email Address is required.",
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            }}
            setValue={setValue(
              "Client.Email",
              props.defaultValues?.client?.email
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="email"
                label="Email Address"
                variant="outlined"
                placeholder="Email Address"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={value}
                fullWidth
                margin="normal"
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.client.email = value;
                  onChange(value);
                }}
                error={Boolean(errors?.email)}
                helperText={errors.email?.message}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="Client.Address"
            rules={{ required: "Address is required." }}
            setValue={setValue(
              "Client.Address",
              props.defaultValues?.client?.address
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="address"
                label="address"
                variant="outlined"
                placeholder="address"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={value}
                fullWidth
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.client.address = value;
                  onChange(value);
                }}
                error={Boolean(errors?.address)}
                helperText={errors.address?.message}
                multiline={true}
                rows={4}
                style={{ width: 560 }}
              />
            )}
          />

          <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Controller
                control={control}
                name="Client.StateId"
                value={state}
                rules={{ required: "State is required." }}
                setValue={setValue(
                  "Client.StateId",
                  props.defaultValues?.client?.stateId
                )}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="StateId"
                    label="State"
                    variant="outlined"
                    placeholder="State"
                    fullWidth
                    select
                    value={value}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      props.defaultValues.client.stateId = value;
                      onChange(value);
                      handleChangeState(value);
                    }}
                    error={Boolean(errors?.StateId)}
                    helperText={errors.StateId?.message}
                  >
                    {stateList?.map((state) => {
                      return (
                        <MenuItem value={state.stateId}>
                          {state.stateTitle}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />

              <Controller
                control={control}
                name="Client.DistrictId"
                value={taluka}
                rules={{ required: "District Address is required." }}
                setValue={setValue(
                  "Client.DistrictId",
                  props.defaultValues?.client?.districtId
                )}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="DistrictId"
                    label="District"
                    variant="outlined"
                    placeholder="District"
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    select
                    value={value}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      props.defaultValues.client.districtId = value;
                      onChange(value);
                      handleChangeTaluka(value);
                    }}
                    error={Boolean(errors?.DistrictId)}
                    helperText={errors.DistrictId?.message}
                  >
                    {districtList?.map((district) => {
                      return (
                        <MenuItem value={district.districtid}>
                          {district.districtTitle}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />
            </Stack>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Controller
                control={control}
                name="Client.CityId"
                value={city}
                rules={{ required: "City Address is required." }}
                setValue={setValue(
                  "Client.CityId",
                  props.defaultValues?.client?.cityId
                )}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="CityId"
                    label="City"
                    variant="outlined"
                    placeholder="City"
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    select
                    value={value}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      props.defaultValues.client.cityId = value;
                      onChange(value);
                      handleChangeCity(value);
                    }}
                    error={Boolean(errors?.CityId)}
                    helperText={errors.CityId?.message}
                  >
                    {cityList?.map((city) => {
                      return <MenuItem value={city.id}>{city.name}</MenuItem>;
                    })}
                  </TextField>
                )}
              />
              <Controller
                control={control}
                name="Client.Pincode"
                rules={{ required: "Pincode is required." }}
                setValue={setValue(
                  "Client.Pincode",
                  props.defaultValues?.client?.pincode
                )}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="pincode"
                    label="Pincode"
                    variant="outlined"
                    placeholder="Pincode"
                    value={value}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    margin="normal"
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      props.defaultValues.client.pincode = value;
                      onChange(value);
                    }}
                    error={Boolean(errors?.pincode)}
                    helperText={errors.pincode?.message}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}

function getSteps() {
  return ["Client information", "Admin Information"];
}

function getStepContent(step, defaultValues, clientId) {
  switch (step) {
    case 0:
      return <RegisterForm defaultValues={defaultValues} ClientId={clientId} />;

    case 1:
      return <AdminInfo defaultValues={defaultValues} ClientId={clientId} />;
    default:
      return "unknown step";
  }
}

const AdminInfo = (props) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [state, setState] = useState("");

  const [city, setCity] = useState("");

  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [taluka, setTaluka] = React.useState("");
  const [currendefaultvalue, setCurrentDefoultValue] = useState({
    address: "",
    adminCityId: 0,
    adminContactNumber: "",
    adminDistrictId: 0,
    adminId: 0,
    adminStateId: 0,
    adminaddress: "",
    adminemail: "",
    adminpincode: "",
    cityId: 0,
    clinetId: 0,
    contactNumber: "",
    districtId: 0,
    email: null,
    fullName: "",
    gstn: "",
    name: "",
    panCardNumber: "",
    password: "",
    pincode: "",
    registrationNumber: "",
    stateId: 0,
    userName: "",
    userTypeId: null,
  });
  console.log(currendefaultvalue);

  useEffect(() => {
    getCities();
    getStates();
    getDistrict();
    if (props.defaultValues) {
      setCurrentDefoultValue(props.defaultValues);
      setState(props.defaultValues.stateId);
      setTaluka(props.defaultValues.districtId);
      setCity(props.defaultValues.cityId);
      console.log("currendefaultvalue", props.defaultValues);
    }
  }, [props.defaultValues]); //

  const [showPassword, setShowPassword] = useState(false);

  async function getStates() {
    let res = await utile.GetAllStates();
    setStateList(res.data);
    // fetch(`https://strata-portal.azurewebsites.net/api/util/GetAllStates`).then(response => response.json()).then(result => {
    //     setStateList(result.data);
    //     console.log(result.data);
    // });
  }

  async function getDistrict(stateID) {
    console.log(stateID);
    let res = await utile.GetAllDistricts(stateID);
    setDistrictList(res.data);
    // if (stateID != undefined) {

    //     // fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllDistricts?stateID=` + stateID).then(response => response.json()).then(result => {
    //     //     setDistrictList(result.data);
    //     // });
    // }
    // else {
    //     let res=await utile.GetAllDistricts(stateID)
    //    setDistrictList(res.data);
    //     // fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllDistricts?stateID=`).then(response => response.json()).then(result => {
    //     //     setDistrictList(result.data);
    //     // });
    // }
  }

  async function getCities(districId) {
    console.log(districId);

    let res = await utile.GetAllCities(districId);
    setCityList(res.data);
    // if (districId != undefined) {
    //     fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllCities?districId=` + districId).then(response => response.json()).then(result => {
    //         setCityList(result.data);
    //     });
    // }
    // else {
    //     fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllCities?districId=`).then(response => response.json()).then(result => {
    //         setCityList(result.data);
    //     });
    // }
  }
  const handleChangeState = (event) => {
    setState(event);
    getDistrict(event);
    console.log(event);
  };

  const handleChangeCity = (event) => {
    setCity(event);
  };

  const handleChangeTaluka = (event) => {
    setTaluka(event);
    getCities(event);
  };
  return (
    <>
      {/* <input type="hidden" value={props.defaultValues?.user?.userId} name="userId"></input> */}

      <Controller
        control={control}
        name="User.UserId"
        setValue={setValue("User.UserId", props.defaultValues?.user?.userId)}
        render={({ field: { onChange, value } }) => (
          // console.log(prop)
          <Hidden value={value}></Hidden>
        )}
      />

      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="User.FullName"
            rules={{ required: "Name is required." }}
            setValue={setValue(
              "User.FullName",
              props.defaultValues?.user?.fullName
            )}
            render={({ field: { onChange, value } }) => (
              // console.log(prop)
              <TextField
                id="fullName"
                label="Full Name"
                variant="outlined"
                placeholder="Full Name"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.user.fullName = value;
                  onChange(value);
                }}
                error={Boolean(errors?.fullName)}
                helperText={errors.fullName?.message}
              />
            )}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="User.Mobile"
            rules={{
              required: "Mobile Number is required.",
              pattern: /[0-9]{10}/,
            }}
            setValue={setValue(
              "User.Mobile",
              props.defaultValues?.user?.mobile
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="adminContactNumber"
                label="Mobile Number"
                variant="outlined"
                placeholder="Mobile Number"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.user.mobile = value;
                  onChange(value);
                }}
                error={Boolean(errors?.adminContactNumber)}
                helperText={errors.adminContactNumber?.message}
                inputProps={{
                  maxLength: 10,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="User.Email"
            rules={{
              required: "Email Address is required.",
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            }}
            setValue={setValue("User.Email", props.defaultValues?.user?.email)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="Client.Email"
                label="Email Address"
                variant="outlined"
                placeholder="Email Address"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.user.email = value;
                  onChange(value);
                }}
                error={Boolean(errors?.adminemail)}
                helperText={errors.adminemail?.message}
              />
            )}
          />
          {props.ClientId == 0 ? (
            <Controller
              control={control}
              name="User.Password"
              rules={{ required: "Password is required." }}
              setValue={setValue("User.Password", "")}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  placeholder="Password"
                  type="password"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.user.password = value;
                    onChange(value);
                  }}
                  error={Boolean(errors?.password)}
                  helperText={errors.password?.message}
                />
              )}
            />
          ) : (
            ""
          )}
        </Stack>
        <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Controller
              control={control}
              name="User.StateId"
              value={state}
              rules={{ required: "State is required." }}
              setValue={setValue(
                "User.StateId",
                props.defaultValues?.user?.stateId
              )}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="adminStateId"
                  label="State"
                  variant="outlined"
                  placeholder="State"
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.user.stateId = value;
                    onChange(value);
                    handleChangeState(value);
                  }}
                  error={Boolean(errors?.adminStateId)}
                  helperText={errors.adminStateId?.message}
                >
                  {stateList?.map((state) => {
                    return (
                      <MenuItem value={state.stateId}>
                        {state.stateTitle}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="User.DistrictId"
              value={taluka}
              rules={{ required: "District Address is required." }}
              setValue={setValue(
                "User.DistrictId",
                props.defaultValues?.user?.districtId
              )}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="adminDistrictId"
                  label="District"
                  variant="outlined"
                  placeholder="District"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  select
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.user.districtId = value;
                    onChange(value);
                    handleChangeTaluka(value);
                  }}
                  error={Boolean(errors?.adminDistrictId)}
                  helperText={errors.adminDistrictId?.message}
                >
                  {districtList?.map((district) => {
                    return (
                      <MenuItem value={district.districtid}>
                        {district.districtTitle}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Controller
              control={control}
              name="User.CityId"
              value={city}
              rules={{ required: "City Address is required." }}
              setValue={setValue(
                "User.CityId",
                props.defaultValues?.user?.cityId
              )}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="adminCityId"
                  label="City"
                  variant="outlined"
                  placeholder="City"
                  fullWidth
                  select
                  value={value}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.user.cityId = value;
                    onChange(value);
                    handleChangeCity(value);
                  }}
                  error={Boolean(errors?.adminCityId)}
                  helperText={errors.adminCityId?.message}
                >
                  {cityList?.map((city) => {
                    return <MenuItem value={city.id}>{city.name}</MenuItem>;
                  })}
                </TextField>
              )}
            />
            <Controller
              control={control}
              name="User.Pincode"
              rules={{ required: "Pincode is required." }}
              setValue={setValue(
                "User.Pincode",
                props.defaultValues?.user?.pinCode
              )}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="adminpincode"
                  label="Pincode"
                  variant="outlined"
                  placeholder="Pincode"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.user.pinCode = value;
                    onChange(value);
                  }}
                  error={Boolean(errors?.adminpincode)}
                  helperText={errors.adminpincode?.message}
                />
              )}
            />
          </Stack>
          <Controller
            control={control}
            name="User.Address"
            rules={{ required: "Email Address is required." }}
            setValue={setValue(
              "User.Address",
              props.defaultValues?.user?.address
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="adminaddress"
                label="address"
                variant="outlined"
                placeholder="address"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.user.address = value;
                  onChange(value);
                }}
                error={Boolean(errors?.adminaddress)}
                helperText={errors.adminaddress?.message}
                multiline={true}
                rows={4}
                style={{ width: 560 }}
              />
            )}
          />
        </Stack>
      </Stack>
    </>
  );
};

const ClientForm = (props) => {
  const { setOpen, fetchClients } = props;
  const classes = useStyles();
  const [defaultValues, setDefaultValues] = useState({
    Client: null,
    User: null,
  });

  const navigate = useNavigate();

  console.log("defaultValues", defaultValues);
  const methods = useForm({
    defaultValues: defaultValues,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [disable, setDisable] = useState(false);
  const steps = getSteps();
  const isStepOptional = (step) => {
    return false;
  };
  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    console.log(JSON.stringify(data));

    console.log(data);
    var requestoptions = {
      method: props.clientId != 0 ? "put" : "post",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(data),
    };

    if (activeStep == steps.length - 1) {
      data["User.UserTypeId"] = 2;
      console.log(requestoptions);
      console.log(data);
      if (props.clientId != 0) {
        console.log(data.Client.clientId);
        setDisable(true);
        fetch(
          configUrl.StrataServer + `/api/Client/UpdateClient`,
          requestoptions
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            // alert("Client updated successfully");
            toast.success("Client updated successfully", {
              position: "top-center",
              toastId: "success1",
            });

            setTimeout(() => {
              props.closeModal();
            }, 2000);

            // navigate('/dashboard/client', { replace: true });
            props.fetchClients();
            props.setRefresh();
          });
      } else {
        fetch(configUrl.StrataServer + `/auth/registerclient`, requestoptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.message) {
              if (result.message == "User Exist") {
                setDisable(!true);
                // alert("User email already is in use. ");
                toast.error("User email already is in use.", {
                  position: "top-center",
                  toastId: "success1",
                });

                return;
              }
            }
            // alert("Client registered successfully");
            toast.success("Client registered successfully", {
              position: "top-center",
              toastId: "success1",
            });

            // navigate('/dashboard/client', { replace: true });
            setTimeout(() => {
              props.closeModal();
            }, 2000);
            props.fetchClients();
            props.setRefresh();
          });
      }
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (props.clientId != 0) {
      getClient(props.clientId);
    }
    console.log(props);
  }, []); //

  // const onSubmit = (data) => {
  //   console.log(data);
  // };

  function getClient(id) {
    fetch(configUrl.StrataServer + `/api/client/GetClientById?clientId=` + id)
      .then((response) => response.json())
      .then((result) => {
        //setDefaultValues(result.data);
        console.log(result.data);
        setDefaultValues(result.data);
      });
  }

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                optional
              </Typography>
            );
          }
          if (isStepFalied() && activeStep == index) {
            labelProps.error = true;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Thank You
        </Typography>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleNext)}>
              {getStepContent(activeStep, defaultValues, props.clientId)}
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                justifyContent="flex-end"
              >
                <Button
                  className={classes.button}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                  >
                    skip
                  </Button>
                )}
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  // onClick={handleNext}
                  type="submit"
                  disabled={disable}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default ClientForm;
