import PropTypes from "prop-types";
import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
// @mui
import { Box, Card, CardHeader } from "@mui/material";
// utils
import { fNumber } from "../../utils/formatNumber";
// components
import { BaseOptionChart } from ".";
import Chart from "react-apexcharts";
import { useState } from "react";
// ----------------------------------------------------------------------

BarChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
};

export default function BarChart({ title, subheader, chartData, ...other }) {
  const chartLabels = chartData.map((i) => i.employeeName);

  const chartSeries = chartData.map((i) => i.treatmentCount);

  const [Data, setData] = useState({
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: chartLabels,
      },
    },
    series: [
      {
        name: "series-1",
        data: chartSeries,
      },
    ],
  });
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />
      {Data ? (
        <Box sx={{ mx: 3 }} dir="ltr">
          <Chart
            type="bar"
            series={Data.series}
            options={Data.options}
            width="500"
          />
        </Box>
      ) : (
        ""
      )}
    </Card>
  );
}
