// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
// components
// sections
import {
  EmployeeByState,
  EmployeeByDistrict,
  EmployeeByCity,
  BarChart,
} from "../chart";

import { AppWidgetSummary } from "../../sections/@dashboard/app";

import { useState, useEffect } from "react";

import clientService from "../../services/clientService";
import analyticService from "../../services/analyticService";
import { AppCurrentVisits } from "../../sections/@dashboard/app";
import { Stack } from "@mui/system";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------

export default function ClientAdminDashboard(props) {
  const theme = useTheme();
  const [employeeCount, setEmployeeCount] = useState();
  const [treatmentCount, setTreatmentCount] = useState();
  const [employeeByStateList, setEmployeeByStateList] = useState([]);
  const [employeeByDistrictList, setEmployeeByDistrictList] = useState([]);
  const [employeeByCityList, setEmployeeByCityList] = useState([]);
  const [employeeByTreatmentList, setEmployeeByTreatmentList] = useState([]);
  const [empCountByCause, setEmpCountByCause] = useState([]);
  const [medicinUses, setMedicinUses] = useState([]);
  const [healthCheckupCount, setHealthCheckupCount] = useState();
  const [firstAidLocationCount, setFirstAidLocationCount] = useState();
  const [sugarChartValue, setSugarChartValues] = useState([]);
  const [bpChartValue, setBpChartValues] = useState([]);
  const [visionChartValue, setVisionChartValues] = useState([]);

  useEffect(() => {
    GetEmployeeCount();
    GetTreatmentCount();
    // GetEmployeeByState();
    // GetEmployeeByDistrict();
    // GetEmployeeByCity();
    GetEmployeeByTreatment();
    GetEmployeeCountByCause();
    GetMedicinUses();
    GetFirstAidLocationCount();
    GetHealthCheckupCount();
    GetPreEmpCharts();
  }, []); //

  async function GetEmployeeCount() {
    let res = await analyticService.GetEmployeeCount(props.auth.clientId);
    console.log(res);
    setEmployeeCount(res.data);
  }
  async function GetEmployeeCountByCause() {
    let res = await analyticService.PatientByCause(props.auth?.clientId);
    console.log(res);
    setEmpCountByCause(res.data);
  }

  async function GetTreatmentCount() {
    let res = await analyticService.GetTreatmentCount(props.auth?.clientId);
    console.log(res);
    setTreatmentCount(res.data);
  }

  async function GetEmployeeByState() {
    let res = await analyticService.GetEmployeeByState(props.auth?.clientId);
    console.log(res);
    setEmployeeByStateList(res.data);
  }

  async function GetEmployeeByDistrict() {
    let res = await analyticService.GetEmployeeByDistrict(props.auth?.clientId);
    console.log(res);
    setEmployeeByDistrictList(res.data);
  }

  async function GetEmployeeByCity() {
    let res = await analyticService.GetEmployeeByCity(props.auth?.clientId);
    console.log(res);
    setEmployeeByCityList(res.data);
  }

  async function GetEmployeeByTreatment() {
    let res = await analyticService.GetEmployeeByTreatment(props.auth?.clientId);
    console.log(res);
    setEmployeeByTreatmentList(res.data);
  }

  async function GetMedicinUses() {
    let res = await analyticService.GetMedicinUses(props.auth?.clientId);
    console.log(res);
    setMedicinUses(res.data);
  }
  async function GetHealthCheckupCount() {
    let res = await analyticService.GetHealthCheckupCount(props.auth.clientId);
    console.log(res);
    setHealthCheckupCount(res.data);
  }
  async function GetFirstAidLocationCount() {
    let res = await analyticService.GetFirstAidLocationCount(
      props.auth.clientId
    );
    console.log(res);
    setFirstAidLocationCount(res.data);
  }

  async function GetPreEmpCharts() {
    let res = await analyticService.GetPreEmpCharts(props.auth.clientId);
    console.log(res);
    setSugarChartValues(res.data.sugarChartValues);
    setBpChartValues(res.data.bpChartValues);
    setVisionChartValues(res.data.visionChartValues);
  }
  // for genrate random hex color
  const colorArray = [];
  const len = empCountByCause.length;
  for (let i = 0; i <= len; i++) {
    let color = `#${Math.random().toString(16).slice(2, 8).padEnd(6, 0)}`;
    colorArray.push(color);
  }

  return (
    <>
      <Stack style={{ marginBottom: 20 }}>
        <ToastContainer />
        <section>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3}>
              <AppWidgetSummary
                title="Employee"
                total={employeeCount}
                icon={"fa-solid:users"}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={3}>
              <AppWidgetSummary
                title="Treatments"
                total={treatmentCount}
                color="info"
                icon={"wpf:doctors-bag"}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <AppWidgetSummary
                title="Total Health Checkup"
                total={healthCheckupCount}
                color="info"
                icon={"wpf:doctors-bag"}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <AppWidgetSummary
                title="First Aid Locations"
                total={firstAidLocationCount}
                color="info"
                icon={"wpf:doctors-bag"}
              />
            </Grid>
          </Grid>
        </section>
      </Stack>
      {/*<Grid item xs={12} sm={6} md={3}>*/}
      {/*    <AppWidgetSummary title="Item Orders" total={1723315} color="warning" icon={'ant-design:windows-filled'} />*/}
      {/*</Grid>*/}

      {/*<Grid item xs={12} sm={6} md={3}>*/}
      {/*    <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />*/}
      {/*</Grid>*/}
      {/* <Grid item xs={12} md={6} lg={8}>
                    <AppWebsiteVisits
                        title="Website Visits"
                        subheader="(+43%) than last year"
                        chartLabels={[
                            '01/01/2003',
                            '02/01/2003',
                            '03/01/2003',
                            '04/01/2003',
                            '05/01/2003',
                            '06/01/2003',
                            '07/01/2003',
                            '08/01/2003',
                            '09/01/2003',
                            '10/01/2003',
                            '11/01/2003',
                        ]}
                        chartData={[
                            {
                                name: 'Team A',
                                type: 'column',
                                fill: 'solid',
                                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                            },
                            {
                                name: 'Team B',
                                type: 'area',
                                fill: 'gradient',
                                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                             },
                             {
                                 name: 'Team C',
                                 type: 'line',
                                 fill: 'solid',
                                 data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                             },
                         ]}
                     />
                  </Grid> */}

      {/* <Grid item xs={12} md={6} lg={4}>
                      <AppCurrentVisits
                          title="Current Visits"
                          chartData={[
                              { label: 'America', value: 4344 },
                              { label: 'Asia', value: 5435 },
                              { label: 'Europe', value: 1443 },
                              { label: 'Africa', value: 4443 },
                          ]}
                          chartColors={[
                              theme.palette.primary.main,
                              theme.palette.chart.blue[0],
                              theme.palette.chart.violet[0],
                              theme.palette.chart.yellow[0],
                          ]}
                      />
                  </Grid> */}
      <Stack style={{ marginBottom: 20 }}>
        <section>
          <Typography
            variant="h4"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Treatment Insights
          </Typography>
          ;
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={6}>
              <AppCurrentVisits
                title="Employee Count By Cause"
                chartData={empCountByCause}
                chartColors={colorArray}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
              <AppCurrentVisits
                title="Total Medicine Uses"
                chartData={medicinUses}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.chart.blue[0],
                  theme.palette.chart.violet[0],
                ]}
              />
            </Grid>

            <Grid item xs={12} md={4} lg={6}>
              {employeeByTreatmentList.length > 0 ? (
                <BarChart
                  title=" Treatment count by employee"
                  subheader=""
                  chartData={employeeByTreatmentList}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </section>
      </Stack>

      <Stack>
        <section>
          <Typography
            variant="h4"
            component="h2"
            style={{ textAlign: "center" }}
          >
            Pre Employement Insights
          </Typography>
          ;
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={6}>
              <AppCurrentVisits
                title="Sugar chart"
                chartData={sugarChartValue}
                chartColors={["#FF3333", "#FFE033", "#55FF33"]}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
              {bpChartValue.length > 0 ? (
                <AppCurrentVisits
                  title="Bp chart"
                  chartData={bpChartValue}
                  chartColors={["#FF3333", "#FFE033", "#55FF33"]}
                />
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} md={4} lg={6}>
              {visionChartValue.length > 0 ? (
                <AppCurrentVisits
                  title="Vision chart"
                  chartData={visionChartValue}
                  chartColors={["#FF3333", "#FFE033", "#55FF33"]}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </section>
      </Stack>
      {/* <Grid item xs={8} md={4} lg={6}>
                      <EmployeeByState
                          title="Employees by state"
                          subheader=""
                          chartData={employeeByStateList}
                      />
                  </Grid>

                  <Grid item xs={8} md={4} lg={6}>
                      <EmployeeByDistrict
                          title="Employees by district"
                          subheader=""
                          chartData={employeeByDistrictList}
                      />
                  </Grid>

                  <Grid item xs={8} md={4} lg={6}>
                      <EmployeeByCity
                          title="Employees by city"
                          subheader=""
                          chartData={employeeByCityList}
                      />
                  </Grid>

                  <Grid item xs={8} md={4} lg={6}>
                      <EmployeeByTreatment
                          title="Employees by Treatment"
                          subheader=""
                          chartData={employeeByTreatmentList}
                      />
                  </Grid> */}

      {/* <Grid item xs={12} md={6} lg={4}>
                      <AppCurrentSubject
                          title="Current Subject"
                          chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
                          chartData={[
                              { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                              { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                              { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
                          ]}
                          chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
                      />
                  </Grid> */}
    </>
  );
}
