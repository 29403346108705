import React, { Component, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { main } from "../js/main";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "../App";
import healthCheckupService from "../services/healthCheckupService";
import analyticService from "../services/analyticService";
import { AppCurrentVisits } from "../sections/@dashboard/app";
import { useTheme } from "@mui/material/styles";

import { Button, Stack, Typography } from "@mui/material";
import { textAlign } from "@mui/system";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function GetNoRangeReport(props) {
  return (
    <>
      {props.data?.map((e) => {
        <>
          <div>
            <h1>{e[0].testName}</h1>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Test Result</th>
                </tr>
              </thead>
              {e?.map((t) => {
                <tr>
                  <td>{t.employeeName}</td>
                  <td>{t.testResult}</td>
                </tr>;
              })}
            </table>
          </div>
        </>;
      })}
    </>
  );
}

function HealthCheckupGraphReport() {
  const location = useLocation();
  const theme = useTheme();

  const hcId = new URLSearchParams(location.search).get("ehn");
  const auth = useRecoilValue(authAtom);
  const styles = `
  table, th, td {
    border: 1px solid black; 
  }
  
 
@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  } 
}
`;

  useEffect(() => {
    getPdf();
    getNoRangePdf();
    console.log(auth);
  }, []); //

  const [bpEmpList, setBpEmpList] = useState([]);
  const [bpGraphData, setBpGraphData] = useState({});
  const [title, setTitle] = useState("");
  const [data, setData] = useState([]);

  async function printpage() {
    window.print();
  }
  async function printpage() {
    window.print();
  }
  function getPdf() {
    let res = analyticService.HcGraphReport(hcId).then((res) => {
      if (res.message == "Success") {
        console.log(res.data);

        // setBpEmpList([]);

        setBpEmpList(res.data.bpEmp);

        setBpGraphData(res.data.bpGraphData);
        setTitle(res.data.healthCheckupTitle);
        console.log(bpEmpList);
        console.log(theme.palette.chart);
        // setMISReportDetails([res.data])
      } else {
        // alert("Something went wrong" + " : " + res.message);

        toast.error("Something went wrong" + " : " + res.message, {
          position: "top-center",
          toastId: "success1",
        });
      }
    });
  }

  function getNoRangePdf() {
    let res = analyticService.HcGraphReportNoRange(hcId).then((res) => {
      console.log(res.data);
      setData(res.result.hcNoRangeResults);
    });
  }

  if (bpEmpList.length > 0) {
    return (
      <>
        <style>{styles}</style>
        <div style={{ float: "right" }}>
          <Button
            className=""
            variant="contained"
            color="primary"
            onClick={printpage}
            type="button"
          >
            Print
          </Button>
        </div>
        <br></br>
        <div id="section-to-print">
          <h2>Graphical Representation of Health Checkup title:{title}</h2>
          <div className="card m-3">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <h5 className="card-title">Blood Pressure</h5>
                <div className="form-row">
                  <div className="form-group col-6">
                    <AppCurrentVisits
                      title=""
                      chartData={[
                        { label: "High BP", value: bpGraphData.bpHigh },
                        { label: "Low BP", value: bpGraphData.bpLow },
                        { label: "Normal", value: bpGraphData.bpNormal },
                      ]}
                      chartColors={["#FF3333", "#FFE033", "#55FF33"]}
                    />
                  </div>

                  <div className="form-group col-6">
                    <table
                      id="table-id"
                      style={{
                        width: "100%",
                        border: "1px solid black",
                        borderCollapse: "collapse",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Reading</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {bpEmpList.map((item, i1) => {
                          return (
                            <tr>
                              <td>{item.name}</td>
                              <td>{item.value}</td>
                              {item.type == "High" ? (
                                <td>
                                  <span style={{ color: "red" }}>
                                    {" "}
                                    {item.type}{" "}
                                  </span>
                                </td>
                              ) : (
                                ""
                              )}
                              {item.type == "Low" ? (
                                <td>
                                  <span style={{ color: "Yellow" }}>
                                    {" "}
                                    {item.type}{" "}
                                  </span>
                                </td>
                              ) : (
                                ""
                              )}
                              {item.type == "Normal" ? (
                                <td>
                                  <span style={{ color: "Green" }}>
                                    {" "}
                                    {item.type}{" "}
                                  </span>
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card m-3"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {data
              ?.reduce(
                (rows, key, index) =>
                  (index % 2 === 0
                    ? rows.push([key])
                    : rows[rows.length - 1].push(key)) && rows,
                []
              )
              .map((row, rowIndex) => (
                <div
                  key={rowIndex}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  {row.map((e) => (
                    <div key={e[0].testName} style={{ flex: 1 }}>
                      <Typography variant="h4" align="center">
                        {e[0].testName}
                      </Typography>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Employee Name</th>
                            <th>Test Result</th>
                          </tr>
                        </thead>
                        <tbody>
                          {e?.map((t) => (
                            <tr key={t.employeeName}>
                              <td>{t.employeeName}</td>
                              <td>{t.testResult}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        {" "}
        <h1>No Data Found</h1> <ToastContainer />
      </>
    );
  }
}

export default HealthCheckupGraphReport;
