import React, { Component } from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../components/Iconify";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "../../components/hook-form";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import doctorService from "../../services/doctorService.js";
import utilService from "../../services/utile";
import { configUrl } from "../../config.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function RegisterForm(props) {
  const [gender, setGender] = React.useState("");

  const [state, setState] = React.useState("");

  const [city, setCity] = React.useState("");

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [disable, setDisable] = useState(false);

  const [defaultValues, setDefaultValues] = useState({
    fullName: "",
    userName: "SSGG",
    password: "",
    email: "",
    mobile: "",
    specialist: "",
    pincode: "",
    address: "",
    Id: "",
    cityId: "",
    stateId: "",
    districtId: "",
    gender: "",
    doctorClinic: "",
    doctorTagLine: "",
    consentAccepted: true,
  });

  const [taluka, setTaluka] = React.useState("");

  const RegisterSchema = Yup.object().shape({
    //fullName: Yup.string().required('full name required'),
    //userName: Yup.string().required('Username name required'),
    //password: Yup.string().required('Password is required'),
    //email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    //ContactNumber: Yup.string().required("Mobile number is Required")
    //    .matches(
    //        '[0-9]{10}',
    //        "Phone number is not valid"
    //    ),
    //specialist: Yup.string().required('Required'),
    //pincode: Yup.string().required('Pincode Required')
    //    .matches(
    //        '[0-9]{6}',
    //        "Pincode is not valid"
    //    ),
    //address: Yup.string().required('Address is required'),
  });

  //const defaultValues = {
  //    fullName: '',
  //    userName: '',
  //    password: '',
  //    email: '',
  //    ContactNumber: '',
  //    specialist: '',
  //    pincode: '',
  //    address: '',
  //};

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    data["CityId"] = city;
    data["StateId"] = state;
    data["DistrictId"] = taluka;
    data["UserTypeId"] = 3;
    data["Id"] = props.doctorID;
    setDisable(true);
    var method = props.doctorID == 0 ? "post" : "put";
    var body = defaultValues;
    body["UserTypeId"] = 3;
    console.log(JSON.stringify(defaultValues));
    var requestoptions = {
      method: method,
      headers: { "content-type": "application/json" },
      body: JSON.stringify(defaultValues),
    };
    // if(defaultValues.consent !=true)
    // {
    //     alert("Please accept all Terms and Conditions");
    //     return;
    // }
    if (props.doctorID != 0) {
      let updateDoctor = await doctorService.UpdateDoctor(defaultValues);
      console.log("response", updateDoctor);
      //fetch(`/api/doctor/UpdateDoctor`, requestoptions).then(response => response.json()).then(result => {
      //    alert("user registered successfully");
      //   alert("Doctor update successfully");
      toast.success("Docter update successfully", {
        position: "top-center",
        toastId: "success1",
      });

      setTimeout(() => {
        props.closeModal();
      }, 2000);
      props.setRefresh(true);
      navigate("/dashboard/doctor", { replace: true });

      //})
    } else {
      let register = await doctorService
        .RegisterDoctor(defaultValues)
        .then((res) => {
          if (res.message) {
            if (res.message == "User Exist") {
              setDisable(!true);
              toast.error("User email already is in use.", {
                position: "top-center",
                toastId: "success1",
              });
              return;
            }
          }
          if (res.message == "Success") {
            toast.success("Docter registered successfully", {
              position: "top-center",
              toastId: "success1",
            });

            setTimeout(() => {
              props.closeModal();
            }, 2000);
            props.setRefresh(true);
            navigate("/dashboard/doctor", { replace: true });
          } else {
            toast.error(res.message, {
              position: "top-center",
              toastId: "success1",
            });
          }
        });
    }
  };

  //     //   alert("Doctor registered successfully");
  //     toast.success("Docter registered successfully", {
  //       position: "top-center",
  //       toastId: "success1",
  //     });
  //     // setTimeout(() => {
  //     //   window.location.reload();
  //     // }, 2000);
  //     navigate("/dashboard/doctor", { replace: true });
  //     //fetch(`/auth/register`, requestoptions).then(response => response.json()).then(result => {
  //     //    alert("user registered successfully");
  //     //    props.fetchdoctors();
  //     //    props.closemodal();
  //     //})
  //   }
  // };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleChangeState = (event) => {
    setState(event.target.value);
    getDistrict(event.target.value);
  };

  const handleChangeCity = (event) => {
    setCity(event.target.value);
  };

  const handleChangeTaluka = (event) => {
    setTaluka(event.target.value);
    getCities(event.target.value);
  };

  useEffect(() => {
    getCities();
    getStates();
    getDistrict();
    console.log(props.doctorID);
    if (props.doctorID != 0) {
      getDoctor(props.doctorID);
    }
  }, []);

  const getStates = async () => {
    let response = await utilService.GetAllStates();

    setStateList(response.data);
  };

  async function getCities(districId) {
    let response = await utilService.GetAllCities(districId);
    console.log(response.data);
    setCityList(response.data);
    //fetch(`/api/util/GetAllCities?districId=` + districId).then(response => response.json()).then(result => {
    //    setCityList(result.data);
    //});
  }

  async function getDistrict(stateID) {
    //if (stateID != undefined) {
    let response = await utilService.GetAllDistricts(stateID);
    console.log(response.data);
    setDistrictList(response.data);
    //fetch(`/api/util/GetAllDistricts?stateID=` + stateID).then(response => response.json()).then(result => {
    //    setDistrictList(result.data);
    //});
    //}
    //else {
    //let response = await doctorService.GetAllDistricts(stateID)
    //setDistrictList(response.data);
    //fetch(`/api/util/GetAllDistricts?stateID=`).then(response => response.json()).then(result => {
    //    setDistrictList(result.data);
    //});
    //}
  }

  function getDoctor(id) {
    fetch(configUrl.StrataServer + `/api/doctor/GetDocByID?id=` + id)
      .then((response) => response.json())
      .then((result) => {
        setDefaultValues(result.data);
        console.log(result.data);
      });
  }

  function FormValueChange(value, key) {
    setDefaultValues((ev) => ({
      ...ev,
      [key]: value,
    }));
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <RHFTextField
            name="fullName"
            value={defaultValues?.fullName}
            onChange={(event) =>
              FormValueChange(event.target.value, "fullName")
            }
            size="small"
            label="Full name"
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <RHFTextField
            name="doctorClinic"
            value={defaultValues?.doctorClinic}
            onChange={(event) =>
              FormValueChange(event.target.value, "doctorClinic")
            }
            size="small"
            label="Clinic Name"
          />
          <RHFTextField
            name="doctorTagLine"
            value={defaultValues?.doctorTagLine}
            onChange={(event) =>
              FormValueChange(event.target.value, "doctorTagLine")
            }
            size="small"
            label="Tag Line"
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <RHFTextField
            name="email"
            size="small"
            value={defaultValues?.email}
            onChange={(event) => FormValueChange(event.target.value, "email")}
            label="Email Address"
          />
          {props.doctorID == 0 ? (
            <RHFTextField
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              size="small"
              value={defaultValues?.password}
              onChange={(event) =>
                FormValueChange(event.target.value, "password")
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            ""
          )}
          <RHFTextField
            name="Mobile"
            value={defaultValues?.mobile}
            onChange={(event) => FormValueChange(event.target.value, "mobile")}
            size="small"
            inputProps={{
              maxLength: 10,
            }}
            label="Mobile Number"
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <RHFTextField
            name="specialist"
            size="small"
            value={defaultValues?.specialist}
            onChange={(event) =>
              FormValueChange(event.target.value, "specialist")
            }
            label="Specialist In"
          />
          <FormControl name="state" required fullWidth>
            <InputLabel id="state">Gender</InputLabel>
            <Select
              labelId="gender"
              id="gender"
              name="gender"
              displayEmpty
              value={defaultValues?.gender}
              size="small"
              onChange={(event) =>
                FormValueChange(event.target.value, "gender")
              }
              style={{ width: 570 }}
              label="Gender"
            >
              <MenuItem value="M">Male</MenuItem>
              <MenuItem value="F">Female</MenuItem>
            </Select>
          </FormControl>
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <FormControl name="state" required fullWidth>
            <InputLabel id="state">State</InputLabel>
            <Select
              labelId="state"
              id="state"
              value={defaultValues?.stateId}
              name="state"
              size="small"
              label="State"
              onChange={(event) => {
                FormValueChange(event.target.value, "stateId");
                handleChangeState(event);
              }}
            >
              {stateList?.map((state) => {
                return (
                  <MenuItem value={state.stateId}>{state.stateTitle}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl name="taluka" required fullWidth>
            <InputLabel id="taluka">District</InputLabel>
            <Select
              labelId="taluka"
              id="taluka"
              name="taluka"
              displayEmpty
              value={defaultValues?.districtId}
              onChange={(event) => {
                FormValueChange(event.target.value, "districtId");
                handleChangeTaluka(event);
              }}
              label="City"
              size="small"
            >
              {districtList?.map((district) => {
                return (
                  <MenuItem value={district.districtid}>
                    {district.districtTitle}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl name="city" required fullWidth>
            <InputLabel id="city">City</InputLabel>
            <Select
              labelId="city"
              id="city"
              value={defaultValues?.cityId}
              label="City"
              onChange={(event) =>
                FormValueChange(event.target.value, "cityId")
              }
              size="small"
            >
              {cityList?.map((city) => {
                return <MenuItem value={city.id}>{city.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <RHFTextField
            name="address"
            size="small"
            value={defaultValues?.address}
            onChange={(event) => FormValueChange(event.target.value, "address")}
            multiline={true}
            rows={4}
            label="address"
          />
          <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
            <RHFTextField
              name="pincode"
              size="small"
              value={defaultValues?.pincode}
              onChange={(event) =>
                FormValueChange(event.target.value, "pincode")
              }
              label="Pincode"
              inputProps={{
                maxLength: 6,
              }}
              style={{ width: 560 }}
            />
          </Stack>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          {/* <RHFCheckbox name="consent" value={defaultValues?.consent} onChange={(event) => FormValueChange(event.target.value,'consent')} size="small" label="Please check this to accept all Terms and Conditions" /> */}
          <label>
            By submiting this you are agree to all Terms and Conditions, Please
            read the below Terms and Conditions before submit the form
          </label>
        </Stack>
        <a href="/static/TAC/Strata _Terms & Conditions.pdf">
          Terms and Conditions
        </a>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={disable}
        >
          Register
        </LoadingButton>
      </Stack>
      <ToastContainer />
    </FormProvider>
  );
}
