import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Switch from "@mui/material/Switch";
import DialogTitle from "@mui/material/DialogTitle";

import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import Iconify from "../components/Iconify";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../sections/@dashboard/user";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ambulanceService from "../services/ambulanceService";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { atom } from "recoil";
import { useRecoilValue } from "recoil";
import { exportPDF } from "../utils/exportPdf";
import * as XLSX from "xlsx";

export const authAtom = atom({
  key: "auth",
  // get initial state from local storage to enable user to stay logged in
  default: JSON.parse(localStorage.getItem("user")),
});
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "DateTime", label: "Report Date Time", alignRight: false },
  { id: "Diver", label: "Driver Name", alignRight: false },
  { id: "AmbulanceNumber", label: "Ambulance Number", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.reportDate
          ?.toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AllAmbulanceList() {
  const auth = useRecoilValue(authAtom);

  const [open, setOpen] = React.useState(false);

  const [ambulancesList, setAmbulancesList] = React.useState([]);

  const [selectedAmbulance, setSelectedAmbulance] = useState(0);

  useEffect(() => {
    fetchAmbulances();
  }, []); //

  const handleClose = () => {
    setOpen(false);
  };

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("reportDate");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = ambulancesList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleExport = () => {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(ambulancesList);

    XLSX.utils.book_append_sheet(workbook, worksheet, "ambulancesList");

    XLSX.writeFile(workbook, "ambulancesList.xlsx");
  };

  const hadleswitchChage = async (type, id, status) => {
    let res = await ambulanceService.SwitctUpdate(type, id, status);

    console.log(res);
    //var requestOptions = {
    //    method: 'Put',
    //};
    //fetch(`https://strata-portal.azurewebsites.net//api/Util/UpdateActiveStatus?type=`+type+`&id=`+id+`&status=+`+status,requestOptions).then(response => response.json()).then(result => {
    //      setAmbulancesList(result.data.result);
    //     alert("Record updated successfully ")
    //     console.log(result.data);
    // })
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  async function deleteAmbulances(employeeID) {
    var result = window.confirm("Want to delete?");
    if (result) {
      let res = await ambulanceService.DeleteAmbulance(employeeID);
      console.log(res);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    //if (result) {
    //    var requestOptions = {
    //        method: 'Delete',
    //    };
    //    fetch(`https://strata-portal.azurewebsites.net//api/Ambulance/DeleteAmbulance?id=`+employeeID,requestOptions).then(response => response.json()).then(result => {
    //         setAmbulancesList(result.data.result);
    //         console.log(result.data);
    //     })
    //     setTimeout(() => {
    //        window.location.reload();
    //     }, 1000);

    // }
  }

  async function fetchAmbulances() {
    //fetch(`https://strata-portal.azurewebsites.net//api/ambulance/GetAllAmbulances`).then(response => response.json()).then(result => {
    //     setAmbulancesList(result.data.result);
    //    console.log(result.data.result);
    // })
    let res = await ambulanceService.GetAllCheckListForClient(auth.clientId);
    setAmbulancesList(res.data);
    console.log(res.data.result);
  }

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - ambulancesList.length)
      : 0;

  const filteredUsers = applySortFilter(
    ambulancesList,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  async function getAmbulanceReport(chekListMappingId) {
    var headers = [["Item", "IsOkay", "Remark", "Report Date", "Driver Name"]];
    let result = await ambulanceService.GetDetailCheckList(chekListMappingId);
    console.log(result);
    const reportData = result.data.map((elt) => [
      elt.title,
      elt.isOkay == false ? "Not Okay" : "Okay",
      elt.notes,
      new Date(elt.date).toLocaleString(),
      elt.driverName,
    ]);
    exportPDF(headers, reportData, " Ambulace CheckList Report");
  }

  return (
    <div>
      {/*<Button variant="outlined" onClick={handleClickOpen}>*/}

      {/*</Button>*/}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Submitted Ambulance CheckList
        </Typography>
      </Stack>

      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          handleExport={handleExport}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={ambulancesList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      monthlyAmbulanceCheckListId,
                      clientId,
                      ambulaceNumber,
                      driverName,
                      reportDate,
                    } = row;
                    const isItemSelected =
                      selected.indexOf(monthlyAmbulanceCheckListId) !== -1;

                    return (
                      <TableRow
                        hover
                        key={monthlyAmbulanceCheckListId}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) =>
                              handleClick(event, monthlyAmbulanceCheckListId)
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            {new Date(reportDate).toLocaleString()}
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{driverName}</TableCell>
                        <TableCell align="left">{ambulaceNumber}</TableCell>

                        <TableCell align="left">
                          <PictureAsPdfIcon
                            onClick={() => {
                              getAmbulanceReport(monthlyAmbulanceCheckListId);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={ambulancesList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </div>
  );
}
