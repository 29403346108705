import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PatientForm from "../components/Forms/PatientForm";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Switch from "@mui/material/Switch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import QuotationDetails from "./QuotationDetails";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import Iconify from "../components/Iconify";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../sections/@dashboard/user";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { authAtom } from "../App";
import healthCheckupService from "../services/healthCheckupService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";

const TABLE_HEAD = [
  { id: "Title", label: "Title", alignRight: false },
  { id: "Doctor", label: "Name", alignRight: false },
  { id: "IsAssigned", label: "Assigned/Not-Assigned", alignRight: false },
  { id: "Amount", label: "Amount", alignRight: false },
  {
    id: "Approved/Not-Approved",
    label: "Approved/Not-Approved",
    alignRight: false,
  },
  { id: "RequestedDate", label: "Requested Date", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_quotation) =>
        _quotation.doctorName

          ?.toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function QuotationList(props) {
  const [open, setOpen] = React.useState(false);

  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();

  useEffect(() => {
    fetchQuotations();
    console.log(auth);
  }, []); //

  const [quotationList, setQuotationList] = React.useState([]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("amount");

  const [filterName, setFilterName] = useState("");
  const [selectedQuotation, setSelectedQuotation] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [utypeId, setUtypeId] = useState(0);
  const [isqAssign, setIsqAssign] = useState(false);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = quotationList.map((n) => n.quotationId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleExport = () => {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(quotationList);

    XLSX.utils.book_append_sheet(workbook, worksheet, "quotationList");

    XLSX.writeFile(workbook, "quotationList.xlsx");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - quotationList.length) : 0;

  const filteredUsers = applySortFilter(
    quotationList,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  async function fetchQuotations() {
    let userTyp = 0;
    setUtypeId(props ? auth.userTypeId : props.auth.userTyp);
    userTyp = props ? auth.userTypeId : props.auth.userTyp;
    switch (userTyp) {
      case 2:
        let res = await healthCheckupService.GetAllQuotationForClients(
          auth.clientId,
          props?.healthcheckupId ?? 0
        );
        console.log(res);
        setQuotationList(res.data);
        break;
      case 3:
        let res1 = await healthCheckupService.GetAllQuotationForDoctor(
          auth.userId
        );
        console.log(res1);
        setQuotationList(res1.data);
        break;
    }
    quotationList.map((item, i) => {
      if (item.isAssign == true) setIsqAssign(true);
      return;
    });
  }

  async function reward(doctorId, healthCheckupId) {
    healthCheckupService
      .AssignHealthCheckupToDoctor(doctorId, healthCheckupId)
      .then((res) => {
        if (res.message == "Success") {
          // alert("Health Checkup Rewared Successfully");

          toast.success("Health Checkup Rewared Successfully", {
            position: "top-center",
            toastId: "success1",
          });

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          //   alert("Something went wrong " + res.message);

          toast.error("Something went wrong " + res.message, {
            position: "top-center",
            toastId: "success1",
          });
        }
      });
  }
  const handleClickOpen = (id) => {
    setSelectedQuotation(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          handleExport={handleExport}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={quotationList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                checkAllDisabled={true}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      quotationId,
                      healthCheckupId,
                      doctorId,
                      doctorName,
                      clientName,
                      totalAmount,
                      isAssign,
                      isHcApproved,
                      title,
                      requestedDate,
                    } = row;
                    const isItemSelected = selected.indexOf(quotationId) !== -1;
                    console.log(utypeId);
                    return (
                      <TableRow
                        hover
                        key={quotationId}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            hidden={true}
                            checked={isItemSelected}
                            onChange={(event) =>
                              handleClick(event, quotationId)
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center">
                            <Typography variant="subtitle2" noWrap>
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center">
                            <Typography variant="subtitle2" noWrap>
                              {utypeId == 2 ? doctorName : clientName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {props.page == "allquotations" ? (
                             <span>
                             {" "}
                             {isAssign ? "Assigned" : "Not-Assigned"}{" "}
                           </span>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell align="left">{totalAmount}</TableCell>

                        {/* <TableCell align="left"><Switch defaultChecked={isActive}  onClick={()=>{hadleswitchChage("User",userId,!isActive)}}/></TableCell> */}
                        <TableCell align="left">
                          {props.page != "allquotations" ? (
                            props.auth.userTypeId == 2 ? (
                              isqAssign != true ? (
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    reward(doctorId, healthCheckupId);
                                  }}
                                  component={RouterLink}
                                  to="#"
                                >
                                  Reward
                                </Button>
                              ) : (
                                ""
                              )
                            ) : (
                              <span>
                                {" "}
                                {isHcApproved ? "Approved" : "Not-Approve"}{" "}
                              </span>
                            )
                          ) : (
                            <span>
                              {" "}
                              {isHcApproved ? "Approved" : "Not-Approve"}{" "}
                            </span>
                          )}
                        </TableCell>
                        
                        <TableCell align="left">{new Date(requestedDate).toLocaleDateString()}</TableCell>
                        <TableCell align="left">
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleClickOpen(quotationId);
                            }}
                          >
                            See Quotation Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={quotationList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Dialog open={open} onClose={handleClose} fullWidth="lg" maxWidth="lg">
        <DialogTitle>Quotation Details</DialogTitle>
        <DialogContent>
          <QuotationDetails
            closeModal={handleClose}
            quotationId={selectedQuotation}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </div>
  );
}
