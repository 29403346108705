import axios from "axios";
import { configUrl } from "../config.js";
import axiosInstance from "./interceptore.js";

// const userToken = localStorage.getItem("userToken");
// const axiosInstance = axios.create({
//   baseURL: configUrl.StrataServer,
//   // headers: {
//   //   Authorization: `Bearer ${userToken}`,
//   // },
// });

const GetAllClient = async () => {
  let response = await axiosInstance.get(`/api/client/GetAllClients`);

  return response.data;
};
const DeleteClient = async (id) => {
  console.log(id);
  let response = await axiosInstance.get(`/api/Client/DeleteClient?id=${id}`);
  console.log(response);
  return response.data;
};
const SwitctUpdate = async (type, id, status) => {
  console.log(id);
  let response = await axiosInstance.put(
    `/api/Util/UpdateActiveStatus?type=${type}&id=${id}&status=+${status}`
  );
  console.log(response);
  return response.data;
};

const GetAllStates = async () => {
  let response = await axiosInstance.get(`/api/util/GetAllStates`);
  console.log(response);
  return response.data;
};
const GetAllCities = async (cities) => {
  if (cities) {
    let response = await axiosInstance.get(
      `/api/util/GetAllCities?districId=` + cities
    );
    return response.data;
  } else {
    let response = await axiosInstance.get(`/api/util/GetAllCities?districId=`);
    return response.data;
  }
};
const GetAllDistricts = async (stateID) => {
  if (stateID != undefined) {
    let response = await axiosInstance.get(
      `/api/util/GetAllDistricts?stateID=` + stateID
    );
    return response.data;
  } else {
    let response = await axiosInstance.get(
      `/api/util/GetAllDistricts?stateID=`
    );
    return response.data;
  }
};

const GetEmployeeByState = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/EmployeeCountByState?clientId=${clientId}`
  );
  return response.data;
};

const GetEmployeeByDistrict = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/EmployeeCountByDistrict?clientId=${clientId}`
  );
  return response.data;
};

const GetEmployeeByCity = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/EmployeeCountByCity?clientId=${clientId}`
  );
  return response.data;
};

const GetEmployeeByTreatment = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/EmployeeCountByTreatment?clientId=${clientId}`
  );
  return response.data;
};

const GetEmployeeCount = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/GetEmployeeCount?clientId=${clientId}`
  );
  return response.data;
};

const GetTreatmentCount = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/Analytics/GetTreatmentCount?clientId=${clientId}`
  );
  console.log(response);
  return response.data;
};

const GetClientById = async (id) => {
  let response = await axiosInstance.get(
    `/api/client/GetClientById?clientId=${id}`
  );
  console.log(response);
  return response.data;
};

export default {
  GetAllClient: GetAllClient,
  DeleteClient: DeleteClient,
  SwitctUpdate: SwitctUpdate,

  GetAllStates: GetAllStates,
  GetAllCities: GetAllCities,
  GetAllDistricts: GetAllDistricts,
  GetEmployeeByState: GetEmployeeByState,
  GetEmployeeCount: GetEmployeeCount,
  GetTreatmentCount: GetTreatmentCount,
  GetEmployeeByDistrict: GetEmployeeByDistrict,
  GetEmployeeByCity: GetEmployeeByCity,
  GetEmployeeByTreatment: GetEmployeeByTreatment,
  GetClientById: GetClientById,
};
