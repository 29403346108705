import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PatientForm from "../components/Forms/PatientForm";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import Switch from "@mui/material/Switch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import Iconify from "../components/Iconify";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../sections/@dashboard/user";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { authAtom } from "../App";
import healthCheckupService from "../services/healthCheckupService";
//
// mock
import UserList from "../_mock/user";
import HealthCheckupRequestForm from "../components/Forms/HealthCheckupRequestForm";
import { App as HCRequestDoc } from "../components/Forms/HCRequestDoc";
import QuotationList from "./QuotationList";
import * as XLSX from "xlsx";
import ExcelDownloadButton from "../components/Forms/ExcelDownloadButton";
import ImportHealthCheckUpPopup from "../components/Forms/ImportHealthCheckUpPopup";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "title", label: "Title", alignRight: false },
  { id: "StartDate", label: "Start Date", alignRight: false },
  { id: "EndDate", label: "End Date", alignRight: false },
  { id: "RequestedDate", label: "Requested Date", alignRight: false },
  // { id: 'DateOfTreatement', label: 'Date Of Treatement', alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_hc) =>
        _hc.title?.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Requests(props) {
  const [open, setOpen] = React.useState(false);

  const [treatmentList, setTreatmentList] = React.useState([]);

  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();

  //

  const [healthCheckupList, setHealthCheckupList] = React.useState([]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("title");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openQuotation, setOpenQuotation] = useState(false);

  const [selectedHCId, setSelectedHCId] = useState(0);
  const [refresh, setRefresh] = React.useState(false);

  useEffect(() => {
    fetchRequests();
    setRefresh();
  }, [refresh]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = healthCheckupList.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const handleExport = () => {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(healthCheckupList);

    XLSX.utils.book_append_sheet(workbook, worksheet, "healthCheckupList");

    XLSX.writeFile(workbook, "healthCheckupList.xlsx");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - healthCheckupList.length)
      : 0;

  const filteredUsers = applySortFilter(
    healthCheckupList,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenQuotation = (open, healthCheckupId) => {
    setOpenQuotation(true);
    setSelectedHCId(healthCheckupId);
  };

  const handleCloseQuotation = () => {
    setOpenQuotation(false);
  };

  async function fetchRequests() {
    switch (auth.userTypeId) {
      case 2:
        let res = await healthCheckupService.GetAllRequestForClients(
          auth.clientId
        );
        console.log(res);
        setHealthCheckupList(res.data);
        break;
      case 3:
        let res1 = await healthCheckupService.GetAllRequestForDoctor(
          auth.userId
        );
        console.log(res1);
        setHealthCheckupList(res1.data);
        break;
      default:
        setHealthCheckupList([]);
        break;
    }
  }

  function renderQuutationComponent(selectedHCId) {
    switch (auth.userTypeId) {
      case 2:
        return (
          <React.Fragment>
            <DialogTitle>View Quotations</DialogTitle>
            <DialogContent>
              <QuotationList
                closeModal={handleCloseQuotation}
                auth={auth}
                healthcheckupId={selectedHCId}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseQuotation}>Cancel</Button>
            </DialogActions>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <DialogTitle>Submit Quotation</DialogTitle>
            <DialogContent>
              <HCRequestDoc
                closeModal={handleCloseQuotation}
                auth={auth}
                healthcheckupId={selectedHCId}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseQuotation}>Cancel</Button>
            </DialogActions>
          </React.Fragment>
        );

      default:
        break;
    }
  }

  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Health Checkup Requests
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={3}>
          {auth.userTypeId == 2 ? (
            <Button
              variant="contained"
              onClick={handleClickOpen}
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Requests
            </Button>
          ) : (
            ""
          )}
          <ImportHealthCheckUpPopup />

          <ExcelDownloadButton />
        </Stack>
      </Stack>

      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
          handleExport={handleExport}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={healthCheckupList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      healthCheckupId,
                      title,
                      startDate,
                      endDate,
                      hcLevel,
                      isActive,
                      createdDate,
                    } = row;
                    const isItemSelected = selected.indexOf(title) !== -1;

                    return (
                      <TableRow
                        hover
                        key={healthCheckupId}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) =>
                              handleClick(event, healthCheckupId)
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar alt={title} />
                            <Typography variant="subtitle2" noWrap>
                              {title}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {new Date(startDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="left">
                          {new Date(endDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell align="left">
                          {new Date(createdDate).toLocaleDateString()}
                        </TableCell>

                        {/* <TableCell align="left"><Switch defaultChecked={isActive}  onClick={()=>{hadleswitchChage("User",userId,!isActive)}}/></TableCell> */}
                        <TableCell align="right">
                          {/* <DeleteIcon  onClick={() => { deletePatients(userId) }}/> */}
                          {/* <EditIcon  onClick={() => { handleClickOpen(userId) }} /> */}
                          {props.page == "request" ? (
                            <Button
                              variant="contained"
                              onClick={() => {
                                handleClickOpenQuotation(true, healthCheckupId);
                              }}
                              component={RouterLink}
                              to="#"
                            >
                              {auth.userTypeId == 2 ? "View" : "Submit"}{" "}
                              Quotations
                            </Button>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={healthCheckupList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Dialog open={open} onClose={handleClose} fullWidth="lg" maxWidth="lg">
        <DialogTitle>Create Requests</DialogTitle>
        <DialogContent>
          <HealthCheckupRequestForm
            closeModal={handleClose}
            auth={auth}
            setRefresh={setRefresh}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openQuotation}
        onClose={handleCloseQuotation}
        fullWidth="lg"
        maxWidth="lg"
      >
        {renderQuutationComponent(selectedHCId)}
      </Dialog>
    </div>
  );
}
