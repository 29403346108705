import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Box, Divider, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

export default function AbhaPopup({ open, handleClose }) {
  const handelTry = () => {
    window.location.reload();
  };
  return (
    <div>
      <Dialog open={open}>
        <Stack spacing={2} p={6}>
          <Typography textAlign="end" onClick={handleClose}>
            <CloseIcon />
          </Typography>
          <DialogTitle textAlign="center" color="red">
            <span
              style={{
                backgroundColor: "#FFF7F5",
                padding: "12px",
                borderRadius: "50%",
              }}
            >
              <LinkOffIcon />
            </span>
          </DialogTitle>
          <Typography variant="h7" fontWeight="bold" textAlign="center">
            Aadhaar number does not have mobile number
          </Typography>
          <Button variant="contained" onClick={handelTry}>
            Try Again
          </Button>
          <Divider />
          <Stack spacing={2}>
            <Typography textAlign="center">
              Use another method to create ABHA
            </Typography>
            <Box
              border="1px solid gray"
              p={2}
              borderRadius="10px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Use Mobile Number</Typography>
              <Link to="#">
                <Button variant="outlined">Coming Soon</Button>
              </Link>
            </Box>
          </Stack>
        </Stack>
      </Dialog>
    </div>
  );
}
