import axios from "axios";
import { da } from "date-fns/locale";
import { configUrl } from "../config.js";
import axiosInstance from "./interceptore.js";
// const userToken = localStorage.getItem("userToken");
// const axiosInstance = axios.create({
//   baseURL: configUrl.StrataServer.trim("/"),
//   // headers: {
//   //   Authorization: `Bearer ${userToken}`,
//   // },
// });

const AddPreEmployment = async (data) => {
  console.log(data);
  let response = await axiosInstance.post(
    `/api/PreEmploymentHC/AddPreEmployment`,
    data
  );
  return response.data;
};

const getAllPreEmplymentHC = async (clientId) => {
  let response = await axiosInstance.get(
    `/api/PreEmploymentHC/getAllPreEmplymentHC?clientId=${clientId}`
  );
  return response.data;
};

export default {
  AddPreEmployment: AddPreEmployment,
  getAllPreEmplymentHC: getAllPreEmplymentHC,
};
