import axios from "axios";
import { configUrl } from "../config.js";
import { useNavigate } from "react-router-dom";

// const userToken = localStorage.getItem("userToken");
const axiosInstance = axios.create({
  baseURL: configUrl.StrataServer,
  // headers: {
  //   Authorization: `Bearer ${userToken}`,
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  // },
});

axiosInstance.interceptors.response.use(
  (response) => {
    console.log(response);
    return response;
  },

  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("userToken");
      // const navigate = useNavigate();
      // navigate("/", { replace: true });
      // window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
