import React, { useState, useForm, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useRecoilValue } from "recoil";
import { atom } from "recoil";
import doctorService from "../services/doctorService";

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const authAtom = atom({
  key: "auth",
  // get initial state from local storage to enable user to stay logged in
  default: JSON.parse(localStorage.getItem("user")),
});

function DoctorAttachments() {
  const auth = useRecoilValue(authAtom);

  const [logo, setLogo] = useState();
  const [sign, setSign] = useState();
  const [stamp, setStamp] = useState();

  useEffect(() => {
    fetchAmbulances("logo");
    fetchAmbulances("signature");
    fetchAmbulances("stamp");
  }, []); //

  async function fetchAmbulances(type) {
    var imageres = await doctorService.GetFile(auth.userId, type);
    console.log(imageres.data);
    if (type == "logo") setLogo(imageres.data);
    else if (type === "signature") setSign(imageres.data);
    else if (type == "stamp") setStamp(imageres.data);
    // setSign(
  }

  function handleChange(e, type) {
    console.log(auth);
    if (type === "logo") {
      setLogo(URL.createObjectURL(e.target.files[0]));
      console.log(e.target.files[0]);
      let res = doctorService.UploadAttachment(
        e.target.files[0],
        auth.userId,
        "logo"
      );
      if (res.message == "Success")
        // alert("Logo Uploaded Successfully");

        toast.success("Logo Uploaded Successfully", {
          position: "top-center",
          toastId: "success1",
        });
    } else if (type === "sign") {
      setSign(URL.createObjectURL(e.target.files[0]));
      let res = doctorService.UploadAttachment(
        e.target.files[0],
        auth.userId,
        "signature"
      );
      if (res.message == "Success")
        // alert("Signature Uploaded Successfully");
        toast.success("Signature Uploaded Successfully", {
          position: "top-center",
          toastId: "success1",
        });
    } else if (type === "stamp") {
      setStamp(URL.createObjectURL(e.target.files[0]));
      let res = doctorService.UploadAttachment(
        e.target.files[0],
        auth.userId,
        "stamp"
      );
      if (res.message == "Success")
        //    alert("Stamp Uploaded Successfully");
        toast.success("Stamp Uploaded Successfully", {
          position: "top-center",
          toastId: "success1",
        });
    }
  }
  function onSubmit(data) {}
  return (
    <Card>
      <h1 style={{ marginLeft: "10px", marginTop: "10px" }}>Attachments</h1>
      <form onSubmit={onSubmit}>
        <div className="form-row">
          <div className="form-group col-4">
            <div className="card m-3">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <h5 className="card-title">Upload Logo</h5>
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label value="Upload Logo" />
                      <input
                        type="file"
                        id="logo"
                        onChange={(e) => handleChange(e, "logo")}
                      />
                    </div>
                    <div className="form-group col-12">
                      <img src={logo} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group col-4">
            <div className="card m-3">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <h5 className="card-title">Upload Signature</h5>
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label value="Upload Logo" />
                      <input
                        type="file"
                        id="sign"
                        onChange={(e) => handleChange(e, "sign")}
                      />
                    </div>
                    <div className="form-group col-12">
                      <img src={sign} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group col-4">
            <div className="card m-3">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <h5 className="card-title">Upload Stamp</h5>
                  <div className="form-row">
                    <div className="form-group col-12">
                      <label value="Upload Logo" />
                      <input
                        type="file"
                        id="stamp"
                        onChange={(e) => handleChange(e, "stamp")}
                      />
                    </div>
                    <div className="form-group col-12">
                      <img src={stamp} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </Card>
  );
}

export default DoctorAttachments;
