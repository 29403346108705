import React, { Component, useRef } from "react";
import * as Yup from "yup";
//import dayjs from 'dayjs';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../components/Iconify";
import {
  FormProvider as MyFromProvider,
  RHFTextField,
} from "../../components/hook-form";
import InputLabel from "@mui/material/InputLabel";
import {
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Controller, useFormContext, FormProvider } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { Typography, Button, Stepper, Step, StepLabel } from "@mui/material";
import { has } from "lodash";
import { sinceWhen } from "../../utils/dataUtils";
import utile from "../../services/utile";
import { configUrl } from "../../config.js";

import CreateIcon from "@material-ui/icons/Create";
import {
  Box,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Personal Details",
    "Home Situation & Occupation",
    "Habits",
    "Medical History",
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <PatientDetails />;

    case 1:
      return <SituationOccupation />;

    case 2:
      return <Habits />;

    case 3:
      return <MedicalHistroy />;
    default:
      return "unknown step";
  }
}

const SituationOccupation = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const [maritalStatus, setMaritalStatus] = useState("Single");
  const [hasChildren, setHasChildren] = useState("true");
  const [healthyChildrens, setHeathyChildren] = useState("true");

  return (
    <>
      <br />
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
          <Controller
            control={control}
            name="maritalStatus"
            value={maritalStatus}
            defaultValue={maritalStatus}
            rules={{ required: "Marital status is required." }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="maritalStatus"
                label="Marital Status"
                variant="outlined"
                placeholder="Marital Status"
                fullWidth
                value={maritalStatus}
                selected={maritalStatus}
                defaultValue={maritalStatus}
                select
                size="small"
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  onChange(value);
                  setMaritalStatus(value);
                }}
                error={Boolean(errors?.maritalStatus)}
                helperText={errors.maritalStatus?.message}
                style={{ width: 200 }}
              >
                <MenuItem value="Single">Single</MenuItem>
                <MenuItem value="Married">Married</MenuItem>
                <MenuItem value="Divorsed">Divorced</MenuItem>
                <MenuItem value="Widowed">Widowed</MenuItem>
              </TextField>
            )}
          />
          {maritalStatus != "Single" ? (
            <Controller
              control={control}
              name="maritalStatusDuration"
              rules={{ required: "required." }}
              render={({ field }) => (
                <TextField
                  id="maritalStatusDuration"
                  label={maritalStatus + " (How long)"}
                  variant="outlined"
                  placeholder={maritalStatus + " (How long)"}
                  size="small"
                  fullWidth
                  {...field}
                  error={Boolean(errors?.maritalStatusDuration)}
                  helperText={errors.maritalStatusDuration?.message}
                  style={{ width: 200 }}
                />
              )}
            />
          ) : (
            ""
          )}
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          {maritalStatus != "Single" ? (
            <React.Fragment>
              <Controller
                control={control}
                name="hasChildrens"
                value={hasChildren}
                defaultValue={hasChildren}
                rules={{ required: "Field is required." }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="hasChildrens"
                    label="Children?"
                    variant="outlined"
                    placeholder="Children?"
                    fullWidth
                    value={value}
                    selected={hasChildren}
                    defaultValue={hasChildren}
                    select
                    size="small"
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      onChange(value);
                      setHasChildren(value);
                    }}
                    error={Boolean(errors?.hasChildrens)}
                    helperText={errors.hasChildrens?.message}
                    style={{ width: 200 }}
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </TextField>
                )}
              />
              {hasChildren == "true" ? (
                <React.Fragment>
                  <Controller
                    control={control}
                    name="childrenHealthy"
                    value={healthyChildrens}
                    defaultValue={healthyChildrens}
                    rules={{ required: "Field is required." }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="childrenHealthy"
                        label="Are they healty?"
                        variant="outlined"
                        placeholder="Are they healty?"
                        fullWidth
                        value={value}
                        defaultValue={healthyChildrens}
                        selected={healthyChildrens}
                        select
                        size="small"
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          onChange(value);
                          setHeathyChildren(value);
                        }}
                        error={Boolean(errors?.childrenHealthy)}
                        helperText={errors.childrenHealthy?.message}
                        style={{ width: 200 }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </TextField>
                    )}
                  />

                  <Controller
                    control={control}
                    name="childrenGender"
                    style={{ width: 200 }}
                    render={({ field }) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          control={
                            <Controller
                              name="isBoy"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="M"
                        />

                        <FormControlLabel
                          control={
                            <Controller
                              name="isGirl"
                              control={control}
                              render={({ field: props }) => (
                                <Checkbox
                                  {...props}
                                  checked={props.value}
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                />
                              )}
                            />
                          }
                          label="F"
                        />
                      </RadioGroup>
                    )}
                  />
                </React.Fragment>
              ) : (
                ""
              )}
            </React.Fragment>
          ) : (
            ""
          )}
          <Controller
            control={control}
            name="family"
            value=""
            rules={{ required: "Field is required." }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="family"
                label="Family"
                variant="outlined"
                placeholder="Family"
                fullWidth
                value={value}
                select
                size="small"
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  onChange(value);
                  setHeathyChildren(value);
                }}
                error={Boolean(errors?.family)}
                helperText={errors.family?.message}
                style={{ width: 200 }}
              >
                <MenuItem value="Nuclear">Nuclear</MenuItem>
                <MenuItem value="Joint">Joint</MenuItem>
              </TextField>
            )}
          />
        </Stack>

        <hr />

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <h4>OCCUPATION/TYPE OF (DETAILS) SERVICE/PROFESSION</h4>
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="occupation"
            value=""
            rules={{ required: "Field is required." }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="occupation"
                label="Occupation"
                variant="outlined"
                placeholder="Occupation"
                fullWidth
                value={value}
                select
                size="small"
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  onChange(value);
                }}
                error={Boolean(errors?.occupation)}
                helperText={errors.occupation?.message}
                style={{ width: 200 }}
              >
                <MenuItem value="Government Servant">
                  Government Servant
                </MenuItem>
                <MenuItem value="Service">Service</MenuItem>
                <MenuItem value="Business">Business</MenuItem>
              </TextField>
            )}
          />
        </Stack>
      </Stack>
    </>
  );
};

const PatientDetails = () => {
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [taluka, setTaluka] = React.useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");

  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const fileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [typeError, setTypeError] = useState("");

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    getCities();
    getStates();
    getDistrict();
  }, []);

  async function getStates() {
    let res = await utile.GetAllStates();
    setStateList(res.data);
    // fetch(`https://strata-portal.azurewebsites.net/api/util/GetAllStates`).then(response => response.json()).then(result => {
    //     setStateList(result.data);
    //     console.log(result.data);
    // });
  }

  async function getDistrict(stateID) {
    console.log(stateID);
    let res = await utile.GetAllDistricts(stateID);
    setDistrictList(res.data);
    // if (stateID != undefined) {

    //     // fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllDistricts?stateID=` + stateID).then(response => response.json()).then(result => {
    //     //     setDistrictList(result.data);
    //     // });
    // }
    // else {
    //     let res=await utile.GetAllDistricts(stateID)
    //    setDistrictList(res.data);
    //     // fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllDistricts?stateID=`).then(response => response.json()).then(result => {
    //     //     setDistrictList(result.data);
    //     // });
    // }
  }

  async function getCities(districId) {
    console.log(districId);

    let res = await utile.GetAllCities(districId);
    setCityList(res.data);
    // if (districId != undefined) {
    //     fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllCities?districId=` + districId).then(response => response.json()).then(result => {
    //         setCityList(result.data);
    //     });
    // }
    // else {
    //     fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllCities?districId=`).then(response => response.json()).then(result => {
    //         setCityList(result.data);
    //     });
    // }
  }
  const defaultValues = {
    fullName: "",
    lastName: "",
    email: "",
    ContactNumber: "",
    identificationMark: "",
    pincode: "",
    address: "",
  };

  const handleChangeState = (event) => {
    setState(event);
    getDistrict(event);
    console.log(event);
  };

  const handleChangeCity = (event) => {
    setCity(event);
  };

  const handleChangeTaluka = (event) => {
    setTaluka(event);
    getCities(event);
  };

  const handleChangeGender = (event) => {
    setGender(event);
  };

  const handleDOBChange = (event) => {
    var today = new Date();
    var birthDate = new Date(event);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setAge(age);
  };

  const types = ["image/png", "image/jpeg"];
  const maxSize = 250000;

  const handleFileInputChange = (event) => {
    const file = event[0];
    console.log(file);
    setSelectedFile(file);

    if (file) {
      if (types.includes(file.type)) {
        if (file.size <= maxSize) {
          setSelectedFile(file);
          setTypeError("");
        } else {
          setSelectedFile(null);
          setTypeError("Image size should be less than 250 kb");
        }
      } else {
        setSelectedFile(null);
        setTypeError("Please select a valid image type (png or jpeg)");
      }
    } else {
      setSelectedFile(null);
      setTypeError("");
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Stack direction={{ xs: "row", sm: "row" }}>
          <Controller
            control={control}
            name="file"
            render={({ field: { onChange, value } }) => (
              <TextField
                id="UserProfile"
                label="User Profile"
                placeholder="User Profile"
                variant="outlined"
                inputRef={fileRef}
                size="small"
                type="file"
                hidden
                autoFocus
                onChange={(e) => {
                  const {
                    target: { files },
                  } = e;
                  onChange(files[0]);
                  handleFileInputChange(files);
                }}
                style={{ width: 390 }}
              />
            )}
          />

          <Button
            onClick={() => {
              fileRef.current.click();
            }}
            variant="outlined"
            style={{ width: 200 }}
            endIcon={<Iconify icon="eva:plus-fill" />}
          >
            Uplod profile
          </Button>
          <Stack style={{ marginLeft: 10 }}>
            {typeError && (
              <Typography variant="caption" color="error">
                {typeError}
              </Typography>
            )}

            {selectedFile && (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Preview"
                style={{ Width: 40, height: 40 }}
              />
            )}
          </Stack>
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          {/* <Controller
                        control={control}
                        name="profile"
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                id="profile"
                                label="profile"
                                variant="outlined"
                                placeholder="Full Name"
                                type="file"
                                size="small"
                                fullWidth
                                onChange={(e) => {
                                    const { target: { value,files } } = e
                                    console.log("fileobject",files)
                                    onChange(files)
                                }}
                            />
                        )}
                    /> */}

          <Controller
            control={control}
            name="Name"
            rules={{ required: "Full Name is required." }}
            render={({ field }) => (
              <TextField
                id="Name"
                label="Name"
                variant="outlined"
                placeholder="Full Name"
                size="small"
                fullWidth
                {...field}
                error={Boolean(errors?.Name)}
                helperText={errors.Name?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="employeeId"
            rules={{ required: "Full Name is required." }}
            render={({ field }) => (
              <TextField
                id="employeeId"
                label="Employee ID"
                variant="outlined"
                placeholder="Employee ID"
                size="small"
                fullWidth
                {...field}
                error={Boolean(errors?.Name)}
                helperText={errors.Name?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="ContractorName"
            rules={{ required: "ContractorName is required." }}
            render={({ field }) => (
              <TextField
                id="ContractorName"
                label="Contractor Name"
                variant="outlined"
                placeholder="Contractor Name"
                size="small"
                fullWidth
                {...field}
                error={Boolean(errors?.ContractorName)}
                helperText={errors.ContractorName?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="ContactNumber"
            rules={{
              required: "Mobile Number is required.",
              pattern: /[0-9]{10}/,
            }}
            render={({ field }) => (
              <TextField
                id="ContactNumber"
                label="Mobile Number"
                variant="outlined"
                placeholder="Mobile Number"
                size="small"
                fullWidth
                {...field}
                error={Boolean(errors?.ContactNumber)}
                helperText={errors.ContactNumber?.message}
                inputProps={{
                  maxLength: 10,
                }}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="identificationMark"
            render={({ field }) => (
              <TextField
                id="identificationMark"
                label="IdentificationMark"
                variant="outlined"
                placeholder="IdentificationMark"
                size="small"
                fullWidth
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            rules={{
              required: "Email Address is required.",
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            }}
            render={({ field }) => (
              <TextField
                id="email"
                label="Email Address"
                variant="outlined"
                placeholder="Email Address"
                size="small"
                fullWidth
                margin="normal"
                {...field}
                error={Boolean(errors?.email)}
                helperText={errors.email?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="password"
            rules={{ required: "Password is required." }}
            render={({ field }) => (
              <TextField
                id="password"
                label="Password"
                variant="outlined"
                placeholder="Password"
                type="password"
                size="small"
                fullWidth
                {...field}
                error={Boolean(errors?.password)}
                helperText={errors.password?.message}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="gender"
            rules={{ required: "Gender is required." }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="gender"
                label="Gender"
                variant="outlined"
                placeholder="Gender"
                size="small"
                fullWidth
                select
                value={gender}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  onChange(value);
                  handleChangeGender(value);
                }}
                error={Boolean(errors?.gender)}
                helperText={errors.gender?.message}
                style={{ width: 390 }}
              >
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
                <MenuItem value="O">Others</MenuItem>
              </TextField>
            )}
          />

          <Controller
            control={control}
            name="date"
            rules={{ required: "Date is required." }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="date"
                label="Date of birth"
                placeholder="Date of birth"
                type="date"
                variant="outlined"
                size="small"
                fullWidth
                autoFocus
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  onChange(value);
                  handleDOBChange(value);
                }}
                error={Boolean(errors?.date)}
                helperText={errors.date?.message}
                style={{ width: 390 }}
              />
            )}
          />

          <Controller
            control={control}
            name="age"
            defaultValue={age}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="age"
                label="Age"
                variant="outlined"
                placeholder="Age"
                size="small"
                fullWidth
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  onChange(value);
                }}
                value={age}
                disabled={true}
                style={{ width: 390 }}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="address"
            rules={{ required: "Address is required." }}
            render={({ field }) => (
              <TextField
                id="address"
                label="address"
                variant="outlined"
                placeholder="address"
                size="small"
                fullWidth
                {...field}
                error={Boolean(errors?.address)}
                helperText={errors.address?.message}
                multiline={true}
                rows={3.5}
                style={{ width: 560 }}
              />
            )}
          />

          <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
            <Controller
              control={control}
              name="StateId"
              value={state}
              rules={{ required: "State is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="StateId"
                  label="State"
                  variant="outlined"
                  placeholder="State"
                  size="small"
                  fullWidth
                  select
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    handleChangeState(value);
                  }}
                  error={Boolean(errors?.StateId)}
                  helperText={errors.StateId?.message}
                  style={{ width: 270 }}
                >
                  {stateList?.map((state) => {
                    return (
                      <MenuItem value={state.stateId}>
                        {state.stateTitle}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="CityId"
              value={city}
              rules={{ required: "City Address is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="CityId"
                  label="City"
                  variant="outlined"
                  placeholder="City"
                  size="small"
                  fullWidth
                  select
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    handleChangeCity(value);
                  }}
                  error={Boolean(errors?.CityId)}
                  helperText={errors.CityId?.message}
                  style={{ width: 270 }}
                >
                  {cityList?.map((city) => {
                    return <MenuItem value={city.id}>{city.name}</MenuItem>;
                  })}
                </TextField>
              )}
            />
          </Stack>

          <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
            <Controller
              control={control}
              name="DistrictId"
              value={taluka}
              rules={{ required: "District Address is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="DistrictId"
                  label="District"
                  variant="outlined"
                  placeholder="District"
                  size="small"
                  fullWidth
                  select
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    handleChangeTaluka(value);
                  }}
                  error={Boolean(errors?.DistrictId)}
                  helperText={errors.DistrictId?.message}
                  style={{ width: 270 }}
                >
                  {districtList?.map((district) => {
                    return (
                      <MenuItem value={district.districtid}>
                        {district.districtTitle}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="pincode"
              rules={{ required: "Pincode is required." }}
              render={({ field }) => (
                <TextField
                  id="pincode"
                  label="Pincode"
                  variant="outlined"
                  placeholder="Pincode"
                  size="small"
                  fullWidth
                  margin="normal"
                  {...field}
                  error={Boolean(errors?.pincode)}
                  helperText={errors.pincode?.message}
                  style={{ width: 270 }}
                />
              )}
            />
          </Stack>
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          style={{ marginBottom: 12 }}
        >
          <Controller
            control={control}
            name="nativePlace"
            rules={{ required: "Native Place is required." }}
            render={({ field }) => (
              <TextField
                id="nativePlace"
                label="Native Place"
                variant="outlined"
                placeholder="Native Place"
                size="small"
                fullWidth
                {...field}
                error={Boolean(errors?.nativePlace)}
                helperText={errors.nativePlace?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="physician"
            rules={{ required: "Required." }}
            render={({ field }) => (
              <TextField
                id="physician"
                label="Family Physician"
                variant="outlined"
                size="small"
                placeholder="Who is your Family Physician?"
                fullWidth
                margin="normal"
                {...field}
                error={Boolean(errors?.physician)}
                helperText={errors.physician?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="BloodGroup"
            render={({ field }) => (
              <TextField
                id="bloodGroup"
                label="Blood Group"
                variant="outlined"
                placeholder="Blood Group"
                size="small"
                fullWidth
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="Department"
            render={({ field }) => (
              <TextField
                id="department"
                label="Department"
                variant="outlined"
                placeholder="Department"
                size="small"
                fullWidth
                {...field}
              />
            )}
          />
        </Stack>
      </Stack>
    </>
  );
};

const MedicalHistroy = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const [isAlcoholIntake, setIsAlcoholIntake] = useState("false");
  const [drugUse, setDrugUse] = useState("false");

  // Initial states
  const [medicineOpen, setmedicineOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [disable, setDisable] = React.useState(true);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [vaccinationList, setVaccinationList] = React.useState([]);
  const classes = useStyles();

  // Function For closing the alert snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setmedicineOpen(false);
  };

  // Function For adding new row object
  const handleAdd = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        firstname: "",
        lastname: "",
        city: "",
      },
    ]);
    setEdit(true);
  };

  // Function to handle edit
  const handleEdit = (i) => {
    // If edit mode is true setEdit will
    // set it to false and vice versa
    setEdit(!isEdit);
  };

  // Function to handle save
  const handleSave = () => {
    setEdit(!isEdit);
    setRows(rows);
    console.log("saved : ", rows);
    setDisable(true);
    setmedicineOpen(true);
  };

  // The handleInputChange handler can be set up to handle
  // many different inputs in the form, listen for changes
  // to input elements and record their values in state
  const handleInputChange = (e, index) => {
    setDisable(false);
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list);
    register(`Vaccinations`, { value: list });
  };

  // Showing delete confirmation to users
  const handleConfirm = () => {
    setShowConfirm(true);
  };

  // Handle the case of delete confirmation where
  // user click yes delete a specific row of id:i
  const handleRemoveClick = (i) => {
    const list = [...rows];
    list.splice(i, 1);
    setRows(list);
    setShowConfirm(false);
  };

  // Handle the case of delete confirmation
  // where user click no
  const handleNo = () => {
    setShowConfirm(false);
  };

  const [rows, setRows] = useState([
    {
      vaccinationId: 0,
      vaccinationName: "",
      employeeId: 0,
      isActive: true,
      createdBy: 0,
      updatedBy: 0,
      createdDate: new Date(),
      updatedDate: new Date(),
      deleteDate: null,
    },
  ]);

  return (
    <>
      <br />

      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="isAlcoholIntake"
            defaultValue={isAlcoholIntake}
            rules={{ required: "Field is required." }}
            render={({ field }) => (
              <TextField
                id="alcoholIntake"
                variant="outlined"
                label="Do family your friends worry about your alcohol intake?"
                placeholder="Do family your friends worry about your alcohol intake?"
                size="small"
                select
                fullWidth
                {...field}
                error={Boolean(errors?.alcoholIntake)}
                helperText={errors.alcoholIntake?.message}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem selected value="false">
                  No
                </MenuItem>
              </TextField>
            )}
          />

          <Controller
            control={control}
            name="drugUse"
            defaultValue={drugUse}
            rules={{ required: "Field is required." }}
            render={({ field }) => (
              <TextField
                id="drugUse"
                variant="outlined"
                label="Have you ever had problems with drug use?"
                placeholder="Have you ever had problems with drug use?"
                select
                size="small"
                fullWidth
                {...field}
                error={Boolean(errors?.drugUse)}
                helperText={errors.drugUse?.message}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem selected value="false">
                  No
                </MenuItem>
              </TextField>
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="otherAddiction"
            render={({ field }) => (
              <TextField
                id="otherAddiction"
                variant="outlined"
                placeholder="Any Other Addictions? Please Mention."
                label="Any Other Addictions? Please Mention."
                size="small"
                fullWidth
                defaultValue="NA"
                {...field}
                error={Boolean(errors?.otherAddiction)}
                helperText={errors.otherAddiction?.message}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="noctural"
            render={({ field }) => (
              <TextField
                id="noctural"
                variant="outlined"
                placeholder="Noctural/Diurnal Bladder Habits - (Frequency/Urgency/Painful Present Complaints/History)"
                label="Noctural/Diurnal Bladder Habits - (Frequency/Urgency/Painful Present Complaints/History)"
                size="small"
                fullWidth
                {...field}
                defaultValue="NA"
                error={Boolean(errors?.noctural)}
                helperText={errors.noctural?.message}
                multiline={true}
                rows={3.5}
                style={{ width: 560 }}
              />
            )}
          />

          <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
            <Controller
              control={control}
              name="constipation"
              rules={{ required: "Field is required." }}
              render={({ field }) => (
                <TextField
                  id="constipation"
                  variant="outlined"
                  label="Bowel Habits-Constipation Yes/No"
                  placeholder="Bowel Habits-Constipation Yes/No"
                  size="small"
                  select
                  fullWidth
                  {...field}
                  error={Boolean(errors?.constipation)}
                  helperText={errors.constipation?.message}
                  style={{ width: 580 }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem selected value="false">
                    No
                  </MenuItem>
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="dyspepsia"
              rules={{ required: "Field is required." }}
              render={({ field }) => (
                <TextField
                  id="dyspepsia"
                  variant="outlined"
                  label="Dyspepsia"
                  placeholder="Dyspepsia"
                  size="small"
                  select
                  fullWidth
                  {...field}
                  error={Boolean(errors?.dyspepsia)}
                  helperText={errors.dyspepsia?.message}
                  style={{ width: 580 }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem selected value="false">
                    No
                  </MenuItem>
                </TextField>
              )}
            />
          </Stack>
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="presentComplaints"
            render={({ field }) => (
              <TextField
                id="presentComplaints"
                variant="outlined"
                placeholder="Present Complaints/History"
                label="Present Complaints/History"
                size="small"
                fullWidth
                defaultValue="NA"
                {...field}
                error={Boolean(errors?.presentComplaints)}
                helperText={errors.presentComplaints?.message}
                multiline={true}
                rows={2}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="drugAllergy"
            render={({ field }) => (
              <TextField
                id="drugAllergy"
                variant="outlined"
                placeholder="Please list other medical conditions from which you have suffered in the past"
                label="Please list other medical conditions from which you have suffered in the past"
                size="small"
                defaultValue="NA"
                fullWidth
                {...field}
                error={Boolean(errors?.drugAllergy)}
                helperText={errors.drugAllergy?.message}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="pastAccident"
            render={({ field }) => (
              <TextField
                id="pastAccident"
                variant="outlined"
                placeholder="Any Past Accident? Please Mention."
                label="Any Past Accident? Please Mention."
                size="small"
                fullWidth
                defaultValue="NA"
                {...field}
                error={Boolean(errors?.pastAccident)}
                helperText={errors.pastAccident?.message}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="surgery"
            render={({ field }) => (
              <TextField
                id="surgery"
                variant="outlined"
                placeholder="Please list any surgeries (operations), reason for the surgery, and date of surgery"
                label="Please list any surgeries (operations), reason for the surgery, and date of surgery"
                size="small"
                fullWidth
                defaultValue="NA"
                {...field}
                error={Boolean(errors?.surgery)}
                helperText={errors.surgery?.message}
                multiline={true}
                rows={2.5}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="diseaseList"
            render={({ field }) => (
              <TextField
                id="diseaseList"
                variant="outlined"
                placeholder="Please list other diseases from which you past suffer (Heart, Lung, etc.)&#10;H/O-Child Hood Sufferings"
                label="Please list other diseases from which you past suffer (Heart, Lung, etc.)&#10;H/O-Child Hood Sufferings"
                size="small"
                fullWidth
                defaultValue="NA"
                {...field}
                error={Boolean(errors?.diseaseList)}
                helperText={errors.diseaseList?.message}
                multiline={true}
                rows={2}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="familyHistory"
            render={({ field }) => (
              <TextField
                id="familyHistory"
                variant="outlined"
                placeholder="Family History:- For diseases like diabetes, H.T., Malignancies, Bleeding Disorders (Tendancies) etc."
                label="Family History:- For diseases like diabetes, H.T., Malignancies, Bleeding Disorders (Tendancies) etc."
                size="small"
                fullWidth
                defaultValue="NA"
                {...field}
                error={Boolean(errors?.familyHistory)}
                helperText={errors.familyHistory?.message}
                multiline={true}
                rows={2}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="activeMedicalHistory"
            render={({ field }) => (
              <TextField
                id="activeMedicalHistory"
                variant="outlined"
                placeholder="Active Medical History:- Presently on any medication and since when"
                label="Active Medical History:- Presently on any medication and since when"
                size="small"
                defaultValue="NA"
                fullWidth
                {...field}
                error={Boolean(errors?.activeMedicalHistory)}
                helperText={errors.activeMedicalHistory?.message}
                multiline={true}
                rows={2}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="menstrualHistory"
            render={({ field }) => (
              <TextField
                id="menstrualHistory"
                variant="outlined"
                placeholder="Menstrual History (In Female)"
                label="Menstrual History (In Female)"
                defaultValue="NA"
                size="small"
                fullWidth
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="obstetricHistory"
            render={({ field }) => (
              <TextField
                id="obstetricHistory"
                variant="outlined"
                placeholder="Obstetric History (In Female)"
                label="Obstetric History (In Female)"
                defaultValue="NA"
                size="small"
                fullWidth
                {...field}
              />
            )}
          />
        </Stack>
        <TableBody>
          <Snackbar
            medicineOpen={medicineOpen}
            autoHideDuration={2000}
            onClose={handleClose}
            className={classes.snackbar}
          >
            <Alert onClose={handleClose} severity="success">
              Record saved successfully!
            </Alert>
          </Snackbar>
          <Box margin={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {isEdit ? (
                  <div>
                    <Button onClick={handleAdd}>
                      <AddBoxIcon onClick={handleAdd} />
                      ADD
                    </Button>
                    {rows.length !== 0 && (
                      <div>
                        {disable ? (
                          <Button disabled align="right" onClick={handleSave}>
                            <DoneIcon />
                            SAVE
                          </Button>
                        ) : (
                          <Button align="right" onClick={handleSave}>
                            <DoneIcon />
                            SAVE
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <Button onClick={handleAdd}>
                      <AddBoxIcon onClick={handleAdd} />
                      ADD
                    </Button>
                    <Button align="right" onClick={handleEdit}>
                      <CreateIcon />
                      EDIT
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <TableRow align="center"> </TableRow>

            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>vaccination Name</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => {
                  return (
                    <>
                      <TableRow>
                        {isEdit ? (
                          <>
                            <TableCell padding="none">
                              <input
                                value={row.vaccinationName}
                                name="vaccinationName"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell component="th" scope="row">
                              {row.vaccinationName}
                            </TableCell>
                            {/* <TableCell
                          component="th"
                          scope="row"
                          align="center"
                        ></TableCell> */}
                          </>
                        )}
                        {isEdit ? (
                          <Button className="mr10" onClick={handleConfirm}>
                            <ClearIcon />
                          </Button>
                        ) : (
                          <Button className="mr10" onClick={handleConfirm}>
                            <DeleteOutlineIcon />
                          </Button>
                        )}
                        {showConfirm && (
                          <>
                            <Dialog
                              open={showConfirm}
                              onClose={handleNo}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {"Confirm Delete"}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure to delete
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => handleRemoveClick(i)}
                                  color="primary"
                                  autoFocus
                                >
                                  Yes
                                </Button>
                                <Button
                                  onClick={handleNo}
                                  color="primary"
                                  autoFocus
                                >
                                  No
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        )}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </TableBody>
      </Stack>
    </>
  );
};

const Habits = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const [isSmoker, setIsSmoker] = useState("false");
  const [isTobaccoChewer, setIsTobaccoChewer] = useState("false");
  const [isAlcoholic, setIsAlcoholic] = useState("false");
  const [dateValue, setDateValue] = useState("");

  return (
    <>
      <br />
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
          <Controller
            control={control}
            name="isSmoker"
            value={isSmoker}
            defaultValue={isSmoker}
            rules={{ required: "Field is required." }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="isSmoker"
                label="Do you Smoke?"
                variant="outlined"
                placeholder="Do you Smoke?"
                fullWidth
                value={isSmoker}
                selected={isSmoker}
                defaultValue={isSmoker}
                select
                size="small"
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  onChange(value);
                  setIsSmoker(value);
                }}
                error={Boolean(errors?.isSmoker)}
                helperText={errors.isSmoker?.message}
                style={{ width: 200 }}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
            )}
          />

          {/*<Controller*/}
          {/*    control={control}*/}
          {/*    name="isTobaccoChewer"*/}
          {/*    value={isTobaccoChewer}*/}
          {/*    defaultValue={isTobaccoChewer}*/}
          {/*    rules={{ required: "Field is required." }}*/}
          {/*    render={({ field: { onChange, value } }) => (*/}
          {/*        <DatePicker*/}
          {/*            views={['year', 'month']}*/}
          {/*            label="Year and Month"*/}
          {/*            minDate={new Date('2012-03-01')}*/}
          {/*            maxDate={new Date('2023-06-01')}*/}
          {/*            value={value}*/}
          {/*            onChange={setDateValue}*/}
          {/*            renderInput={(params) => <TextField {...params} helperText={null} />}*/}
          {/*        />*/}
          {/*    )}*/}
          {/*/>*/}

          {isSmoker != "false" ? (
            <React.Fragment>
              <Controller
                control={control}
                name="isTobaccoChewer"
                value={isTobaccoChewer}
                defaultValue={isTobaccoChewer}
                rules={{ required: "Field is required." }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="isTobaccoChewer"
                    label="Type: Tobacco/Chewer"
                    variant="outlined"
                    placeholder="Type: Tobacco/Chewer"
                    fullWidth
                    value={isTobaccoChewer}
                    selected={isTobaccoChewer}
                    defaultValue={isTobaccoChewer}
                    select
                    size="small"
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      onChange(value);
                      setIsTobaccoChewer(value);
                    }}
                    error={Boolean(errors?.isTobaccoChewer)}
                    helperText={errors.isTobaccoChewer?.message}
                    style={{ width: 200 }}
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </TextField>
                )}
              />

              <Controller
                control={control}
                name="smokeSinceWhen"
                rules={{ required: "required." }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="smokeSinceWhen"
                    label="Since When"
                    variant="outlined"
                    placeholder="Since When"
                    size="small"
                    fullWidth
                    select
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      onChange(value);
                    }}
                    error={Boolean(errors?.smokeSinceWhen)}
                    helperText={errors.smokeSinceWhen?.message}
                    style={{ width: 200 }}
                  >
                    {sinceWhen?.map((item) => {
                      return (
                        <MenuItem value={item.value}>{item.text}</MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />

              <Controller
                control={control}
                name="howManyPacks"
                rules={{ required: "required." }}
                render={({ field }) => (
                  <TextField
                    id="howManyPacks"
                    label="if yes, how many packs per day?"
                    variant="outlined"
                    placeholder="if yes, how many packs per day?"
                    size="small"
                    fullWidth
                    {...field}
                    error={Boolean(errors?.howManyPacks)}
                    helperText={errors.howManyPacks?.message}
                    style={{ width: 450 }}
                  />
                )}
              />
            </React.Fragment>
          ) : (
            ""
          )}
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="quitSmokingHowLong"
            render={({ field }) => (
              <TextField
                id="quitSmokingHowLong"
                label="if you have quit, how long ago?"
                variant="outlined"
                placeholder="if you have quit, how long ago?"
                size="small"
                fullWidth
                {...field}
                error={Boolean(errors?.quitSmokingHowLong)}
                helperText={errors.quitSmokingHowLong?.message}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
          <Controller
            control={control}
            name="isAlcoholic"
            value={isAlcoholic}
            defaultValue={isAlcoholic}
            rules={{ required: "Field is required." }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="isAlcoholic"
                label="Do you consume alcohol?"
                variant="outlined"
                placeholder="Do you consume alcohol?"
                fullWidth
                value={isAlcoholic}
                selected={isAlcoholic}
                defaultValue={isAlcoholic}
                select
                size="small"
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  onChange(value);
                  setIsAlcoholic(value);
                }}
                error={Boolean(errors?.isAlcoholic)}
                helperText={errors.isAlcoholic?.message}
                style={{ width: 300 }}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
            )}
          />

          {isAlcoholic != "false" ? (
            <React.Fragment>
              <Controller
                control={control}
                name="howOften"
                render={({ field }) => (
                  <TextField
                    id="howOften"
                    label="if yes, how often do you drink?"
                    variant="outlined"
                    placeholder="if yes, how often do you drink?"
                    size="small"
                    fullWidth
                    {...field}
                    error={Boolean(errors?.howOften)}
                    helperText={errors.howOften?.message}
                    style={{ width: 500 }}
                  />
                )}
              />

              <Controller
                control={control}
                name="alcoholicSinceWhen"
                rules={{ required: "required." }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="alcoholicSinceWhen"
                    label="Since When"
                    variant="outlined"
                    placeholder="Since When"
                    size="small"
                    fullWidth
                    select
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      onChange(value);
                    }}
                    error={Boolean(errors?.alcoholicSinceWhen)}
                    helperText={errors.alcoholicSinceWhen?.message}
                    style={{ width: 200 }}
                  >
                    {sinceWhen?.map((item) => {
                      return (
                        <MenuItem value={item.value}>{item.text}</MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />
            </React.Fragment>
          ) : (
            ""
          )}
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="quitAlcoholHowLong"
            rules={{ required: "required." }}
            render={({ field }) => (
              <TextField
                id="quitAlcoholHowLong"
                label="if you have quit, how long ago?"
                variant="outlined"
                placeholder="if you have quit, how long ago?"
                size="small"
                fullWidth
                {...field}
                error={Boolean(errors?.quitAlcoholHowLong)}
                helperText={errors.quitAlcoholHowLong?.message}
              />
            )}
          />
        </Stack>
      </Stack>
    </>
  );
};

const AddEmployee = (props) => {
  const { setOpen, fetchPatients } = props;

  const classes = useStyles();
  const methods = useForm({
    defaultValues: {
      fullName: "",
      sex: "",
      dob: "",
      emailAddress: "",
      phoneNumber: "",
      alternatePhone: "",
      address1: "",
      age: "",
      nativePlace: "",
      physician: "",
    },
  });
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [disable, setDisable] = useState(false);
  const steps = getSteps();
  const isStepOptional = (step) => {
    return false;
  };
  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = (data) => {
    console.log(data);

    if (activeStep == steps.length - 1) {
      console.log(JSON.stringify(data));
      data["UserTypeId"] = 4;
      data["ClientId"] = props.auth.clientId;
      let formData = new FormData();
      Object.keys(data).forEach(function (key) {
        formData.append(key, data[key]);
      });
      var requestOptions = {
        method: "post",
        body: formData,
      };
      console.log(formData);
      console.log(requestOptions.body);
      setDisable(true);
      fetch(
        configUrl.StrataServer + `/api/Patient/registerPatient`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message) {
            if (result.message == "User Exist") {
              setDisable(!true);
              // alert("User email already is in use. ");
              toast.error("User email already is in use.", {
                position: "top-center",
                toastId: "success1",
              });
              return;
            }
          }
          if (result.message == "Success") {
            //alert("client register successfully");
            toast.success("Employee registered successfully", {
              position: "top-center",
              toastId: "success1",
            });

            setTimeout(() => {
              props.closeModal();
            }, 2000);
             props.setRefresh(true);
          } else {
            // alert(result.message);
            toast.error(result.message, {
              position: "top-center",
              toastId: "success1",
            });
          }
        });
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  //       .then((result) => {
  //         console.log(result.message);
  //         // alert("Employee registered successfully");
  //         toast.success("Employee registered successfully", {
  //           position: "top-center",
  //           toastId: "success1",
  //         });
  //         // setTimeout(() => {
  //         //   window.location.reload();
  //         // }, 2000);
  //       })
  //       .catch((err) => {
  //         toast.error("email already exis", {
  //           position: "top-center",
  //           toastId: "success1",
  //         });
  //       });
  //   } else {
  //     setActiveStep(activeStep + 1);
  //     setSkippedSteps(
  //       skippedSteps.filter((skipItem) => skipItem !== activeStep)
  //     );
  //   }
  // };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  // const onSubmit = (data) => {
  //   console.log(data);
  // };
  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                optional
              </Typography>
            );
          }
          if (isStepFalied() && activeStep == index) {
            labelProps.error = true;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Thank You
        </Typography>
      ) : (
        <>
          <Stack spacing={2}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleNext)}>
                {getStepContent(activeStep)}

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  justifyContent="flex-end"
                >
                  <Button
                    className={classes.button}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    back
                  </Button>
                  {isStepOptional(activeStep) && (
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={handleSkip}
                    >
                      skip
                    </Button>
                  )}
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    // onClick={handleNext}
                    type="submit"
                    disabled={disable}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Stack>
              </form>
              <ToastContainer />
            </FormProvider>
          </Stack>
        </>
      )}
    </div>
  );
};

export default AddEmployee;
