import { Box } from "@mui/material";
import React from "react";

const Header = () => {
  return (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Heebo:400,500,700|Fira+Sans:600"
        rel="stylesheet"
      />
      <link rel="stylesheet" href="/static/css/style.css" />
      <Box boxShadow={6}>
        <header className="site-header">
          <div className="header-shape header-shape-1"></div>
          <div className="header-shape header-shape-2"></div>
          <div className="container">
            <div className="site-header-inner">
              <div className="brand header-brand">
                <h1 className="m-0">
                  <a href="/">
                    <img
                      src="/static/images/Strata_Logo_new.jpg"
                      style={{ width: "15%" }}
                    />
                  </a>
                </h1>
              </div>
              <div className="menu">
                <a href="/">
                  <h4 className="sign-in-a">Home</h4>
                </a>
                <h4>
                  <a href="/login">
                    <h4 className="sign-in-a">Sign In</h4>
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </header>
      </Box>
    </>
  );
};

export default Header;
