import React, { Component } from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../components/Iconify";
import { FormProvider, RHFTextField } from "../../components/hook-form";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import treatmentService from "../../services/treatmentService";
import stockManagementService from "../../services/stockManagementService";
import EditableTable from "../../utils/EditableTable";
import CreateIcon from "@material-ui/icons/Create";
import {
  Box,
  Button,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactApexChart from "react-apexcharts";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Creating styles
const useStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  table: {
    minWidth: 650,
  },
  snackbar: {
    bottom: "104px",
  },
});

export default function TreatmentForm(props) {
  const [patientOpen, setPatientOpen] = useState(false);
  const [causeOpen, setCauseOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [shift, setShift] = useState("");
  const [highBP, setHighBp] = useState("");
  const [lowBP, setLowBp] = useState("");
  const [patientValue, setPatientvalue] = useState("");

  const [patientOptions, setPatientOptions] = React.useState([]);

  const [causeOptions, setCauseOptions] = React.useState([]);

  const Patientloading = patientOpen && patientOptions.length === 0;

  const Causeloading = causeOpen && causeOptions.length === 0;

  const [defaultDate, setDefaultDate] = React.useState(
    new Date().toISOString()
  );

  const [medicineList, setMedicineList] = React.useState([]);

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const classes = useStyles();
  console.log(medicineList);

  // Defining a state named rows
  // which we can update by calling on setRows function
  const [rows, setRows] = useState([
    {
      medicineTreatmentId: 0,
      name: "",
      treatmentId: 0,
      medicineId: 0,
      quantity: 0,
      isActive: true,
      createdBy: 0,
      updatedBy: 0,
      createdDate: new Date(),
      updatedDate: new Date(),
      deleteDate: null,
    },
  ]);

  // Initial states
  const [medicineOpen, setmedicineOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [disable, setDisable] = React.useState(true);
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(false);

  // Function For closing the alert snackbar
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setmedicineOpen(false);
  };

  // Function For adding new row object
  const handleAdd = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        firstname: "",
        lastname: "",
        city: "",
      },
    ]);
    setEdit(true);
  };

  // Function to handle edit
  const handleEdit = (i) => {
    // If edit mode is true setEdit will
    // set it to false and vice versa
    setEdit(!isEdit);
  };

  // Function to handle save
  const handleSave = () => {
    setEdit(!isEdit);
    setRows(rows);
    console.log("saved : ", rows);
    setDisable(true);
    setmedicineOpen(true);
  };

  // The handleInputChange handler can be set up to handle
  // many different inputs in the form, listen for changes
  // to input elements and record their values in state
  const handleInputChange = (e, index) => {
    setDisable(false);
    const { name, value } = e.target;
    const list = [...rows];
    list[index][name] = value;
    setRows(list);
  };

  // Showing delete confirmation to users
  const handleConfirm = () => {
    setShowConfirm(true);
  };

  // Handle the case of delete confirmation where
  // user click yes delete a specific row of id:i
  const handleRemoveClick = (i) => {
    const list = [...rows];
    list.splice(i, 1);
    setRows(list);
    setShowConfirm(false);
  };

  // Handle the case of delete confirmation
  // where user click no
  const handleNo = () => {
    setShowConfirm(false);
  };

  // useEffect(() => {
  //     setTableData(props.tableData);
  //   }, [])

  const RegisterSchema = Yup.object().shape({
    //PatientId: Yup.string().required('patient is required'),
    Observations: Yup.string().required("Observations is required"),
    Symptoms: Yup.string().required("Symptoms is required"),
    HighBP: Yup.string().required("HighBP is required"),
    LowBP: Yup.string().required("LowBP is required"),
    //Cause: Yup.string().email('Email must be a valid email address').required('Email is required'),
    // Height: Yup.string().required("Height is Required")
    //     .matches(
    //         '[0-9]',
    //         "Height is not valid"
    //     ),
    Weight: Yup.string()
      .required("Weight is Required")
      .matches("[0-9]", "Height is not valid"),
    Prescription: Yup.string().required("Prescription date Required"),
  });

  const defaultValues = {
    PatientId: "",
    lastName: "",
    username: "",
    password: "",
    email: "",
    ContactNumber: "",
    specialist: "",
    pincode: "",
    address: "",
    dateOfTreatment: new Date().toISOString().substring(0, 10),
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    data["PhysicianId"] = props.auth.clientId;
    data["FirstAider"] = props.auth.userId;
    data["IsActive"] = true;
    data["bp"] = highBP + "-" + lowBP;
    let obj = {
      treatment: data,
      medicineForTreatment: rows,
    };
    console.log(obj);
    setDisable(true);
    let response = await treatmentService.AddTreatment(obj);
    console.log(response);
    if (response.message == "Success") {
      // alert("Treatment registered successfully");
      toast.success("Treatment registered successfully", {
        position: "top-center",
        toastId: "success1",
      });
      setDisableButton(true);
      setTimeout(() => {
        props.closeModal();
      }, 2000);
      props.setRefresh(true);
    } else {
      // alert("Something went wrong. Please contact admin " + response.message);
      setDisable(!true);
      toast.error(
        "Something went wrong. Please contact admin " + response.message,
        {
          position: "top-center",
          toastId: "success1",
        }
      );
    }
    //     var requestOptions = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify(data)
    //     };
    //    fetch(`https://strata-portal.azurewebsites.net/api/doctor/AddTreatment`, requestOptions).then(response => response.json()).then(result => {
    //         alert("Treatment registered successfully");
    //         window.location.reload();
    //        // props.closeModal();
    //     })

    //     console.log(JSON.stringify(data));
  };

  //function populatePatientList(query) {
  //    fetch(`https://strata-portal.azurewebsites.net//api/Patient/searchPatient?query=`+query).then(response => response.json()).then(result => {
  //         setPatientList(result.data);
  //         console.log(result.data);
  //     })
  // }

  const onPatientChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log(value);
    let res = await treatmentService.SearchPetiont(value, props.auth.clientId);
    console.log(res);
    setPatientOptions(res.data);
    // const response = await fetch(
    //     `https://strata-portal.azurewebsites.net/api/Patient/searchPatient?query=` + value + `&clientId=` + props.auth.clientId
    // );

    // const result = await response.json();
    // const data = result.data;
    // console.log(data);
    // setPatientOptions(data);
  };

  const onCauseChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log(value);
    let res = await treatmentService.GetAllCauses(value);
    setCauseOptions(res.data);
    // const response = await fetch(
    //     `https://strata-portal.azurewebsites.net/api/Doctor/getAllCauses?query=` + value
    // );

    // const result = await response.json();
    // const data = result.data;
    // setCauseOptions(data);
  };

  React.useEffect(() => {
    if (!open) {
      onPatientChangeHandle("");
      onCauseChangeHandle("");
    }
    getStock();
    console.log(props.auth);
  }, [open]);

  const getStock = async () => {
    let allmedicines = await stockManagementService.GetAllMedicines(
      props.auth.clientId
    );

    console.log("alldoctor", allmedicines);

    setMedicineList(
      allmedicines.data.result.map((x, i) => {
        console.log("alldoctor", x);
        return { ...x, id: i };
      })
    );
  };

  const handleChangeShift = (event) => {
    setShift(event);
  };

  const handleHighBP = (e) => {
    setHighBp(e);
  };
  const handleLowBP = (e) => {
    setLowBp(e);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                style={{ width: 300 }}
                open={patientOpen}
                onOpen={() => {
                  setPatientOpen(true);
                }}
                onClose={() => {
                  setPatientOpen(false);
                }}
                onChange={(e, value) => {
                  console.log(value);
                  onChange(value.id);
                }}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.label}
                options={patientOptions}
                loading={Patientloading}
                name="PatientId"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Patient"
                    variant="outlined"
                    size="small"
                    style={{ width: 500 }}
                    name="PatientId"
                    onChange={(ev) => {
                      // dont fire API if the user delete or not entered anything
                      if (ev.target.value !== "" || ev.target.value !== null) {
                        onPatientChangeHandle(ev.target.value);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {Patientloading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
            onChange={([event, data]) => {
              return data;
            }}
            name="PatientId"
            control={control}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <RHFTextField
            name="ContractorName"
            label="Contractor Name"
            variant="outlined"
            placeholder="Contractor Name"
            type="text"
            size="small"
            fullWidth
          />
          <Controller
            control={control}
            name="Shift"
            rules={{ required: "Shift is required." }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="Shift"
                label="Shift"
                variant="outlined"
                placeholder="Shift"
                size="small"
                fullWidth
                select
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  onChange(value);
                  handleChangeShift(value);
                }}
                style={{ width: 390 }}
              >
                <MenuItem style={{ width: "100%" }} value="1">
                  1
                </MenuItem>
                <br />
                <MenuItem style={{ width: "100%" }} value="2">
                  2
                </MenuItem>
                <br />
                <MenuItem style={{ width: "100%" }} fullWidth value="3">
                  3
                </MenuItem>
              </TextField>
            )}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <RHFTextField
            name="Observations"
            label="Patient issue"
            variant="outlined"
            placeholder="Patient issue"
            size="small"
            multiline={true}
            rows={3.5}
            fullWidth
          />
          <RHFTextField
            name="Symptoms"
            label="Symptoms"
            variant="outlined"
            placeholder="Symptoms"
            size="small"
            fullWidth
            multiline={true}
            rows={3.5}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                freeSolo
                autoSelect
                id="CauseId"
                style={{ width: 300 }}
                open={causeOpen}
                onOpen={() => {
                  setCauseOpen(true);
                }}
                onClose={() => {
                  setCauseOpen(false);
                }}
                onChange={(e, value) => {
                  onChange(value);
                }}
                disableClearable
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={causeOptions}
                loading={Causeloading}
                name="Cause"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cause"
                    variant="outlined"
                    size="small"
                    style={{ width: 300 }}
                    onChange={(ev) => {
                      // dont fire API if the user delete or not entered anything
                      if (ev.target.value !== "" || ev.target.value !== null) {
                        onCauseChangeHandle(ev.target.value);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {Causeloading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            )}
            onChange={([event, data]) => {
              return data;
            }}
            name="Cause"
            control={control}
          />

          {/* <RHFTextField
                        name="Height"
                        label="Height in cms"
                        variant="outlined"
                        placeholder="Height"
                        size="small"
                        type="number"
                        fullWidth
                    /> */}
          <RHFTextField
            name="Weight"
            label="Weight in kgs"
            variant="outlined"
            placeholder="Weight"
            type="number"
            size="small"
            fullWidth
          />

          <RHFTextField
            name="Pulse"
            label="Pulse"
            variant="outlined"
            placeholder="Pulse"
            type="text"
            size="small"
            fullWidth
          />

          <RHFTextField
            name="SPO2"
            label="SPO2"
            variant="outlined"
            placeholder="SPO2"
            type="text"
            size="small"
            fullWidth
          />

          <RHFTextField
            name="Temp"
            label="Temperature"
            variant="outlined"
            placeholder="Temperature"
            type="text"
            size="small"
            fullWidth
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
            <Controller
              control={control}
              name="HighBP"
              rules={{ required: "HighBP is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="HighBP"
                  label="High BP"
                  type="number"
                  variant="outlined"
                  placeholder="High BP"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    handleHighBP(value);
                  }}
                  error={Boolean(errors?.HighBP)}
                  helperText={errors.HighBP?.message}
                  style={{ width: 150 }}
                ></TextField>
              )}
            />
            <Controller
              control={control}
              name="LowBP"
              rules={{ required: "LowBP is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="LowBP"
                  label="Low BP"
                  variant="outlined"
                  placeholder="Low BP"
                  type="number"
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    handleLowBP(value);
                  }}
                  error={Boolean(errors?.LowBP)}
                  helperText={errors.LowBP?.message}
                  style={{ width: 150 }}
                ></TextField>
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <RHFTextField
              name="Prescription"
              label="Notes"
              variant="outlined"
              placeholder="Notes"
              size="small"
              multiline={true}
              rows={3.5}
              style={{ width: "550px" }}
            />
          </Stack>
          <Stack direction={{ xs: "column", sm: "column" }} spacing={2}>
            <RHFTextField
              name="Condition"
              label="Condition"
              variant="outlined"
              placeholder="Condition"
              size="small"
              fullWidth
            />

            <RHFTextField
              name="DateOfTreatment"
              type="datetime-local"
              variant="outlined"
              // value={defaultDate}
              // onChange={(event) => setDefaultDate(event.target.value)}
              size="small"
              fullWidth
              style={{ width: 390 }}
            />
          </Stack>
        </Stack>

        <TableBody>
          <Snackbar
            medicineOpen={medicineOpen}
            autoHideDuration={2000}
            onClose={handleClose}
            className={classes.snackbar}
          >
            <Alert onClose={handleClose} severity="success">
              Record saved successfully!
            </Alert>
          </Snackbar>
          <Box margin={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {isEdit ? (
                  <div>
                    <Button onClick={handleAdd}>
                      <AddBoxIcon onClick={handleAdd} />
                      ADD
                    </Button>
                    {rows.length !== 0 && (
                      <div>
                        {disable ? (
                          <Button disabled align="right" onClick={handleSave}>
                            <DoneIcon />
                            SAVE
                          </Button>
                        ) : (
                          <Button align="right" onClick={handleSave}>
                            <DoneIcon />
                            SAVE
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <Button onClick={handleAdd}>
                      <AddBoxIcon onClick={handleAdd} />
                      ADD
                    </Button>
                    <Button align="right" onClick={handleEdit}>
                      <CreateIcon />
                      EDIT
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <TableRow align="center"> </TableRow>

            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Medicine</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => {
                  return (
                    <>
                      <TableRow>
                        {isEdit ? (
                          <>
                            <TableCell padding="none">
                              <select
                                style={{ width: "100px" }}
                                name="medicineId"
                                value={row.medicineId}
                                onChange={(e) => {
                                  handleInputChange(e, i);
                                }}
                              >
                                <option value="">select Medicine</option>
                                {medicineList.map((i) => {
                                  console.log(props.medicineList);
                                  return (
                                    <option value={i.medicineId}>
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </TableCell>
                            <TableCell padding="none">
                              <input
                                value={row.quantity}
                                name="quantity"
                                onChange={(e) => handleInputChange(e, i)}
                              />
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell component="th" scope="row">
                              {medicineList
                                .filter(function (obj) {
                                  return obj.medicineId == row.medicineId;
                                })
                                .map(function (obj) {
                                  return obj.name;
                                })}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.quantity}
                            </TableCell>
                            {/* <TableCell
                          component="th"
                          scope="row"
                          align="center"
                        ></TableCell> */}
                          </>
                        )}
                        {isEdit ? (
                          <Button className="mr10" onClick={handleConfirm}>
                            <ClearIcon />
                          </Button>
                        ) : (
                          <Button className="mr10" onClick={handleConfirm}>
                            <DeleteOutlineIcon />
                          </Button>
                        )}
                        {showConfirm && (
                          <>
                            <Dialog
                              open={showConfirm}
                              onClose={handleNo}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">
                                {"Confirm Delete"}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure to delete
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => handleRemoveClick(i)}
                                  color="primary"
                                  autoFocus
                                >
                                  Yes
                                </Button>
                                <Button
                                  onClick={handleNo}
                                  color="primary"
                                  autoFocus
                                >
                                  No
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        )}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </TableBody>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={disableButton}
        >
          Register
        </LoadingButton>
      </Stack>
      <ToastContainer />
    </FormProvider>
  );
}
