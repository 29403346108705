import axios from "axios";

const apiKey = "NzYzNDM0NGQ2NjU0NjU1MjQ4NmM1MzRmNDU2ZjY2NWE=";
const number = "8692873260";

export const sendMessage = async () => {
  const message = "helloworld";
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  try {
    const response = await axios.post(
      "https://api.textlocal.in/send/",
      `apikey=${apiKey}&numbers=${number}&message=${message}&sender=TXTLCL`,
      config
    );
    const result = response.data;
    console.log(result);
    return result;
  } catch (error) {
    console.error(error);
  }
};
