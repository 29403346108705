import React from "react";

const Footer = () => {
  return (
    <>
      <link
        href="https://fonts.googleapis.com/css?family=Heebo:400,500,700|Fira+Sans:600"
        rel="stylesheet"
      />
      <link rel="stylesheet" href="/static/css/style.css" />
      <>
        <section className="newsletter section text-light">
          <div className="container-sm">
            <div className="newsletter-inner section-inner">
              <div className="newsletter-header text-center">
                <h2 className="section-title mt-0">connect with us.</h2>
                <p className="section-paragraph">
                  eOHC  <br />
                  103, Primus Business Park, Road -16A, Wagle MIDC, Thane –
                  400604 Maharashtra, India # +91 7039016081 / 82/ 83/ 84
                </p>
              </div>
              <div className="footer-form newsletter-form field field-grouped">
                {/* <div class="control control-expanded">
                          <input class="input" type="email" name="email" placeholder="Your best email&hellip;">
                      </div> */}
                <div className="control">
                  <a
                    className="button button-primary button-block button-shadow"
                    href="/login"
                  >
                    Sign In
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="site-footer">
          <div className="container">
            <div className="site-footer-inner has-top-divider">
              <div className="brand footer-brand">
                <a href="#">eOHC </a>
              </div>
              <ul className="footer-links list-reset">
                <li>
                  <a href="#">Contact</a>
                </li>
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">FAQ's</a>
                </li>
                <li>
                  <a href="#">Support</a>
                </li>
              </ul>
              <ul className="footer-social-links list-reset">
                <li>
                  <a href="#">
                    <span className="screen-reader-text">Facebook</span>
                    <svg
                      width={16}
                      height={16}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z"
                        fill="#FFF"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="screen-reader-text">Twitter</span>
                    <svg
                      width={16}
                      height={16}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z"
                        fill="#FFF"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span className="screen-reader-text">Google</span>
                    <svg
                      width={16}
                      height={16}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z"
                        fill="#FFF"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
              <div className="footer-copyright">
                © 2022 April, all rights reserved
              </div>
            </div>
          </div>
        </footer>
      </>
    </>
  );
};

export default Footer;
