import axios from "axios";
import { configUrl } from "../config.js";
import axiosInstance from "./interceptore.js";

// const userToken = localStorage.getItem("userToken");

// const axiosInstance = axios.create({
//   baseURL: configUrl.StrataServer,
//   // headers: {
//   //   "Access-Control-Allow-Origin": "https://localhost:3000",
//   //   Authorization: `Bearer ${userToken}`,
//   // },
// });

const CheckUser = async (requestOptions) => {
  let response = await axiosInstance.post(`/auth/Login`, requestOptions);
  console.log(response);
  const token = response.data.data.token;
  localStorage.setItem("userToken", token);
  return response.data;
};

const registerClient = async (requestOptions) => {
  let response = await axiosInstance.post(
    `/Auth/RegisterClient`,
    requestOptions
  );
  console.log(response);

  return response.data;
};
export default {
  CheckUser: CheckUser,
  registerClient: registerClient,
};
