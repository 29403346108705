// component
import Iconify from "../../components/Iconify";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import PeopleIcon from "@mui/icons-material/People";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import PersonIcon from "@mui/icons-material/Person";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon("eva:pie-chart-2-fill"),
    scope: [1, 2, 3, 4],
  },
  {
    title: "Client",
    path: "/dashboard/client",
    icon: <PeopleAltIcon />,
    scope: [1],
  },
  {
    title: "Doctor",
    path: "/dashboard/doctor",
    icon: <LocalHospitalIcon />,
    scope: [1],
  },
  {
    title: "DefaultValues",
    path: "/dashboard/defaultvalue",
    icon: <DisplaySettingsIcon />,
    scope: [1],
  },
  {
    title: "Employee",
    path: "/dashboard/patient",
    icon: <PeopleIcon />,
    scope: [2],
  },
  {
    title: "Treatment",
    path: "/dashboard/treatment",
    icon: <VaccinesIcon />,
    scope: [2],
  },
  {
    title: "Stock Management",
    path: "/dashboard/stock",
    icon: <ShowChartIcon />,
    scope: [2],
  },
  {
    title: "Pre-Employeement",
    path: "/preemp/preemp",
    icon: <PersonIcon />,
    scope: [2],
    children: [
      {
        title: "Pre-Employment",
        path: "/preemp/preempList",
        icon: getIcon("uil:user"),
        scope: [2],
      },
      {
        title: "Pre-Employment Report By Date",
        path: "/preemp/preempReport",
        icon: getIcon("uil:user"),
        scope: [2],
      },
    ],
  },
  {
    title: "Medical Test",
    path: "/dashboard/medicaltest",
    icon: <MonitorHeartIcon />,
    scope: [1],
  },
  {
    title: "Health Checkup",
    path: "/dashboard/#",
    icon: <FactCheckIcon />,
    scope: [2, 3],
    children: [
      {
        title: "Requests",
        path: "/dashboard/healthcheckrequest",
        icon: getIcon("uil:user"),
        scope: [2, 3],
      },
      {
        title: "Quotation",
        path: "/dashboard/allquotations",
        icon: getIcon("uil:user"),
        scope: [2, 3],
      },
      {
        title: "Reports",
        path: "/dashboard/dochc",
        icon: getIcon("uil:user"),
        scope: [2],
        // children:[
        //     {
        //         title: 'Employee Health CheckUp',
        //         path: '/reports/allemphcList',
        //         icon: getIcon('uil:user'),
        //         scope: [2],
        //     },
        //     {
        //         title: 'Employee Health CheckUp',
        //         path: '/reports/misReport',
        //         icon: getIcon('uil:user'),
        //         scope: [2,3],
        //     },
        // ]
      },
      {
        title: "Assigned Chekups",
        path: "/dashboard/dochc",
        icon: getIcon("uil:user"),
        scope: [3],
      },
    ],
  },
  {
    title: "Attachments",
    path: "/doctor/UploadAttachments",
    icon: <AttachEmailIcon />,
    scope: [3],
  },
  {
    title: "Ambulance",
    path: "/ambulance/#",
    icon: getIcon("mdi:ambulance"),
    scope: [2],
    children: [
      {
        title: "Submit CheckList",
        path: "/ambulance/ambulanceCheckList",
        icon: getIcon("uil:user"),
        scope: [2, 3],
      },
      {
        title: "View all CheckLists",
        path: "/ambulance/submitedCheckList",
        icon: getIcon("uil:user"),
        scope: [2, 3],
      },
      {
        title: "Ambulance Report By Date",
        path: "/ambulance/Ambulacereport",
        icon: getIcon("uil:user"),
        scope: [2, 3],
      },
    ],
  },
  {
    title: "first aid box",
    path: "/dashboard/#",
    icon: <MedicalServicesIcon />,
    scope: [2],
  },
];

export default navConfig;
