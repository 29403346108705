import React, { Component } from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment, Switch } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../components/Iconify";
import {
  FormProvider as MyFormProvider,
  RHFTextField,
} from "../../components/hook-form";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { phoneRegex } from "../../App";
import useApi from "../../hooks/useApi";
import { getStates, getCities, getDistrict } from "../../api/util";
import { result } from "lodash";
import Alert from "@mui/material/Alert";
import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
} from "@mui/material";
import utile from "../../services/utile";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core/styles";
import {
  Controller,
  useFormContext,
  FormProvider,
  useFormState,
} from "react-hook-form";
import { configUrl } from "../../config.js";
import clientService from "../../services/clientService";
const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

export function RegisterForm(props) {
  console.log("RegisterForm", props);
  //console.log("RegisterForm", props.defaultValues.name)

  const navigate = useNavigate();
  const [state, setState] = useState("");

  const [city, setCity] = useState("");

  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);

  const getStateData = useApi(getStates);
  const getDistrictData = useApi(getDistrict);
  const getCityData = useApi(getCities);
  const [currendefaultvalue, setCurrentDefoultValue] = useState({
    address: "",
    adminCityId: 0,
    adminContactNumber: "",
    adminDistrictId: 0,
    adminId: 0,
    adminStateId: 0,
    adminaddress: "",
    adminemail: "",
    adminpincode: "",
    cityId: 0,
    clinetId: 0,
    contactNumber: "",
    districtId: 0,
    email: null,
    fullName: "",
    gstn: "",
    name: "",
    panCardNumber: "",
    password: "",
    pincode: "",
    registrationNumber: "",
    stateId: 0,
    userName: "",
    userTypeId: null,
  });
  console.log(currendefaultvalue);

  const [taluka, setTaluka] = React.useState("");
  const [disable, setDisable] = useState(false);

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const formState = useFormState();

  console.log(formState);
  useEffect(() => {
    getCities();
    getStates();
    getDistrict();
    //setCurrentDefoultValue(props.defaultValues)
    //console.log(currendefaultvalue)
  }, []); //

  useEffect(() => {
    if (props.defaultValues) {
      setCurrentDefoultValue(props.defaultValues);
      console.log("currendefaultvalue", currendefaultvalue);
    }
  }, [props.defaultValues]);
  const [showPassword, setShowPassword] = useState(false);

  async function getStates() {
    let res = await utile.GetAllStates();
    console.log(res);
    setStateList(res.data);
    // fetch(`https://strata-portal.azurewebsites.net/api/util/GetAllStates`).then(response => response.json()).then(result => {
    //     setStateList(result.data);
    //     console.log(result.data);
    // });
  }

  async function getDistrict(stateID) {
    console.log(stateID);
    let res = await utile.GetAllDistricts(stateID);
    setDistrictList(res.data);
    // if (stateID != undefined) {

    //     // fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllDistricts?stateID=` + stateID).then(response => response.json()).then(result => {
    //     //     setDistrictList(result.data);
    //     // });
    // }
    // else {
    //     let res=await utile.GetAllDistricts(stateID)
    //    setDistrictList(res.data);
    //     // fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllDistricts?stateID=`).then(response => response.json()).then(result => {
    //     //     setDistrictList(result.data);
    //     // });
    // }
  }

  async function getCities(districId) {
    console.log(districId);

    let res = await utile.GetAllCities(districId);
    setCityList(res.data);
    // if (districId != undefined) {
    //     fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllCities?districId=` + districId).then(response => response.json()).then(result => {
    //         setCityList(result.data);
    //     });
    // }
    // else {
    //     fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllCities?districId=`).then(response => response.json()).then(result => {
    //         setCityList(result.data);
    //     });
    // }
  }

  const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  };

  const RegisterSchema = Yup.object().shape({
    Name: Yup.string().required("First name required"),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    userName: Yup.string().required("Username name required"),
    ContactNumber: Yup.string()
      .required("Mobile number is Required")
      .matches("[0-9]{10}", "Phone number is not valid"),
    pincode: Yup.string().required("Pincode Required"),
    address: Yup.string().required("Address is required"),
    Consent: Yup.boolean(),
    panCardNumber: Yup.string().required("PAN number Required"),
    registrationNumber: Yup.string().required("Registration number Required"),
    gstn: Yup.string().required("GSTN Required"),
  });

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  function FormValueChange(value, key) {
    setCurrentDefoultValue((ev) => ({
      ...ev,
      [key]: value,
    }));
  }

  const handleChangeState = (event) => {
    setState(event);
    getDistrict(event);
    console.log(event);
  };

  const handleChangeCity = (event) => {
    setCity(event);
  };

  const handleChangeTaluka = (event) => {
    setTaluka(event);
    getCities(event);
  };

  return (
    <Stack spacing={3}>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <Controller
          control={control}
          name="Client.Name"
          rules={{ required: "Name is required." }}
          render={({ field }) => (
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              placeholder="Name"
              fullWidth
              size="small"
              {...field}
              error={Boolean(formState.errors?.Name)}
              helperText={formState.errors.Name?.message}
            />
          )}
        />
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <Controller
          control={control}
          name="Client.PanCardNumber"
          rules={{ required: "PAN Number is required." }}
          render={({ field }) => (
            <TextField
              id="panCardNumber"
              label="PAN Number"
              variant="outlined"
              placeholder="PAN Number"
              size="small"
              fullWidth
              {...field}
              error={Boolean(errors?.panCardNumber)}
              helperText={errors.panCardNumber?.message}
            />
          )}
        />

        <Controller
          control={control}
          name="Client.RegistrationNumber"
          rules={{ required: "Registration Number is required." }}
          render={({ field }) => (
            <TextField
              id="registrationNumber"
              label="Registration Number"
              variant="outlined"
              placeholder="Registration Number"
              size="small"
              fullWidth
              {...field}
              error={Boolean(errors?.registrationNumber)}
              helperText={errors.registrationNumber?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="Client.GSTN"
          rules={{ required: "GST Number is required." }}
          render={({ field }) => (
            <TextField
              id="gstn"
              label="GST Number"
              variant="outlined"
              placeholder="GST Number"
              size="small"
              fullWidth
              {...field}
              error={Boolean(errors?.gstn)}
              helperText={errors.gstn?.message}
            />
          )}
        />
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <Controller
          control={control}
          name="Client.ContactNumber"
          rules={{
            required: "Mobile Number is required.",
            pattern: /[0-9]{10}/,
          }}
          render={({ field }) => (
            <TextField
              id="ContactNumber"
              label="Mobile Number"
              variant="outlined"
              placeholder="Mobile Number"
              size="small"
              fullWidth
              {...field}
              error={Boolean(errors?.ContactNumber)}
              helperText={errors.ContactNumber?.message}
              inputProps={{
                maxLength: 10,
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="Client.Email"
          rules={{
            required: "Email Address is required.",
            pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          }}
          render={({ field }) => (
            <TextField
              id="email"
              label="Email Address"
              variant="outlined"
              placeholder="Email Address"
              size="small"
              fullWidth
              margin="normal"
              {...field}
              error={Boolean(errors?.email)}
              helperText={errors.email?.message}
            />
          )}
        />
      </Stack>

      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <Controller
          control={control}
          name="Client.Address"
          rules={{ required: "Address is required." }}
          render={({ field }) => (
            <TextField
              id="address"
              label="address"
              variant="outlined"
              placeholder="address"
              size="small"
              fullWidth
              {...field}
              error={Boolean(errors?.address)}
              helperText={errors.address?.message}
              multiline={true}
              rows={4}
              style={{ width: 560 }}
            />
          )}
        />

        <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Controller
              control={control}
              name="Client.StateId"
              value={state}
              rules={{ required: "State is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="StateId"
                  label="State"
                  variant="outlined"
                  placeholder="State"
                  fullWidth
                  select
                  value={value}
                  size="small"
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    handleChangeState(value);
                  }}
                  error={Boolean(errors?.StateId)}
                  helperText={errors.StateId?.message}
                >
                  {stateList?.map((state) => {
                    return (
                      <MenuItem value={state.stateId}>
                        {state.stateTitle}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="Client.DistrictId"
              value={taluka}
              rules={{ required: "District Address is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="DistrictId"
                  label="District"
                  variant="outlined"
                  placeholder="District"
                  fullWidth
                  size="small"
                  select
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    handleChangeTaluka(value);
                  }}
                  error={Boolean(errors?.DistrictId)}
                  helperText={errors.DistrictId?.message}
                >
                  {districtList?.map((district) => {
                    return (
                      <MenuItem value={district.districtid}>
                        {district.districtTitle}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Controller
              control={control}
              name="Client.CityId"
              value={city}
              rules={{ required: "City Address is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="CityId"
                  label="City"
                  variant="outlined"
                  placeholder="City"
                  fullWidth
                  size="small"
                  select
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    handleChangeCity(value);
                  }}
                  error={Boolean(errors?.CityId)}
                  helperText={errors.CityId?.message}
                >
                  {cityList?.map((city) => {
                    return <MenuItem value={city.id}>{city.name}</MenuItem>;
                  })}
                </TextField>
              )}
            />
            <Controller
              control={control}
              name="Client.Pincode"
              rules={{ required: "Pincode is required." }}
              render={({ field }) => (
                <TextField
                  id="pincode"
                  label="Pincode"
                  variant="outlined"
                  placeholder="Pincode"
                  size="small"
                  fullWidth
                  margin="normal"
                  {...field}
                  error={Boolean(errors?.pincode)}
                  helperText={errors.pincode?.message}
                />
              )}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

function getSteps() {
  return ["Client information", "Admin Information"];
}

function getStepContent(step, defaultValues) {
  switch (step) {
    case 0:
      return <RegisterForm defaultValues={defaultValues} />;

    case 1:
      return <AdminInfo defaultValues={defaultValues} />;
    default:
      return "unknown step";
  }
}

const AdminInfo = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [state, setState] = useState("");

  const [city, setCity] = useState("");

  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [taluka, setTaluka] = React.useState("");
  useEffect(() => {
    getCities();
    getStates();
    getDistrict();
  }, []); //

  const [showPassword, setShowPassword] = useState(false);

  async function getStates() {
    let res = await utile.GetAllStates();
    setStateList(res.data);
    // fetch(`https://strata-portal.azurewebsites.net/api/util/GetAllStates`).then(response => response.json()).then(result => {
    //     setStateList(result.data);
    //     console.log(result.data);
    // });
  }

  async function getDistrict(stateID) {
    console.log(stateID);
    let res = await utile.GetAllDistricts(stateID);
    setDistrictList(res.data);
    // if (stateID != undefined) {

    //     // fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllDistricts?stateID=` + stateID).then(response => response.json()).then(result => {
    //     //     setDistrictList(result.data);
    //     // });
    // }
    // else {
    //     let res=await utile.GetAllDistricts(stateID)
    //    setDistrictList(res.data);
    //     // fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllDistricts?stateID=`).then(response => response.json()).then(result => {
    //     //     setDistrictList(result.data);
    //     // });
    // }
  }

  async function getCities(districId) {
    console.log(districId);

    let res = await utile.GetAllCities(districId);
    setCityList(res.data);
    // if (districId != undefined) {
    //     fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllCities?districId=` + districId).then(response => response.json()).then(result => {
    //         setCityList(result.data);
    //     });
    // }
    // else {
    //     fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllCities?districId=`).then(response => response.json()).then(result => {
    //         setCityList(result.data);
    //     });
    // }
  }

  const [checked, setChecked] = React.useState(false);
  const handleChange = (event) => {
    alert(event);
    setChecked(event.target.checked);
  };
  const handleChangeState = (event) => {
    setState(event);
    getDistrict(event);
    console.log(event);
  };

  const handleChangeCity = (event) => {
    setCity(event);
  };

  const handleChangeTaluka = (event) => {
    setTaluka(event);
    getCities(event);
  };
  return (
    <>
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="User.FullName"
            rules={{ required: "Name is required." }}
            render={({ field }) => (
              <TextField
                id="fullName"
                label="Full Name"
                variant="outlined"
                placeholder="Full Name"
                fullWidth
                {...field}
                error={Boolean(errors?.fullName)}
                helperText={errors.fullName?.message}
              />
            )}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="User.Mobile"
            rules={{
              required: "Mobile Number is required.",
              pattern: /[0-9]{10}/,
            }}
            render={({ field }) => (
              <TextField
                id="adminContactNumber"
                label="Mobile Number"
                variant="outlined"
                placeholder="Mobile Number"
                fullWidth
                {...field}
                error={Boolean(errors?.adminContactNumber)}
                helperText={errors.adminContactNumber?.message}
                inputProps={{
                  maxLength: 10,
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="User.Email"
            rules={{
              required: "Email Address is required.",
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            }}
            render={({ field }) => (
              <TextField
                id="Client.Email"
                label="Email Address"
                variant="outlined"
                placeholder="Email Address"
                fullWidth
                margin="normal"
                {...field}
                error={Boolean(errors?.adminemail)}
                helperText={errors.adminemail?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="User.Password"
            rules={{ required: "Password is required." }}
            render={({ field }) => (
              <TextField
                id="password"
                label="Password"
                variant="outlined"
                placeholder="Password"
                type="password"
                fullWidth
                {...field}
                error={Boolean(errors?.password)}
                helperText={errors.password?.message}
              />
            )}
          />
        </Stack>
        <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Controller
              control={control}
              name="User.StateId"
              value={state}
              rules={{ required: "State is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="adminStateId"
                  label="State"
                  variant="outlined"
                  placeholder="State"
                  fullWidth
                  select
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    handleChangeState(value);
                  }}
                  error={Boolean(errors?.adminStateId)}
                  helperText={errors.adminStateId?.message}
                >
                  {stateList?.map((state) => {
                    return (
                      <MenuItem value={state.stateId}>
                        {state.stateTitle}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="User.DistrictId"
              value={taluka}
              rules={{ required: "District Address is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="adminDistrictId"
                  label="District"
                  variant="outlined"
                  placeholder="District"
                  fullWidth
                  select
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    handleChangeTaluka(value);
                  }}
                  error={Boolean(errors?.adminDistrictId)}
                  helperText={errors.adminDistrictId?.message}
                >
                  {districtList?.map((district) => {
                    return (
                      <MenuItem value={district.districtid}>
                        {district.districtTitle}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Controller
              control={control}
              name="User.CityId"
              value={city}
              rules={{ required: "City Address is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="adminCityId"
                  label="City"
                  variant="outlined"
                  placeholder="City"
                  fullWidth
                  select
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    handleChangeCity(value);
                  }}
                  error={Boolean(errors?.adminCityId)}
                  helperText={errors.adminCityId?.message}
                >
                  {cityList?.map((city) => {
                    return <MenuItem value={city.id}>{city.name}</MenuItem>;
                  })}
                </TextField>
              )}
            />
            <Controller
              control={control}
              name="User.Pincode"
              rules={{ required: "Pincode is required." }}
              render={({ field }) => (
                <TextField
                  id="adminpincode"
                  label="Pincode"
                  variant="outlined"
                  placeholder="Pincode"
                  fullWidth
                  margin="normal"
                  {...field}
                  error={Boolean(errors?.adminpincode)}
                  helperText={errors.adminpincode?.message}
                />
              )}
            />
          </Stack>
          <Controller
            control={control}
            name="User.Address"
            rules={{ required: "Email Address is required." }}
            render={({ field }) => (
              <TextField
                id="adminaddress"
                label="address"
                variant="outlined"
                placeholder="address"
                fullWidth
                {...field}
                error={Boolean(errors?.adminaddress)}
                helperText={errors.adminaddress?.message}
                multiline={true}
                rows={4}
                style={{ width: 560 }}
              />
            )}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          {/* 
            <Controller
            control={control}
            name="User.Consent"
            defaultValue={checked}
            render={({ value: valueProp, onChange }) => {
              return (
                <Switch
                  value={valueProp}
                  onChange={(event, val) => {
                    if (val) {
                        alert(val)
                      setChecked(!val);
                    }
                    return onChange(val);
                  }}
                />
              );
            }}
          /> */}
          {/* <Switch
            name='Consent'
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                /> */}
          {/* <input type={"checkbox"} onchange={(e)=>{handleChange(e)}} checked={checked} name="User.Consent" /> */}
          {/* <Controller
                    control={control}
                    name="User.Consent"
                    rules={{ }}
                    render={({ field }) => (
                        <> */}
          {/* <Checkbox
                            name="User.Consent"
                            id="Consent"
                            onChange={handleChange}
                            // label="Please check this to accept all Terms and Conditions"
                            // variant="outlined"
                            size='small'
                            // {...field}
                            checked={checked}
                            value={checked}
                            helperText={errors.Consent?.message}
                        /> */}
          By submiting this you are agree to all Terms and Conditions, Please
          read the below Terms and Conditions before submit the form
          {/* </>
                    )}
                /> */}
          {/* <Checkbox name="consent" value={errors?.consent} onChange={(event) => FormValueChange(event.target.value,'consent')} size="small" label="Please check this to accept all Terms and Conditions" /> */}
        </Stack>
        <a href="/static/TAC/Strata _Terms & Conditions.pdf">
          Terms and Conditions
        </a>
      </Stack>
    </>
  );
};

const ClientForm = (props) => {
  const { setOpen, fetchClients } = props;
  const classes = useStyles();
  const [defaultValues, setDefaultValues] = useState({
    Client: null,
    User: null,
  });

  const navigate = useNavigate();

  console.log("defaultValues", defaultValues);
  const methods = useForm({
    defaultValues: defaultValues,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [disable, setDisable] = useState(false);
  const steps = getSteps();
  const isStepOptional = (step) => {
    return false;
  };
  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = async (data) => {
    console.log(JSON.stringify(data));

    if (activeStep == steps.length - 1) {
      data["User.UserTypeId"] = 2;
      var requestoptions = {
        method: "post",
        headers: { "content-type": "application/json" },
        body: JSON.stringify(data),
      };
      console.log(requestoptions.body);
      // alert(data.User.Consent)
      // if(data.User.Consent ==false || data.User.Consent ==null)
      // {
      //     alert("Please accept all Terms and Conditions");
      //     return;
      // }

      fetch(configUrl.StrataServer + `/Auth/RegisterClient`, requestoptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.message) {
            if (result.message == "User Exist") {
              // alert("User email already is in use. ");
              toast.error("User email already is in use.", {
                position: "top-center",
                toastId: "success1",
              });

              return;
            }
          }
          if (result.message == "Success") {
            //alert("client register successfully");
            toast.success("client registered successfully", {
              position: "top-center",
              toastId: "success1",
            });
            setDisable(true);
            navigate("/dashboard/client", { replace: true });

            setTimeout(() => {
              props.closeModal();
            }, 2000);
            props.setRefresh(true);
          } else {
            // alert(result.message);
            toast.error(result.message, {
              position: "top-center",
              toastId: "success1",
            });
          }
          // navigate('/dashboard/client', { replace: true });
          // props.fetchClients();
          // props.closemodal();
        });
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (props.clientId != 0) {
      getClient(props.clientId);
    }
    console.log(props);
  }, []); //

  // const onSubmit = (data) => {
  //   console.log(data);
  // };

  async function getClient(id) {
    // fetch(configUrl.StrataServer + `/api/client/GetClientById?clientId=` + id)
    //   .then((response) => response.json())
    //   .then((result) => {
    //     //setDefaultValues(result.data);
    //     console.log(result.data);
    //     setDefaultValues(result.data);
    //   });

    const res = await clientService.GetClientById(id);
    console.log(res);
  }

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                optional
              </Typography>
            );
          }
          if (isStepFalied() && activeStep == index) {
            labelProps.error = true;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Thank You
        </Typography>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleNext)}>
              {getStepContent(activeStep, defaultValues)}
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                justifyContent="flex-end"
              >
                <Button
                  className={classes.button}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  back
                </Button>
                {isStepOptional(activeStep) && (
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                  >
                    skip
                  </Button>
                )}
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  // onClick={handleNext}
                  type="submit"
                  disabled={disable}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Stack>
            </form>
            <ToastContainer />
          </FormProvider>
        </>
      )}
    </div>
  );
};

export default ClientForm;
