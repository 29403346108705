import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PatientForm from "../components/Forms/PatientForm";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { renderToString } from "react-dom/server";

import { Link as RouterLink } from "react-router-dom";
import Switch from "@mui/material/Switch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Mustache from "mustache";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../components/Page";
import Label from "../components/Label";
import Scrollbar from "../components/Scrollbar";
import Iconify from "../components/Iconify";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../sections/@dashboard/user";
import { useRecoilValue } from "recoil";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { authAtom } from "../App";
import healthCheckupService from "../services/healthCheckupService";
import { fShortenNumber } from "../utils/formatNumber";
import jsPDF from "jspdf";
import { renderTotalPrice } from "@mui/x-data-grid-generator";
import HealthCheckupReport from "./HealthCheckupReport";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TABLE_HEAD = [
  { id: "EmployeeName", label: "Employee Name", alignRight: false },
  // { id: 'HealthCheckup', label: 'Health Checkup Title', alignRight: false },
  { id: "Report Link" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_empList) =>
        _empList.amount
          ?.toString()
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AllEmpHealthCheckupList(props) {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  console.log(new URLSearchParams(location.search).get("hcri"));
  const [hc, setHc] = useState(
    new URLSearchParams(location.search).get("hcri")
  );
  // setHc(URLSearchParams(location.search).get("hcri"));
  if (hc == null) {
    setHc(0);
  }
  console.log(hc);
  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const [clientId, setClientId] = React.useState(auth.clientId);
  if (clientId == null) {
    setClientId(new URLSearchParams(location.search).get("cl"));
  }

  useEffect(() => {
    fetchEmpLists();
    console.log(auth);
  }, []); //

  const [empListList, setEmpListList] = React.useState([]);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("amount");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = empListList.map((n) => n.empListId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - empListList.length) : 0;

  const filteredUsers = applySortFilter(
    empListList,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  async function fetchEmpLists() {
    let res = await healthCheckupService
      .GetEmployeeHealthCheckupListByClient(clientId, hc)
      .then((res) => {
        if (res.message == "Success") {
          console.log(res);
          setEmpListList(res.data);
        } else {
          //   alert("Something went wrong" + " : " + res.message);
          toast.error("Something went wrong" + " : " + res.message, {
            position: "top-center",
            toastId: "success1",
          });
        }
      });
  }

  async function getPdf(hcId, empId) {
    // let res= await healthCheckupService.PrintHealthCheckupForm(hcId,empId).then(res => {
    //     if (res.message == "Success") {
    //         console.log(res);
    //         const htmlTemplate= require('../ReportTemplates/Finalreaport');
    //         console.log(htmlTemplate);
    //         let testData = res.data.testDetails
    //         let count= 0
    //         let newDirectory = Object.values(testData.reduce((acc, item) => {
    //             console.log(acc)
    //             if (!acc[item.categoryName]) acc[item.categoryName] = {
    //               category: item.categoryName,
    //               data: []
    //             };
    //             acc[item.categoryName].data.push(new Object({ testName: item.testName, testReading: item.testReading,
    //                                                           isVision: item.isVission,normalRange:item.normalRange, unit:item.unit }));
    //             return acc;
    //           }, {}));
    //           res.data.testDetails = newDirectory;
    //         var html = Mustache.render(htmlTemplate, res.data);
    //         // const doc = new jsPDF({
    //         //     format: 'a4',
    //         //     unit: 'px',
    //         // });
    //         // doc.html(html , {
    //         //     async callback(doc) {
    //         //         await doc.save('document');
    //         //     },
    //         // });
    //         console.log(html);
    //         // $(targetContainer).html(html);
    //     }
    //     else {
    //       alert("Something went wrong" + " : " + res.message);
    //     }
    //   });
  }

  return (
    <div>
      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={empListList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                checkAllDisabled={true}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const { title, healthCheckupId, employeeId, employeeName } =
                      row;
                    const isItemSelected = selected.indexOf(employeeId) !== -1;

                    return (
                      <TableRow
                        hover
                        key={employeeId}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            hidden={true}
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, employeeId)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          {/* <Stack direction="row" alignItems="center"> */}

                          <Typography variant="subtitle2" noWrap>
                            {employeeName}
                          </Typography>
                          {/* </Stack> */}
                        </TableCell>
                        {/* <TableCell align="left">{title}</TableCell> */}
                        {hc == 0 ? (
                          <TableCell component="th" scope="row" padding="none">
                            {title}
                          </TableCell>
                        ) : (
                          ""
                        )}
                        <TableCell align="left">
                          <Link
                            // target={"_blank"}
                            to={{
                              pathname: "/reports/healthCheckupReport",
                              // state:  {obj}
                              search:
                                "?ebp=" +
                                employeeId +
                                "&ehn=" +
                                healthCheckupId,
                            }}
                          >
                            Show Report
                          </Link>
                          {/* <Button variant="contained" onClick={() => { getPdf(healthCheckupId,employeeId) }} component={RouterLink} to="#" >
                                                        GetPdf 
                                                    </Button> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={empListList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <ToastContainer />
    </div>
  );
}
