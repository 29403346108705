import React, { Component, useRef } from "react";
import * as Yup from "yup";
//import dayjs from 'dayjs';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../components/Iconify";
import {
  FormProvider as MyFromProvider,
  RHFTextField,
} from "../../components/hook-form";
import InputLabel from "@mui/material/InputLabel";
import {
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Controller, useFormContext, FormProvider } from "react-hook-form";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { Typography, Button, Stepper, Step, StepLabel } from "@mui/material";
import { has } from "lodash";
import { sinceWhen } from "../../utils/dataUtils";
import { Hidden } from "@material-ui/core";
import utile from "../../services/utile";
import { configUrl } from "../../config.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import employeeService from "../../services/employeeService";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    "Personal Details",
    "Home Situation & Occupation",
    "Habits",
    "Medical History",
  ];
}

function getStepContent(step, patientDetails, employeeId) {
  switch (step) {
    case 0:
      return (
        <PatientDetails
          defaultValues={patientDetails}
          employeeId={employeeId}
        />
      );

    case 1:
      return (
        <SituationOccupation
          defaultValues={patientDetails}
          employeeId={employeeId}
        />
      );

    case 2:
      return <Habits defaultValues={patientDetails} employeeId={employeeId} />;

    case 3:
      return (
        <MedicalHistroy
          defaultValues={patientDetails}
          employeeId={employeeId}
        />
      );
    default:
      return "unknown step";
  }
}

const SituationOccupation = (props) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [maritalStatus, setMaritalStatus] = useState("Single");
  const [hasChildren, setHasChildren] = useState("true");
  const [healthyChildrens, setHeathyChildren] = useState("true");

  return (
    <>
      <br />
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
          <Controller
            control={control}
            name="maritalStatus"
            value={maritalStatus}
            defaultValue={maritalStatus}
            rules={{ required: "Marital status is required." }}
            setValue={setValue(
              "maritalStatus",
              props.defaultValues?.maritalStatus
            )}
            render={({ field: { onChange, value } }) => (
              setMaritalStatus(value),
              (
                <TextField
                  id="maritalStatus"
                  label="Marital Status"
                  variant="outlined"
                  placeholder="Marital Status"
                  fullWidth
                  value={value}
                  selected={value}
                  defaultValue={value}
                  select
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.maritalStatus = value;
                    onChange(value);
                    setMaritalStatus(value);
                  }}
                  error={Boolean(errors?.maritalStatus)}
                  helperText={errors.maritalStatus?.message}
                  style={{ width: 200 }}
                >
                  <MenuItem value="Single">Single</MenuItem>
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Divorsed">Divorced</MenuItem>
                  <MenuItem value="Widowed">Widowed</MenuItem>
                </TextField>
              )
            )}
          />
          {maritalStatus != "Single" ? (
            <Controller
              control={control}
              name="maritalStatusDuration"
              rules={{ required: "required." }}
              setValue={setValue(
                "maritalStatusDuration",
                props.defaultValues?.maritalStatusDuration
              )}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="maritalStatusDuration"
                  label={maritalStatus + " (How long)"}
                  variant="outlined"
                  placeholder={maritalStatus + " (How long)"}
                  size="small"
                  fullWidth
                  value={value}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.maritalStatusDuration = value;
                    onChange(value);
                  }}
                  error={Boolean(errors?.maritalStatusDuration)}
                  helperText={errors.maritalStatusDuration?.message}
                  style={{ width: 200 }}
                />
              )}
            />
          ) : (
            ""
          )}
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          {maritalStatus != "Single" ? (
            <React.Fragment>
              <Controller
                control={control}
                name="hasChildrens"
                value={hasChildren}
                defaultValue={hasChildren}
                rules={{ required: "Field is required." }}
                setValue={setValue(
                  "hasChildrens",
                  props.defaultValues?.hasChildrens.toLowerCase()
                )}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="hasChildrens"
                    label="Children?"
                    variant="outlined"
                    placeholder="Children?"
                    fullWidth
                    value={value}
                    selected={hasChildren}
                    defaultValue={hasChildren}
                    select
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      props.defaultValues.hasChildrens = value;
                      onChange(value);
                      setHasChildren(value);
                    }}
                    error={Boolean(errors?.hasChildrens)}
                    helperText={errors.hasChildrens?.message}
                    style={{ width: 200 }}
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </TextField>
                )}
              />
              {hasChildren == "true" ? (
                <React.Fragment>
                  <Controller
                    control={control}
                    name="childrenHealthy"
                    value={healthyChildrens}
                    defaultValue={healthyChildrens}
                    rules={{ required: "Field is required." }}
                    setValue={setValue(
                      "childrenHealthy",
                      props.defaultValues?.childrenHealthy.toLowerCase()
                    )}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="childrenHealthy"
                        label="Are they healty?"
                        variant="outlined"
                        placeholder="Are they healty?"
                        fullWidth
                        value={value}
                        defaultValue={healthyChildrens}
                        selected={healthyChildrens}
                        select
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;
                          props.defaultValues.childrenHealthy = value;
                          onChange(value);
                          setHeathyChildren(value);
                        }}
                        error={Boolean(errors?.childrenHealthy)}
                        helperText={errors.childrenHealthy?.message}
                        style={{ width: 200 }}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </TextField>
                    )}
                  />

                  <RadioGroup row>
                    <FormControlLabel
                      control={
                        <Controller
                          control={control}
                          name="isBoy"
                          style={{ width: 200 }}
                          setValue={setValue(
                            "isBoy",
                            props.defaultValues?.isBoy
                          )}
                          render={({ field: { onChange, value } }) => (
                            <Checkbox
                              checked={value}
                              onChange={(e) => {
                                props.defaultValues.isBoy = e.target.checked;
                                onChange(e.target.checked);
                              }}
                            />
                          )}
                        />
                      }
                      label="M"
                    />

                    <FormControlLabel
                      control={
                        <Controller
                          control={control}
                          name="isGirl"
                          style={{ width: 200 }}
                          setValue={setValue(
                            "isGirl",
                            props.defaultValues?.isGirl
                          )}
                          render={({ field: { onChange, value } }) => (
                            <Checkbox
                              checked={value}
                              onChange={(e) => {
                                props.defaultValues.isGirl = e.target.checked;
                                onChange(e.target.checked);
                              }}
                            />
                          )}
                        />
                      }
                      label="F"
                    />
                  </RadioGroup>
                </React.Fragment>
              ) : (
                ""
              )}
            </React.Fragment>
          ) : (
            ""
          )}
          <Controller
            control={control}
            name="family"
            value=""
            rules={{ required: "Field is required." }}
            setValue={setValue("family", props.defaultValues?.family)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="family"
                label="Family"
                variant="outlined"
                placeholder="Family"
                fullWidth
                value={value}
                select
                size="small"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.family = value;
                  onChange(value);
                  setHeathyChildren(value);
                }}
                error={Boolean(errors?.family)}
                helperText={errors.family?.message}
                style={{ width: 200 }}
              >
                <MenuItem value="Nuclear">Nuclear</MenuItem>
                <MenuItem value="Joint">Joint</MenuItem>
              </TextField>
            )}
          />
        </Stack>

        <hr />

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <h4>OCCUPATION/TYPE OF (DETAILS) SERVICE/PROFESSION</h4>
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="occupation"
            value=""
            rules={{ required: "Field is required." }}
            setValue={setValue("occupation", props.defaultValues?.occupation)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="occupation"
                label="Occupation"
                variant="outlined"
                placeholder="Occupation"
                fullWidth
                value={value}
                selected={value}
                select
                size="small"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.occupation = value;
                  onChange(value);
                }}
                error={Boolean(errors?.occupation)}
                helperText={errors.occupation?.message}
                style={{ width: 200 }}
              >
                <MenuItem value="Government Servant">
                  Government Servant
                </MenuItem>
                <MenuItem value="Service">Service</MenuItem>
                <MenuItem value="Business">Business</MenuItem>
              </TextField>
            )}
          />
        </Stack>
      </Stack>
    </>
  );
};

const PatientDetails = (props) => {
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [taluka, setTaluka] = React.useState("");
  const [gender, setGender] = useState("");

  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [age, setAge] = useState("");
  const fileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [typeError, setTypeError] = useState("");

  const {
    register,
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  useEffect(() => {
    getCities();
    getStates();
    getDistrict();
    console.log(props);
    console.log(props.defaultValues);
    console.log(props.employeeId);
    //formateDate()
  }, []);

  async function getStates() {
    let res = await utile.GetAllStates();
    setStateList(res.data);
    // fetch(`https://strata-portal.azurewebsites.net/api/util/GetAllStates`).then(response => response.json()).then(result => {
    //     setStateList(result.data);
    //     console.log(result.data);
    // });
  }

  async function getDistrict(stateID) {
    console.log(stateID);
    let res = await utile.GetAllDistricts(stateID);
    setDistrictList(res.data);
    // if (stateID != undefined) {

    //     // fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllDistricts?stateID=` + stateID).then(response => response.json()).then(result => {
    //     //     setDistrictList(result.data);
    //     // });
    // }
    // else {
    //     let res=await utile.GetAllDistricts(stateID)
    //    setDistrictList(res.data);
    //     // fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllDistricts?stateID=`).then(response => response.json()).then(result => {
    //     //     setDistrictList(result.data);
    //     // });
    // }
  }

  async function getCities(districId) {
    console.log(districId);

    let res = await utile.GetAllCities(districId);
    setCityList(res.data);
    // if (districId != undefined) {
    //     fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllCities?districId=` + districId).then(response => response.json()).then(result => {
    //         setCityList(result.data);
    //     });
    // }
    // else {
    //     fetch(`https://strata-portal.azurewebsites.net//api/util/GetAllCities?districId=`).then(response => response.json()).then(result => {
    //         setCityList(result.data);
    //     });
    // }
  }

  const defaultValues = {
    fullName: "",
    lastName: "",
    email: "",
    ContactNumber: "",
    identificationMark: "",
    pincode: "",
    address: "",
  };

  const handleChangeState = (event) => {
    setState(event);
    getDistrict(event);
    console.log(event);
  };

  const handleChangeCity = (event) => {
    setCity(event);
  };

  const handleChangeTaluka = (event) => {
    setTaluka(event);
    getCities(event);
  };

  const handleChangeGender = (event) => {
    setGender(event);
  };
  let ads = props.defaultValues?.dob;

  //const formateDate = (datetime) => {
  //    function appendLeadingZeroes(n) {
  //        if (n <= 9) {
  //            return "0" + n;
  //        }
  //        return n
  //    }
  //    let formatted_date = appendLeadingZeroes(new Date(datetime).getDate()) + "-" + appendLeadingZeroes(new Date(datetime).getMonth() + 1) + "-" + new Date(datetime).getFullYear() + " " + appendLeadingZeroes(new Date(datetime).getHours()) + ":" + appendLeadingZeroes(new Date(datetime).getMinutes()) + ":" + appendLeadingZeroes(new Date(datetime).getSeconds())

  //    console.log("formateDate...", formatted_date);
  //    return formatted_date;
  //}
  //console.log(formateDate(props.defaultValues?.dob))
  console.log(ads);
  function formatDate(d) {
    if (d != null) {
      const date = new Date(d);
      const futureDate = date.getDate();
      date.setDate(futureDate);
      const defaultValue = `${date.getFullYear()}-${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth()).padStart(2, '0')}`
      return defaultValue;
    }
    return d;
  }

  const handleDOBChange = (event) => {
    var today = new Date();
    var birthDate = new Date(event);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    setAge(age.toString());
  };
  const types = ["image/png", "image/jpeg"];
  const maxSize = 250000;

  const handleFileInputChange = (event) => {
    const file = event[0];
    console.log(file);
    setSelectedFile(file);

    if (file) {
      if (types.includes(file.type)) {
        if (file.size <= maxSize) {
          setSelectedFile(file);
          setTypeError("");
        } else {
          setSelectedFile(null);
          setTypeError("Image size should be less than 250 kb");
        }
      } else {
        setSelectedFile(null);
        setTypeError("Please select a valid image type (png or jpeg)");
      }
    } else {
      setSelectedFile(null);
      setTypeError("");
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Stack direction={{ xs: "row", sm: "row" }}>
          <Controller
            control={control}
            name="file"
            render={({ field: { onChange, value } }) => (
              <TextField
                id="UserProfile"
                label="UserProfile"
                placeholder="UserProfile"
                variant="outlined"
                inputRef={fileRef}
                size="small"
                type="file"
                hidden
                autoFocus
                onChange={(e) => {
                  const {
                    target: { files },
                  } = e;
                  onChange(files[0]);
                  handleFileInputChange(files);
                }}
                style={{ width: 390 }}
              />
            )}
          />

          <Button
            onClick={() => {
              fileRef.current.click();
            }}
            variant="outlined"
            style={{ width: 200 }}
            endIcon={<Iconify icon="eva:plus-fill" />}
          >
            Uplod profile
          </Button>
          <Stack style={{ marginLeft: 10 }}>
            {typeError && (
              <Typography variant="caption" color="error">
                {typeError}
              </Typography>
            )}

            {selectedFile && (
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Preview"
                style={{ Width: 40, height: 40 }}
              />
            )}
          </Stack>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="Id"
            setValue={setValue("Id", props.employeeId)}
            render={({ field: { onChange, value } }) => (
              <Hidden value={value}></Hidden>
            )}
          />
          <Controller
            control={control}
            name="Name"
            rules={{ required: "Full Name is required." }}
            setValue={setValue("Name", props.defaultValues?.fullName)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="Name"
                label="Name"
                variant="outlined"
                placeholder="Full Name"
                size="small"
                fullWidth
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.name = value;
                  onChange(value);
                }}
                error={Boolean(errors?.Name)}
                helperText={errors.Name?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="ContractorName"
            rules={{ required: "ContractorName is required." }}
            setValue={setValue(
              "ContractorName",
              props.defaultValues?.contractorName
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="ContractorName"
                label="Contractor Name"
                variant="outlined"
                placeholder="Contractor Name"
                size="small"
                fullWidth
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.contractorName = value;
                  onChange(value);
                }}
                error={Boolean(errors?.ContractorName)}
                helperText={errors.ContractorName?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="ContactNumber"
            rules={{
              required: "Mobile Number is required.",
              pattern: /[0-9]{10}/,
            }}
            setValue={setValue(
              "ContactNumber",
              props.defaultValues?.contactNumber
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="ContactNumber"
                label="Mobile Number"
                variant="outlined"
                placeholder="Mobile Number"
                size="small"
                fullWidth
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.name = value;
                  onChange(value);
                }}
                error={Boolean(errors?.ContactNumber)}
                helperText={errors.ContactNumber?.message}
                inputProps={{
                  maxLength: 10,
                }}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="identificationMark"
            rules={{ required: "identificationMark is required." }}
            setValue={setValue(
              "identificationMark",
              props.defaultValues?.identificationMark
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="identificationMark"
                label="IdentificationMark"
                variant="outlined"
                placeholder="IdentificationMark"
                size="small"
                fullWidth
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.identificationMark = value;
                  onChange(value);
                }}
                error={Boolean(errors?.identificationMark)}
                helperText={errors.identificationMark?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="Department"
            rules={{ required: "Department is required." }}
            setValue={setValue("Department", props.defaultValues?.department)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="Department"
                label="Department"
                variant="outlined"
                placeholder="Department"
                size="small"
                fullWidth
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.department = value;
                  onChange(value);
                }}
                error={Boolean(errors?.department)}
                helperText={errors.department?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            rules={{
              required: "Email Address is required.",
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            }}
            setValue={setValue("email", props.defaultValues?.emailAddress)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="email"
                label="Email Address"
                variant="outlined"
                placeholder="Email Address"
                size="small"
                fullWidth
                margin="normal"
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.emailAddress = value;
                  onChange(value);
                }}
                error={Boolean(errors?.email)}
                helperText={errors.email?.message}
              />
            )}
          />
          {props.employeeId == 0 ? (
            <Controller
              control={control}
              name="password"
              rules={{ required: "Password is required." }}
              render={({ field }) => (
                <TextField
                  id="password"
                  label="Password"
                  variant="outlined"
                  placeholder="Password"
                  type="password"
                  size="small"
                  fullWidth
                  {...field}
                  error={Boolean(errors?.password)}
                  helperText={errors.password?.message}
                />
              )}
            />
          ) : (
            ""
          )}
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="gender"
            rules={{ required: "Gender is required." }}
            setValue={setValue("gender", props.defaultValues?.gender)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="gender"
                label="Gender"
                variant="outlined"
                placeholder="Gender"
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.gender = value;
                  onChange(value);
                  handleChangeGender(value);
                }}
                error={Boolean(errors?.gender)}
                helperText={errors.gender?.message}
                style={{ width: 390 }}
              >
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
                <MenuItem value="O">Others</MenuItem>
              </TextField>
            )}
          />

          <Controller
            control={control}
            name="date"
            rules={{ required: "Date is required." }}
            setValue={setValue("date", formatDate(props.defaultValues?.dob))}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="date"
                label="Date of birth"
                placeholder="Date of birth"
                type="date"
                variant="outlined"
                size="small"
                fullWidth
                autoFocus
                InputLabelProps={{ shrink: true }}
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.dob = value;
                  onChange(value);
                  handleDOBChange(value);
                }}
                error={Boolean(errors?.date)}
                helperText={errors.date?.message}
                style={{ width: 390 }}
              />
            )}
          />

          <Controller
            control={control}
            name="age"
            rules={{ required: "Age is required." }}
            setValue={setValue("age", age || props.defaultValues?.age)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="age"
                label="Age"
                variant="outlined"
                placeholder="Age"
                size="small"
                disabled={true}
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.age = value;
                  onChange(value);
                }}
                error={Boolean(errors?.age)}
                helperText={errors.age?.message}
                style={{ width: 390 }}
              />
            )}
          />

          <Controller
            control={control}
            name="BloodGroup"
            rules={{ required: "Blood Group is required." }}
            setValue={setValue("BloodGroup", props.defaultValues?.bloodGroup)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="bloodGroup"
                label="Blood Group"
                variant="outlined"
                placeholder="Blood Group"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.bloodGroup = value;
                  onChange(value);
                }}
                error={Boolean(errors?.BloodGroup)}
                helperText={errors.BloodGroup?.message}
                style={{ width: 390 }}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="address"
            rules={{ required: "Address is required." }}
            setValue={setValue("address", props.defaultValues?.address)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="address"
                label="address"
                variant="outlined"
                placeholder="address"
                size="small"
                fullWidth
                value={value}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.address = value;
                  onChange(value);
                }}
                error={Boolean(errors?.address)}
                helperText={errors.address?.message}
                multiline={true}
                rows={3.5}
                style={{ width: 560 }}
              />
            )}
          />

          <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
            <Controller
              control={control}
              name="StateId"
              value={state}
              rules={{ required: "State is required." }}
              setValue={setValue("StateId", props.defaultValues?.stateId)}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="StateId"
                  label="State"
                  variant="outlined"
                  placeholder="State"
                  size="small"
                  fullWidth
                  select
                  value={value}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.stateId = value;
                    onChange(value);
                    handleChangeState(value);
                  }}
                  error={Boolean(errors?.StateId)}
                  helperText={errors.StateId?.message}
                  style={{ width: 270 }}
                >
                  {stateList?.map((state) => {
                    return (
                      <MenuItem value={state.stateId}>
                        {state.stateTitle}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="CityId"
              rules={{ required: "City Address is required." }}
              setValue={setValue("CityId", props.defaultValues?.cityId)}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="CityId"
                  label="City"
                  variant="outlined"
                  placeholder="City"
                  size="small"
                  fullWidth
                  select
                  value={value}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.cityId = value;
                    onChange(value);
                    handleChangeCity(value);
                  }}
                  error={Boolean(errors?.CityId)}
                  helperText={errors.CityId?.message}
                  style={{ width: 270 }}
                >
                  {cityList?.map((city) => {
                    return <MenuItem value={city.id}>{city.name}</MenuItem>;
                  })}
                </TextField>
              )}
            />
          </Stack>

          <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
            <Controller
              control={control}
              name="DistrictId"
              value={taluka}
              rules={{ required: "District Address is required." }}
              setValue={setValue("DistrictId", props.defaultValues?.districtId)}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="DistrictId"
                  label="District"
                  variant="outlined"
                  placeholder="District"
                  size="small"
                  fullWidth
                  select
                  value={value}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.districtId = value;
                    onChange(value);
                    handleChangeTaluka(value);
                  }}
                  error={Boolean(errors?.DistrictId)}
                  helperText={errors.DistrictId?.message}
                  style={{ width: 270 }}
                >
                  {districtList?.map((district) => {
                    return (
                      <MenuItem value={district.districtid}>
                        {district.districtTitle}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="pincode"
              rules={{ required: "Pincode is required." }}
              setValue={setValue("pincode", props.defaultValues?.pincode)}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="pincode"
                  label="Pincode"
                  variant="outlined"
                  placeholder="Pincode"
                  size="small"
                  fullWidth
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.pincode = value;
                    onChange(value);
                  }}
                  error={Boolean(errors?.pincode)}
                  helperText={errors.pincode?.message}
                  style={{ width: 270 }}
                />
              )}
            />
          </Stack>
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="nativePlace"
            rules={{ required: "Native Place is required." }}
            setValue={setValue("nativePlace", props.defaultValues?.nativePlace)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="nativePlace"
                label="Native Place"
                variant="outlined"
                placeholder="Native Place"
                size="small"
                fullWidth
                style={{ width: 270 }}
                value={value}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.nativePlace = value;
                  onChange(value);
                }}
                error={Boolean(errors?.nativePlace)}
                helperText={errors.nativePlace?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="physician"
            rules={{ required: "Required." }}
            setValue={setValue("physician", props.defaultValues?.physician)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="physician"
                label="Family Physician"
                variant="outlined"
                size="small"
                placeholder="Who is your Family Physician?"
                fullWidth
                margin="normal"
                value={value}
                style={{ width: 270 }}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.physician = value;
                  onChange(value);
                }}
                error={Boolean(errors?.physician)}
                helperText={errors.physician?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="DateOfLeaving"
            setValue={setValue(
              "DateOfLeaving",
              formatDate(props.defaultValues?.dateOfLeaving)
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="date"
                label="Date of Leaving"
                placeholder="Date of Leaving"
                type="date"
                variant="outlined"
                size="small"
                fullWidth
                autoFocus
                InputLabelProps={{ shrink: true }}
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.dateOfLeaving = value;
                  onChange(value);
                }}
                error={Boolean(errors?.date)}
                helperText={errors.date?.message}
                style={{ width: 390 }}
              />
            )}
          />

          <Controller
            control={control}
            name="ReasonForLeaving"
            setValue={setValue(
              "ReasonForLeaving",
              props.defaultValues?.reasonForLeaving
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="nativePlace"
                label="Reason For Leaving"
                variant="outlined"
                placeholder="Reason For Leaving"
                size="small"
                fullWidth
                style={{ width: 270 }}
                value={value}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.reasonForLeaving = value;
                  onChange(value);
                }}
                error={Boolean(errors?.nativePlace)}
                helperText={errors.nativePlace?.message}
              />
            )}
          />
        </Stack>
      </Stack>
    </>
  );
};

const MedicalHistroy = (props) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [isAlcoholIntake, setIsAlcoholIntake] = useState("false");
  const [drugUse, setDrugUse] = useState("false");

  return (
    <>
      <br />
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="isAlcoholIntake"
            defaultValue={isAlcoholIntake}
            rules={{ required: "Field is required." }}
            setValue={setValue(
              "isAlcoholIntake",
              props.defaultValues?.isAlcoholIntake.toLowerCase()
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="alcoholIntake"
                variant="outlined"
                label="Do family your friends worry about your alcohol intake?"
                placeholder="Do family your friends worry about your alcohol intake?"
                size="small"
                select
                fullWidth
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.isAlcoholIntake = value;
                  onChange(value);
                  setIsAlcoholIntake(value);
                }}
                error={Boolean(errors?.alcoholIntake)}
                helperText={errors.alcoholIntake?.message}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem selected value="false">
                  No
                </MenuItem>
              </TextField>
            )}
          />

          <Controller
            control={control}
            name="drugUse"
            defaultValue={drugUse}
            rules={{ required: "Field is required." }}
            setValue={setValue(
              "drugUse",
              props.defaultValues?.drugUse.toLowerCase()
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="drugUse"
                variant="outlined"
                label="Have you ever had problems with drug use?"
                placeholder="Have you ever had problems with drug use?"
                select
                size="small"
                fullWidth
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.drugUse = value;
                  onChange(value);
                  setDrugUse(value);
                }}
                error={Boolean(errors?.drugUse)}
                helperText={errors.drugUse?.message}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem selected value="false">
                  No
                </MenuItem>
              </TextField>
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="otherAddiction"
            setValue={setValue(
              "otherAddiction",
              props.defaultValues?.otherAddiction
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="otherAddiction"
                variant="outlined"
                placeholder="Any Other Addictions? Please Mention."
                label="Any Other Addictions? Please Mention."
                size="small"
                fullWidth
                defaultValue="NA"
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.otherAddiction = value;
                  onChange(value);
                }}
                error={Boolean(errors?.otherAddiction)}
                helperText={errors.otherAddiction?.message}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="noctural"
            setValue={setValue("noctural", props.defaultValues?.noctural)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="noctural"
                variant="outlined"
                placeholder="Noctural/Diurnal Bladder Habits - (Frequency/Urgency/Painful Present Complaints/History)"
                label="Noctural/Diurnal Bladder Habits - (Frequency/Urgency/Painful Present Complaints/History)"
                size="small"
                fullWidth
                defaultValue="NA"
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.noctural = value;
                  onChange(value);
                }}
                error={Boolean(errors?.noctural)}
                helperText={errors.noctural?.message}
                multiline={true}
                rows={3.5}
                style={{ width: 560 }}
              />
            )}
          />

          <Stack direction={{ xs: "row", sm: "column" }} spacing={2}>
            <Controller
              control={control}
              name="constipation"
              rules={{ required: "Field is required." }}
              setValue={setValue(
                "constipation",
                props.defaultValues?.constipation.toLowerCase()
              )}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="constipation"
                  variant="outlined"
                  label="Bowel Habits-Constipation Yes/No"
                  placeholder="Bowel Habits-Constipation Yes/No"
                  size="small"
                  select
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.constipation = value;
                    onChange(value);
                  }}
                  error={Boolean(errors?.constipation)}
                  helperText={errors.constipation?.message}
                  style={{ width: 580 }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem selected value="false">
                    No
                  </MenuItem>
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="dyspepsia"
              rules={{ required: "Field is required." }}
              setValue={setValue(
                "dyspepsia",
                props.defaultValues?.dyspepsia.toLowerCase()
              )}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="dyspepsia"
                  variant="outlined"
                  label="Dyspepsia"
                  placeholder="Dyspepsia"
                  size="small"
                  select
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.dyspepsia = value;
                    onChange(value);
                  }}
                  error={Boolean(errors?.dyspepsia)}
                  helperText={errors.dyspepsia?.message}
                  style={{ width: 580 }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem selected value="false">
                    No
                  </MenuItem>
                </TextField>
              )}
            />
          </Stack>
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="presentComplaints"
            setValue={setValue(
              "presentComplaints",
              props.defaultValues?.presentComplaints
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="presentComplaints"
                variant="outlined"
                placeholder="Present Complaints/History"
                label="Present Complaints/History"
                size="small"
                fullWidth
                defaultValue="NA"
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.presentComplaints = value;
                  onChange(value);
                }}
                error={Boolean(errors?.presentComplaints)}
                helperText={errors.presentComplaints?.message}
                multiline={true}
                rows={2}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="drugAllergy"
            setValue={setValue("drugAllergy", props.defaultValues?.drugAllergy)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="drugAllergy"
                variant="outlined"
                placeholder="Please list other medical conditions from which you have suffered in the past"
                label="Please list other medical conditions from which you have suffered in the past"
                size="small"
                defaultValue="NA"
                fullWidth
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.drugAllergy = value;
                  onChange(value);
                }}
                error={Boolean(errors?.drugAllergy)}
                helperText={errors.drugAllergy?.message}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="pastAccident"
            setValue={setValue(
              "pastAccident",
              props.defaultValues?.pastAccident
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="pastAccident"
                variant="outlined"
                placeholder="Any Past Accident? Please Mention."
                label="Any Past Accident? Please Mention."
                size="small"
                fullWidth
                defaultValue="NA"
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.pastAccident = value;
                  onChange(value);
                }}
                error={Boolean(errors?.pastAccident)}
                helperText={errors.pastAccident?.message}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="surgery"
            setValue={setValue("surgery", props.defaultValues?.surgery)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="surgery"
                variant="outlined"
                placeholder="Please list any surgeries (operations), reason for the surgery, and date of surgery"
                label="Please list any surgeries (operations), reason for the surgery, and date of surgery"
                size="small"
                fullWidth
                defaultValue="NA"
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.surgery = value;
                  onChange(value);
                }}
                error={Boolean(errors?.surgery)}
                helperText={errors.surgery?.message}
                multiline={true}
                rows={2.5}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="diseaseList"
            setValue={setValue("diseaseList", props.defaultValues?.diseaseList)}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="diseaseList"
                variant="outlined"
                placeholder="Please list other diseases from which you past suffer (Heart, Lung, etc.)&#10;H/O-Child Hood Sufferings"
                label="Please list other diseases from which you past suffer (Heart, Lung, etc.)&#10;H/O-Child Hood Sufferings"
                size="small"
                fullWidth
                defaultValue="NA"
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.diseaseList = value;
                  onChange(value);
                }}
                error={Boolean(errors?.diseaseList)}
                helperText={errors.diseaseList?.message}
                multiline={true}
                rows={2}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="familyHistory"
            setValue={setValue(
              "familyHistory",
              props.defaultValues?.familyHistory
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="familyHistory"
                variant="outlined"
                placeholder="Family History:- For diseases like diabetes, H.T., Malignancies, Bleeding Disorders (Tendancies) etc."
                label="Family History:- For diseases like diabetes, H.T., Malignancies, Bleeding Disorders (Tendancies) etc."
                size="small"
                fullWidth
                defaultValue="NA"
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.familyHistory = value;
                  onChange(value);
                }}
                error={Boolean(errors?.familyHistory)}
                helperText={errors.familyHistory?.message}
                multiline={true}
                rows={2}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="activeMedicalHistory"
            setValue={setValue(
              "activeMedicalHistory",
              props.defaultValues?.activeMedicalHistory
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="activeMedicalHistory"
                variant="outlined"
                placeholder="Active Medical History:- Presently on any medication and since when"
                label="Active Medical History:- Presently on any medication and since when"
                size="small"
                defaultValue="NA"
                fullWidth
                value={value == null ? "NA" : value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.activeMedicalHistory = value;
                  onChange(value);
                }}
                error={Boolean(errors?.activeMedicalHistory)}
                helperText={errors.activeMedicalHistory?.message}
                multiline={true}
                rows={2}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="menstrualHistory"
            setValue={setValue(
              "menstrualHistory",
              props.defaultValues?.menstrualHistory
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="menstrualHistory"
                variant="outlined"
                placeholder="Menstrual History (In Female)"
                label="Menstrual History (In Female)"
                defaultValue="NA"
                size="small"
                fullWidth
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.menstrualHistory = value;
                  onChange(value);
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="obstetricHistory"
            setValue={setValue(
              "obstetricHistory",
              props.defaultValues?.obstetricHistory
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="obstetricHistory"
                variant="outlined"
                placeholder="Obstetric History (In Female)"
                label="Obstetric History (In Female)"
                defaultValue="NA"
                size="small"
                fullWidth
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.obstetricHistory = value;
                  onChange(value);
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="Vaccinations"
            setValue={setValue(
              "Vaccinations",
              props.defaultValues?.vaccinations
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="vaccinations"
                variant="outlined"
                placeholder="Vaccinations"
                label="Vaccinations"
                size="small"
                fullWidth
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.vaccinations = value;
                  onChange(value);
                }}
              />
            )}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          By submiting this you are agree to all Terms and Conditions, Please
          read the below Terms and Conditions before submit the form
          <a href="/static/TAC/Strata _Terms & Conditions.pdf">
            Terms and Conditions
          </a>
        </Stack>
      </Stack>
    </>
  );
};

const Habits = (props) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [isSmoker, setIsSmoker] = useState("false");
  const [isTobaccoChewer, setIsTobaccoChewer] = useState("false");
  const [isAlcoholic, setIsAlcoholic] = useState("false");
  const [dateValue, setDateValue] = useState("");

  return (
    <>
      <br />
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
          <Controller
            control={control}
            name="isSmoker"
            value={isSmoker}
            defaultValue={isSmoker}
            rules={{ required: "Field is required." }}
            setValue={setValue(
              "isSmoker",
              props.defaultValues?.isSmoker.toLowerCase()
            )}
            render={({ field: { onChange, value } }) => (
              setIsSmoker(props.defaultValues?.isSmoker.toLowerCase()),
              (
                <TextField
                  id="isSmoker"
                  label="Do you Smoke?"
                  variant="outlined"
                  placeholder="Do you Smoke?"
                  fullWidth
                  value={value}
                  selected={isSmoker}
                  defaultValue={isSmoker}
                  select
                  size="small"
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.isSmoker = value;
                    onChange(value);
                    setIsSmoker(value);
                    props.defaultValues.smokeSinceWhen = null;
                  }}
                  error={Boolean(errors?.isSmoker)}
                  helperText={errors.isSmoker?.message}
                  style={{ width: 200 }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
              )
            )}
          />
          {isSmoker != "false" ? (
            <React.Fragment>
              <Controller
                control={control}
                name="smokeSinceWhen"
                rules={{ required: "required." }}
                setValue={setValue(
                  "smokeSinceWhen",
                  props.defaultValues?.smokeSinceWhen
                )}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="smokeSinceWhen"
                    label="Since When"
                    variant="outlined"
                    placeholder="Since When"
                    size="small"
                    fullWidth
                    select
                    value={value}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      props.defaultValues.smokeSinceWhen = value;
                      onChange(value);
                    }}
                    error={Boolean(errors?.smokeSinceWhen)}
                    helperText={errors.smokeSinceWhen?.message}
                    style={{ width: 200 }}
                  >
                    {sinceWhen?.map((item) => {
                      return (
                        <MenuItem value={item.value}>{item.text}</MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />
            </React.Fragment>
          ) : (
            ""
          )}

          <Controller
            control={control}
            name="isTobaccoChewer"
            value={isTobaccoChewer}
            defaultValue={isTobaccoChewer}
            rules={{ required: "Field is required." }}
            setValue={setValue(
              "isTobaccoChewer",
              props.defaultValues?.isTobaccoChewer.toLowerCase()
            )}
            render={({ field: { onChange, value } }) => (
              setIsTobaccoChewer(value),
              (
                <TextField
                  id="isTobaccoChewer"
                  label="Are you Tobacco/Chewer?"
                  variant="outlined"
                  placeholder="Are you Tobacco/Chewer?"
                  fullWidth
                  value={value}
                  selected={isTobaccoChewer}
                  defaultValue={isTobaccoChewer}
                  select
                  size="small"
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.isTobaccoChewer = value;
                    onChange(value);
                    setIsTobaccoChewer(value);
                    props.defaultValues.tobaccoSinceWhen = null;
                  }}
                  error={Boolean(errors?.isTobaccoChewer)}
                  helperText={errors.isTobaccoChewer?.message}
                  style={{ width: 200 }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
              )
            )}
          />

          {isTobaccoChewer != "false" ? (
            <React.Fragment>
              <Controller
                control={control}
                name="tobaccoSinceWhen"
                rules={{ required: "required." }}
                setValue={setValue(
                  "tobaccoSinceWhen",
                  props.defaultValues?.tobaccoSinceWhen
                )}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="tobaccoSinceWhen"
                    label="Since When"
                    variant="outlined"
                    placeholder="Since When"
                    size="small"
                    fullWidth
                    select
                    value={value}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      props.defaultValues.tobaccoSinceWhen = value;
                      onChange(value);
                    }}
                    error={Boolean(errors?.tobaccoSinceWhen)}
                    helperText={errors.tobaccoSinceWhen?.message}
                    style={{ width: 200 }}
                  >
                    {sinceWhen?.map((item) => {
                      return (
                        <MenuItem value={item.value}>{item.text}</MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />
            </React.Fragment>
          ) : (
            ""
          )}

          <Controller
            control={control}
            name="howManyPacks"
            rules={{ required: "required." }}
            setValue={setValue(
              "howManyPacks",
              props.defaultValues?.howManyPacks
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="howManyPacks"
                label="if yes, how many packs per day?"
                variant="outlined"
                placeholder="if yes, how many packs per day?"
                size="small"
                value={value}
                fullWidth
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.howManyPacks = value;
                  onChange(value);
                }}
                error={Boolean(errors?.howManyPacks)}
                helperText={errors.howManyPacks?.message}
                style={{ width: 450 }}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="quitSmokingHowLong"
            setValue={setValue(
              "quitSmokingHowLong",
              props.defaultValues?.quitSmokingHowLong
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="quitSmokingHowLong"
                label="if you have quit, how long ago?"
                variant="outlined"
                placeholder="if you have quit, how long ago?"
                size="small"
                value={value}
                fullWidth
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.quitSmokingHowLong = value;
                  onChange(value);
                }}
                error={Boolean(errors?.quitSmokingHowLong)}
                helperText={errors.quitSmokingHowLong?.message}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
          <Controller
            control={control}
            name="isAlcoholic"
            value={isAlcoholic}
            defaultValue={isAlcoholic}
            rules={{ required: "Field is required." }}
            setValue={setValue(
              "isAlcoholic",
              props.defaultValues?.isAlcoholic.toLowerCase()
            )}
            render={({ field: { onChange, value } }) => (
              setIsAlcoholic(props.defaultValues?.isAlcoholic.toLowerCase()),
              (
                <TextField
                  id="isAlcoholic"
                  label="Do you consume alcohol?"
                  variant="outlined"
                  placeholder="Do you consume alcohol?"
                  fullWidth
                  value={value}
                  selected={isAlcoholic}
                  defaultValue={isAlcoholic}
                  select
                  size="small"
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    props.defaultValues.isAlcoholic = value;
                    onChange(value);
                    setIsAlcoholic(value);
                    props.defaultValues.howOften = null;
                    props.defaultValues.alcoholicSinceWhen = null;
                  }}
                  error={Boolean(errors?.isAlcoholic)}
                  helperText={errors.isAlcoholic?.message}
                  style={{ width: 300 }}
                >
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </TextField>
              )
            )}
          />

          {isAlcoholic != "false" ? (
            <React.Fragment>
              <Controller
                control={control}
                name="howOften"
                setValue={setValue("howOften", props.defaultValues?.howOften)}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="howOften"
                    label="if yes, how often do you drink?"
                    variant="outlined"
                    placeholder="if yes, how often do you drink?"
                    size="small"
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      props.defaultValues.howOften = value;
                      onChange(value);
                    }}
                    error={Boolean(errors?.howOften)}
                    helperText={errors.howOften?.message}
                    style={{ width: 500 }}
                  />
                )}
              />

              <Controller
                control={control}
                name="alcoholicSinceWhen"
                rules={{ required: "required." }}
                setValue={setValue(
                  "alcoholicSinceWhen",
                  props.defaultValues?.alcoholicSinceWhen
                )}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="alcoholicSinceWhen"
                    label="Since When"
                    variant="outlined"
                    placeholder="Since When"
                    size="small"
                    fullWidth
                    value={value}
                    select
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      props.defaultValues.alcoholicSinceWhen = value;
                      onChange(value);
                    }}
                    error={Boolean(errors?.alcoholicSinceWhen)}
                    helperText={errors.alcoholicSinceWhen?.message}
                    style={{ width: 200 }}
                  >
                    {sinceWhen?.map((item) => {
                      return (
                        <MenuItem value={item.value}>{item.text}</MenuItem>
                      );
                    })}
                  </TextField>
                )}
              />
            </React.Fragment>
          ) : (
            ""
          )}
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="quitAlcoholHowLong"
            rules={{ required: "required." }}
            setValue={setValue(
              "quitAlcoholHowLong",
              props.defaultValues?.quitAlcoholHowLong
            )}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="quitAlcoholHowLong"
                label="if you have quit, how long ago?"
                variant="outlined"
                placeholder="if you have quit, how long ago?"
                size="small"
                fullWidth
                value={value}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  props.defaultValues.quitAlcoholHowLong = value;
                  onChange(value);
                }}
                error={Boolean(errors?.quitAlcoholHowLong)}
                helperText={errors.quitAlcoholHowLong?.message}
              />
            )}
          />
        </Stack>
      </Stack>
    </>
  );
};

const PatientForm = (props) => {
  const { setOpen, fetchPatients } = props;
  const classes = useStyles();
  const methods = useForm({
    defaultValues: {
      fullName: "",
      sex: "",
      dob: "",
      emailAddress: "",
      phoneNumber: "",
      alternatePhone: "",
      address1: "",
      age: "",
      nativePlace: "",
      physician: "",
    },
  });
  const patient = {
    fullName: "",
    sex: "",
    dob: "",
    emailAddress: "",
    phoneNumber: "",
    alternatePhone: "",
    address1: "",
    age: "",
    nativePlace: "",
    physician: "",
    name: "",
    contactNumber: "",
    email: "",
    gender: null,
    date: "",
    address: "",
    stateId: null,
    cityId: null,
    districtId: null,
    pincode: "",
    maritalStatus: "",
    family: "",
    occupation: "",
    hasChildrens: "",
    childrenHealthy: "",
    isSmoker: "",
    isTobaccoChewer: "",
    quitSmokingHowLong: "",
    howManyPacks: "",
    isAlcoholic: "",
    howOften: "",
    quitAlcoholHowLong: "",
    isAlcoholIntake: "",
    drugUse: "",
    otherAddiction: "",
    noctural: "",
    constipation: "",
    dyspepsia: "",
    presentComplaints: "",
    drugAllergy: "",
    pastAccident: "",
    surgery: "",
    diseaseList: "",
    familyHistory: "",
    activeMedicalHistory: "",
    menstrualHistory: "",
    obstetricHistory: "",
    userTypeId: 0,
    smokeSinceWhen: "",
    alcoholicSinceWhen: "",
    maritalStatusDuration: "",
    password: "",
    clientId: 0,
    id: 0,
  };
  const [defaultValues, setDefaultValues] = useState(patient);
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const [disable, setDisable] = useState(false);
  const steps = getSteps();
  const isStepOptional = (step) => {
    return false;
  };
  const isStepFalied = () => {
    return Boolean(Object.keys(methods.formState.errors).length);
  };
  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = async (data) => {
    console.log(data);

    if (activeStep == steps.length - 1) {
      console.log(JSON.stringify(data));
      data["UserTypeId"] = 4;
      data["ClientId"] = props.auth.clientId;
      let formData = new FormData();
      Object.keys(data).forEach(function (key) {
        if(data[key] == undefined || data[key] == "Invalid Date"){
          formData.append(key, "");
        }else{
          formData.append(key, data[key]);
        }
       
      });
      // var requestOptions = {
      //   method: "POST",
      //   body: formData,
      // };

      console.log("form data",formData);

      let result = await employeeService.UpdateEmployee(formData).then((res)=>{
        toast.success("Employee updated successfully", {
          position: "top-center",
          toastId: "success1",
        });

        setDisable(true);

        setTimeout(() => {
          props.closeModal();
        }, 2000);
        props.setRefresh(true);
      }).catch((error)=>{
          console.log(error);
      })

      // fetch(
      //   configUrl.StrataServer + `/api/patient/UpdatePatient`,
      //   requestOptions
      // )
      //   .then((response) => response.json())
      //   .then((result) => {
      //     // alert("Employee updated successfully");
      //     toast.success("Employee updated successfully", {
      //       position: "top-center",
      //       toastId: "success1",
      //     });

      //     setDisable(true);

      //     setTimeout(() => {
      //       props.closeModal();
      //     }, 2000);
      //     props.setRefresh(true);
      //   });
    } else {
      setActiveStep(activeStep + 1);
      setSkippedSteps(
        skippedSteps.filter((skipItem) => skipItem !== activeStep)
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (props.employeeId != 0) {
      getEmployee(props.employeeId);
    } else {
      setDefaultValues(patient);
    }
    console.log(props);
  }, []); //

  function getEmployee(id) {
    fetch(
      configUrl.StrataServer + `/api/Patient/GetPatientById?patientId=` + id
    )
      .then((response) => response.json())
      .then((result) => {
        //setDefaultValues(result.data);
        console.log(result.data);
        setDefaultValues(result.data);
      });
  }

  // const onSubmit = (data) => {
  //   console.log(data);
  // };
  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography
                variant="caption"
                align="center"
                style={{ display: "block" }}
              >
                optional
              </Typography>
            );
          }
          if (isStepFalied() && activeStep == index) {
            labelProps.error = true;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Thank You
        </Typography>
      ) : (
        <>
          <Stack spacing={2}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleNext)}>
                {getStepContent(activeStep, defaultValues, props.employeeId)}

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  justifyContent="flex-end"
                >
                  <Button
                    className={classes.button}
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    back
                  </Button>
                  {isStepOptional(activeStep) && (
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={handleSkip}
                    >
                      skip
                    </Button>
                  )}
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    // onClick={handleNext}
                    type="submit"
                    disabled={disable}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Stack>
              </form>
            </FormProvider>
          </Stack>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default PatientForm;
