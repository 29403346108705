import React, { Component } from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment, Switch } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../Iconify";
import { FormProvider, RHFTextField } from "../hook-form";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import treatmentService from "../../services/treatmentService";
import stockManagementService from "../../services/stockManagementService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddStock(props) {
  const [medicineOpen, setMedicineOpen] = useState(false);
  const [unit, setUnit] = useState("");
  const [open, setOpen] = useState(false);
  const [tps, setTps] = useState(0);
  const [ns, setNs] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [medicineOptions, setMedicineOptions] = React.useState([]);
  const [type, setType] = useState("");
  const [focusedInput, setFocus] = useState(null);
  const [disable, setDisable] = useState(false);
  const medicineloading = medicineOpen && medicineOptions.length === 0;

  const navigate = useNavigate();

  const defaultValues = {};

  const methods = useForm();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
  } = methods;

  const onSubmit = async (data) => {
    console.log(data);
    data["clientId"] = props.clientId;
    data["NoStrips"] =
      data["NoStrips"] == undefined ? data["Quantity"] : data["NoStrips"];
    stockManagementService.AddMedicines(data);
    //  alert("Stock added successfully");
    toast.success("Stock added successfully", {
      position: "top-center",
      toastId: "success1",
    });
    setDisable(true);
    setTimeout(() => {
      props.closeModal();
    }, 2000);
    props.setRefresh(true);
  };

  const onMedicineChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log(value);
    let res = await stockManagementService.GetAllMedicinesByName(
      props.clientId,
      value
    );
    setMedicineOptions(res.data);
    // const response = await fetch(
    //     `https://strata-portal.azurewebsites.net/api/Doctor/getAllCauses?query=` + value
    // );

    // const result = await response.json();
    // const data = result.data;
    // setCauseOptions(data);
  };

  React.useEffect(() => {
    if (!open) {
      onMedicineChangeHandle("");
    }
    if (tps && ns) {
      setQuantity(tps * ns);
      setValue("Quantity", tps * ns);
    }
    // if price and count exist and user isnt changing count, calculate count
    console.log(props.auth);
  }, [open, tps, ns, quantity]);

  const handleChangeType = (event) => {
    setType(event);
    switch (event) {
      case 2:
        setUnit(2);
        setValue("Unit", 2);
        break;
      case 3:
        setUnit(3);
        setValue("Unit", 2);
      default:
        break;
    }
  };

  const handleChangeUnit = (event) => {
    setUnit(event);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <br />
      <Stack spacing={5}>
        <Stack direction={{ xs: "column", sm: "row" }} top={3} spacing={3}>
          <Controller
            rules={{ required: "Field is required." }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                freeSolo
                autoSelect
                id="Name"
                style={{ width: 300 }}
                open={medicineOpen}
                onOpen={() => {
                  setMedicineOpen(true);
                }}
                onClose={() => {
                  setMedicineOpen(false);
                }}
                onChange={(e, value) => {
                  console.log(value);
                  onChange(value);
                }}
                disableClearable
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={(option) => option.name || option}
                options={medicineOptions}
                loading={medicineloading}
                name="Name"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Medicine Name"
                    variant="outlined"
                    size="small"
                    style={{ width: 300 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(ev) => {
                      // dont fire API if the user delete or not entered anything
                      if (ev.target.value !== "" || ev.target.value !== null) {
                        console.log(ev.target.value);
                        onMedicineChangeHandle(ev.target.value);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {medicineloading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    error={Boolean(errors?.Name)}
                    helperText={errors.Name?.message}
                  />
                )}
              />
            )}
            name="Name"
            control={control}
          />

          <Controller
            control={control}
            name="MedicineType"
            rules={{ required: "Field is required." }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="MedicineType"
                label="Medicine Type"
                variant="outlined"
                placeholder="Medicine Type"
                size="small"
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                value={type}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  onChange(value);
                  handleChangeType(value);
                }}
                error={Boolean(errors?.MedicineType)}
                helperText={errors.MedicineType?.message}
                style={{ width: 390 }}
              >
                <MenuItem value={1}>Tablet</MenuItem>
                <MenuItem value={2}>Syrup</MenuItem>
                <MenuItem value={3}>Powder</MenuItem>
              </TextField>
            )}
          />
        </Stack>

        {type == 1 ? (
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <Controller
              control={control}
              name="TabletPerStrip"
              rules={{ required: "Tablet Per Strip is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="TabletPerStrip"
                  label="Tablet Per Strip"
                  variant="outlined"
                  placeholder="Tablet Per Strip"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  type="number"
                  fullWidth
                  value={tps}
                  onFocus={() => setFocus("tps")}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    setTps(value);
                  }}
                  error={Boolean(errors?.TabletPerStrip)}
                  helperText={errors.TabletPerStrip?.message}
                  style={{ width: 390 }}
                />
              )}
            />

            <Controller
              control={control}
              name="NoStrips"
              rules={{ required: "Field is required." }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="NoStrips"
                  label="Number of Strips"
                  variant="outlined"
                  placeholder="Number Of Strips"
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  value={ns}
                  onFocus={() => setFocus("ns")}
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    onChange(value);
                    setNs(value);
                  }}
                  error={Boolean(errors?.NoStrips)}
                  helperText={errors.NoStrips?.message}
                  style={{ width: 390 }}
                />
              )}
            />
          </Stack>
        ) : (
          ""
        )}

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          {type == 1 ? (
            ""
          ) : (
            <React.Fragment>
              <Controller
                control={control}
                name="Weight"
                rules={{ required: "Weight is required." }}
                render={({ field }) => (
                  console.log(errors),
                  (
                    <TextField
                      id="Weight"
                      label="Weight"
                      variant="outlined"
                      placeholder="Weight"
                      type="number"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      {...field}
                      error={Boolean(errors?.Weight)}
                      helperText={errors.Weight?.message}
                      style={{ width: 390 }}
                    />
                  )
                )}
              />
              <Controller
                control={control}
                name="Unit"
                rules={{ required: "Field is required." }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    id="Unit"
                    label="Unit"
                    variant="outlined"
                    placeholder="Unit"
                    size="small"
                    fullWidth
                    select
                    InputLabelProps={{ shrink: true }}
                    value={unit}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      onChange(value);
                      handleChangeUnit(value);
                    }}
                    error={Boolean(errors?.Unit)}
                    helperText={errors.Unit?.message}
                    style={{ width: 390 }}
                  >
                    <MenuItem value={1}>KG</MenuItem>
                    <MenuItem value={2}>ML</MenuItem>
                    <MenuItem value={3}>GM</MenuItem>
                  </TextField>
                )}
              />
            </React.Fragment>
          )}
          <Controller
            control={control}
            name="Quantity"
            rules={{ required: "Quantity is required." }}
            render={({ field: { onChange, value } }) => (
              <TextField
                id="Quantity"
                label="Quantity"
                variant="outlined"
                placeholder="Quantity"
                size="small"
                disabled={type == 1}
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={quantity}
                onChange={(e) => {
                  const {
                    target: { value },
                  } = e;
                  onChange(value);
                  setQuantity(value);
                }}
                error={Boolean(errors?.Quantity)}
                helperText={errors.Quantity?.message}
                style={{ width: 390 }}
              />
            )}
          />
        </Stack>

        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Controller
            control={control}
            name="ManifacturedDate"
            rules={{ required: "Manifactured Date is required." }}
            render={({ field }) => (
              <TextField
                id="ManifacturedDate"
                label="Manifactured Date"
                variant="outlined"
                placeholder="Quantity"
                InputLabelProps={{ shrink: true }}
                size="small"
                type="date"
                fullWidth
                {...field}
                error={Boolean(errors?.ManifacturedDate)}
                helperText={errors.ManifacturedDate?.message}
                style={{ width: 390 }}
              />
            )}
          />

          <Controller
            control={control}
            name="ExpiredDate"
            rules={{ required: "Expired Date is required." }}
            render={({ field }) => (
              <TextField
                id="ExpiredDate"
                label="Expired Date"
                variant="outlined"
                placeholder="Expired Date"
                InputLabelProps={{ shrink: true }}
                size="small"
                type="date"
                fullWidth
                {...field}
                error={Boolean(errors?.ExpiredDate)}
                helperText={errors.ExpiredDate?.message}
                style={{ width: 390 }}
              />
            )}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={disable}
        >
          Save
        </LoadingButton>
      </Stack>
      <ToastContainer />
    </FormProvider>
  );
}
