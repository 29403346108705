import React, { Component } from "react";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../components/Iconify";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "../../components/hook-form";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import doctorService from "../../services/doctorService.js";
import utilService from "../../services/utile";
import { configUrl } from "../../config.js";
import { register } from "numeral";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import treatmentService from "../../services/treatmentService";
import perEmploymentService from "../../services/PreEmploymentHCService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function RegisterForm(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [patientOpen, setPatientOpen] = useState(false);
  const [patientOptions, setPatientOptions] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);

  const Patientloading = patientOpen && patientOptions.length === 0;

  const [defaultValues, setDefaultValues] = useState({
    preEmploymentId: 0,
    employeeId: 0,
    date: new Date(),
    spO2: 0,
    temp: 0,
    pulse: 0,
    height: 0,
    weight: 0,
    bpLow: 0,
    bpHigh: 0,
    vertigo: "",
    Vision: "",
    suger: "",
    allergy: "",
    remark: "",
    createdBy: 0,
    updatedBy: 0,
    createdDate: new Date(),
    updatedDate: new Date(),
    deleteDate: new Date(),
  });

  console.log(defaultValues.vertigo);

  const RegisterSchema = Yup.object().shape({
    date: Yup.string().required("Date required"),
    spO2: Yup.string().required("SpO2"),
    temp: Yup.number()
      .typeError("Please enter value")
      .required("Temperature is required"),
    pulse: Yup.number().typeError("Please enter value"),
    height: Yup.number()
      .typeError("Please enter value")
      .required("Height is Required"),
    weight: Yup.number()
      .typeError("Please enter value")
      .required("Weight	is Required"),
    bpLow: Yup.number()
      .typeError("Please enter value")
      .required("BPLow Required"),
    bpHigh: Yup.number()
      .typeError("Please enter value")
      .required("BPHigh	 Required"),
    //,
    // vertigo: Yup.boolean().required('Vertigo Required')
    //    ,
    // vision: Yup.string().required('Vision Required')
    //   ,
    // suger	: Yup.string().required('Sugar Required')
    remark: Yup.string().required("Remark	 Required"),
    allergy: Yup.string().required("Allergy Required"),
  });

  const formOptions = { resolver: yupResolver(RegisterSchema) };

  const { register, control, handleSubmit, reset, formState, watch, setValue } =
    useForm(formOptions);
  const { errors } = formState;
  const onSubmit = async (data) => {
    data["PreEmploymentId"] = props.selectedID;
    data["CreatedBy"] = props.auth.userId;
    data["CreatedBy"] = props.auth.userId;
    data["Vision"] = defaultValues.Vision;
    data["Vertigo"] = defaultValues.vertigo;
    setDisable(true);
    let addHC = await perEmploymentService.AddPreEmployment(data);
    console.log("response", addHC);

    // alert("Pre-employement checup submitted successfully");
    toast.success("Treatment registered successfully", {
      position: "top-center",
      toastId: "success1",
    });

    setTimeout(() => {
      props.closeModal();
    }, 2000);
    props.setRefresh(true);
  };

  function FormValueChange(value, key) {
    setDefaultValues((ev) => ({
      ...ev,
      [key]: value,
    }));
    console.log(defaultValues);
    // register(key,{value:value})
  }

  const onPatientChangeHandle = async (value) => {
    // this default api does not support searching but if you use google maps or some other use the value and post to get back you reslut and then set it using setOptions
    console.log(value);
    let res = await treatmentService.SearchPetiont(value, props.auth.clientId);
    console.log(res);
    setPatientOptions(res.data);
  };

  React.useEffect(() => {
    if (!open) {
      onPatientChangeHandle("");
    }
  }, [open]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <br />
      <div className="list-group list-group-flush">
        <div className="list-group-item">
          <div className="form-row">
            <div className="form-group col-3">
              <Controller
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    style={{ width: 300 }}
                    open={patientOpen}
                    onOpen={() => {
                      setPatientOpen(true);
                    }}
                    onClose={() => {
                      setPatientOpen(false);
                    }}
                    onChange={(e, value) => {
                      console.log(value);
                      onChange(value.id);
                    }}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) => option.label}
                    options={patientOptions}
                    loading={Patientloading}
                    name="EmployeeId"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Patient"
                        variant="outlined"
                        size="small"
                        style={{ width: 250 }}
                        name="EmployeeId"
                        onChange={(ev) => {
                          // dont fire API if the user delete or not entered anything
                          if (
                            ev.target.value !== "" ||
                            ev.target.value !== null
                          ) {
                            onPatientChangeHandle(ev.target.value);
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {Patientloading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
                onChange={([event, data]) => {
                  return data;
                }}
                name="EmployeeId"
                control={control}
              />
            </div>
            <div className="form-group col-3">
              <TextField
                error={Boolean(errors?.date)}
                helperText={errors?.date ? errors?.date.message : " "}
                InputLabelProps={{ shrink: true }}
                {...register(`date`)}
                name="date"
                type="datetime-local"
                style={{ width: 250 }}
                value={defaultValues?.date}
                onChange={(event) =>
                  FormValueChange(event.target.value, "date")
                }
                size="small"
                label="Date"
              />
              {/* <input placeholder='Date' name={`date`} {...register(`date`)} type="number" className={`form-control ${errors?.date ? 'is-invalid' : ''}`} /> */}
            </div>
            <div className="form-group col-3">
              <TextField
                name="spO2"
                // defaultValue={defaultValues?.spO2}
                // onChange={(event) => FormValueChange(event.target.value, 'spO2')}
                size="small"
                label="SpO2"
                type="number"
                {...register(`spO2`)}
                error={Boolean(errors?.spO2)}
                helperText={errors?.spO2 ? errors?.spO2.message : " "}
              />
            </div>
            <div className="form-group col-3">
              <TextField
                name="temp"
                // value={defaultValues?.Temp}
                // onChange={(event) => FormValueChange(event.target.value, 'temp')}
                size="small"
                label="Temp"
                type="number"
                {...register(`temp`)}
                error={Boolean(errors?.temp)}
                helperText={errors?.temp ? errors?.temp.message : " "}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-3">
              <TextField
                name="height"
                size="small"
                // value={defaultValues?.Height}
                // onChange={(event) => FormValueChange(event.target.value, 'height')}
                label="Height(cm)"
                type="number"
                {...register(`height`)}
                error={Boolean(errors?.height)}
                helperText={errors?.height ? errors?.height.message : " "}
              />
            </div>
            <div className="form-group col-3">
              <TextField
                name="weight"
                size="small"
                // value={defaultValues?.Weight}
                // onChange={(event) => FormValueChange(event.target.value, 'weight')}
                label="Weight(KG)"
                type="number"
                {...register(`weight`)}
                error={Boolean(errors?.weight)}
                helperText={errors?.weight ? errors?.weight.message : " "}
              />
            </div>
            <div className="form-group col-3">
              <FormControl name="suger" required size="small">
                <InputLabel id="suger">Suger</InputLabel>
                <Select
                  labelId="suger"
                  id="suger"
                  name="suger"
                  displayEmpty
                  value={defaultValues?.Suger}
                  size="small"
                  onChange={(event) =>
                    FormValueChange(event.target.value, "suger")
                  }
                  style={{ width: 570 }}
                  label="Suger"
                  {...register(`suger`)}
                >
                  <MenuItem value="Normal">Normal</MenuItem>
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-3">
              <TextField
                name="pulse"
                size="small"
                // value={defaultValues?.Pulse}
                // onChange={(event) => FormValueChange(event.target.value, 'pulse')}
                label="Pulse"
                type="number"
                {...register(`pulse`)}
                error={Boolean(errors?.pulse)}
                helperText={errors?.pulse ? errors?.pulse.message : " "}
              />
            </div>
            <div className="form-group col-3">
              <div className="form-row">
                <div className="form-group col-6">
                  <TextField
                    name="bpLow"
                    size="small"
                    // value={defaultValues?.bpLow}
                    // onChange={(event) => FormValueChange(event.target.value, 'bpLow')}
                    label="BPLow"
                    type="number"
                    {...register(`bpLow`)}
                    error={Boolean(errors?.bpLow)}
                    helperText={errors?.bpLow ? errors?.bpLow.message : " "}
                  />
                </div>
                <div className="form-group col-6">
                  <TextField
                    name="bpHigh"
                    size="small"
                    // value={defaultValues?.BPHigh}
                    // onChange={(event) => FormValueChange(event.target.value, 'bpHigh')}
                    label="BPHigh"
                    type="number"
                    {...register(`bpHigh`)}
                    error={Boolean(errors?.bpHigh)}
                    helperText={errors?.bpHigh ? errors?.bpHigh.message : " "}
                  />
                </div>
              </div>
            </div>
            <div className="form-group col-3">
              <FormControl
                name="vision"
                required
                fullWidth
                size="small"
                variant="outlined"
              >
                <InputLabel id="Vision">Vision</InputLabel>
                <Select
                  labelId="Vision"
                  id="Vision"
                  name="Vision"
                  displayEmpty
                  value={defaultValues?.Vision}
                  size="small"
                  onChange={(event) =>
                    FormValueChange(event.target.value, "Vision")
                  }
                  style={{ width: 570 }}
                  label="Vision"
                >
                  <MenuItem value="Normal">Normal</MenuItem>
                  <MenuItem value="Specks">Specks</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* <TextField
                    name="vision"
                    size="small"
                    value={defaultValues?.vision}
                    onChange={(event) => FormValueChange(event.target.value, 'vision')}
                    label="Vision"
                    {...register(`vision`)}
                    error={Boolean(errors?.vision)}
                    helperText={errors?.vision ? errors?.vision.message : " "}
                /> */}

            {/* <TextField name="vertigo" 
                    size="small" 
                    value={defaultValues?.vertigo} 
                    onChange={(event) => FormValueChange(event.target.value, 'vertigo')}
                    label="Vertigo"
                    {...register(`vertigo`)}
                    error={Boolean(errors?.vertigo)}
                    helperText={errors?.vertigo ? errors?.vertigo.message : " "}
                /> */}
          </div>
          <div className="form-row">
            <div className="form-group col-3">
              <TextField
                name="allergy"
                size="small"
                // value={defaultValues?.Allergy}
                // onChange={(event) => FormValueChange(event.target.value, 'allergy')}
                label="Allergy"
                {...register(`allergy`)}
                error={Boolean(errors?.allergy)}
                helperText={errors?.allergy ? errors?.allergy.message : " "}
              />
            </div>
            {/* <TextField
                 name="suger" 
                 size="small" 
                 value={defaultValues?.suger} 
                 onChange={(event) => FormValueChange(event.target.value, 'suger')} 
                 label="Suger" 
                 {...register(`suger`)}
                 error={Boolean(errors?.suger)}
                 helperText={errors?.suger ? errors?.suger.message : " "}
                /> */}
            <div className="form-group col-3">
              <TextField
                name="remark"
                size="small"
                // value={defaultValues?.Remark}
                // onChange={(event) => FormValueChange(event.target.value, 'remark')}
                label="Remark"
                {...register(`remark`)}
                error={Boolean(errors?.remark)}
                helperText={errors?.remark ? errors?.remark.message : " "}
              />
            </div>
            <div className="form-group col-4">
              <FormControl name="vertigo" required fullWidth size="small">
                <InputLabel id="vertigo">Vertigo</InputLabel>
                <Select
                  labelId="vertigo"
                  id="vertigo"
                  name="vertigo"
                  displayEmpty
                  value={defaultValues?.vertigo}
                  size="small"
                  onChange={(event) =>
                    FormValueChange(event.target.value, "vertigo")
                  }
                  style={{ width: 570 }}
                  label="Vertigo"
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={disable}
          >
            Register
          </LoadingButton>
        </div>
      </div>
      {/* <style>{styles}</style> */}
      <ToastContainer />
    </form>
  );
}
