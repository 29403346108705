import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import * as XLSX from "xlsx";
import healthCheckupService from "../../services/healthCheckupService"

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Alert,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import "../../App.css";
import ImportHealthCheckUpErrorPopup from "../ImportHealthCheckUpErrorPopup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ImportHealthCheckUpPopup() {
  const [open, setOpen] = React.useState(false);
  const [excelData, setExcelData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const [validationErrorsPopUp, setValidationErrorsPopUp] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const populateData = (e) => {
    setSelectedFile(e.target.files[0])
    setValidationErrors([]);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetNames = workbook.SheetNames;
        const tableData = [];

        sheetNames.forEach((sheetName) => {
          const sheet = workbook.Sheets[sheetName];
          const table = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          tableData.push({ name: sheetName, data: table });
        });

        const errors = validateData(tableData);
        if (errors.length > 0) {
          setValidationErrors(errors);
          setValidationErrorsPopUp(true);
        } else {
          // Convert the tableData to array of objects
          const convertedData = tableData.map((sheet) => {
            const headers = sheet.data[0];
            const rows = sheet.data.slice(1);
            return rows.map((row) => {
              const obj = {};
              headers.forEach((header, index) => {
                obj[header] = row[index];
              });
              return obj;
            });
          });
          setExcelData(convertedData);
        }
      };
      reader.readAsBinaryString(selectedFile);
    }
  }

  const handleImport = () => {
    healthCheckupService.importEmployeeHealthCheckups(excelData);
  };

  const handelCloseErrorPopUp = () => {
    setValidationErrorsPopUp(false);
  };

  console.log(validationErrors);

  const validateData = (data) => {
    const errors = [];

    data.forEach((sheet) => {
      const sheetErrors = [];
      for (let rowIndex = 1; rowIndex < sheet.data.length; rowIndex++) {
        const row = sheet.data[rowIndex];
        const rowErrors = [];
        for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
          const cell = row[cellIndex];
          if (cellIndex === 0) {
            if (cell === "") {
              rowErrors.push(
                `Field at row ${rowIndex + 1}, column ${
                  cellIndex + 1
                } is required.`
              );
            }
          } else if (cellIndex === 1) {
            if (!isValidEmail(cell)) {
              rowErrors.push(
                `Field at row ${rowIndex + 1}, column ${
                  cellIndex + 1
                } is not a valid email.`
              );
            }
          } else if (cellIndex >= 3 && cellIndex <= 19) {
            if (isNaN(cell)) {
              rowErrors.push(
                `Field at row ${rowIndex + 1}, column ${
                  cellIndex + 1
                } is not a valid number.`
              );
            }
          }
        }
        if (rowErrors.length > 0) {
          sheetErrors.push(rowErrors);
        }
      }
      if (sheetErrors.length > 0) {
        errors.push({ name: sheet.name, errors: sheetErrors });
      }
    });

    return errors;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Import HealthCheakup
      </Button>

      <BootstrapDialog
        fullWidth="xl"
        maxWidth="xl"
        style={{ marginBottom: "150px" }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Stack m={5}>
          <Stack direction={{ xs: "column", sm: "row" }} mb={3}>
            <Typography variant="h5">Import HealthCheakup</Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} m={2} spacing={5}>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={(e) => populateData(e)}
            />
            <Button
              variant="contained"
              component="label"
              onClick={handleImport}
              // startIcon={<CloudUpload />}
            >
              Import
            </Button>
          </Stack>

          <ImportHealthCheckUpErrorPopup
            validationErrors={validationErrors}
            validationErrorsPopUp={validationErrorsPopUp}
            handelCloseErrorPopUp={handelCloseErrorPopUp}
          />
          {excelData.length > 0 && (
            <div className="multi-table-container">
              {excelData.map((table, index) => (
                <div className="table-container" key={index}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {Object.keys(table[0]).map((header, cellIndex) => (
                          <TableCell key={cellIndex}>{header}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {table.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {Object.values(row).map((cell, cellIndex) => (
                            <TableCell key={cellIndex}>{cell}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ))}
            </div>
          )}
        </Stack>
      </BootstrapDialog>
    </div>
  );
}

export default ImportHealthCheckUpPopup;
