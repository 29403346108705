import React, { Component, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { main } from "../js/main";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "../App";
import healthCheckupService from "../services/healthCheckupService";
import doctorService from "../services/doctorService";
import analyticService from "../services/analyticService";
import { Button } from "@mui/material";
import { textAlign } from "@mui/system";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
const ExcelJS = require("exceljs");

function MisReport() {
  const location = useLocation();

  const hcId = new URLSearchParams(location.search).get("ehn");
  const auth = useRecoilValue(authAtom);
  const styles = `
  table, th, td {
    border: 1px solid black;  border-collapse: collapse;
   
  }
  
 
@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  } 
}
`;

  useEffect(() => {
    getPdf();

    console.log(auth);
  }, []); //

  let [misReportDetails, setMISReportDetails] = useState([]);
  let [testName, setTestName] = useState([]);

  async function printpage() {
    window.print();
  }

  console.log(testName);

  // const exportExcelFile = () => {
  //   const workbook = new ExcelJS.Workbook();
  //   const sheet = workbook.addWorksheet("My Sheet");

  //   const testHeader = testName.map((i) => i.testName);
  //   const testReading = testName.map((i) => i.testReading);

  //   const exportData = testName.map((i) => {
  //     return {
  //       header: i.testName,
  //       key: i.testName,
  //       width: 10,
  //     };
  //   });
  //   console.log("data", exportData);

  //   sheet.columns = exportData;
  //   // // sheet.addRow(exportData);

  //   const temp = testName.map((i) => {
  //     let obj = {};
  //     let keylength = Object.keys(i).length;
  //     for (var j = 0; j < keylength; j++) {
  //       obj[Object.keys(i)[j]] = Object.values(i)[j];
  //     }
  //     const key = i.testName;
  //     const value = i.testReading;
  //     return obj;
  //   });

  //   const mergedObj = Object.assign({}, ...temp);

  //   console.log("ex", mergedObj);

  //   // misReportDetails?.map((product) => {
  //   //   console.log(product.empTests[0].testReading);
  //   //   sheet.addRow({
  //   //     empName: product?.empName,
  //   //     vision1: product.empTests[0].testReading,
  //   //     ecg: product.empTests[1].testReading,
  //   //     xray: product.empTests[2].testReading,
  //   //     pulomonaryTest: product.empTests[3].testReading,
  //   //     bloodSugar: product.empTests[4].testReading,
  //   //     esr: product.empTests[5].testReading,
  //   //     proteins: product.empTests[6].testReading,
  //   //     glucose: product.empTests[7].testReading,
  //   //   });
  //   // });

  //   workbook.xlsx.writeBuffer().then(function (data) {
  //     const blob = new Blob([data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });
  //     const url = window.URL.createObjectURL(blob);
  //     const anchor = document.createElement("a");
  //     anchor.href = url;
  //     anchor.download = "download.xlsx";
  //     anchor.click();
  //     window.URL.revokeObjectURL(url);
  //   });
  // };
  const exportExcelFile = () => {
    const table = document.getElementsByTagName("table")[0];
    const worksheet = XLSX.utils.table_to_sheet(table, { raw: true });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "misReport.xlsx");
  };

  function getPdf() {
    let res = analyticService.GetMISReportForHC(hcId).then((res) => {
      if (res.message == "Success") {
        setMISReportDetails([]);
        setTestName([]);

        res.data.map((item, i) =>
          //  misReportDetails.push(item)
          setMISReportDetails((old) => [...old, item])
        );
        // setMISReportDetails([res.data])
        console.log(res.data);
        if (misReportDetails) {
          if (res.data[0].empTests) {
            res.data[0].empTests.map((item) => {
              //testName.push(item);
              setTestName((old) => [...old, item]);
            });
          }
          console.log(testName);
          console.log(misReportDetails);
        }
      } else {
        // alert("Something went wrong" + " : " + res.message);

        toast.error("Something went wrong" + " : " + res.message, {
          position: "top-center",
          toastId: "success1",
        });
      }
    });
  }

  if (misReportDetails) {
    return (
      <>
        <div>
          <h3>MIS Report</h3>
        </div>
        <div style={{ float: "right", marginBottom: "5%" }}>
          <Button
            className=""
            variant="contained"
            color="primary"
            onClick={exportExcelFile}
            type="button"
          >
            Export to Excel
          </Button>
        </div>
        <br></br>
        <div id="section-to-print">
          <div
            style={{
              display: "inline-block !important",
              position: "static !important",
              overflow: "visible !important",
              width: "100% !important" /* styles */,
              // fontSize: "1vmin"
            }}
          >
            <div style={{ width: "100%;" }}>
              <div>
                <div style={{ float: "left" }}>
                  {/* <img src={logo}></img> */}
                </div>
              </div>
            </div>
            <div style={{ width: "100%", textAlign: "center" }}>
              <table
                id="table-id"
                style={{
                  width: "100%",
                  border: "1px solid black",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr>
                    <th> Employee Name </th>
                    {testName.map((item, i) => {
                      return <th>{item.testName}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {misReportDetails.map((item, i) => (
                    <tr>
                      <th style={{ height: 15, fontWeight: "bold" }}>
                        <u>{item?.empName}</u>
                      </th>

                      {item?.empTests.map((item1, i) =>
                        item1?.visionTest == null ? (
                          <>
                            <td>{item1?.testReading}</td>
                          </>
                        ) : (
                          <>
                            <td>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>Vision</td>
                                    <td colSpan={2}>Without Glass</td>
                                    <td>Vision</td>
                                    <td colSpan={2}>With Glass </td>
                                    <td>Color Blindness</td>
                                    <th colSpan={2}>
                                      {item1.visionTest?.colorBlind == true
                                        ? "Yes"
                                        : "No"}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <td>Right</td>
                                    <td>Left</td>
                                    <td></td>
                                    <td>Right</td>
                                    <td>Left</td>
                                    <td colSpan={3}>Vision Remark</td>
                                  </tr>
                                  <tr>
                                    <td>Near</td>
                                    <th>{item1.visionTest?.woGlassRighNear}</th>
                                    <th>{item1.visionTest?.woGlassLeftNear}</th>
                                    <td>Near</td>
                                    <th>{item1.visionTest?.woGlassRightFar}</th>
                                    <th>{item1.visionTest?.woGlassLeftFar}</th>
                                    <th rowSpan={2} colSpan={3}>
                                      {item1.testReading}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>Far</td>
                                    <th>{item1.visionTest?.wGlassRightNear}</th>
                                    <th>{item1.visionTest?.wGlassLeftNear}</th>
                                    <td>Far</td>
                                    <th>{item1.visionTest?.wGlassRightFar}</th>
                                    <th>{item1.visionTest?.wGlassLeftFar}</th>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </>
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
          </div>
          <style>{styles}</style>
        </div>
        <br />
        <br />
        <Button
          className=""
          variant="contained"
          color="primary"
          onClick={printpage}
          type="button"
        >
          Print
        </Button>
        <ToastContainer />
      </>
    );
  } else {
    return <></>;
  }
}

export default MisReport;
