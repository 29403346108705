import { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { atom } from "recoil";
import { useRecoilValue } from "recoil";

import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import ambulanceService from "../../services/ambulanceService";
// material
import {
  Switch,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const authAtom = atom({
  key: "auth",
  // get initial state from local storage to enable user to stay logged in
  default: JSON.parse(localStorage.getItem("user")),
});
export default function () {
  const validationSchema = Yup.object().shape({
    monthlyCheckList: Yup.array().of(
      Yup.object().shape({
        ambulanceCheckListId: Yup.number(),
        notes: Yup.string(),
        isOkay: Yup.boolean(),
        // isOkay: Yup.boolean().required("Select the ")
      })
    ),
    ambulaceNumber: Yup.string().required("Please Enter Ambulance number"),
    clientId: Yup.number(),
    diverName: Yup.string().required("Please enter Driver's Name"),
    reportDate: Yup.string().required("Please enter Date"),
  });
  const auth = useRecoilValue(authAtom);

  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, control, handleSubmit, reset, formState, watch } =
    useForm(formOptions);
  const { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    name: "monthlyCheckList",
    control,
  });
  const [GroupArr, setGroupArr] = useState([]);
  useEffect(() => {
    getCehckList();
  }, []);

  async function getCehckList() {
    fields.forEach((item) => {
      remove(item);
    });
    let res = await ambulanceService.GetAllCheckList();

    let temparr = [];
    res.data.map((item, i) => {
      item.sr = i;
      fields.push(item);
      temparr.push(item);
    });
    console.log(fields);
    setGroupArr(temparr);
  }
  function onSubmit(data) {
    ambulanceService.SubmitCheckList(data).then((res) => {
      if (res.message == "Success") {
        //   alert("Check List Added Sucessfully");
        toast.success("Check List Added  successfully", {
          position: "top-center",
          toastId: "success1",
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        //   alert("Something went wrong, Please try after some time or contact Admin with this error." + "  " + res.message);
        toast.error(
          "Something went wrong, Please try after some time or contact Admin with this error." +
            "  " +
            res.message,
          {
            position: "top-center",
            toastId: "success1",
          }
        );
      }
    });
    // alert(JSON.stringify(data));
  }

  return (
    <>
      <h1>Ambulance Check List</h1>

      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="card m-3">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <h5 className="card-title">Ambulance CheckList</h5>

                <div className="form-row">
                  <div className="form-group col-12"></div>
                </div>
                <div className="form-row" style={{ fontWeight: "bold" }}>
                  <div className="form-group col-4">
                    <label>Name </label>
                  </div>
                  <div className="form-group col-4">
                    <label>Status</label>
                  </div>
                  <div className="form-group col-4">Notes</div>
                </div>
                {GroupArr?.filter((Cl) => Cl.type == "A")?.map(
                  (item, i) => (
                    console.log(item),
                    (
                      <>
                        <input
                          type="hidden"
                          value={item.checkListId}
                          {...register(
                            `monthlyCheckList.${item.sr}.ambulanceCheckListId`
                          )}
                        />
                        <div className="form-row">
                          <div className="form-group col-4">
                            <label>{item.title}</label>
                          </div>
                          <div className="form-group col-4">
                            <Switch
                              name={`monthlyCheckList[${item.sr}]isOkay`}
                              {...register(
                                `monthlyCheckList.${item.sr}.isOkay`
                              )}
                            />
                            <label>Okay</label>
                          </div>
                          <div className="form-group col-4">
                            <input
                              placeholder="Notes"
                              name={`monthlyCheckList[${item.sr}]notes`}
                              {...register(`monthlyCheckList.${item.sr}.notes`)}
                              type="text"
                              className={`form-control ${
                                errors.monthlyCheckList?.[item.sr]?.notes
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                          </div>
                        </div>
                      </>
                    )
                  )
                )}
              </div>
            </div>
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <div className="form-row">
                  <div className="form-group col-4">
                    <input
                      placeholder="Nurse Name"
                      name={`diverName`}
                      {...register(`diverName`)}
                      type="text"
                      className={`form-control ${
                        errors.diverName ? "is-invalid" : ""
                      }`}
                    />
                  </div>
                  <div className="form-group col-4">
                    <input
                      placeholder="Ambulance Number"
                      name={`ambulaceNumber`}
                      {...register(`ambulaceNumber`)}
                      type="text"
                      className={`form-control ${
                        errors.ambulaceNumber ? "is-invalid" : ""
                      }`}
                    />
                  </div>
                  <div className="form-group col-4">
                    <input
                      placeholder="Ambulance Number"
                      name={`reportDate`}
                      {...register(`reportDate`)}
                      type="datetime-local"
                      className={`form-control ${
                        errors.reportDate ? "is-invalid" : ""
                      }`}
                    />
                  </div>
                  <input
                    type="hidden"
                    value={auth.clientId}
                    {...register(`clientId`)}
                  />
                </div>
              </div>
            </div>
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <h5 className="card-title">Equipments CheckList</h5>
                <div className="form-row" style={{ fontWeight: "bold" }}>
                  <div className="form-group col-4">
                    <label>Name </label>
                  </div>
                  <div className="form-group col-4">
                    <label>Status</label>
                  </div>
                  <div className="form-group col-4">Notes</div>
                </div>
                {GroupArr?.filter((Cl) => Cl.type == "E")?.map(
                  (item, i) => (
                    console.log(item),
                    (
                      <>
                        <input
                          type="hidden"
                          value={item.checkListId}
                          {...register(
                            `monthlyCheckList.${item.sr}.ambulanceCheckListId`
                          )}
                        />
                        <div className="form-row">
                          <div className="form-group col-4">
                            <label>{item.title}</label>
                          </div>
                          <div className="form-group col-4">
                            <Switch
                              name={`monthlyCheckList[${item.sr}]isOkay`}
                              {...register(
                                `monthlyCheckList.${item.sr}.isOkay`
                              )}
                            />
                            <label>Okay</label>
                          </div>
                          <div className="form-group col-4">
                            <input
                              placeholder="Notes"
                              name={`monthlyCheckList[${item.sr}]notes`}
                              {...register(`monthlyCheckList.${item.sr}.notes`)}
                              type="text"
                              className={`form-control ${
                                errors.monthlyCheckList?.[item.sr]?.notes
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                          </div>
                        </div>
                      </>
                    )
                  )
                )}
              </div>
            </div>
            <button type="submit" className="btn btn-primary mr-1">
              Submit CheckList
            </button>
          </div>
        </form>
      </Card>
      <ToastContainer />
    </>
  );
}
