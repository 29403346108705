import { faker } from "@faker-js/faker";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import {
  EmployeeByState,
  EmployeeByDistrict,
  EmployeeByCity,
  EmployeeByTreatment,
  AdminCharts,
} from "../chart";

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from "../../sections/@dashboard/app";
import analyticService from "../../services/analyticService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------

export default function AdminDashboard() {
  const theme = useTheme();
  const [clientCount, setClientCount] = useState([]);
  const [clientByStateList, setClientByStateList] = useState([]);
  const [clientByDistrictList, setClientByDistrictList] = useState([]);
  const [clientByCityList, setClientByCityList] = useState([]);

  useEffect(() => {
    GetClientCount();
    GetClientByState();
    GetClientByDistrict();
    GetClientByCity();
  }, []); //

  async function GetClientCount() {
    let res = await analyticService.GetAdminDashboardCount();
    console.log(res);
    setClientCount(res.data);
  }

  async function GetClientByState() {
    let res = await analyticService.GetClientByState();
    console.log(res);
    setClientByStateList(res.data);
  }

  async function GetClientByDistrict() {
    let res = await analyticService.GetClientByDistrict();
    console.log(res);
    setClientByDistrictList(res.data);
  }

  async function GetClientByCity() {
    let res = await analyticService.GetClientByCity();
    console.log(res);
    setClientByCityList(res.data);
  }

  return (
    <Grid container spacing={3} wrap="wrap">
      <ToastContainer />
      <Grid item xs={12} sm={6} md={6}>
        <AppWidgetSummary
          title="Clients"
          total={clientCount.clientCount}
          icon={"fa-solid:users"}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <AppWidgetSummary
          title="Doctors"
          total={clientCount.doctorsCount}
          color="info"
          icon={"wpf:doctors-bag"}
        />
      </Grid>

      <Grid item xs={12} md={4} lg={6}>
        <AdminCharts
          title="Clients by state"
          subheader=""
          chartData={clientByStateList}
        />
      </Grid>

      <Grid item xs={12} md={4} lg={6}>
        <AdminCharts
          title="Clients by district"
          subheader=""
          chartData={clientByDistrictList}
        />
      </Grid>

      <Grid item xs={12} md={4} lg={6}>
        <AdminCharts
          title="Clients by city"
          subheader=""
          chartData={clientByCityList}
        />
      </Grid>

      {/*<Grid item xs={12} sm={6} md={3}>*/}
      {/*    <AppWidgetSummary title="Item Orders" total={1723315} color="warning" icon={'ant-design:windows-filled'} />*/}
      {/*</Grid>*/}

      {/*<Grid item xs={12} sm={6} md={3}>*/}
      {/*    <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12} md={6} lg={12}>*/}
      {/*    <AppWebsiteVisits*/}
      {/*        title="Website Visits"*/}
      {/*        subheader="(+43%) than last year"*/}
      {/*        chartLabels={[*/}
      {/*            '01/01/2003',*/}
      {/*            '02/01/2003',*/}
      {/*            '03/01/2003',*/}
      {/*            '04/01/2003',*/}
      {/*            '05/01/2003',*/}
      {/*            '06/01/2003',*/}
      {/*            '07/01/2003',*/}
      {/*            '08/01/2003',*/}
      {/*            '09/01/2003',*/}
      {/*            '10/01/2003',*/}
      {/*            '11/01/2003',*/}
      {/*        ]}*/}
      {/*        chartData={[*/}
      {/*            {*/}
      {/*                name: 'Team A',*/}
      {/*                type: 'column',*/}
      {/*                fill: 'solid',*/}
      {/*                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],*/}
      {/*            },*/}
      {/*            {*/}
      {/*                name: 'Team B',*/}
      {/*                type: 'area',*/}
      {/*                fill: 'gradient',*/}
      {/*                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],*/}
      {/*            },*/}
      {/*            {*/}
      {/*                name: 'Team C',*/}
      {/*                type: 'line',*/}
      {/*                fill: 'solid',*/}
      {/*                data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],*/}
      {/*            },*/}
      {/*        ]}*/}
      {/*    />*/}
      {/*</Grid>*/}

      {/*<Grid item xs={12} md={6} lg={4}>*/}
      {/*    <AppCurrentVisits*/}
      {/*        title="Current Visits"*/}
      {/*        chartData={[*/}
      {/*            { label: 'America', value: 4344 },*/}
      {/*            { label: 'Asia', value: 5435 },*/}
      {/*            { label: 'Europe', value: 1443 },*/}
      {/*            { label: 'Africa', value: 4443 },*/}
      {/*        ]}*/}
      {/*        chartColors={[*/}
      {/*            theme.palette.primary.main,*/}
      {/*            theme.palette.chart.blue[0],*/}
      {/*            theme.palette.chart.violet[0],*/}
      {/*            theme.palette.chart.yellow[0],*/}
      {/*        ]}*/}
      {/*    />*/}
      {/*</Grid>*/}

      {/*<Grid item xs={12} md={6} lg={8}>*/}
      {/*    <AppConversionRates*/}
      {/*        title="Conversion Rates"*/}
      {/*        subheader="(+43%) than last year"*/}
      {/*        chartData={[*/}
      {/*            { label: 'Italy', value: 400 },*/}
      {/*            { label: 'Japan', value: 430 },*/}
      {/*            { label: 'China', value: 448 },*/}
      {/*            { label: 'Canada', value: 470 },*/}
      {/*            { label: 'France', value: 540 },*/}
      {/*            { label: 'Germany', value: 580 },*/}
      {/*            { label: 'South Korea', value: 690 },*/}
      {/*            { label: 'Netherlands', value: 1100 },*/}
      {/*            { label: 'United States', value: 1200 },*/}
      {/*            { label: 'United Kingdom', value: 1380 },*/}
      {/*        ]}*/}
      {/*    />*/}
      {/*</Grid>*/}

      {/*<Grid item xs={12} md={6} lg={4}>*/}
      {/*    <AppCurrentSubject*/}
      {/*        title="Current Subject"*/}
      {/*        chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}*/}
      {/*        chartData={[*/}
      {/*            { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },*/}
      {/*            { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },*/}
      {/*            { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },*/}
      {/*        ]}*/}
      {/*        chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}*/}
      {/*    />*/}
      {/*</Grid>*/}
    </Grid>
  );
}
