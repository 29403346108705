import * as React from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";

export default function FormDialog({ onClose, onOpen }) {
  const isMobile = useMediaQuery("(max-width:550px)");

  return (
    <>
      <Dialog open={onOpen}>
        <Stack padding={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <DialogTitle>eOHC</DialogTitle>
            <DialogTitle onClick={onClose}>
              <CloseIcon />
            </DialogTitle>
          </Box>

          <Box
            display="flex"
            flexWrap={isMobile ? "wrap" : "nowrap"}
            alignItems={isMobile ? "center" : "normal"}
            justifyContent={isMobile ? "center" : "normal"}
          >
            <img
              src="./images/softwarelogo.js.png"
              alt="img"
              style={{
                height: isMobile ? "150px" : "300px",
                width: isMobile ? "130px" : "260px",
              }}
            />
            <Typography fontSize={isMobile ? "16px" : "auto"}>
              eOHC is specializes in providing enterprise software
              solutions to mid and large-sized businesses. We have experience
              working with industries such as Manufacturing, Banking,
              Electronics, Healthcare, Finance, E-Commerce, Legal, Housing, F&B,
              Technology, Textiles, Agriculture, Construction, Trucking,
              Distribution
            </Typography>
          </Box>
        </Stack>
      </Dialog>
    </>
  );
}
