import React, { Component, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { main } from "../js/main";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "../App";
import healthCheckupService from "../services/healthCheckupService";
import doctorService from "../services/doctorService";
import analyticService from "../services/analyticService";
import { Button } from "@mui/material";
import { textAlign } from "@mui/system";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Form7Report() {
  const location = useLocation();

  const hcId = new URLSearchParams(location.search).get("ehn");
  const auth = useRecoilValue(authAtom);
  const styles = `
  table, th, td {
    border: 1px solid black;  border-collapse: collapse;
    padding: 4px;
    font-size: 13px !important;
  }
  .verticalTableHeader {
       transform: rotate(180deg);
    writing-mode: vertical-rl;
}

@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  } 
}
`;
  const [form7ReportDetails, setForm7ReportDetails] = useState({});
  const [date, setDate] = useState(new Date().toLocaleDateString());
  useEffect(() => {
    getReportData(hcId);
  });

  async function printpage() {
    window.print();
  }

  function getReportData() {
    analyticService.GetForm7Report(hcId).then((res) => {
      if (res.message == "Success") {
        setForm7ReportDetails(res.data);
        if (form7ReportDetails) {
          setDate(
            new Date(
              form7ReportDetails?.form7Details[0]?.checkUpDate
            ).toLocaleDateString()
          );
        }
      } else {
        // alert("Something went wrong" + " : " + res.message);
        toast.error("Something went wrong" + " : " + res.message, {
          position: "top-center",
          toastId: "success1",
        });
      }
    });
  }

  if (form7ReportDetails) {
    return (
      <>
        <div></div>
        <div style={{ float: "right", marginBottom: "5%" }}>
          <Button
            className=""
            variant="contained"
            color="primary"
            onClick={printpage}
            type="button"
          >
            Print
          </Button>
        </div>
        <br></br>
        <div id="section-to-print">
          <div
            style={{
              display: "inline-block !important",
              position: "static !important",
              overflow: "visible !important",
              width: "100% !important" /* styles */,
              // fontSize: "1vmin"
            }}
          >
            <div style={{ width: "100%;", textAlign: "center" }}>
              <div>
                <div>
                  <p>
                    <b>FORM7</b>
                  </p>{" "}
                  <br />
                  <p>
                    (See Rule 18(7) and Schedules Il, Ill, IV, VI, VIII, X, XI,
                    XIll, XIV, XV, XVIL, XVII & XX to Rule 114)
                  </p>
                  <b>Health Register</b>
                  <b>
                    (As per Section 14 of the Factories Act 1948 & Rule 18,
                    Clause 7 of the Maharashtra Factories Rules 1963)
                  </b>
                  <p>
                    In respect of persons employed in occupations declared to be
                    dangerous operation under Sec. 87,{" "}
                  </p>
                  <b>
                    {" "}
                    Name of Certifying Surgeon: {
                      form7ReportDetails?.doctorName
                    }{" "}
                  </b>{" "}
                  <br />
                  <b> Date: {date} </b>
                  {/* <img src={logo}></img> */}
                </div>
              </div>
            </div>
            <br />
            <div style={{ width: "100%", textAlign: "center" }}>
              <table
                id="table-id"
                style={{
                  width: "100%",
                  border: "1px solid black",
                  borderCollapse: "collapse",
                }}
              >
                <thead>
                  <tr>
                    <th className="verticalTableHeader">Sr. No </th>
                    <th className="verticalTableHeader">Works No.</th>
                    <th className="verticalTableHeader">Name of Worker</th>
                    <th className="verticalTableHeader">Sex</th>
                    <th className="verticalTableHeader">Age</th>
                    <th className="verticalTableHeader">
                      Date Of Employment of <br /> present Work
                    </th>
                    <th className="verticalTableHeader">
                      Date Of Leaving Or <br /> transfer To other Work
                    </th>
                    <th className="verticalTableHeader">
                      Reason For Leaving <br /> Transfer
                    </th>
                    <th className="verticalTableHeader">
                      Nature of Job Or <br /> Occupation
                    </th>
                    <th className="verticalTableHeader">
                      Raw Material Or bye <br /> Product Handled
                    </th>
                    <th className="verticalTableHeader">
                      Date of Medical Examination <br /> By Certifying Surgeon
                    </th>
                    <th className="verticalTableHeader">
                      Results Of Medical <br /> Examination
                    </th>
                    <th className="verticalTableHeader">
                      If suspended from work, <br /> state period of suspension{" "}
                      <br /> with detailed reasons.
                    </th>
                    <th className="verticalTableHeader">
                      Certified fit to resume <br /> duty on with signature of{" "}
                      <br /> Certifying Surgeon
                    </th>
                    <th className="verticalTableHeader">
                      If certificate of unfitness or <br /> suspension issued to
                      worker
                    </th>
                    <th className="verticalTableHeader">
                      Signature with date of <br /> Certifying Surgeon.
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {form7ReportDetails?.form7Details?.map((item, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item.employeeId}</td>
                      <td>{item.employeeName}</td>
                      <td>{item.sex}</td>
                      <td>{item.age}</td>
                      <td>
                        {new Date(item.emplymentDate).toLocaleDateString()}
                      </td>
                      <td>
                        {item.dateOfLeaving == null
                          ? " - "
                          : new Date(item.dateOfLeaving).toLocaleDateString()}
                      </td>
                      <td>{item.reasonOfLeaving ?? " - "}</td>
                      <td>{item.occupation}</td>
                      <td>{item.rawMaterialByeProduct}</td>
                      <td>{date}</td>
                      <td>{item.checkUpAdvice}</td>
                      <td>
                        {item.suspendedDate == null
                          ? " - "
                          : new Date(item.suspendedDate).toLocaleDateString()}
                      </td>
                      <td>{item.fitRemark}</td>
                      <td>{item.unfitRemark}</td>
                      <td>
                        {" "}
                        <img
                          style={{ width: "100px", height: "50px" }}
                          src={form7ReportDetails?.sign}
                        ></img>
                        <br />
                        {new Date().toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
          </div>
          <style>{styles}</style>
          <ToastContainer />
        </div>
        <br />
        <br />
        <Button
          className=""
          variant="contained"
          color="primary"
          onClick={printpage}
          type="button"
        >
          Print
        </Button>
      </>
    );
  } else {
    return <></>;
  }
}

export default Form7Report;
