import React, { Component, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { main } from "../js/main";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom } from "../App";
import healthCheckupService from "../services/healthCheckupService";
import doctorService from "../services/doctorService";
import { Button } from "@mui/material";
import { textAlign } from "@mui/system";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function HealthCheckupReport() {
  const location = useLocation();

  const empId = new URLSearchParams(location.search).get("ebp");
  const hcId = new URLSearchParams(location.search).get("ehn");
  const auth = useRecoilValue(authAtom);
  const styles = `
  table, th, td {
    border: 1px solid black;  border-collapse: collapse;
   
  }
  
 
@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  } 
}
`;

  useEffect(() => {
    getPdf();
    fetchAmbulances("logo");
    fetchAmbulances("signature");
    fetchAmbulances("stamp");
    console.log(auth);
  }, []); //
  const [clientDetails, setClientDetails] = useState({});
  const [healthCheckupDetails, setHealthCheckupDetails] = useState({});
  const [testDetails, setTestDetails] = useState([]);
  const [employeeDetails, setEmployeeDetails] = useState({});
  const [visionTestMapping, setVisionTestMapping] = useState({});
  const [logo, setLogo] = useState();
  const [sign, setSign] = useState();
  const [stamp, setStamp] = useState();

  async function printpage() {
    window.print();
  }

  async function getPdf() {
    let res = await healthCheckupService
      .PrintHealthCheckupForm(hcId, empId)
      .then((res) => {
        if (res.message == "Success") {
          console.log(res);

          let testData = res.data.testDetails;
          let newDirectory = Object.values(
            testData.reduce((acc, item) => {
              console.log(acc);
              if (!acc[item.categoryName])
                acc[item.categoryName] = {
                  category: item.categoryName,
                  data: [],
                };
              acc[item.categoryName].data.push(
                new Object({
                  testName: item.testName,
                  testReading: item.testReading,
                  isVision: item.isVission,
                  normalRange: item.normalRange,
                  unit: item.unit,
                })
              );
              return acc;
            }, {})
          );
          res.data.testDetails = newDirectory;

          setEmployeeDetails(res.data.employeeDetails);
          setTestDetails(res.data.testDetails);
          setHealthCheckupDetails(res.data.healthCheckupDetails);
          setClientDetails(res.data.clientDetails);
          setVisionTestMapping(res.data.visionTestMapping);
          // $(targetContainer).html(html);
        } else {
          // alert("Something went wrong" + " : " + res.message);
          toast.error("Something went wrong" + " : " + res.message, {
            position: "top-center",
            toastId: "success1",
          });
        }
      });
  }

  async function fetchAmbulances(type) {
    var imageres = await doctorService.GetFile(auth.userId, type);
    console.log(imageres.data);
    if (type == "logo") setLogo(imageres.data);
    else if (type === "signature") setSign(imageres.data);
    else if (type == "stamp") setStamp(imageres.data);
    // setSign(
  }

  return (
    <>
      <div style={{ float: "right" }}>
        <Button
          className=""
          variant="contained"
          color="primary"
          onClick={printpage}
          type="button"
        >
          Print
        </Button>
      </div>
      <br></br>
      <div id="section-to-print">
        <div
          style={{
            display: "inline-block !important",
            position: "static !important",
            overflow: "visible !important",
            width: "100% !important" /* styles */,
            // fontSize: "1vmin"
          }}
        >
          <div style={{ width: "100%;" }}>
            <div>
              <div style={{ float: "left" }}>
                <img style={{ width: "10%" }} src={logo}></img>
              </div>
              <div style={{ fontWeight: "bold", textAlign: "center" }}>
                <h2>{healthCheckupDetails?.doctorName}</h2>
                <p>
                  {healthCheckupDetails?.doctorAddress}
                  <br />
                  {healthCheckupDetails?.doctorEmail} /{" "}
                  {healthCheckupDetails?.doctorPhone}
                </p>
              </div>
            </div>
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <table
              id="table-id"
              style={{
                width: "100%",
                border: "1px solid black",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <label>Name of the Employee </label>
                  </td>
                  <th colspan="8">
                    <label>{employeeDetails?.fullName}</label>
                  </th>
                </tr>
                <tr>
                  <td>
                    <label>Employee Adderss</label>
                  </td>
                  <th colspan="8">
                    <label>{employeeDetails?.address}</label>
                  </th>
                </tr>
                <tr>
                  <td>
                    <label>Name of the Employer</label>
                  </td>
                  <th colspan="8">
                    <label>{clientDetails?.clientName}</label>
                  </th>
                </tr>
                <tr>
                  <td style={{ height: 15 }} colspan="9"></td>
                </tr>
                <tr>
                  <td colspan="9" style={{ textAlign: "center" }}>
                    <h5>
                      <u>Periodic Medical Examination Report</u>
                    </h5>
                  </td>
                </tr>
                <tr>
                  <td style={{ height: 15 }} colspan="9"></td>
                </tr>
                <tr>
                  <td>Medical Test Date:</td>
                  <th>
                    {new Date(healthCheckupDetails?.testDate).toDateString()}
                  </th>
                  <td>Certificate No:</td>
                  <th colspan="6">{healthCheckupDetails?.certificateId}</th>
                </tr>
                <tr>
                  <td>Age:</td>
                  <th>{employeeDetails?.age}</th>
                  <td>Sex:</td>
                  <th colspan="6">{employeeDetails.sex}</th>
                </tr>
                <tr>
                  <td>Designation:</td>
                  <th>{healthCheckupDetails?.designation}</th>
                  <td>Dep. Name:</td>
                  <td colspan="6"></td>
                </tr>
                <tr>
                  <td style={{ height: 15 }} colspan="9"></td>
                </tr>
                <tr>
                  <td>Height</td>
                  <th>{healthCheckupDetails?.height} </th> <td>Cms</td>
                  <td>Weight</td>
                  <th>{healthCheckupDetails?.weight} </th> <td> Kgs</td>
                  <td>Expected Weight:</td>
                  <th>{healthCheckupDetails?.expectedWeight} </th> <td> kgs</td>
                </tr>
                <tr>
                  <td>B.P</td>
                  <th>
                    {healthCheckupDetails?.bp +
                      "/" +
                      healthCheckupDetails?.bpHigh}{" "}
                  </th>{" "}
                  <td> mmHg</td>
                  <td>Pulse</td>
                  <th>{healthCheckupDetails?.pulse}</th>
                  <td> /min</td>
                  <td>BMI</td>
                  <th>{healthCheckupDetails?.bmi}</th>
                </tr>
                <tr>
                  <td>Past History</td>
                  <th colspan="8">{employeeDetails?.pastHistory}</th>
                </tr>
                <tr>
                  <td>Present History</td>
                  <th colspan="8">{healthCheckupDetails?.presentHistory}</th>
                </tr>
                <tr>
                  <td>Family History</td>
                  <th colspan="8">{healthCheckupDetails?.familyHistory}</th>
                </tr>
                <tr>
                  <td>Addication</td>
                  <th colspan="8">{healthCheckupDetails?.otherAddictions}</th>
                </tr>
                <tr>
                  <td>Tobacco:</td>
                  <th>
                    {healthCheckupDetails.isTobacoChewer == true ? "Yes" : "No"}
                  </th>
                  <td>Smoking:</td>
                  <th>
                    {healthCheckupDetails.isSmoking == true ? "Yes" : "No"}
                  </th>
                  <td>Drink:</td>
                  <th>
                    {healthCheckupDetails.isAlcoholic == true ? "Yes" : "No"}
                  </th>
                </tr>
                <tr>
                  <td style={{ height: 15 }} colspan="9"></td>
                </tr>
                {testDetails.map((item, i) => (
                  <>
                    <tr>
                      <th
                        style={{ height: 15, fontWeight: "bold" }}
                        colspan="9"
                      >
                        <u>{item?.category}</u>
                      </th>
                    </tr>
                    <tr>
                      <th colspan="1"></th>
                      <th>RESULT</th>
                      <th>UNIT</th>
                      <th colspan="6">NORMAL RANGE</th>
                    </tr>
                    {item?.data.map((item1, i) =>
                      item1?.isVision == false ? (
                        <tr>
                          <td colspan="1">{item1?.testName}</td>
                          <th>{item1?.testReading}</th>
                          <th>{item1?.unit}</th>
                          <th colspan="6">{item1?.normalRange}</th>
                        </tr>
                      ) : visionTestMapping != null ? (
                        <>
                          <tr>
                            <td>Vision</td>
                            <td colSpan={2}>Without Glass</td>
                            <td>Vision</td>
                            <td colSpan={2}>With Glass </td>
                            <td>Color Blindness</td>
                            <th colSpan={2}>
                              {visionTestMapping?.colorBlind == true
                                ? "Yes"
                                : "No"}
                            </th>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Right</td>
                            <td>Left</td>
                            <td></td>
                            <td>Right</td>
                            <td>Left</td>
                            <td colSpan={3}>Vision Remark</td>
                          </tr>
                          <tr>
                            <td>Near</td>
                            <th>{visionTestMapping?.woGlassRighNear}</th>
                            <th>{visionTestMapping?.woGlassLeftNear}</th>
                            <td>Near</td>
                            <th>{visionTestMapping?.woGlassRightFar}</th>
                            <th>{visionTestMapping?.woGlassLeftFar}</th>
                            <th rowSpan={2} colSpan={3}>
                              {item1.testReading}
                            </th>
                          </tr>
                          <tr>
                            <td>Far</td>
                            <th>{visionTestMapping?.wGlassRightNear}</th>
                            <th>{visionTestMapping?.wGlassLeftNear}</th>
                            <td>Far</td>
                            <th>{visionTestMapping?.wGlassRightFar}</th>
                            <th>{visionTestMapping?.wGlassLeftFar}</th>
                          </tr>
                        </>
                      ) : (
                        ""
                      )
                    )}
                  </>
                ))}
                <tr>
                  <th>
                    Vaccination : <br /> {healthCheckupDetails?.vaccination}
                  </th>
                  <th>Cholera</th>
                  <th>
                    {healthCheckupDetails?.cholera == true ? "Yes" : "No"}
                  </th>
                  <th>Typhoid</th>
                  <th>
                    {healthCheckupDetails?.typhoid == true ? "Yes" : "No"}
                  </th>
                </tr>
                <tr>
                  <th>Results</th>
                  <th colspan="8">{healthCheckupDetails?.result}</th>
                </tr>
                <tr>
                  <th>Advice</th>
                  <th colspan="8">{healthCheckupDetails?.advice}</th>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div style={{ marginTop: "20px", marginL: "1%" }}>
            <div>
              <b>{healthCheckupDetails?.doctorName}</b>
            </div>
            <div>
              <b>({healthCheckupDetails?.doctorSpeciality})</b>
            </div>
            <div>
              {" "}
              <img
                style={{ width: "100px", height: "50px" }}
                src={sign}
              ></img>{" "}
            </div>
          </div>
        </div>
        <style>{styles}</style>
      </div>
      <br />
      <br />
      <Button
        className=""
        variant="contained"
        color="primary"
        onClick={printpage}
        type="button"
      >
        Print
      </Button>
      <ToastContainer />
    </>
  );
}

export default HealthCheckupReport;
